import React, { Component } from 'react'
import cookie from 'react-cookies'
import { Redirect } from 'react-router-dom'
import {
    Button,
    ButtonGroup,
    Col,
    Collapse,
    Container,
    Row,
    Table,
    ButtonToolbar
} from 'react-bootstrap'
import { urlBase } from '../_utiilities/data.json'
import { Documentation, AutoComplete, Loading, VerticallyCenteredModal, GetCurrency, getQueryObject } from '../_utiilities/functions_react'

let newList = {id:"New", name: "New List Name", items:[],currency:[], professions:[]};

export class Account_Verification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            session: cookie.load('session'),
            error:false,
            errorCode:undefined,
            account: undefined,
            nameBox:undefined,
            defaultList: undefined,
            selectedGameAccount:undefined,
            open:{advanced:true, items:true, currency:false,professions:false, bulk:false},
            bulk:{items:"", currency:"", professions:""},
            number:{items:1, currency:1, professions:1},
            selected:{items:{name:"Add items", id:0}, currency:{name:"Add Currencies", id:0}, professions:{name:"Add Professions", id:0}},
            dropdown:{items:[], currency:[], professions:[]},
            data:{items:undefined, currency:undefined, professions:undefined},
            query: getQueryObject(window.location.hash),
            dataTypes:["items","currency","professions"],
            template:{questioned:false,using:false}
        }

    }

    async loadData(url,extra) {return await fetch(url,extra).then(response => {return response.json()}).catch((err) => {console.log(this.props.url, err.toString()); return {result:"error", error:"could not fetch"};})}

    async loadAccount(tmp){
        let account = await this.loadData(urlBase.account+ "/v1/website/account",{method: 'GET', headers: {'Content-Type': 'application/json', session: this.state.session}});
        if(account.result === "success"){
            tmp.account = account.account;
            if(typeof tmp.account.verification === "undefined"){tmp.account.verification = {}}
            let lists = tmp.account.verification;
            let listKeys = Object.keys(lists);
            // gameAccounts
            // selectedGameAccount
            if(typeof tmp.defaultList === "undefined"){
                if(typeof this.state.defaultList === "undefined"){
                    tmp.defaultList = listKeys[0];
                }else{
                    if(tmp.deleted){
                        tmp.defaultList = listKeys[0]
                    }else{
                        tmp.defaultList = this.state.defaultList
                    }
                }
            }
            delete tmp.deleted;
            if(typeof tmp.defaultList === "undefined"){
                tmp.selectedList = newList;
            }else{
                tmp = await this.onList(tmp,tmp.defaultList, tmp.account)
            }
            let gameAccounts = Object.keys(tmp.account.gameAccounts)
            if(gameAccounts.length >0){tmp.selectedGameAccount = gameAccounts[0]}
        }else{
            tmp.error = true;
            tmp.errorCode = account.error;
        }
        if(account.result ==="error"){
            if(account.error ==="Invalid session"){
                cookie.remove('session');
                tmp.session = undefined;
            }
        }
        return tmp;
    }

    async componentDidMount() {
        let rawItemData = await this.loadData(urlBase.parser + "/v1/items/json?fields=img,id,name&beautify=min&filter=");
        let rawCurrenciesData = await this.loadData(urlBase.parser + "/v1/currencies?beautify=min&fields=id,img,name");
        let professionsRaw = [{"id":1,"name":"Armorsmith","img":"https://render.guildwars2.com/file/2952B92FA93C03A5281F94D223A4CE4C7E0B0906/102461.png"}, {"id":2,"name":"Artificer","img":"https://render.guildwars2.com/file/0D75999D6DEA1FDFF9DB43BBC2054B62764EB9A0/102463.png"}, {"id":3,"name":"Chef","img":"https://render.guildwars2.com/file/424E410B90DE300CEB4A1DE2AB954A287C7A5419/102465.png"}, {"id":4,"name":"Huntsman","img":"https://render.guildwars2.com/file/0C91017241F016EF35A2BCCE183CA9F7374023FC/102462.png"}, {"id":5,"name":"Jeweler","img":"https://render.guildwars2.com/file/F97F4D212B1294052A196734C71BCE42E199735B/102458.png"}, {"id":6,"name":"Leatherworker","img":"https://render.guildwars2.com/file/192D1D0D73BA7899F1745F32BAC1634C1B4671BF/102464.png"}, {"id":7,"name":"Scribe","img":"https://render.guildwars2.com/file/F95DFA3FBDCC9E9F317551A903E5A2A6DF1CC7E3/1293677.png"}, {"id":8,"name":"Tailor","img":"https://render.guildwars2.com/file/0EB64958BE48AB9605DD56807713215095B8BEED/102459.png"}, {"id":9,"name":"Weaponsmith","img":"https://render.guildwars2.com/file/AEEF1CF774EE0D5917D5E1CF3AAC269FEE5EC03A/102460.png"}]

        let tmp = {};
        tmp.dropdown = this.state.dropdown
        tmp.data = this.state.data

        tmp.dropdown.items = this.formatDropdown(rawItemData)
        tmp.dropdown.currency = this.formatDropdown(rawCurrenciesData)
        tmp.dropdown.professions = this.formatDropdown(professionsRaw)

        tmp.data.items = {}
        for(let i=0;i<rawItemData.length;i++){
            tmp.data.items[rawItemData[i].id] = rawItemData[i]
        }

        tmp.data.currency = {}
        for(let i=0;i<rawCurrenciesData.length;i++){
            tmp.data.currency[rawCurrenciesData[i].id] = rawCurrenciesData[i]
        }

        tmp.data.professions = {}
        for(let i=0;i<professionsRaw.length;i++){
            tmp.data.professions[professionsRaw[i].id] = professionsRaw[i]
        }

        tmp = await this.loadAccount(tmp)
        this.setState(tmp)
    }

    async onList(tmp,list, account){
        let result = await this.loadData(urlBase.account + "/v1/verification/management/lists", {method: 'GET', headers: {'Content-Type': 'application/json', session: this.state.session, listID:list}});
        if(result.result ==="success"){
            tmp.selectedList = result.success
            tmp.selectedList.name = account.lists[list]
            tmp.open = this.state.open

            for(let i=0;i<this.state.dataTypes.length;i++){
                if(tmp.selectedList[this.state.dataTypes[i]].length >0){
                    tmp.open[this.state.dataTypes[i]] = true
                }
            }

        }
        return tmp;
    }

    handleListSelection = async (event) => {
        let tmp = {};
        tmp.defaultList = event.target.value;
        if(tmp.defaultList !== "new"){
            tmp = await this.onList(tmp,tmp.defaultList,this.state.account);
        }else{
            tmp.selectedList = newList;
        }
        this.setState(tmp);
    }

    getDataFromID = (id, items) =>{
        id = id-0;
        let data = {};
        let result = items[id]
        if(typeof result !== "undefined"){
            data = result
        }
        return data;
    }

    async submitData(tmp,selectedList){
        if(typeof this.state.selectedList !== "undefined"){
            let sorting = "new";
            if(selectedList.id !== "New"){ sorting = "update"}else{ selectedList.user = this.state.selectedGameAccount}
            let submitUrl = urlBase.account + "/v1/verification/management/" + sorting;

            let options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', session:this.state.session},
                body:JSON.stringify(selectedList)
            };

            await fetch(submitUrl, options)
                .then(async (response)=> {
                    let body = await response.json();
                    if (body.result === "success") {
                        tmp.selectedItem = {name:"Add items", id:0}
                        tmp.numberBox = 1
                        tmp.nameBox = "";
                        tmp.defaultList = body.success;
                        tmp = await this.loadAccount(tmp);
                        //alert("Success")
                    }
                    if (body.result === "error") {
                        alert(body.error)
                    }
                })
                .catch((err) => {alert(err)});
        }
        return tmp;
    }

    handleDeleteList = async () => {
        let tmp = {};
        if (window.confirm('Are you sure you wish to delete this list?')){
            let submitUrl = urlBase.account + "/v1/verification/management/delete";
            let options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', session:this.state.session},
                body:JSON.stringify(this.state.selectedList)
            };
            await fetch(submitUrl, options)
                .then(async (response)=> {
                    let body = await response.json();
                    if (body.result === "success") {
                        tmp.bulkBox = undefined;
                        tmp.nameBox = undefined;
                        tmp.defaultList = undefined
                        tmp.deleted = true
                        tmp = await this.loadAccount(tmp);
                    }
                    if (body.result === "error") {
                        alert(body.error)
                    }
                })
                .catch((err) => {alert(err)});
        }

        this.setState(tmp)
    }

    handleChangeName = (event) => {
        let name =  event.target.value;
        let selectedList = this.state.selectedList;
        selectedList.name = name;
        this.setState({nameBox:name, selectedList:selectedList });
    }

    handleSubmit = async(event) => {
        event.preventDefault()
        let tmp = {}
        let list = this.state.selectedList

        // tmp.template = {questioned:true,using:true}
        if(this.state.template.questioned && this.state.template.using){
            let dataTypes = this.state.dataTypes
            tmp.open = this.state.open
            tmp.open.advanced = true
            for(let i=0;i<dataTypes.length;i++){
                if(typeof this.state.query[dataTypes[i]] === "undefined"){continue}
                tmp.open[dataTypes[i]] = true
                let bulkBox = this.state.query[dataTypes[i]].split(",")
                for(let j=0;j<bulkBox.length;j++){
                    let data = bulkBox[j].split("x")
                    if(!isNaN(data[0]-0) && !isNaN(data[1]-0) ){
                        if(typeof this.state.data[dataTypes[i]][data[0]] !== undefined && data[0] > 0 && data[1] > 0){
                            list[dataTypes[i]].push({id:data[0]-0, qty:data[1]-0})
                        }
                    }
                }
            }
        }

        tmp = await this.submitData(tmp,list);
        this.setState(tmp);
    }

    addRowItems = async (group) =>{
      if(this.state.selected[group].id >0){
        let list = this.state.selectedList
        list[group].push({id:this.state.selected[group].id, qty:this.state.number[group]})
        let tmp = await this.submitData({},list);
        this.setState(tmp)
      }
    }

    myCallback = ([dataFromChild,identifier]) => {
        let tmp = {};
        let [id,name] = this.getIDFromMerged(dataFromChild, identifier)
        tmp.selected = this.state.selected
        tmp.selected[identifier] = {name:name, id:id}
        if(id>0){
            this.setState(tmp)
        }
    }

    formatDropdown = (dropdown) => {
        for(let i=0;i<dropdown.length; i++){
            let tmp = "";
            if(typeof dropdown[i].name !== "undefined"){
                tmp = tmp +"Name: "+ dropdown[i].name;
            }
            if(typeof dropdown[i].id !== "undefined"){
                tmp = tmp +"\t ID: "+ dropdown[i].id;
            }
            dropdown[i].merged = tmp;
        }
        return dropdown;
    }

    getIDFromMerged = (merged, group) => {
        let dataArray = this.state.dropdown[group]
        for(let i=0;i<dataArray.length;i++){
            if(merged === dataArray[i].merged){
                return [dataArray[i].id,dataArray[i].name]
            }
        }
        return [0,"Error"];
    }

    handleDeleteItem = async(deleteID, group) => {
        let tmp = {}
        tmp.selectedList = this.state.selectedList
        tmp.selectedList[group] = tmp.selectedList[group].filter(item => item !== deleteID);
        tmp = await this.submitData(tmp,tmp.selectedList)
        this.setState(tmp)
    }

    // Reusable for all inputs
    onChange = e => {
        let { target: { value, name }, } = e
        if(name.indexOf(".") === -1){
            this.setState({ [name]: value })
        }else{
            let subElements = name.split(".")
            let element = this.state[subElements[0]]
            if(subElements[0] === "number"){
                if(isNaN(value -0)){
                    if(value !== ""){
                        value = 0
                    }
                }
                value = value-0
            }
            if(subElements.length === 2 ){
                element[subElements[1]] = value
            }
            if(subElements.length === 3 ){
                element[subElements[1]][subElements[2]] = value
            }
            if(subElements.length === 4 ){
                element[subElements[1]][subElements[2]][subElements[3]] = value
            }
            this.setState({[subElements[0]]: element})
        }

    }

    addBulk = async(e) => {
        e.preventDefault()
        let { target: { name } } = e
        ///*
        let tmp = {}
        tmp.selectedList = this.state.selectedList
        tmp.bulk = this.state.bulk
        let bulkBox = this.state.bulk[name].split(",")
        for(let i=0;i<bulkBox.length;i++){
            let data = bulkBox[i].split("x")
            if(!isNaN(data[0]-0) && !isNaN(data[1]-0) ){
                if(typeof this.state.data[name][data[0]] !== undefined && data[0] > 0 && data[1] > 0){
                    tmp.selectedList[name].push({id:data[0]-0, qty:data[1]-0})
                }
            }
        }
        tmp = await this.submitData(tmp,tmp.selectedList)
        tmp.bulk[name] = ""
        this.setState(tmp)
        //*/
    }

    getBulkStr = (group) =>{
        let bulkOutput = []
        for(let i=0;i<this.state.selectedList[group].length;i++){
            bulkOutput.push(this.state.selectedList[group][i].id + "x" + this.state.selectedList[group][i].qty)
        }
        return bulkOutput.join(",")
    }

    getBulk = (group) =>{
        return <Container>
            <Row>
                <Col>
                    <form onSubmit={this.addBulk} name={group}>
                        <input
                          style={{width:"400px"}}
                          name={"bulk."+group}
                          type="text"
                          value={this.state.bulk[group]}
                          onChange={this.onChange}
                          placeholder={"Comma separated list of ID's and Quantities"}
                        />
                        &nbsp;
                        <input type="submit" value={"Add Bulk - "+ this.capitalize(group)}/>
                    </form>
                </Col>
            </Row>
            <Row>
                <Col>
                    {this.capitalize(group)}: {this.getBulkStr(group)}
                </Col>
            </Row>
        </Container>
    }

    getSelector = (group) =>{
        let startRender = 2
        let limit = 30
        if(group !== "items"){
            startRender = -1
            limit = -1
        }

        return <Container style={{width:"500px"}}>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <input
                      name={"number."+ group}
                      value={this.state.number[group]}

                      style={{width:"50px"}}
                      type="number"
                      onChange={this.onChange}
                    />
                </Col>
                <Col >
                    <AutoComplete
                      identifier={group}
                      array={this.state.dropdown[group]}
                      placeholder={this.state.selected[group].name}
                      callbackToParent={this.myCallback}
                      sorter={"merged"}
                      startRenderString={startRender}
                      limit={limit}
                    />
                </Col>
                <Col md="auto">
                    <input type="submit" value={"Add "+ this.capitalize(group)} onClick={async()=>{await this.addRowItems(group)}}  />
                </Col>
            </Row>
        </Container>
    }

    getTable = (group) =>{
        let square = 25
        let rows = []
        if(this.state.selectedList[group].length >0){
            let data = this.state.selectedList[group]
            let dataSource = this.state.data[group]

            for(let i=0;i<data.length;i++){
                let thisItemData = this.getDataFromID(data[i].id, dataSource)
                let quantity = data[i].qty
                if(group === "currency" && thisItemData.name === "Coin"){
                    quantity = <GetCurrency number={quantity} size={25} />
                }else{
                    quantity = quantity.toLocaleString()
                }

                rows.push(<tr key={i + data[i].id + group}>
                    <td className={"left"} style={{width:"30px"}} >
                        <img
                          key={thisItemData.name}
                          style={{width: square,height:square}}
                          src={thisItemData.img}
                          title={thisItemData.name}
                          alt={thisItemData.name}
                        />
                    </td>
                    <td className={"left"}>{quantity}</td>
                    <td className={"left"}>{thisItemData.name}</td>
                    <td className={"left"} style={{width:"70px"}}>
                        <input type="submit" value="Delete" onClick={async()=>{await this.handleDeleteItem(data[i],group)}} />
                    </td>
                </tr>)
            }
        }

        return <div>
            <Table responsive size={"sm"} striped={true} style={{width:"500px", align:"center", margin:"auto"}} >
                <thead>
                <tr key={group}>
                    <th className={"left"} style={{width:"30px"}}>Img</th>
                    <th className={"left"} style={{width:"70px"}}>Quantity</th>
                    <th className={"left"}>Name</th>
                    <th className={"left"} style={{width:"70px"}}>Delete</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
        </div>
    }

    getTemplate = () =>{
        let show = false
        let first = true
        let url = urlBase.site +"/verification_management?"

        for(let i=0;i<this.state.dataTypes.length;i++){
            if(this.state.selectedList[this.state.dataTypes[i]].length >0){
                show = true
                if(first){
                   first = false
                }else{
                    url += "&"
                }
                url += this.state.dataTypes[i] +"="+ this.getBulkStr(this.state.dataTypes[i])
            }
        }

        if(show){return <span>Template: <a href={url} target='_blank' rel="noopener noreferrer" >{url}</a></span>}
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    render() {
        if(typeof this.state.session === "undefined"){return <Redirect to='/login'  />;}
        if(typeof this.state.data.items === "undefined"){return <Loading />}

        if(typeof this.state.query.items !== "undefined" || typeof this.state.query.currency !== "undefined" || typeof this.state.query.professions !== "undefined"){
            if(!this.state.template.questioned){
                let buttons = <ButtonToolbar
                  className="justify-content-between"
                >
                    <ButtonGroup>
                        <Button
                          variant="info"
                          onClick={()=> {
                              this.setState({
                                  selectedList: newList,
                                  defaultList: "new",
                                  template: {questioned:true,using:true}
                              })
                          }}>
                            Use Template
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Button
                          variant="info"
                          onClick={()=> {
                              this.setState({
                                  template: {questioned:true,using:false}
                              })
                          }}
                        >
                            No Template
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>

                return <VerticallyCenteredModal
                  size="sm"
                  header={"Template Available"}
                  body={"Do you want to create a new list to use the template?"}
                  closeButton={true}
                  show={!this.state.template.questioned}
                  buttons={buttons}
                />
            }
        }

        let info, error, listsDropdown, nameBox, advanced, urlInfo, tables

        // Displaying errors
        if(this.state.error === true){
            error =  <p>Error: {this.state.errorCode}</p>
        }

        if(this.state.account !== undefined){
            listsDropdown = [];
            let lists = this.state.account.verification;
            let listKeys = Object.keys(lists);

            listsDropdown.push(<option key={-1} value={"new"} >New List</option>);
            for(let i=0;i<listKeys.length;i++){
                listsDropdown.push(
                    <option key={i} value={listKeys[i]}>{lists[listKeys[i]]}</option>
                )
            }

            info = (
                <select
                    value={this.state.defaultList}
                    onChange={this.handleListSelection}>
                    {listsDropdown}
                </select>
            );
        }

        if(this.state.selectedList !== undefined){
            if(this.state.selectedList.id !== "New"){
                info = <div>
                    <select
                      value={this.state.defaultList}
                        onChange={this.handleListSelection}>
                          {listsDropdown}
                      </select>
                      &nbsp;
                      <input type="submit" value="Delete List" onClick={async()=>{await this.handleDeleteList()}}  />
                      &nbsp;
                      <input
                        type="submit"
                        onClick={() => {
                            let open = this.state.open
                            open.advanced = !this.state.open.advanced
                            this.setState({open:open})
                        }}
                        value={(this.state.open.advanced) ? "Advanced - Open":"Advanced - Closed"}
                      />
                  </div>

                advanced = <Collapse in={this.state.open.advanced}  >
                    <div id="example-collapse-text">
                        <Container  style={{maxWidth:"800px"}}>
                            <Row>
                                <Col>
                                    <input
                                      type="submit"
                                      onClick={() => {
                                          let open = this.state.open
                                          open.bulk = !this.state.open.bulk
                                          this.setState({open:open})
                                      }}
                                      value={(this.state.open.bulk) ? "Bulk - Open":"Bulk - Closed"}
                                    />
                                </Col>
                                <Col>
                                    <input
                                      type="submit"
                                      onClick={() => {
                                          let open = this.state.open
                                          open.items = !this.state.open.items
                                          this.setState({open:open})
                                      }}
                                      value={(this.state.open.items) ? "Items - Open":"Items - Closed"}
                                    />
                                </Col>
                                <Col>
                                    <input
                                      type="submit"
                                      onClick={() => {
                                          let open = this.state.open
                                          open.currency = !this.state.open.currency
                                          this.setState({open:open})
                                      }}
                                      value={(this.state.open.currency) ? "Currency - Open":"Currency - Closed"}
                                    />
                                </Col>
                                <Col>
                                    <input
                                      type="submit"
                                      onClick={() => {
                                          let open = this.state.open
                                          open.professions = !this.state.open.professions
                                          this.setState({open:open})
                                      }}
                                      value={(this.state.open.professions) ? "Professions - Open":"Professions - Closed"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.getTemplate()}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Collapse>

                let url = urlBase.site +"/verification?list=" + this.state.selectedList.id

                urlInfo = <div>
                    For {this.state.selectedList.gameID}
                    <br />
                    Share this link: <a href={url} target='_blank' rel="noopener noreferrer" >{url}</a>
                </div>

                tables = []
                if(this.state.open.items){
                    if(this.state.open.bulk){
                        tables.push(this.getBulk("items"))
                    }
                    tables.push(<br />)
                    tables.push(this.getSelector("items"))
                    tables.push(this.getTable("items"))
                    tables.push(<hr />)
                }

                if(this.state.open.currency){
                    if(this.state.open.bulk){
                        tables.push(this.getBulk("currency"))
                    }
                    tables.push(<br />)
                    tables.push(this.getSelector("currency"))
                    tables.push(this.getTable("currency"))
                    tables.push(<hr />)
                }
                if(this.state.open.professions){
                    if(this.state.open.bulk){
                        tables.push(this.getBulk("professions"))
                    }
                    tables.push(<br />)
                    tables.push(this.getSelector("professions"))
                    tables.push(this.getTable("professions"))
                    tables.push(<hr />)
                }
            }else{
                let characterDropdownArray = []
                let gameAccounts = Object.keys(this.state.account.gameAccounts)

                for(let i=0;i<gameAccounts.length;i++){
                    characterDropdownArray.push(
                      <option key={i} value={gameAccounts[i]}>{gameAccounts[i]}</option>
                    )
                }

                let characterDropdown = <select
                  value={this.state.selectedGameAccount}
                  name={"selectedGameAccount"}
                  onChange={this.onChange}
                  >
                    {characterDropdownArray}
                </select>

                nameBox = <div>
                    <form onSubmit={this.handleSubmit}>
                        <input
                          style={{width:"25%"}}
                          type="text"
                          value={this.state.nameBox}
                          onChange={this.handleChangeName}
                          placeholder={this.state.selectedList.name}
                        />
                        &nbsp;
                        {characterDropdown}
                        &nbsp;
                        <input type="submit" value="Create List"/>
                    </form>
                    <br />
                </div>
            }
        }

        return <div>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/management#verification-lists"} />
            <br/>
            {info}
            <br />
            {error}
            <br />
            {nameBox}
            {urlInfo}
            <hr />
            {advanced}
            <hr />
            {tables}
        </div>
    }
}