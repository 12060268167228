import React, { Component } from 'react'
import LZUTF8 from "lzutf8";
import { urlBase } from '../_utiilities/data.json'
import { Documentation, AutoComplete, Loading, SilverTable, GetCurrency, getQueryObject, } from '../_utiilities/functions_react'
import { filterGeneral, sortGeneral } from '../_utiilities/functions'

let digits = {
    percentage:{min:3, max:3},
    number:{min:0, max:2}
};
export class Stats_Viewer extends Component {
    constructor(props) {
        super(props);
        let tmp = {
            itemData: [],
            statsAvailable: [],
            selectedID: -1,
            selectedOptions: [],
            selectedData:[],
            folded:{
                col_0: false,
                col_1: false,
                col_2: true,
                col_3: false,
                col_4: false,
                col_5: true,
                col_6: true,
                col_7: true,
                col_8: true,
                col_9: true,
                col_10: true,
                col_11: true
            },
            selectedActivity:"",
            defaultFilter:[],
            filter: []
        };

        let query = getQueryObject(window.location.hash);
        if(typeof query["basic"] !== "undefined") {
            let data = query["basic"];
            data = data.split(",");
            tmp.selectedID = data[0]-0;
            tmp.selectedActivity = data[1];
        }
        if(typeof query["report"] !== "undefined") {
            let data = JSON.parse(LZUTF8.decompress(query["report"], {inputEncoding: "Base64"}));
            tmp.selectedID = data.selectedID;
            tmp.selectedActivity = data.selectedActivity
        }
        
        this.state = tmp;
    }

    async componentDidMount() {
        let tmp = {};
        let rawItemData = await this.loadData(urlBase.parser + "/v1/items/json?fields=vendor_value,type,img,id,name,buy_price,sell_price,description,rarity&beautify=min&filter=");
        tmp.itemData = {};
        for(let i=0;i<rawItemData.length;i++){
            tmp.itemData[rawItemData[i].id] = rawItemData[i];
        }

        let gems = await this.loadData("https://api.guildwars2.com/v2/commerce/exchange/gems?quantity=100");
        tmp.gemPrices = gems.quantity/100;
        tmp.activityAvailable = await this.loadData(urlBase.stats + "/v1/general?beautify=min");
        if(this.state.selectedActivity !== ""){
            tmp.activityIDS = await this.loadData(urlBase.stats + "/v1/general/" + this.state.selectedActivity +"?beautify=min");
            if(this.state.selectedID !== -1){
                tmp.selectedOptions = await this.loadData(urlBase.stats + "/v1/general/" + this.state.selectedActivity + "/"+ this.state.selectedID +"?beautify=min");
                tmp.tableData = this.getTableData(tmp.selectedOptions, tmp.itemData, tmp.gemPrices);
            }
        }
        this.setState(tmp);
    }

    async loadData(url) {
        return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))
    }

    getDataFromID =(id, items)=> {
        id = id-0;
        let data = {};
        let result = items[id];
        if(typeof result !== "undefined"){
            data = result
        }
        return data;
    }

    getIDFromMerged = (input) =>{
        return input.slice(input.indexOf(" - ")+3)-0;
    }
    callbackItemSelection = async (dataFromChild) => {
        if(dataFromChild !== "") {
            let tmp = {};
            tmp.selectedID = this.getIDFromMerged(dataFromChild);
            tmp.selectedOptions = await this.loadData(urlBase.stats + "/v1/general/" + this.state.selectedActivity + "/"+ tmp.selectedID +"?beautify=min");
            tmp.tableData = this.getTableData(tmp.selectedOptions, this.state.itemData, this.state.gemPrices);
            this.setState(tmp)
        }
    };

    callbackActivitySelection = async (dataFromChild) => {
        if(dataFromChild !== "") {
            let tmp = {};
            tmp.selectedActivity = dataFromChild;
            tmp.activityIDS = await this.loadData(urlBase.stats + "/v1/general/" + tmp.selectedActivity + "?beautify=min");
            tmp.selectedID = -1;
            this.setState(tmp)
        }
    };

    getTableData = (data, itemData, gemPrices) => {
        let allIDs = [];
        let allItems = [];
        let total = {
            user:"Total",
            itemQuantity: 0,
            totalValue0:0,
            totalValue:0,
            iso:"-",
            level:"-",
            mf:"-",
            imputValue0:0,
            imputValue:"",
            differenceValue0:0,
            differenceValue:"",

            imputValueNow0:0,
            totalValueNow0:0,
            differenceValueNow0:0
        };

        for(let i=0;i<data.length;i++){
            let tmp = {};
            let comsumedItemData = this.getDataFromID(data[i].consumed.id -0, itemData);

            tmp.user = data[i].account;
            tmp.salvageKit = data[i].salvageKit;
            tmp.itemQuantity = data[i].consumed.quantity * -1;
            tmp.stdv = "±"+(1/Math.pow(tmp.itemQuantity, 0.5)).toLocaleString(undefined, {style: "percent",minimumFractionDigits:2, maximumFractionDigits: 2});
            total.itemQuantity += tmp.itemQuantity;
            tmp.imputValue0 = tmp.itemQuantity * data[i].consumed.price || 0;
            total.imputValue0 += tmp.imputValue0;
            tmp.imputValue = tmp.imputValue0
            total.imputValue = total.imputValue0

            tmp.imputValueNow0 = tmp.itemQuantity * comsumedItemData.sell_price || 0;
            if(comsumedItemData.name === "Black Lion Chest"){
                tmp.imputValueNow0 = tmp.itemQuantity * (comsumedItemData.sell_price + (gemPrices*84))|| 0;
            }
            total.imputValueNow0 += tmp.imputValueNow0;
            tmp.imputValueNow = tmp.imputValueNow0
            total.imputValueNow = total.imputValueNow0

            tmp.iso = data[i].iso;
            tmp.level = data[i].level;
            tmp.mf = data[i].mf;
            let totalValue0 = 0;
            let totalValueNow0 = 0;
            tmp.totalValue = "";
            let items = data[i].items;
            for(let j=0;j<items.length;j++){
                let item = items[j].id;

                let thisItemData = this.getDataFromID(item, itemData);
                let priceNow = 0;
                let thisPriceVendor = 0;
                if(typeof thisItemData.vendor_value !== "undefined"){
                    thisPriceVendor = thisItemData.vendor_value;
                }
                if(typeof thisItemData.sell_price === "undefined" && typeof thisItemData.vendor_value !== "undefined"){
                    priceNow = thisPriceVendor;
                }
                if(typeof thisItemData.sell_price !== "undefined"){
                    priceNow = thisItemData.sell_price;
                }

                let quantity  = items[j].quantity;
                let price  = items[j].price || thisPriceVendor;
                let value = quantity*price;
                let valueNow = quantity*priceNow;
                if(allIDs.indexOf(item) === -1){
                    allIDs.push(item)
                }
                if(typeof total[item + "Quantity"] === "undefined"){
                    total[item + "Quantity"] = 0;
                    total[item + "Price"] = 0;
                    total[item + "Value0"] = 0;
                    total[item + "ValueNow0"] = 0;
                }
                if(typeof tmp[item + "Quantity"] === "undefined"){
                    tmp[item + "Quantity"] = 0;
                    tmp[item + "Price"] = 0;
                }
                total[item + "Quantity"] += quantity;
                total[item + "Price"] += price;
                total[item + "Value0"] += value;
                total.totalValue0  += value;
                total[item + "Value"] = total[item + "Value0"]

                tmp[item + "Quantity"] += quantity;
                tmp[item + "Price"] += price;
                totalValue0 += value;

                tmp[item + "Value"] = value
                tmp[item + "PerQuantity"] = (quantity/tmp.itemQuantity).toLocaleString(undefined, {style: "percent",minimumFractionDigits:digits.percentage.min, maximumFractionDigits: digits.percentage.max});

                total[item + "ValueNow0"] += valueNow;
                total[item + "ValueNow"] = total[item + "ValueNow0"]
                total.totalValueNow0  += valueNow;
                totalValueNow0 += valueNow;
                tmp[item + "ValueNow"] = valueNow
            }
            for(let j=0;j<items.length;j++){
                let item = items[j].id;

                let thisItemData = this.getDataFromID(item, itemData);
                let priceNow = 0;
                let thisPriceVendor = 0;
                if(typeof thisItemData.vendor_value !== "undefined"){
                    thisPriceVendor = thisItemData.vendor_value;
                }
                if(typeof thisItemData.sell_price === "undefined" && typeof thisItemData.vendor_value !== "undefined"){
                    priceNow = thisPriceVendor;
                }
                if(typeof thisItemData.sell_price !== "undefined"){
                    priceNow = thisItemData.sell_price;
                }

                let quantity  = items[j].quantity;
                let price  = items[j].price || thisPriceVendor;
                let value = quantity*price;
                let perValue = value/totalValue0;
                tmp[item + "PerValue"] = perValue.toLocaleString(undefined, {style: "percent",minimumFractionDigits:digits.percentage.min, maximumFractionDigits: digits.percentage.max});
                tmp.totalValue = totalValue0
                tmp.differenceValue = totalValue0 - tmp.imputValue0
                tmp.differenceValueTax = (totalValue0 * 0.85) - tmp.imputValue0

                let valueNow = quantity*priceNow;
                let perValueNow = valueNow/totalValueNow0;
                tmp[item + "PerValueNow"] = perValueNow.toLocaleString(undefined, {style: "percent",minimumFractionDigits:digits.percentage.min, maximumFractionDigits: digits.percentage.max});
                tmp.totalValueNow = totalValueNow0
                tmp.differenceValueNow = totalValueNow0 - tmp.imputValueNow0
                tmp.differenceValueTaxNow = (totalValueNow0 * 0.85) - tmp.imputValueNow0

                tmp[item + "ValueDifference"] = valueNow -value
                tmp[item + "PerValueDifference"] = (perValueNow -perValue).toLocaleString(undefined, {style: "percent",minimumFractionDigits:digits.percentage.min, maximumFractionDigits: digits.percentage.max});

            }
            allItems.push(tmp)
        }

        let average = [total].slice(0);
        average.user = "Average";

        let expandedIDs = [];
        for(let i=0;i<allIDs.length;i++){
            let item = allIDs[i];

            total[item + "PerQuantity"] = (total[item + "Quantity"]/total.itemQuantity).toLocaleString(undefined, {style: "percent",minimumFractionDigits:digits.percentage.min, maximumFractionDigits: digits.percentage.max});
            total[item + "PerValue"] = (total[item + "Value0"]/total.totalValue0).toLocaleString(undefined, {style: "percent",minimumFractionDigits:digits.percentage.min, maximumFractionDigits: digits.percentage.max});
            if(isNaN(total[item + "Value0"]/total.totalValue0)){total[item + "PerValue"] = "0%"}

            average[item + "Quantity"] = (total[item + "Quantity"]/total.itemQuantity).toLocaleString(undefined, {minimumFractionDigits: digits.number.min, maximumFractionDigits: digits.number.max});
            average[item + "Value"] = total[item + "Value0"]/total.itemQuantity
            average[item + "PerQuantity"] = total[item + "PerQuantity"];
            average[item + "PerValue"] = total[item + "PerValue"];

            average[item + "ValueNow"] = total[item + "ValueNow0"]/total.itemQuantity
            total[item + "PerValueNow"] = (total[item + "ValueNow0"]/total.totalValueNow0).toLocaleString(undefined, {style: "percent",minimumFractionDigits:digits.percentage.min, maximumFractionDigits: digits.percentage.max});
            if(isNaN(total[item + "ValueNow0"]/total.totalValueNow0)){total[item + "PerValueNow"] = "0%"}
            average[item + "PerValueNow"] = total[item + "PerValueNow"];

            let tmp = {};
            let data = this.getDataFromID(item, itemData);
            tmp.id = item;
            tmp.name = data.name;
            tmp.rarity = data.rarity;
            tmp.merged = tmp.name +"-"+ tmp.rarity;
            tmp.img = data.img;
            expandedIDs.push(tmp)
        }
        let stdv = "±"+(1/Math.pow(total.itemQuantity, 0.5)).toLocaleString(undefined, {style: "percent",minimumFractionDigits:digits.percentage.min, maximumFractionDigits: digits.percentage.max});
        average.itemQuantity = 1;
        average.stdv = stdv;
        average.imputValue = total.imputValue0/total.itemQuantity
        average.totalValue = total.totalValue0/total.itemQuantity
        average.differenceValue = (total.totalValue0  - total.imputValue0)/total.itemQuantity
        average.differenceValueTax = ((total.totalValue0 * 0.85) - total.imputValue0)/total.itemQuantity

        total.stdv = stdv;
        total.totalValue = total.totalValue0
        total.differenceValue = total.totalValue0  - total.imputValue0
        total.differenceValueTax = (total.totalValue0 * 0.85) - total.imputValue0


        average.imputValueNow = total.imputValueNow0/total.itemQuantity
        average.totalValueNow  = total.totalValueNow0/total.itemQuantity
        average.differenceValueNow  = (total.totalValueNow0  - total.imputValueNow0)/total.itemQuantity
        average.differenceValueTaxNow  = ((total.totalValueNow0 * 0.85) - total.imputValueNow0)/total.itemQuantity

        total.totalValueNow = total.totalValueNow0
        total.differenceValueNow = total.totalValueNow0  - total.imputValueNow0
        total.differenceValueTaxNow = (total.totalValueNow0 * 0.85) - total.imputValueNow0

        let returnArray = [];
        returnArray.push(total);
        returnArray.push(average);
        for(let i=0;i<allItems.length;i++){
            returnArray.push(allItems[i])
        }
        return {ids:expandedIDs,table:returnArray}
    }

    tableManager = (data, ids) => {
        let config = {
            className: {
                table: "centerTable table-primary table-striped table-highlight",
            },
            templates: {
                "text": {
                    className: "left",
                },
                "number": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "percent": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "gold": {
                    className: "right",
                    contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
                    sort: sortGeneral,
                    filter: (item, filter) => filterGeneral(item, filter, 10000)
                }
            },
            colsToDisplay:20,
            filter: {active:true},
            sort:{col:"user", desc:false},
            headers: {
                "User": {
                    className:"left",
                    cols: [
                        { template: "text", header: "", accessor: "user" },
                    ]
                },
                "Time": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "text", header: "", accessor: "iso" },
                    ]
                },
                "User Details": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "number", header: "Level", accessor: "level" },
                        { template: "number", header: "MF", accessor: "mf" },
                        { template: "text", header: "Salvage Kit", accessor: "salvageKit" },
                    ]
                },
                "Count": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "number", header: "Quantity", accessor: "itemQuantity" },
                        { template: "percent", header: "Accuracy", accessor: "stdv" },
                    ]
                },
                "Percentage Chance": {
                    collapse:true,
                    className:"left",
                    cols: []
                },
                "Quantity": {
                    collapse:true,
                    className:"left",
                    cols: []
                },
                "Value": {
                    collapse:true,
                    className:"left",
                    cols: []
                },
                "Percentage Value": {
                    collapse:true,
                    className:"left",
                    cols: []
                },
                "Returns Value": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "gold", header: "Input Value", accessor: "imputValue" },
                        { template: "gold", header: "Total Value", accessor: "totalValue" },
                        { template: "gold", header: "Difference Value", accessor: "differenceValue" },
                        { template: "gold", header: "Difference Value (Tax)", accessor: "differenceValueTax" },
                    ]
                },
                "Value Now": {
                    collapse:true,
                    className:"left",
                    cols: []
                },
                "Percentage Value Now": {
                    collapse:true,
                    className:"left",
                    cols: []
                },
                "Returns Value Now": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "gold", header: "Input Value", accessor: "imputValueNow" },
                        { template: "gold", header: "Total Value", accessor: "totalValueNow" },
                        { template: "gold", header: "Difference Value", accessor: "differenceValueNow" },
                        { template: "gold", header: "Difference Value (Tax)", accessor: "differenceValueTaxNow" },
                    ]
                },
            },
            collapse:{
                "User Details": true,
                "Quantity": true,
                "Value": true,
                "Percentage Value": true,
                "Returns Value": true,
                "Value Now": true,
                "Percentage Value Now": true,
                "Returns Value Now": true
            },
            headerOrder:["User","Time", "User Details","Count","Percentage Chance","Quantity","Percentage Value","Returns Value","Value Now","Percentage Value Now","Returns Value Now"]
        }

        let square = 25;
        for(let i=0;i<ids.length;i++){
            let id = ids[i].id;
            let name = ids[i].merged;
            let image = <img
              key={name}
              style={{width: square,height:square}}
              src={ids[i].img}
              title={name}
              alt={name}
            />
            config.headers["Percentage Chance"].cols.push(
              { template: "percent", header: id, accessor: id + "PerQuantity", customHeader: ()=>{return <span className={"center"} title={name}>{image}</span>}}
            )
            config.headers["Quantity"].cols.push(
              { template: "number", header: id, accessor: id + "Quantity", customHeader: ()=>{return <span className={"center"}  title={name}>{image}</span>}}
            )
            config.headers["Value"].cols.push(
              { template: "gold", header: id, accessor: id + "Value", customHeader: ()=>{return <span className={"center"}  title={name}>{image}</span>}}
            )
            config.headers["Percentage Value"].cols.push(
              { template: "percent", header: id, accessor: id + "PerValue", customHeader: ()=>{return <span className={"center"}  title={name}>{image}</span>}}
            )
            config.headers["Value Now"].cols.push(
              { template: "gold", header: id, accessor: id + "ValueNow", customHeader: ()=>{return <span className={"center"}  title={name}>{image}</span>}}
            )
            config.headers["Percentage Value Now"].cols.push(
              { template: "percent", header: id, accessor: id + "PerValueNow", customHeader: ()=>{return <span className={"center"}  title={name}>{image}</span>}}
            )
        }
        return this.createTable(data, config)
    }

    createTable = (data, config) => {
        return <SilverTable
          data={data}
          config={config}
        />
    }

    render() {
        let itemDropdown;
        let activityDropdown;
        let info = "";
        let table
        if(this.state.itemData.length === 0 || this.state.activityAvailable.length === 0 ){return <Loading/>}

        let activityOptionsList = [];
        for(let i=0;i<this.state.activityAvailable.length;i++){
            activityOptionsList.push({name:this.state.activityAvailable[i]})
        }

        activityDropdown = <div>
            <AutoComplete
                array={activityOptionsList}
                callbackToParent={this.callbackActivitySelection}
                sorter={"name"}
                placeholder={"Activities"}
                startRenderString={"-1"}/>
        </div>;

        if(this.state.selectedActivity !== "") {
            let statsAvailable = [];
            for(let i=0;i<this.state.activityIDS.length;i++){
                let tmp = {};
                tmp.id = this.state.activityIDS[i];
                let data = this.getDataFromID(tmp.id,this.state.itemData);
                tmp.name = data.name;
                tmp.merged = tmp.name +" - "+ tmp.id;
                statsAvailable.push(tmp)
            }

            statsAvailable.sort(function (a, b) {return a.id - b.id;});

            itemDropdown = <div>
                <AutoComplete array={statsAvailable} callbackToParent={this.callbackItemSelection} sorter={"merged"}  placeholder={"Research Items"} startRenderString={"-1"}/>
            </div>;
        }

        if(this.state.selectedID !== -1){
            let tmp = {
                selectedID: this.state.selectedID,
                selectedActivity: this.state.selectedActivity
            }
            let url = urlBase.site +"/stats_viewer?report="+LZUTF8.compress(JSON.stringify(tmp), {outputEncoding: "Base64"});
            let item = this.getDataFromID(this.state.selectedID, this.state.itemData);
            info = <div>
                <p style={{wordWrap: "break-word"}}>
                    {item.name} - ID:{item.id} - {this.state.selectedActivity}
                    <br />
                    Flavor Text: {item.description}
                    <br />
                    This Page: <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                </p>
            </div>;

            let result = this.state.tableData
            table = this.tableManager(result.table, result.ids)
        }

        return <div style={{align:"center"}}>
            <Documentation url={"https://slate.silveress.ie/gw2_site#viewer"} />
            {activityDropdown}
            <br />
            {itemDropdown}
            <br />
            {info}
            <br />
            {table}
        </div>
    }
}