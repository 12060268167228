import React, { Component } from 'react'
import {getRarityColour} from '../_utiilities/functions'

interface Objects extends Object{
  [propName: string]: any;
}

interface ItemTP {
  id: number,
  name: string,
  rarity: string,
  img: string,
  chat_link: string
}

interface TextImgTP_Props {
  size?: number,
  item: ItemTP,
  siteAddress: string,
}

export class TextImgTP extends Component<TextImgTP_Props, any> {
  constructor(props: any) {
    super(props)
  }

  render() {
    const {item, siteAddress, size} = this.props;
    const square = size ? size : 25;
    const rarityColour = getRarityColour(item.rarity);

    const image = <img
      key={item.name}
      style={{ width: square,height:square, border: "1px solid "+ rarityColour }}
      src={item.img}
      title={item.name}
      alt={item.name}
    />;

    const wiki = <a
      href={`https://wiki.guildwars2.com/wiki/?search=${encodeURIComponent(item.chat_link)}`}
      target={"_blank"} rel={"noreferrer noopener"}
      title={`Wiki page for ${item.name}`}
    >𝐖</a>;

    const slash = siteAddress.endsWith("/") ? "": "/";

    const itemLink =  <a
      href={`${siteAddress}${slash}#/tradepost/item/${item.id}`}
      target={"_blank"} rel={"noreferrer noopener"}
      title={`Item page for ${item.name}`}
    >{item.name}</a>;

    return <span title={`${item.name} - ${item.rarity}`} style={{color: rarityColour}} >{image} {wiki} {itemLink}</span>;
  }
}

interface Item {
  name: string,
  img: string,
}
interface TextImg_Props {
  size?: number,
  item: Item,
}
export class TextImg extends Component<TextImg_Props, any> {
  constructor(props: any) {
    super(props)
  }

  render() {
    const {item, size} = this.props;
    const square = size ? size : 25;

    let image = <img
      key={item.name}
      style={{width: square,height:square}}
      src={item.img}
      title={item.name}
      alt={item.name}
    />;
    return <span title={item.name}>{image} {item.name}</span>
  }
}