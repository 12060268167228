import React, { Component } from 'react';
import { urlBase } from '../_utiilities/data.json'
import { filterGeneral, sortGeneral, } from '../_utiilities/functions'
import {
    Documentation,
    GetCurrency,
    Loading,
    SilverTable,
} from '../_utiilities/functions_react'
import {TextImgTP} from "./tp_textImg";

export class Vendor_Trash extends Component {
    constructor (props) {
        super(props);
        this.state = {
          siteAddress: window.location.protocol + "//" + window.location.host
        }
    }

    async loadData (url) {return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))}

    async componentDidMount () {
        let result = await this.loadData(urlBase.parser + "/v1/items/json?beautify=min&fields=id,name,img,buy_price,sell_price,vendor_value,rarity,chat_link,NoSell,AccountBound,type&filter=marketable:TRUE,vendor_value:gte:0,sell_price:gte:0");

        if(result.length === 0){
            this.setState({itemData: undefined})
        }else{
            this.setState({itemData: result})
        }
    }

    processData = () =>{
        let items = this.state.itemData
        let tmp = []
        for(let i=0;i<items.length;i++){
            // these feck it up
            if(items[i].NoSell){continue}
            if(items[i].AccountBound){continue}
            if(items[i].vendor_value === 0){continue}

            // max loss
            items[i].buy_loss = items[i].vendor_value - items[i].buy_price
            items[i].sell_loss = items[i].vendor_value - items[i].sell_price

            // % loss
            items[i].buy_loss_percent = ((items[i].buy_loss/ items[i].buy_price)*100).toFixed(2)+"%"
            items[i].sell_loss_percent = ((items[i].sell_loss/ items[i].sell_price)*100).toFixed(2)+"%"

            // clean stuff
            if(items[i].buy_price === 0){
                items[i].buy_loss = -100000000
                items[i].buy_loss_percent = "-100.00%"
            }
            if(items[i].sell_price === 0){
                items[i].sell_loss = -100000000
                items[i].sell_loss_percent = "-100.00%"
            }

            tmp.push(items[i])
        }
        return tmp
    }

    createTable = (data) => {
        let config = {
            className: { table: "centerTable table-primary table-striped table-highlight", },
            templates: {
                "text": {
                    className: "left",
                },
                "textImg": {
                    contents:(item)=> <TextImgTP item={item} siteAddress={this.state.siteAddress}/>,
                    className: "left",
                },
                "percent": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "gold": {
                    className: "right",
                    contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
                    sort: sortGeneral,
                    filter: (item, filter) => filterGeneral(item, filter, 10000)
                }
            },
            colsToDisplay:30,
            filter: { active:true },
            sort:{col:"buy_loss", desc:true},
            headers: {
                "Details": {
                    className:"left",
                    cols: [
                        { template: "textImg", header: "Name", accessor: "name" },
                        { template: "text", header: "Type", accessor: "type" },
                        { template: "gold", header: "Vendor", accessor: "vendor_value" },
                    ]
                },
                "TradePost": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "gold", header: "Buy", accessor: "buy_price" },
                        { template: "gold", header: "Sell", accessor: "sell_price" },
                    ]
                },
                "Difference": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "gold", header: "Buy", accessor: "buy_loss" },
                        { template: "gold", header: "Sell", accessor: "sell_loss" },
                    ]
                },
                "Difference %": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "percent", header: "Buy", accessor: "buy_loss_percent" },
                        { template: "percent", header: "Sell", accessor: "sell_loss_percent" },
                    ]
                },
            },
            headerOrder:["Details","TradePost","Difference", "Difference %"],
            collapse:{}
        }
        return <SilverTable
          data={data}
          config={config}
        />
    }

    render () {
        if (typeof this.state.itemData === "undefined") {return <Loading/>}

        let data = this.processData()
        let table = this.createTable(data)

        return <div style={{ align: "center" }}>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#vendor-trash"} />
            <br/>
            <h3>Items that are close to vendor value</h3>
            <br/>
            {table}
        </div>
    }
}