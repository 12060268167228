import React, {Component} from 'react';
import cookie from "react-cookies";
import {Redirect} from 'react-router-dom'
import {Table} from "react-bootstrap";
import { urlBase } from '../_utiilities/data.json'
import { Documentation, AutoComplete, Loading} from '../_utiilities/functions_react'

let newList = {id:"New", name: "New List Name", items:[]};

function uniq(a) {
    let seen = {};
    return a.filter(function(item) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
}

export class Account_Lists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            session: cookie.load('session'),
            error:false,
            errorCode:undefined,
            account: undefined,
            nameBox:undefined,
            bulkBox:undefined,
            defaultList: undefined
        };

    }


    async loadData(url,extra) {return await fetch(url,extra).then(response => {return response.json()}).catch((err) => {console.log(this.props.url, err.toString()); return {result:"error", error:"could not fetch"};})}

    async loadAccount(tmp){
        let account = await this.loadData(urlBase.account+ "/v1/website/account",{method: 'GET', headers: {'Content-Type': 'application/json', session: this.state.session}});
        if(account.result === "success"){
            tmp.account = account.account;
            let lists = tmp.account.lists;
            let listKeys = Object.keys(lists);

            if(typeof tmp.defaultList === "undefined" && typeof tmp.deleted ==="undefined"){
                if(typeof this.state.defaultList === "undefined"){
                    tmp.defaultList = listKeys[0];
                }else{
                    tmp.defaultList = this.state.defaultList;
                }
            }
            delete tmp.deleted;
            if(typeof tmp.defaultList === "undefined"){
                tmp.selectedList = newList;
            }else{
                tmp = await this.onList(tmp,tmp.defaultList, tmp.account)
            }
        }else{
            tmp.error = true;
            tmp.errorCode = account.error;
        }
        if(account.result ==="error"){
            if(account.error ==="Invalid session"){
                cookie.remove('session');
                tmp.session = undefined;
            }
        }
        return tmp;
    }

    async componentDidMount() {
        let tmp = {};
        tmp.rawItemData = await this.loadData(urlBase.parser + "/v1/items/json?fields=img,id,name&beautify=min&filter=");
        tmp.dropdown = this.formatDropdown(tmp.rawItemData);
        tmp.marketPrices = {};
        for(let i=0;i<tmp.rawItemData.length;i++){
            tmp.marketPrices[tmp.rawItemData[i].id] = tmp.rawItemData[i];
        }

        tmp = await this.loadAccount(tmp);
        this.setState(tmp)
    }

    async onList(tmp,list, account){
        let result = await this.loadData(urlBase.account + "/v1/website/lists", {method: 'GET', headers: {'Content-Type': 'application/json', session: this.state.session, listID:list}});
        if(result.result ==="success"){
            tmp.selectedList = result.success;
            tmp.selectedList.name = account.lists[list];
        }
        return tmp;
    }

    handleListSelection = async (event) => {
        let tmp = {};
        tmp.defaultList = event.target.value;
        if(tmp.defaultList !== "new"){
            tmp = await this.onList(tmp,tmp.defaultList,this.state.account);
        }else{
            tmp.selectedList = newList;
        }
        this.setState(tmp);
    }

     getDataFromID = (id, items) => {
        id = id-0;
        let data = {};
        let result = items[id];
        if(typeof result !== "undefined"){
            data = result
        }
        return data;
    }

    async submitData(tmp,selectedList){
        if(typeof this.state.selectedList !== "undefined"){
            let sorting = "new";
            if(selectedList.id !== "New"){ sorting = "update"}
            let submitUrl = urlBase.account + "/v1/website/lists/" + sorting;

            let options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', session:this.state.session},
                body:JSON.stringify(selectedList)
            };

            await fetch(submitUrl, options)
                .then(async (response)=> {
                    let body = await response.json();
                    if (body.result === "success") {
                        tmp.bulkBox = "";
                        tmp.nameBox = "";
                        tmp.defaultList = body.success;
                        tmp = await this.loadAccount(tmp);
                        //alert("Success")
                    }
                    if (body.result === "error") {
                        alert(body.error)
                    }
                })
                .catch((err) => {alert(err)});
        }
        return tmp;
    }

    handleDeleteList = async () => {
        let tmp = {};
        if (window.confirm('Are you sure you wish to delete this list?')){
            let submitUrl = urlBase.account + "/v1/website/lists/delete";
            let options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', session:this.state.session},
                body:JSON.stringify(this.state.selectedList)
            };
            await fetch(submitUrl, options)
                .then(async (response)=> {
                    let body = await response.json();
                    if (body.result === "success") {
                        tmp.bulkBox = undefined;
                        tmp.nameBox = undefined;
                        tmp.defaultList = undefined;
                        tmp.deleted = true;
                        tmp = await this.loadAccount(tmp);
                        //alert("Success")
                    }
                    if (body.result === "error") {
                        alert(body.error)
                    }
                })
                .catch((err) => {alert(err)});
        }

        this.setState(tmp)
    }

    handleChangeName = (event) =>{
        let name =  event.target.value;
        let selectedList = this.state.selectedList;
        selectedList.name = name;
        this.setState({nameBox:name, selectedList:selectedList });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let tmp = await this.submitData({},this.state.selectedList);
        this.setState(tmp);
    }

    handleChangeBulk = (event) => {
        this.setState({bulkBox:event.target.value});
    }

     addBulk = async (event) =>  {
        event.preventDefault();
        let tmp = {};

        let bulkBoxRaw = this.state.bulkBox;
        tmp.selectedList = this.state.selectedList;
        let bulkBox = bulkBoxRaw.split(",");
        for(let i=0;i<bulkBox.length;i++){
            if(!isNaN(bulkBox[i]-0)){
                let id = bulkBox[i]-0;
                if(typeof this.state.marketPrices[id] !== undefined && id >0){
                    tmp.selectedList.items.push(id);
                }
            }
        }
        tmp.selectedList.items = uniq(tmp.selectedList.items);
        tmp = await this.submitData(tmp,tmp.selectedList);
        this.setState(tmp);
    }

    myCallback = async (dataFromChild) => {
        let tmp = {};
        let id = this.getIDFromMerged(dataFromChild);
        if(id>0){
            tmp.selectedList = this.state.selectedList;
            tmp.selectedList.items.push(id);
            tmp.selectedList.items = uniq(tmp.selectedList.items);
            tmp = await this.submitData(tmp,tmp.selectedList);
        }
        this.setState(tmp);
    };

    formatDropdown= (dropdown) => {
        for(let i=0;i<dropdown.length; i++){
            let tmp = "";
            if(typeof dropdown[i].name !== "undefined"){
                tmp = tmp +"Name: "+ dropdown[i].name;
            }
            if(typeof dropdown[i].id !== "undefined"){
                tmp = tmp +"\t ID: "+ dropdown[i].id;
            }
            dropdown[i].merged = tmp;
        }
        return dropdown;
    }

    getIDFromMerged(merged){
        let dataArray = this.state.dropdown;
        for(let i=0;i<dataArray.length;i++){
            if(merged === dataArray[i].merged){
                return dataArray[i].id;
            }
        }
        return 0;
    }

    handleDeleteItem = async (deleteID) => {
        let tmp = {};
        tmp.selectedList = this.state.selectedList;
        tmp.selectedList.items = tmp.selectedList.items.filter(item => item !== deleteID);
        tmp = await this.submitData(tmp,tmp.selectedList);
        this.setState(tmp);
    }

    render() {
        if(typeof this.state.session === "undefined"){
            return <Redirect to='/login'  />;
        }

        let info = <Loading />;
        let error, listDetails;
        if(this.state.error === true){
            error =  <p>
                Error: {this.state.errorCode}
            </p>
        }
        if(this.state.account !== undefined){
            let listsDropdown = [];
            let lists = this.state.account.lists;
            let listKeys = Object.keys(lists);

            listsDropdown.push(<option key={-1} value={"new"} >New List</option>);
            for(let i=0;i<listKeys.length;i++){
                listsDropdown.push(
                    <option key={i} value={listKeys[i]}>{lists[listKeys[i]]}</option>
                )
            }

            info = (
                <select
                    value={this.state.defaultList}
                    onChange={this.handleListSelection}>
                    {listsDropdown}
                </select>
            );
        }

        if(this.state.selectedList !== undefined){
            let square = 25;
            let nameBox;

            let rows = [];
            if(this.state.selectedList.items.length >0){
                let items = this.state.selectedList.items;
                for(let i=0;i<items.length;i++){
                    let thisItemData = this.getDataFromID(items[i], this.state.marketPrices);

                    rows.push(
                        <tr key={items[i]}>
                        <td className={"left"}><img
                            key={thisItemData.name}
                            style={{width: square,height:square}}
                            src={thisItemData.img}
                            title={thisItemData.name}
                            alt={thisItemData.name}
                        /></td>
                        <td className={"left"}>{thisItemData.name}</td>
                        <td className={"left"}>
                            <input type="submit" value="Delete" onClick={async()=>{await this.handleDeleteItem(items[i])}} />

                        </td>
                    </tr>)
                }
            }

            let selector =  <AutoComplete array={this.state.dropdown} callbackToParent={this.myCallback} sorter={"merged"}  placeholder={"Add Items"} startRenderString={2} buttonText={"Search"} limit={5} />;

            let table = <div>
                <Table responsive size={"sm"} striped={true} style={{width:"800px", align:"center", margin:"auto"}} >
                    <thead>
                    <tr>
                        <th>Img</th>
                        <th>Name</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </Table>
            </div>;

            let ids = "Id's: "+ this.state.selectedList.items.join(",");

            let addBulk = <div>
                <form onSubmit={this.addBulk}>
                    <input
                        type="text"
                        value={this.state.bulkBox}
                        onChange={this.handleChangeBulk}
                        placeholder={"Comma separated list of ID's"}
                    />
                    &nbsp;
                    <input type="submit" value="Add Bulk"/>
                </form>
            </div>;

            let initial;
            if(this.state.selectedList.id !== "New"){
                nameBox = <div>
                    <form onSubmit={async()=>{await this.handleDeleteList(this.state.selectedList.id)}}>
                        <input
                            type="text"
                            value={this.state.nameBox}
                            onChange={this.handleChangeName}
                            placeholder={this.state.selectedList.name}
                        />
                        &nbsp;
                        <input type="submit" value="Delete"/>
                    </form>
                    <br />
                </div>;

                initial = <div>
                    <br />
                    {nameBox}
                    <br />
                    {selector}
                    <br />
                    {addBulk}
                    <br />
                    {ids}
                    <br />
                    {table}
                </div>
            }else{
                nameBox = <div>
                    <form onSubmit={this.handleSubmit}>
                        <input
                            type="text"
                            value={this.state.nameBox}
                            onChange={this.handleChangeName}
                            placeholder={this.state.selectedList.name}
                        />
                        &nbsp;
                        <input type="submit" value="   Name   "/>
                    </form>
                    <br />
                </div>;

                initial = <div>
                    <br />
                    {nameBox}
                </div>
            }

            listDetails = <div>
                {initial}
            </div>;
        }

        return <div>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/management#lists"} />
            <br/>
            {info}
            <br />
            {error}
            {listDetails}
        </div>
    }
}