import React, { Component } from 'react'
import * as statsCalc from 'stats-lite'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { urlBase } from '../_utiilities/data.json'
import { Documentation, AutoComplete, Loading, SilverTable, GetCurrency, useQuery } from '../_utiilities/functions_react'
import { filterGeneral, sortGeneral } from '../_utiilities/functions'
import {TextImg} from "./tp_textImg";

function reloadPage(){window.parent.location = window.parent.location.href;}
export class Precursors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forgingData: [],
            priceData: [],
            query: useQuery(window.location.hash)
        }
    }

    async componentDidMount() {
        let tmp = {};
        let priceData = await this.loadData(urlBase.parser + "/v1/items/json?beautify=min&fields=id,name,sell_price,level,upgrade1,img&filter=AccountBound:FALSE,marketable:TRUE,type:Weapon,rarity:Exotic");
        tmp.dropdown = this.formatDropdown(priceData);
        tmp.priceData = {};
        for(let i=0;i<priceData.length;i++){
            tmp.priceData[priceData[i].id] = priceData[i];
        }

        tmp.forgingData = await this.loadData(urlBase.stats + "/v1/forging/precurser?beautify=min");

        let result = this.reduceForgingData(tmp.forgingData,tmp.priceData);
        tmp.overallData = result.overallData;
        tmp.output = result.output;
        tmp.preGaps = result.preGaps;

        this.setState(tmp)
    }

    async loadData(url) {return await fetch(url).then(response => response.json()).then(data => { return data;}).catch(err => console.error(this.props.url, err.toString()))}

    formatDropdown = (dropdown) =>{
        for(let i=0;i<dropdown.length; i++){
            let tmp = "";
            if(typeof dropdown[i].name !== "undefined"){
                tmp = tmp +"Name: "+ dropdown[i].name;
            }
            if(typeof dropdown[i].upgradeName !== "undefined"){
                tmp = tmp +"\t Upgrade: "+ dropdown[i].upgradeName;
            }
            if(typeof dropdown[i].level !== "undefined"){
                tmp = tmp +"\t Level: "+ dropdown[i].level;
            }
            dropdown[i].merged = tmp;
        }
        return dropdown;
    }

    reduceForgingData = (forgingData,priceData) => {
        const pres = ["Carcharias", "Chaos Gun", "Dawn", "Dusk", "Howl", "Leaf of Kudzu", "Rage", "Rodgort's Flame", "Spark", "Storm", "The Bard", "The Chosen", "The Colossus", "The Energizer", "The Hunter", "The Legend", "The Lover", "Tooth of Frostfang", "Venom", "Zap"];
        let overallData = { "total": forgingData.length, "originalTotalValue": 0, "currentTotalValue": 0, "originalSansPreTotalAvg": 0, "currentSansPreTotalAvg": 0, "originalSansPreTotalValue": 0, "currentSansPreTotalValue": 0 };
        let counter = [];
        let ids = [];
        let output = [];
        let preGaps = [];
        let sinceLastPre = 0;

        for (let i = 0; i < forgingData.length; i++) {
            let name = forgingData[i].name;
            let price = forgingData[i].sell;
            let id = forgingData[i].ID;

            // Forges since last pre, use for teh overallData
            if (pres.indexOf(name) !== -1) {
                preGaps.push(sinceLastPre);
                sinceLastPre = 0;
            } else {
                sinceLastPre = sinceLastPre + 1;
            }

            if (typeof counter[id] === "undefined") {
                ids.push(id);
                let temp = {};
                temp.count = 1;
                temp.price = price;
                counter[id] = temp;
            } else {
                counter[id].count = counter[id].count + 1;
                counter[id].price = counter[id].price + price;
            }
        }
        preGaps.push(sinceLastPre)

        for (let iii = 0; iii < ids.length; iii++) {
            let id = ids[iii]
            let item = this.getDataFromID(id, priceData)
            if (typeof item.name === "undefined") {continue}

            let itemData = counter[id]
            let originalPrice = (itemData.price * 0.85) || 0
            let currentPrice = item.sell_price * 0.85 || 0

            itemData.id = id
            itemData.img = item.img
            itemData.name = item.name
            itemData.chance = itemData.count / overallData.total

            itemData.originalAvgValueFormatted = originalPrice / itemData.count;
            itemData.originalPerValueFormatted = itemData.chance * itemData.originalAvgValueFormatted;

            itemData.currentAvgValueFormatted = currentPrice;
            itemData.currentPerValueFormatted = itemData.chance * itemData.currentAvgValueFormatted;

            output.push(itemData)

            overallData.originalTotalValue = overallData.originalTotalValue + originalPrice;
            overallData.currentTotalValue = overallData.currentTotalValue + (currentPrice * itemData.count);

            if (pres.indexOf(itemData.name) === -1) {
                overallData.originalSansPreTotalAvg = overallData.originalSansPreTotalAvg + itemData.originalPerValueFormatted;
                overallData.currentSansPreTotalAvg = overallData.currentSansPreTotalAvg + itemData.currentPerValueFormatted;
            }

            itemData.chanceFormatted = parseFloat(itemData.chance * 100).toFixed(3) + "%";
        }

        overallData.sinceLastPre = sinceLastPre;
        overallData.originalTotalAvg = overallData.originalTotalValue / overallData.total;
        overallData.currentTotalAvg = overallData.currentTotalValue / overallData.total;

        let result = {};
        result.overallData = overallData;
        result.output = output;
        result.preGaps = preGaps;
        return result;
    }

    getIDFromMerged = (merged) =>{
        let dataArray = this.state.dropdown;
        for(let i=0;i<dataArray.length;i++){
            if(merged === dataArray[i].merged){
                return dataArray[i].id;
            }
        }
        return 0;
    }

    getDataFromID = (id, items)=>{
        id = id-0;
        let data = {};
        let result = items[id];
        if(typeof result !== "undefined"){
            data = result
        }
        return data;
    }

    myCallback = async (dataFromChild) => {
        let id = this.getIDFromMerged(dataFromChild)
        let itemData = this.getDataFromID(id, this.state.priceData)
        let name = itemData.name
        if(typeof name === "undefined"){return null}
        let price = itemData.sell_price || 0;

        let sending = {"name": name, "ISO": new Date().toISOString(), "sell": price, "ID": id};
        let headers = {'Content-Type': 'application/json'};

        for (const [key, value] of this.state.query.entries()) {
            headers[key] = value
        }

        fetch(urlBase.stats + '/v1/forging/precurser', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(sending)
        })
        .then(function (response) {
            if (response.status === 401) {
                let message = ["The Maze isn't meant for you", "This isn't the matrix", "This is a wall not a door!", "Really?"];
                alert(message[Math.floor(Math.random() * message.length)]);
            }
            if (response.status === 200) {
                reloadPage();
                console.log("Success")
            }
        })
        .catch(err => console.log(err.res))
    };

    tableManager = (data) => {
        let config = {
            className: {
                table: "centerTable table-primary table-striped table-highlight",
            },
            templates: {
                "text": {
                    className: "left",
                },
                "textImg": {
                    contents: (item) => <TextImg item={item}/>,
                    className: "left",
                },
                "number": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "percent": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "gold": {
                    className: "right",
                    contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
                    sort: sortGeneral,
                    filter: (item, filter) => filterGeneral(item, filter, 10000)
                }
            },
            colsToDisplay:20,
            filter: {active:true},
            sort:{col:"name", desc:true},
            headers: {
                "Data": {
                    className:"center",
                    cols: [
                        { template: "textImg", header: "Output", accessor: "name" },
                        { template: "number", header: "Count", accessor: "count" },
                        { template: "percent", header: "% Chance", accessor: "chanceFormatted" }
                    ]
                },
                "Original": {
                    className:"center",
                    cols: [
                        { template: "gold", header: "Avg Value", accessor: "originalAvgValueFormatted" },
                        { template: "gold", header: "Per Forge", accessor: "originalPerValueFormatted" },
                    ]
                },
                "Current": {
                    className:"center",
                    cols: [
                        { template: "gold", header: "Avg Value", accessor: "currentAvgValueFormatted" },
                        { template: "gold", header: "Per Forge", accessor: "currentPerValueFormatted" },
                    ]
                }
            },
            headerOrder:["Data","Original", "Current"]
        }

        return this.createTable(data, config)
    }

    createTable = (data, config) => {
        return <SilverTable
          data={data}
          config={config}
          callbackToParent={this.callbackToParent}
        />
    }

    render() {
        if(typeof this.state.output === "undefined" ){return <Loading/>}
        let overallData = this.state.overallData;
        let preGaps = this.state.preGaps;

        let inputBox = <AutoComplete array={this.state.dropdown} callbackToParent={this.myCallback} sorter={"merged"}  placeholder={"Items"} startRenderString={"0"}/>

        let overallForgeStatsTable = <Table striped bordered className="table-primary">
            <thead>
            <tr>
                <th className={"left"}>
                    Parameter
                </th>
                <th>
                    Result
                </th>
                <th>
                    Percent
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={"left"}>
                    Forges
                </td>
                <td className={"right"}>
                    {overallData.total}
                </td>
                <td className={"right"}>
                    N/A
                </td>
            </tr>
            <tr>
                <td className={"left"}>
                    Forges since last pre
                </td>
                <td className={"right"}>
                    {overallData.sinceLastPre}
                </td>
                <td className={"right"}>
                    {(1/overallData.sinceLastPre).toLocaleString("en", {style: "percent",minimumFractionDigits:2})}
                </td>
            </tr>
            <tr>
                <td className={"left"}>
                    Min
                </td>
                <td className={"right"}>
                    {Math.min.apply(null, preGaps)}
                </td>
                <td className={"right"}>
                    {(1/Math.min.apply(null, preGaps)).toLocaleString("en", {style: "percent",minimumFractionDigits:2})}
                </td>
            </tr>
            <tr>
                <td className={"left"}>
                    Max
                </td>
                <td className={"right"}>
                    {Math.max.apply(null, preGaps)}
                </td>
                <td className={"right"}>
                    {(1/Math.max.apply(null, preGaps)).toLocaleString("en", {style: "percent",minimumFractionDigits:2})}
                </td>
            </tr>
            <tr>
                <td className={"left"}>
                    Mean
                </td>
                <td className={"right"}>
                    {statsCalc.mean(preGaps).toFixed(3)}
                </td>
                <td className={"right"}>
                    {(1/statsCalc.mean(preGaps)).toLocaleString("en", {style: "percent",minimumFractionDigits:2})}
                </td>
            </tr>
            <tr>
                <td className={"left"}>
                    Median
                </td>
                <td className={"right"}>
                    {statsCalc.median(preGaps).toFixed(3)}
                </td>
                <td className={"right"}>
                    {(1/statsCalc.median(preGaps)).toLocaleString("en", {style: "percent",minimumFractionDigits:2})}
                </td>
            </tr>
            <tr>
                <td className={"left"}>
                    Standard Deviation
                </td>
                <td  className={"right"}>
                    {statsCalc.mean(preGaps).toFixed(3) + " ±" + statsCalc.stdev(preGaps).toFixed(3)}
                </td>
                <td className={"right"}>
                    {(1/Math.max(0, statsCalc.mean(preGaps) + statsCalc.stdev(preGaps))).toLocaleString("en", {style: "percent",minimumFractionDigits:2}) + " - " + (1/Math.max(1, statsCalc.mean(preGaps) - statsCalc.stdev(preGaps))).toLocaleString("en", {style: "percent",minimumFractionDigits:2})}
                </td>
            </tr>
            </tbody>
        </Table>;

        let overallStatsTable = <Table striped bordered className="table-primary">
            <thead>
            <tr>
                <th className={"left"}>
                    Parameter
                </th>
                <th>
                    Original
                </th>
                <th>
                    Current
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={"left"}>
                    Total Value
                </td>
                <td className={"right"}>
                    <GetCurrency number={overallData.originalTotalValue} size={25} />
                </td>
                <td className={"right"}>
                    <GetCurrency number={overallData.currentTotalValue} size={25} />
                </td>
            </tr>
            <tr>
                <td className={"left"}>
                    Per Forge
                </td>
                <td className={"right"}>
                    <GetCurrency number={overallData.originalTotalAvg} size={25} />
                </td>
                <td className={"right"}>
                    <GetCurrency number={overallData.currentTotalAvg} size={25} />
                </td>
            </tr>
            <tr>
                <td className={"left"}>
                    Per Forge (No Pres)
                </td>
                <td className={"right"}>
                    <GetCurrency number={overallData.originalSansPreTotalAvg} size={25} />
                </td>
                <td className={"right"}>
                    <GetCurrency number={overallData.currentSansPreTotalAvg} size={25} />
                </td>
            </tr>
            <tr>
                <td className={"left"}>
                    Per Forge (Just Pres)
                </td>
                <td className={"right"}>
                    <GetCurrency number={overallData.originalTotalAvg - overallData.originalSansPreTotalAvg} size={25} />
                </td>
                <td className={"right"}>
                    <GetCurrency number={overallData.currentTotalAvg - overallData.currentSansPreTotalAvg} size={25} />
                </td>
            </tr>
            </tbody>
        </Table>;

        let mainTable = this.tableManager(this.state.output)

        return <div>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/forging#precursors"} />
            <br/>
            <Container>
                <Row>
                    <Col>
                        {inputBox}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm={4}>
                        {overallStatsTable}
                    </Col>
                    <Col sm={4}> </Col>
                    <Col sm={4}>{overallForgeStatsTable}</Col>
                </Row>
            </Container>
            <br/>
            {mainTable}
        </div>;
    }
}