import React from 'react';
import ReactDOM from 'react-dom';

import { App } from "./app"

// Css for everything, importing once
import './_css/highChartsDark.css'
import './_css/bootstrap_slate.css'
import './_css/index.css';
import './_css/App.css';

ReactDOM.render(<App />, document.getElementById('root'));