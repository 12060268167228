import React, { Component } from 'react'
import { urlBase } from '../_utiilities/data.json'
import {Documentation, GetCurrency, Loading, SilverTable} from '../_utiilities/functions_react'
import {  filterGeneral, sortGeneral } from '../_utiilities/functions'
import {TextImg} from "./tp_textImg";


interface Objects extends Object{
  [propName: string]: any;
}


interface Main_Props {

}
interface Main_State {
  item_data: Objects;
  festival_data: Array<Objects>;
  history_data: Array<Objects> | undefined;
}

interface Row extends Objects {
  // basic info
  item: string;
  year: number;
  material: string;
  quantity: number;
  cost_current: number;

  // extra info
  date_start: string;
  cost_start: number;
  quantity_start: number;

  date_end: string;
  cost_end: number;
  quantity_end: number;

  cost_difference:number;
  quantity_difference: number;

  img: string;
}
export class Festival_Winds extends Component<any, Main_State> {
  constructor(props: any) {
    super(props)
    this.state = {
      item_data: {},
      festival_data: [],
      history_data: undefined
    }
  }

  // load up base data
  async componentDidMount() {
    // get teh item data
    let item_data = await this.get_itemData()

    // get the festival data
    let [ids, festival_data] = await this.get_festival()

    // set initially
    this.setState({item_data,festival_data })

    // then get history
    let history_data = await this.get_history(ids)

    // set history
    this.setState({history_data })
  }

  get_itemData = async() =>{
    let tmp:Objects = {}
    let url = `${urlBase.parser}/v1/items/json?fields=id,name,sell_price,img&beautify=min`
    let result = await fetch(url).then(response => response.json()).catch(err => {console.error(url, err.toString()); return []})
    for(let i=0;i<result.length;i++){
      tmp[result[i]["id"]] = result[i]
    }
    return tmp
  }

  get_festival = async():Promise<[Array<number>,Array<object>]> =>{
    let url = `${urlBase.parser}/v1/misc/festival?filter=festival:Four%20Winds&beautify=min`
    let result = await fetch(url).then(response => response.json()).catch(err => {console.error(url, err.toString()); return []})

    let ids: Set<number> = new Set()
    for(let i=0;i<result.length;i++){
      ids.add(result[i].id)
    }

    return [Array.from(ids).sort(),result]
  }

  get_history = async(ids: Array<number>)=>{
    let url = `${urlBase.parser}/v1/history?itemID=${ids.join(",")}&fields=itemID,date,sell_price_avg,sell_price_min,sell_price_max,sell_quantity_min`
    return await fetch(url).then(response => response.json()).catch(err => {console.error(url, err.toString()); return []})
  }

  process_data = (): Array<Row> =>{
    let {festival_data, item_data, history_data} = this.state

    let rows = []
    for(let i=0;i<festival_data.length;i++){
      let id = festival_data[i].id
      let item = item_data[id]
      if (!item){continue}

      let cost_start = 0
      let cost_end = 0

      let quantity_start = 0
      let quantity_end = 0

      if(history_data){
        let sortField = "date"
        const sorter = (a:Objects,b:Objects)=>{if (a[sortField] < b[sortField]) { return -1}if (a[sortField] > b[sortField]) {return 1 }return 0}

        let filtered_start = history_data.filter(historic => historic.itemID === id && historic.date < festival_data[i].start)
        filtered_start.sort(sorter)
        let before_start = filtered_start[filtered_start.length-2] || { sell_quantity_min: 0, sell_price_avg:0 }

        quantity_start = before_start.sell_quantity_min || 0

        if(before_start.sell_price_avg){
          cost_start = before_start.sell_price_avg || 0
        }else{
          cost_start = (before_start.sell_price_min + before_start.sell_price_max)/2 || 0
        }

        let filtered_end = history_data.filter(historic => historic.itemID === id && historic.date > festival_data[i].end)
        filtered_end.sort(sorter)
        let before_end = filtered_end[1] || { sell_quantity_min: 0, sell_price_avg:0 }

        quantity_end = before_end.sell_quantity_min || 0

        if(before_end.sell_price_avg){
          cost_end = before_end.sell_price_avg || 0
        }else{
          cost_end = (before_end.sell_price_min + before_end.sell_price_max)/2 || 0
        }
      }

      let row:Row = {
        item: festival_data[i].type,
        year: festival_data[i].year,
        material: item.name,
        quantity: festival_data[i].quantity,
        cost_current: item.sell_price * festival_data[i].quantity,

        date_start: festival_data[i].start,
        cost_start: cost_start * festival_data[i].quantity,
        quantity_start: quantity_start,

        date_end: festival_data[i].end,
        cost_end: cost_end * festival_data[i].quantity,
        quantity_end: quantity_end,

        cost_difference:(cost_end * festival_data[i].quantity) - (cost_start * festival_data[i].quantity),
        quantity_difference: quantity_end - quantity_start,

        img: item.img
      }

      rows.push(row)
    }

    return rows
  }

  tableManager = () => {
    let data: Array<Row> = this.process_data()
    let square = 25
    let config = {
      className: {
        //table:"table table-primary table-striped table-bordered",
        table: "centerTable table-primary table-striped table-highlight",
        // button:"btn btn-primary",input:"form-control"
      },
      templates: {
        "text": {
          className: "left",
        },
        "textImg": {
          contents: (item: Row) => <TextImg item={{name: item.material, img: item.img}}/>,
          className: "left",
        },
        "year": {
          className: "right",
          sort: (a:any, b:any) => sortGeneral(a, b),
          //filter: (item:Row, filter:string) => filterGeneral(item, filter)
        },
        "number": {
          className: "right",
          sort: (a:any, b:any) => sortGeneral(a, b),
          filter: (item:Row, filter:string) => filterGeneral(item, filter)
        },
        "percent": {
          className: "right",
          sort: (a:any, b:any) => sortGeneral(a, b),
          filter: (item:Row, filter:string) => filterGeneral(item, filter)
        },
        "gold": {
          className: "right",
          contents:(item:Row, accessor: string)=> <GetCurrency number={item[accessor]} size={25} />,
          sort: (a:any, b:any)=> sortGeneral(a, b),
          filter: (item:Row, filter:string) => filterGeneral(item, filter, 10000)
        }
      },
      //colsToDisplay:20,
      filter: {active:true},
      sort:{col:"year", desc:true},
      headers: {
        "Info": {
          className:"left",
          cols: [
            { template: "text", header: "Box", accessor: "item" },
            { template: "year", header: "Year", accessor: "year" },
            { template: "textImg", header: "Material", accessor: "material" },
            { template: "number", header: "Quantity", accessor: "quantity" },
            { template: "gold", header: "Cost Now", accessor: "cost_current" },
          ]
        },
        "Start": {
          collapse:true,
          className:"left",
          cols: [
            { template: "text", header: "Date", accessor: "date_start" },
            { template: "gold", header: "Cost", accessor: "cost_start" },
            { template: "number", header: "Quantity", accessor: "quantity_start" },

          ]
        },
        "End": {
          collapse:true,
          className:"left",
          cols: [
            { template: "text", header: "Date", accessor: "date_end" },
            { template: "gold", header: "Cost", accessor: "cost_end" },
            { template: "number", header: "Quantity", accessor: "quantity_end" },
          ]
        },
        "Difference": {
          collapse:true,
          className:"left",
          cols: [

            { template: "gold", header: "Cost", accessor: "cost_difference" },
            { template: "number", header: "Quantity", accessor: "quantity_difference" },
          ]
        },
      },
      collapse:{ "Start": true, "End": true, "Difference": true },
      headerOrder:["Info","Start", "End", "Difference"]
    }

    return <SilverTable data={data} config={config}/>
  }

  render() {
    //console.log(this.state)
    if(Object.keys(this.state.item_data).length === 0){return <Loading />}

    return <div
       // style={{"align":"center"}}
    >
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#zephyrite-supply-box"}/>
      <br/>
      <div><h3>Zephyr Sanctum/Zephyrite Supply Box</h3></div>
      <br />
      {this.tableManager()}
    </div>
  }
}

