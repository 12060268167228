import React, { Component } from 'react'
import {Table} from 'react-bootstrap'
import {Container, Row, Col} from 'react-bootstrap'
import { urlBase } from '../_utiilities/data.json'
import { Documentation, AutoComplete, Loading, SilverTable, GetCurrency, useQuery } from '../_utiilities/functions_react'
import { filterGeneral, sortGeneral } from '../_utiilities/functions'

function reloadPage(){window.parent.location = window.parent.location.href;}
export class Minis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forgingData: [],
            priceData: [],
            loading: true,
            query: useQuery(window.location.hash)
        }
    }

    async loadData(url) {
        return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))
    }

    async componentDidMount() {
        let tmp = {};
        tmp.forgingData = await this.loadData(urlBase.stats + "/v1/forging/minis?beautify=min");

        tmp.priceData0 = await this.loadData(urlBase.parser + "/v1/items/json?beautify=min&fields=id,name,sell_price&filter=AccountBound:FALSE,marketable:TRUE,type:MiniPet");
        tmp.priceData = {};
        for(let i=0;i<tmp.priceData0.length;i++){
            tmp.priceData[tmp.priceData0[i].name] = tmp.priceData0[i];
        }

        let results = this.reduceForgingData(tmp.forgingData, tmp.priceData);
        tmp.overallData = results.overallData;
        tmp.output = results.output;

        this.setState(tmp)
    }

    getPriceData = (name, items) => {
        let data = {};
        let result = items[name];
        if(typeof result !== "undefined"){
            data = result
        }
        return data;
    }

    reduceForgingData = (forgingData, priceData) => {
        let overallData = {"total":forgingData.length,"originalTotalValue":0,"currentTotalValue":0,"originalSansPreTotalAvg":0,"currentSansPreTotalAvg":0,"originalSansPreTotalValue":0,"currentSansPreTotalValue":0};
        let counter = [];
        let names = [];
        let output = [];

        for(let i=0;i<forgingData.length; i++){
            let name = forgingData[i].name;
            let price = forgingData[i].sell;

            if(typeof counter[name] === "undefined"){
                names.push(name);
                let temp = {};
                temp.count = 1;
                temp.price = price;
                counter[name] = temp;
            }else{
                counter[name].count = counter[name].count +1;
                counter[name].price = counter[name].price  + price;
            }

        }
        names.sort();

        for(let i=0;i<names.length;i++){
            let itemData = counter[names[i]];
            let originalPrice         = (itemData.price * 0.85) || 0;
            let currentPrice          = this.getPriceData(names[i],priceData).sell_price * 0.85 || 0;

            itemData.name               = names[i];
            itemData.chance             = itemData.count    / overallData.total;

            itemData.originalAvgValueFormatted   = originalPrice   / itemData.count;
            itemData.originalPerValueFormatted   = itemData.chance   * itemData.originalAvgValueFormatted;

            itemData.currentAvgValueFormatted    = currentPrice;
            itemData.currentPerValueFormatted    = itemData.chance   * itemData.currentAvgValueFormatted;

            output.push(itemData);

            overallData.originalTotalValue  = overallData.originalTotalValue + originalPrice;
            overallData.currentTotalValue   = overallData.currentTotalValue + (currentPrice * itemData.count);


            itemData.chanceFormatted = parseFloat(itemData.chance *100).toFixed(3)+"%";
        }

        overallData.originalTotalAvg    = overallData.originalTotalValue/overallData.total;
        overallData.currentTotalAvg     = overallData.currentTotalValue/overallData.total;

        return {overallData: overallData, output: output}
    }

    getCurrentPrice(name){
        let price = 0;
        let priceData = this.state.priceData0;
        for(let ii=0;ii<priceData.length; ii++){
            if(priceData[ii].name === name){
                price = priceData[ii].sell_price;
                if(typeof price ==="undefined"){
                    price = 0
                }
                break
            }
        }
        return price
    }

    myCallback = async (dataFromChild) => {
        let name = dataFromChild
        let price = this.getCurrentPrice(name) || 0;

        let sending = {"name": name, "ISO": new Date().toISOString(), "sell": price};
        let headers = {'Content-Type': 'application/json'};

        for (const [key, value] of this.state.query.entries()) {
            headers[key] = value
        }

        fetch(urlBase.stats + '/v1/forging/minis', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(sending)
        })
        .then(function (response) {
            if (response.status === 401) {
                let message = ["The Maze isn't meant for you", "This isn't the matrix", "This is a wall not a door!", "Really?"];
                alert(message[Math.floor(Math.random() * message.length)]);
            }
            if (response.status === 200) {
                reloadPage();
                console.log("Success")
            }
        })
        .catch(err => console.log(err))

    }


    tableManager = (data) => {
        let config = {
            className: {
                table: "centerTable table-primary table-striped table-highlight",
            },
            templates: {
                "text": {
                    className: "left",
                },
                "number": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "percent": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "gold": {
                    className: "right",
                    contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
                    sort: sortGeneral,
                    filter: (item, filter) => filterGeneral(item, filter, 10000)
                }
            },
            colsToDisplay:20,
            sort:{ col: "name", desc: true },
            headers: {
                "Data": {
                    cols: [
                        { template: "text", header: "Name", accessor: "name" },
                        { template: "number", header: "Count", accessor: "count" },
                        { template: "percent", header: "% Chance", accessor: "chanceFormatted" },
                    ]
                },
                "Original": {
                    cols: [
                        { template: "gold", header: "Avg Value", accessor: "originalAvgValueFormatted" },
                        { template: "gold", header: "Per Forge", accessor: "originalPerValueFormatted" },
                    ]
                },
                "Current": {
                    cols: [
                        { template: "gold", header: "Avg Value", accessor: "currentAvgValueFormatted" },
                        { template: "gold", header: "Per Forge", accessor: "currentPerValueFormatted" },
                    ]
                }
            },
            headerOrder:["Data","Original", "Current"]
        }
        return this.createTable(data, config)
    }

    createTable = (data, config) => {
        return <SilverTable
          data={data}
          config={config}
        />
    }


    render() {
        if (typeof this.state.output === "undefined") {return <Loading/>}

        let imputBox = <div>
            <AutoComplete
              array={this.state.priceData0}
              callbackToParent={this.myCallback}
              sorter={"name"}
              placeholder={"Mini Name"}
              startRenderString={"-1"}
            />
        </div>

        let overallData = this.state.overallData;
        let overallStatsTable = <Table
          striped bordered
          className="table-primary"
        >
            <thead>
            <tr>
                <th className={"left"}>Parameter</th>
                <th>Original</th>
                <th>Current</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={"left"}>Forges</td>
                <td className={"right"}>{overallData.total}</td>
                <td className={"right"}>N/A</td>
            </tr>
            <tr>
                <td className={"left"}>Total Value</td>
                <td className={"right"}><GetCurrency number={overallData.originalTotalValue} size={25} /></td>
                <td className={"right"}><GetCurrency number={overallData.currentTotalValue} size={25} /></td>
            </tr>
            <tr>
                <td className={"left"}>Per Forge</td>
                <td className={"right"}><GetCurrency number={overallData.originalTotalAvg} size={25} /></td>
                <td className={"right"}><GetCurrency number={overallData.currentTotalAvg} size={25} /></td>
            </tr>
            </tbody>
        </Table>

        let style = { "overflow-x": "auto", "white-space":"nowrap", "margin": "0 auto" , "max-width":"1100px"}
        return <div>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/forging#minis"} />
            <br/>
            <div style={style}>
                <Container>
                    <Row>
                        <Col>{imputBox}</Col>
                        <Col>{overallStatsTable}</Col>
                    </Row>
                </Container>
            </div>
            <br/>
            {this.tableManager(this.state.output)}
        </div>
    }
}
