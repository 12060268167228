import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import dateFormat from 'dateformat'
import Highcharts from 'highcharts/highstock'
import { Chart, ColumnSeries, HighchartsStockChart, Legend, Navigator, RangeSelector, Tooltip, withHighcharts, XAxis, YAxis, } from 'react-jsx-highstock'
import { graphOptions, urlBase } from '../_utiilities/data'
import { Documentation, Loading, SilverTable} from '../_utiilities/functions_react'
import { filterGeneral, filterGeneralText, loadData, sortGeneral } from '../_utiilities/functions'

function secondsToHms(d) {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor(d % 3600 / 60);
  let s = Math.floor(d % 3600 % 60);

  let hDisplay = h > 0 ? h + (m > 9 ? "h" : "h0") : "";
  let mDisplay = m > 0 ? m + (s > 9 ? "m" : "m0") : "";
  let sDisplay = s > 0 ? s + "s" : "";
  return hDisplay + mDisplay + sDisplay;
}
function sorting (array, attribute, reverse) {
  return array.sort(function (a, b) {
    let attrA = a[attribute]
    let attrB = b[attribute]

    if (reverse) {
      if (attrA < attrB) { return 1 }
      if (attrA > attrB) { return -1 }
    } else {
      if (attrA < attrB) { return -1 }
      if (attrA > attrB) { return 1 }
    }

    return 0
  })
}


class WvWStatsViewer_Component extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: props.session,
      accountMain: props.account,
      account: undefined,
      wvwStats: undefined,
      fullCompact: 1,
      graph:true,
      filteredArray:[],
      filter: [],
      graphData:{},
      dateRangeStart: undefined,
      dateRangeEnd: undefined,
      minDate:undefined,
      maxDate:undefined,
      defaultAccountID:"",
      error: undefined,

    };

    this.changeFullCompact = this.changeFullCompact.bind(this);
    this.handleCharacterSelection = this.handleCharacterSelection.bind(this);
  }

  // will need this in all sub components
  async componentDidUpdate(prevProps ) {
    if (typeof prevProps.session === "undefined" && typeof this.props.session !== "undefined"){
      this.setState({ session: this.props.session })
    }
    if (typeof prevProps.account === "undefined" && typeof this.props.account !== "undefined"){
      this.setState(await this.processAccount(this.props.account))
    }
  }

  async componentDidMount() {
    this.setState(await this.processAccount(this.state.accountMain));
  }

  processAccount = async (account) =>{
    let tmp = {}
    if(!account){return tmp}

    tmp.accountMain = account;
    let userIDs = Object.keys(account.gameAccounts);
    if(userIDs.length > 0){
      tmp.defaultAccountID = userIDs[0];
      tmp = await this.loadWvWStats(tmp,this.state.session,tmp.defaultAccountID)
    }
    if(userIDs.length > 1){
      tmp.accountMain.gameAccounts["All"] = {};
    }
    return tmp
  }

  async loadWvWStats(tmp,session,accountID) {
    let wvwStatsRaw = await loadData(urlBase.account + "/v1/wvwStats?beautify=min", {method: 'GET', headers: { 'Content-Type': 'application/json', session: session, accountID: accountID } }, []);

    tmp.account = wvwStatsRaw.account;
    tmp.wvwStats = [];
    tmp.minDate = undefined;
    tmp.maxDate = undefined;

    if (wvwStatsRaw.result.length > 0) {
      tmp.wvwStats = wvwStatsRaw.result;
      tmp = this.processStats(tmp);
    }
    return tmp;
  }

   processStats = (tmp) => {
    if(tmp.wvwStats.length >0){
      tmp.wvwStats = sorting(tmp.wvwStats, "date", false)
      tmp.minDate = Date.parse(tmp.wvwStats[0].date)
      tmp.maxDate = Date.parse(tmp.wvwStats[tmp.wvwStats.length - 1].date)
    }
    return tmp;
  }

  convertToTable = (compacted, start, end) =>{
    // Expanded is each day browken down into each character
    // normal is each days totals
    // compact is each character
    let expanded = []
    let normal = []
    let compact = []
    let characterCompacted = {}

    for(let i=0;i<compacted.length;i++){
      let date = compacted[i].date
      if(typeof start !== "undefined" && typeof end !== "undefined") {
        let currentDate = Date.parse(date);
        if(start <= currentDate && currentDate <= end) {}else{continue;}
      }
      date= date.substring(0,10)
      let totalKeys = Object.keys(compacted[i])
      if(totalKeys.length <= 4){continue}
      let dayTemp = {deaths:0}
      for(let j=0;j<totalKeys.length;j++){
        if(totalKeys[j] === "character"){continue}
        if(totalKeys[j] === "characters"){continue}
        dayTemp[totalKeys[j]] = compacted[i][totalKeys[j]]
      }
      dayTemp.date = date
      let totalPlaytime = compacted[i].age
      let characters = Object.keys(compacted[i].characters)
      for(let j=0;j<characters.length;j++){
        let characterTemp = {}
        characterTemp.date = date
        characterTemp.character = characters[j].slice(21)
        characterTemp.age = compacted[i].characters[characters[j]].age
        characterTemp.deaths = compacted[i].characters[characters[j]].deaths

        if(typeof characterCompacted[characterTemp.character] === "undefined"){
          characterCompacted[characterTemp.character] = { character: characterTemp.character }
        }

        dayTemp.deaths+= characterTemp.deaths
        for(let k=0;k<totalKeys.length;k++){
          let ratio = compacted[i].characters[characters[j]].age/totalPlaytime
          if(totalKeys[k] === "character"){continue}
          if(totalKeys[k] === "characters"){continue}
          if(totalKeys[k] === "date"){continue}
          if(totalKeys[k] === "age"){
            ratio =0.00027777777
            characterTemp[totalKeys[k]] = (characterTemp.age * ratio).toFixed(2)-0
            continue
          }
          characterTemp[totalKeys[k]] = Math.round(dayTemp[totalKeys[k]] * ratio)

          if(typeof characterCompacted[characterTemp.character][totalKeys[k]] === "undefined"){
            characterCompacted[characterTemp.character][totalKeys[k]] =0
          }
          characterCompacted[characterTemp.character][totalKeys[k]]+=characterTemp[totalKeys[k]]
        }

        if(typeof characterCompacted[characterTemp.character].deaths === "undefined"){
          characterCompacted[characterTemp.character].deaths = 0
        }
        characterCompacted[characterTemp.character].deaths += characterTemp.deaths
        if(typeof characterCompacted[characterTemp.character].age === "undefined"){
          characterCompacted[characterTemp.character].age = 0
        }
        characterCompacted[characterTemp.character].age += characterTemp.age

        characterCompacted[characterTemp.character].kdr = (characterCompacted[characterTemp.character].kills/characterCompacted[characterTemp.character].deaths).toFixed(2)-0 || 0
        characterCompacted[characterTemp.character].kPerHr = (characterCompacted[characterTemp.character].kills/characterCompacted[characterTemp.character].age).toFixed(2)-0 || 0
        characterCompacted[characterTemp.character].rankPerHr = (characterCompacted[characterTemp.character].wvw_rank/characterCompacted[characterTemp.character].age).toFixed(2)-0 || 0

        characterTemp.kdr = (characterTemp.kills/characterTemp.deaths).toFixed(2)-0 || 0
        characterTemp.kPerHr = (characterTemp.kills/characterTemp.age).toFixed(2)-0 || 0
        characterTemp.rankPerHr = (characterTemp.wvw_rank/characterTemp.age).toFixed(2)-0 || 0
        expanded.push(characterTemp)
      }
      dayTemp.age = (compacted[i].age  * 0.00027777777).toFixed(2)-0 || 0
      dayTemp.kdr = (dayTemp.kills/dayTemp.deaths).toFixed(2)-0 || 0
      dayTemp.kPerHr = (dayTemp.kills/dayTemp.age).toFixed(2)-0 || 0
      dayTemp.rankPerHr = (dayTemp.wvw_rank/dayTemp.age).toFixed(2)-0 || 0
      normal.push(dayTemp)
    }

    let allCharacters = Object.keys(characterCompacted)
    for(let i=0;i<allCharacters.length;i++){
      characterCompacted[allCharacters[i]].age = characterCompacted[allCharacters[i]].age.toFixed(2)-0 || 0
      compact.push(characterCompacted[allCharacters[i]])
    }

    return {expanded:expanded,normal:normal,compact:compact}
  }

  changeFullCompact = () => {
    let newLevel
    switch (this.state.fullCompact) {
      case 0:newLevel = 1; break;
      case 1:newLevel = 2; break;
      case 2:newLevel = 0; break;
      default: newLevel = 0;
    }
    this.setState({ fullCompact: newLevel });
  };

  changeMode = () => {
    this.setState({ mode: !this.state.mode });
  }

  processFilter = (tmp, filter) =>{
    for(let i=0;i<filter.length;i++){
      let template = this.state.templates[filter[i].template]
      let accessor = filter[i].id
      if(typeof template.filter !== "undefined"){
        tmp = tmp.filter((item) => template.filter(item[accessor], filter[i].value))
      }else{
        tmp = tmp.filter((item)=> filterGeneralText(item[accessor],filter[i].value))
      }
    }
    return tmp;
  }

  graphManager = (data, filter) => {
    data = this.processFilter(data, filter)
    let graphData = this.processGraph(data)
    return this.createGraph(graphData)
  }

  processGraph = (data)=> {
    let tmp = {}
    let dates = []
    for (let i = 0; i < data.length; i++) {
      let date = new Date(data[i].date).getTime()

      if (typeof tmp[date] === "undefined") {
        tmp[date] = {}
        dates.push(date)
      }
      let keys = Object.keys(data[i])
      for (let j = 0; j < keys.length; j++) {
        let key = keys[j]
        if (key === "date") {continue}
        if (key === "character") {continue}
        if (typeof tmp[date][key] === "undefined") {
          tmp[date][key] = 0
        }
        tmp[date][key] += data[i][key]
      }
    }
    let result = {}
    for(let i=0;i<dates.length;i++){
      let date = dates[i]
      let data = tmp[date]
      let keys = Object.keys(data)
      for (let j = 0; j < keys.length; j++) {
        let key = keys[j]
        if(typeof result[key] === "undefined"){
          result[key] = []
        }
        result[key].push([date,data[key] ])
      }
    }
    return result
  }

  createGraph = (graphData) => {
    let navigator = []
    let yAxis = []
    let yAxisOpposite = []
    let yAxisKills = []
    let converter = {
      "age": " Hours",
      "kills": " Kills",
      "deaths": " Deaths",
      "wvw_rank": " WvW Rank",
      "kdr": " K/D",
      "supplySpentRepair": "Supply spent on repair",
      "objectiveDefend": "Objective Defend",
      "objectiveCapture": "Objective Capture",
      "stoneMistDefend": "StoneMist Defend",
      "stoneMistCapture": "StoneMist Capture",
      "keepDefend": "Keep Defend",
      "keepCapture": "Keep Capture",
      "towerDefend": "Tower Defend",
      "towerCapture": "Tower Capture",
      "campDefend": "Camp Defend",
      "campCapture": "Camp Capture",
      "yakDefend": "Yak Defend",
      "yakCapture": "Yak Capture"
    }
    let graphKeys = Object.keys(converter)
    for (let i = 0; i < graphKeys.length; i++) {
      let converted = converter[graphKeys[i]]
      let visible = false
      if (
        graphKeys[i] === "age"
        || graphKeys[i] === "kills"
        || graphKeys[i] === "deaths"
        || graphKeys[i] === "wvw_rank"
        || graphKeys[i] === "kdr"
      ) {
        visible = true
      }

      navigator.push(<Navigator.Series seriesId={converted} key={"navigator_" + converted}/>)
      if (graphKeys[i] === "age") {
        yAxisOpposite.push(<ColumnSeries name={converted} id={converted} key={"yaxis_" + converted} data={graphData[graphKeys[i]]} visible={visible}/>)
        continue
      }
      if (graphKeys[i] === "kills") {
        yAxisKills.push(<ColumnSeries name={converted} id={converted} key={"yaxis_" + converted} data={graphData[graphKeys[i]]} visible={visible}/>)
        continue
      }
      yAxis.push(<ColumnSeries name={converted} id={converted} key={"yaxis_" + converted} data={graphData[graphKeys[i]]} visible={visible}/>)
    }

    return <HighchartsStockChart
      callback={this.getChart}
      styledMode
      plotOptions={graphOptions.plotOptions}
    >
      <Chart/>
      <Legend/>
      <RangeSelector
       // selected={4}
      >
        <RangeSelector.Button count={1} type="day">1d</RangeSelector.Button>
        <RangeSelector.Button count={7} type="day">7d</RangeSelector.Button>
        <RangeSelector.Button count={1} type="month">1m</RangeSelector.Button>
        <RangeSelector.Button count={3} type="month">3m</RangeSelector.Button>
        <RangeSelector.Button count={12} type="month">1y</RangeSelector.Button>
        <RangeSelector.Button type="all">All</RangeSelector.Button>
        <RangeSelector.Input boxBorderColor="#7cb5ec"/>
      </RangeSelector>

      <Tooltip
        shared={true}
        formatter={function () {
          let s = '<b> ' + dateFormat(new Date(this.x), "yyyy-mm-dd ddd") + '</b>'
          for (let i = 0; i < this.points.length; i++) {
            let name = this.points[i].series.name;
            let value = this.points[i].y
            let colour = this.points[i].color
            let tmp = '<br/><span style="color:' + colour + '">\u25CF</span>' + name + ': '
            if (name === " Hours" || name === " K/D") {
              value = value.toFixed(2)-0 || 0
            }else{
              value = value.toFixed(0)-0 || 0
            }
            tmp += value
            s += tmp;
          }
          return s;
        }}
      />

      <XAxis onSetExtremes={this.getDataFromNav} min={this.state.minDate}
             max={this.state.maxDate} type={"datetime"}/>
      <YAxis
        labels={{ enabled: false }}
        opposite
      >
        {yAxisOpposite}
      </YAxis>
      <YAxis
        labels={{ enabled: false }}
      >
        {yAxisKills}
      </YAxis>
      <YAxis
        labels={{ enabled: false }}
      >
        {yAxis}
      </YAxis>
      <Navigator xAxis={{ min: this.state.minDate, max: this.state.maxDate }}>
        {navigator}
      </Navigator>
    </HighchartsStockChart>
  }

  getDataFromNav = async (dataFromChild) => {
    let tmp= {};
    if(!isNaN(dataFromChild.min)){
      tmp.startDate = new Date(new Date(dataFromChild.min).setUTCHours(0,0,0,0)).toISOString();
      tmp.startGraphDate = dataFromChild.min;
    }
    if(!isNaN(dataFromChild.max)){
      tmp.endDate = new Date(new Date(dataFromChild.max).setUTCHours(0,0,0,0)).toISOString();
      tmp.endGraphDate = dataFromChild.max;
    }
    tmp.dateRangeStart =Date.parse(tmp.startDate);
    tmp.dateRangeEnd = Date.parse(tmp.endDate);
    this.setState(tmp);
  };

  async handleCharacterSelection(event){
    let tmp = {};
    tmp.defaultAccountID = event.target.value;
    if(tmp.defaultAccountID === "All"){
      tmp.wvwStats = [];

      let userIDs = Object.keys(this.state.accountMain.gameAccounts);
      for(let i=0;i<userIDs.length;i++){
        if(userIDs[i] !== "All") {
          let wvwStatsRaw = await loadData(urlBase.account + "/v1/wvwStats?beautify=min", {method: 'GET', headers: { 'Content-Type': 'application/json', session: this.state.session, accountID: userIDs[i] } }, []);
          if (i === 0) {
            tmp.account = wvwStatsRaw.account;
          }
          for(let j=0;j<wvwStatsRaw.result.length;j++){
            tmp.wvwStats.push(wvwStatsRaw.result[j])
          }
        }
      }

      tmp = await this.processStats(tmp,false);
    }else{
      tmp = await this.loadWvWStats(tmp,this.state.session,tmp.defaultAccountID)
    }
    this.setState(tmp)
  }

  getChart = chart => {
    chart.reflow();
  }

  tableManager = (tableData,fullCompact) => {
    let data
    if (fullCompact === 0) {
      data = tableData.expanded
    }
    if (fullCompact === 1) {
      data = tableData.normal
    }
    if (fullCompact === 2) {
      data = tableData.compact
    }

    let config = {
      className: { table: "centerTable table-primary table-striped table-highlight" },
      templates: {
        "text": {
          className: "left",
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "age": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "percent": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        }
      },
      colsToDisplay:20,
      filter: {active:true},
      sort:{col:"date", desc:true},
      headers: {
        "Details": {
          className:"left",
          cols: [
            { template: "text", header: "Date", accessor: "date" },
            { template: "text", header: "Character", accessor: "character" },
          ]
        },
        "Main Data": {
          collapse:true,
          className:"left",
          cols: [
            { template: "age", header: "Hours", accessor: "age" },
            { template: "number", header: "Rank", accessor: "wvw_rank" },
            { template: "number", header: "Kills", accessor: "kills" },
            { template: "number", header: "Deaths", accessor: "deaths" },
            { template: "number", header: "Supply", accessor: "supplySpentRepair" },
          ]
        },
        "Calculated": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "K/D", accessor: "kdr" },
            { template: "number", header: "K/h", accessor: "kPerHr" },
            { template: "number", header: "Rank/h", accessor: "rankPerHr" },
          ]
        },
        "Objectives": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Captured", accessor: "objectiveCapture" },
            { template: "number", header: "Defended", accessor: "objectiveDefend" },
          ]
        },
        "Stonemist": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Captured", accessor: "stoneMistCapture" },
            { template: "number", header: "Defended", accessor: "stoneMistDefend" },
          ]
        },
        "Keeps": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Captured", accessor: "keepCapture" },
            { template: "number", header: "Defended", accessor: "keepDefend" },
          ]
        },
        "Towers": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Captured", accessor: "towerCapture" },
            { template: "number", header: "Defended", accessor: "towerDefend" },
          ]
        },
        "Camps": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Captured", accessor: "campCapture" },
            { template: "number", header: "Defended", accessor: "campDefend" },
          ]
        },
        "Yaks": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Captured", accessor: "yakCapture" },
            { template: "number", header: "Defended", accessor: "yakDefend" },
          ]
        }
      },
      headerOrder:["Details","Main Data", "Calculated", "Objectives", "Stonemist","Keeps","Towers","Camps","Yaks"]
    }

    if (fullCompact === 1) {
      config.headers["Details"].cols = [{ template: "text", header: "Date", accessor: "date" }]
    }
    if (fullCompact === 2) {
      config.headers["Details"].cols = [{ template: "text", header: "Character", accessor: "character" }]
      config.sort.col = "character"
    }

    if(typeof this.state.templates === "undefined"){
      this.setState({templates:config.templates})
    }
    return this.createTable(data, config)
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
      callbackToParent={this.callbackToParent}
    />
  }

  callbackToParent = (result) => {
    let filter = []
    let newFilters = Object.keys(result.filter)
    for (let i = 0; i < newFilters.length; i++) {
      let data = result.colOrder.filter(item => item.accessor === newFilters[i])
      if(data.length === 0){continue}
      let selected = data[0]
      filter.push({
        id: selected.accessor,
        value: result.filter[selected.accessor],
        template: selected.template
      })
    }
    this.setState({ filter: filter })
  }

  render() {
    let {session,wvwStats, error, accountMain, defaultAccountID, account, maxDate, minDate,dateRangeEnd, dateRangeStart, fullCompact, filter} = this.state;
    if (typeof this.state.session === "undefined") {return <Redirect to='/login'/>;}
    if (typeof this.state.wvwStats === "undefined") {return <Loading/>}
    if (typeof this.state.error !== "undefined") {return this.state.error}

    //if (typeof this.state.accountMain !== "undefined" && typeof this.state.account !== "undefined") {
      let characterDropdown = []
      let userIDs = Object.keys(accountMain.gameAccounts);
      if (userIDs.length > 0) {
        characterDropdown.push(<option key={-1} value={defaultAccountID}>{defaultAccountID}</option>);
        for (let i = 0; i < userIDs.length; i++) {
          if (userIDs[i] !== defaultAccountID) {
            characterDropdown.push(
              <option key={i} value={userIDs[i]}>{userIDs[i]}</option>
            )
          }
        }
      }

      let characterSelect =  <select value={defaultAccountID} onChange={this.handleCharacterSelection}>{characterDropdown}</select>

      let sinceLastUpdate = secondsToHms((new Date() - new Date(account.lastUpdate)) / 1000);
      let toNextUpdate = secondsToHms((new Date(account.nextUpdate) - new Date()) / 1000);

      let days = {};
      days.total = Math.ceil((new Date(maxDate) - new Date(minDate)) / 86400000) + 1;
      days.selected = Math.ceil((new Date(dateRangeEnd) - new Date(dateRangeStart)) / 86400000) + 1;

      let fullCompactText
      if (fullCompact === 0) {
        fullCompactText = <span><b>Expanded</b>/Normal/Compact</span>;
      }
      if (fullCompact === 1) {
        fullCompactText = <span>Expanded/<b>Normal</b>/Compact</span>;
      }
      if (fullCompact === 2) {
        fullCompactText = <span>Expanded/Normal/<b>Compact</b></span>;
      }

      let switches = <div>
        <span>
          <b>Account:</b> {characterSelect}&nbsp;
          <b>Last Update:</b> {sinceLastUpdate}&nbsp;
          <b>Next Update:</b> {toNextUpdate}&nbsp;
        </span>
        <br/>
        <span>
          <span onClick={this.changeFullCompact} style={{ cursor: "pointer" }}>{fullCompactText}</span> <b>Days (Total):</b> {days.total} <b>Days (Selected):</b> {days.selected}
        </span>
        <br/>
      </div>;

    let tableData = this.convertToTable(wvwStats,dateRangeStart, dateRangeEnd)
    let graph = this.graphManager(tableData.expanded, filter)
    let outputTable = this.tableManager(tableData, fullCompact)

    return <div style={{align:"center"}} >
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/archives#wvw-stats"} />
      <br/>
      {switches}
      {graph}
      {outputTable}
    </div>;
  }
}

export const WvW_Stats_Viewer = withHighcharts(WvWStatsViewer_Component, Highcharts)
