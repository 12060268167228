import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {version} from "../package.json";
import React from "react";
import {Account} from "./_utiilities/entities"

interface Header_Link {
    link: string;
    title: string;
}
interface Header_Links {
    [propName: string]: Header_Link[]
}

export const Header = (account?: Account, logout?: any) => {
    let order = ["Support","Friends","Archives", "TradePost", "Forging", "Stats", "To CSV" ,"Misc"]
    // farmingTracker
    let loggedInData: Header_Links = {
        Archives:[
            {
                link:"#/transaction_viewer",
                title:"TradePost Transactions"
            },
            {
                link:"#/WvW_Stats",
                title:"WvW Stats"
            }
        ],
        Stats:[
        ],
        "To CSV":[
            {
                link:"#/account_to_csv",
                title:"Account(s) to CSV"
            },
            {
                link:"#/farmingTracker",
                title:"Farming Tracker"
            }
        ],
        Misc:[
            {
                link:"#/skin_2_win",
                title:"Skin to Win"
            },
            {
                link:"#/freeBagSlots",
                title:"Free Bag Slots"
            },
            {
                link:"#/story_tracker",
                title:"Story Tracker"
            },
            {
                link:"#/gemstore_notifier",
                title:"Gemstore Notifierr"
            }
        ]
    }
    let loggedOutData: Header_Links = {
        "To CSV":[
            // now able to be used with a dialog box
            {
                link:"#/inventory_to_csv",
                title:"Inventory to CSV"
            },
        ],
        Support:[
            {
                link:"https://slate.silveress.ie/gw2_site#contact",
                title:"Contact"
            },
            {
                link:"https://gitlab.com/Silvers_Gw2/Stats_Frontend/issues",
                title:"Make a Suggestion"
            },
            {
                link:"#",
                title:""
            },
            {
                link:"#/support",
                title:"This Site"
            },
            {
                link:"https://www.patreon.com/Silver_Golden",
                title:"This Site - Patreon"
            },
            {
                link:"#",
                title:""
            },
            {
                link:"#/reset/username",
                title:"Username Reminder"
            },
            {
                link:"#/reset/password",
                title:"Reset Password"
            }
        ],
        Friends:[
            {
                link:"#/friends",
                title:"Friends"
            }
        ],
        TradePost:[
            {
                link:"#/tradepost",
                title:"Overview"
            },
            {
                link:"#/tradepost/detailed",
                title:"Detailed"
            },
            {
                link:"#/tradepost/gnashblade",
                title:"Gnashblade"
            },
        ],
        Forging:[
            {
                link:"#/minis",
                title:"Minis"
            },
            {
                link:"#/gemstones",
                title:"Gemstones"
            },
            {
                link:"#/precursors",
                title:"Precursors"
            }
        ],
        Stats:[
            {
                link:"#/precursors_stats",
                title:"Precursors Stats"
            }
        ],
        Misc:[
            {
                link:"#/snatch_it",
                title:"Snatch-it"
            },
            {
                link:"#/snipe_it",
                title:"Snipe-it"
            },
            {
                link:"#/bltc_tickets",
                title:"BLTC Tickets"
            },
            {
                link:"#/refinement",
                title:"Refinement"
            },
            {
                link:"#/buyout",
                title:"Buyout"
            },
            {
                link:"#/mc_standard",
                title:"The Mystic Coin Standard"
            },
            {
                link:"#/alt_standard",
                title:"Alt Currencies"
            },
            {
                link:"#/raid_currency",
                title:"Raid Currency"
            },
            {
                link:"#/extracting",
                title:"Extracting"
            },
            {
                link:"#/historicCrafting",
                title:"Historic Crafting"
            },
            {
                link:"#/vendor_trash",
                title:"Vendor Trash"
            },
            {
                link:"#/festival_winds",
                title:"Zephyrite Supply Box"
            }
        ]
    }

    // first defaults to logged out
    let dropdown =  <Nav className="mr-auto">
        <NavDropdown title={"Login/SignUp"} id="basic-nav-dropdown" alignRight>
            <NavDropdown.Item href={"#/login"}>Login</NavDropdown.Item>
            <NavDropdown.Item href={"#/signup"}>Sign-Up</NavDropdown.Item>
        </NavDropdown>
    </Nav>

    let tempNav: Header_Links = {}
    if (typeof account !== "undefined") {
        let username = account.user

        // give loggedin dropdown
        dropdown = <Nav className="mr-auto">
            <NavDropdown title={username} id="basic-nav-dropdown" alignRight>
                <NavDropdown.Item href={"#/account"}>Account Management</NavDropdown.Item>
                <NavDropdown.Item href={"#/lists"}>Market Lists</NavDropdown.Item>
                <NavDropdown.Item href={"#/verification_management"}>Verification Lists</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item href={"#/login"} onClick={logout}>Logout</NavDropdown.Item>
            </NavDropdown>
        </Nav>

        // build logged in data First if available
        for(let i=0;i<order.length;i++){
            if(typeof loggedInData[order[i]] !== "undefined"){
                tempNav[order[i]] = loggedInData[order[i]]
            }
        }
    }
    // build logged out data
    for(let i=0;i<order.length;i++){
        if(typeof loggedOutData[order[i]] !== "undefined"){
            if(typeof tempNav[order[i]] === "undefined"){ tempNav[order[i]] = []}
            for(let j=0;j<loggedOutData[order[i]].length;j++){
                tempNav[order[i]].push(loggedOutData[order[i]][j])
            }
        }
    }

    // now build teh navbat
    let dropdowns = []
    for(let i=0;i<order.length;i++){
        if(typeof tempNav[order[i]] === "undefined"){ continue}
        let tmp = []
        for(let j=0;j<tempNav[order[i]].length;j++){
            if(tempNav[order[i]][j].link.indexOf("http") === -1){
                tmp.push(<NavDropdown.Item href={tempNav[order[i]][j].link} key={j+"NavDropdown"}>{tempNav[order[i]][j].title}</NavDropdown.Item>)
            }else{
                tmp.push(<NavDropdown.Item href={tempNav[order[i]][j].link} key={j+"NavDropdown"} target={"_blank"} rel={"noopener noreferrer"}>{tempNav[order[i]][j].title}</NavDropdown.Item>)
            }
        }
        dropdowns.push(
            <NavDropdown title={order[i]} id='basic-nav-dropdown' alignRight key={i+"dropdowns"}>
                {tmp}
            </NavDropdown>
        )
    }

    return <Navbar collapseOnSelect expand="lg" bg={"primary"} variant={"dark"}>
        <Navbar.Brand href="#/">
            DataWars2 <sub>v{version}</sub>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
            {dropdown}
            <Nav className="ml-auto">
                {dropdowns}
            </Nav>
        </Navbar.Collapse>
    </Navbar>

}