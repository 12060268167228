import React from "react";
import {Switch, Route} from "react-router-dom";
import {Account} from "./_utiilities/entities"

import { Home } from "./loggedOut/home";
import { Precursors } from "./loggedOut/precursors";
import { Precursor_Stats } from "./loggedOut/precursors_stats";
import { Gemstones } from "./loggedOut/gemstones";
import { BLTC_Tickets } from "./loggedOut/bltc_tickets";
import { Minis } from "./loggedOut/minis";
import { TP } from "./loggedOut/tp"
import { TP_Detailed } from "./loggedOut/tp_detailed"
import { TP_Gnashblade } from "./loggedOut/tp_gnashblade"
import { TP_Item } from "./loggedOut/tp_item"
import { Skin_2_Win } from "./loggedIn/skin2win"
import { Snatch_It } from "./loggedOut/snatch_it"
import { Snipe_It } from "./loggedOut/snipe_it"
import { Stats_Collection } from "./loggedIn/stats_collection"
import { Stats_Viewer } from "./loggedOut/stats_collection_viewer"
import { Friends } from "./loggedOut/friends"
import { Account_Page } from "./loggedIn/A_account"
import { Transaction_Viewer } from "./loggedIn/transactionViewer"
import { Account_Lists } from "./loggedIn/A_lists"
import { WvW_Stats_Viewer } from "./loggedIn/wvwStats"
import { Inventory_To_CSV } from "./loggedIn/inventoryToCSV"
import { Support } from "./loggedOut/support"
import { Refinement } from "./loggedOut/refinement"
import { Free_Bag_Slots } from "./loggedIn/freeBagSlots"
import { Account_Verification } from "./loggedIn/A_verification"
import { Verification } from "./loggedOut/verification"
import { Buyout } from "./loggedOut/buyout"
import { Account_To_CSV } from "./loggedIn/account_to_csv"
import { MC_Standard } from "./loggedOut/mc_standard"
import { Alt_Standard } from "./loggedOut/alt_standard"
import { Story_Tracker } from "./loggedIn/storyTracker"
import { Raid_Currency } from "./loggedOut/raid_currency"
import { Extracting } from "./loggedOut/extracting"
import { Fractal_Encryption } from "./loggedOut/fractal_encryption"
import { Login } from "./login/login"
import { Signup } from "./login/signup"
import { Recover_Username } from "./recovery/username"
import { Recover_Password } from "./recovery/password"
import { Farming_Tracker } from "./loggedIn/farmingTracker"
import { Vendor_Trash } from "./loggedOut/vendor_trash"
import { Gemstore } from "./loggedIn/gemstore"
import { Festival_Winds } from "./loggedOut/festival_winds"

export const Routes = (account?: Account, session?: string) =>{
      let routes =  [
            <Route exact path="/"><Home account={account} session={session}/></Route>,
            <Route path="/precursors"><Precursors account={account} session={session}/></Route>,
            <Route path="/precursors_stats"><Precursor_Stats account={account} session={session}/></Route>,
            <Route path="/gemstones"><Gemstones account={account} session={session}/></Route>,
            <Route path="/bltc_tickets"><BLTC_Tickets account={account} session={session}/></Route>,
            <Route path="/minis"><Minis account={account} session={session}/></Route>,

            <Route exact path="/tradepost"><TP account={account} session={session}/></Route>,
            <Route exact path="/tradepost/detailed"><TP_Detailed account={account} session={session}/></Route>,
            <Route exact path="/tradepost/gnashblade"><TP_Gnashblade account={account} session={session}/></Route>,
            <Route exact path="/tradepost/item/:id" children={({ match }) => (<TP_Item account={account} session={session} match={match} />)}/>,

            <Route path="/skin_2_win"><Skin_2_Win account={account} session={session}/></Route>,
            <Route path="/snatch_it"><Snatch_It account={account} session={session}/></Route>,
            <Route path="/snipe_it"><Snipe_It account={account} session={session}/></Route>,
            <Route path="/stats_collection"><Stats_Collection account={account} session={session}/></Route>,
            <Route path="/stats_viewer"><Stats_Viewer account={account} session={session}/></Route>,
            <Route path="/friends"><Friends account={account} session={session}/></Route>,

            <Route path="/login"><Login account={account} session={session}/></Route>,
            <Route path="/signup"><Signup account={account} session={session}/></Route>,
            <Route path="/reset/username"><Recover_Username account={account} session={session}/></Route>,
            <Route path="/reset/password"><Recover_Password account={account} session={session}/></Route>,

            <Route path="/account"><Account_Page account={account} session={session}/></Route>,
            <Route path="/lists"><Account_Lists account={account} session={session}/></Route>,

            <Route path="/transaction_viewer"><Transaction_Viewer account={account} session={session}/></Route>,
            <Route path="/WvW_Stats"><WvW_Stats_Viewer account={account} session={session}/></Route>,

            <Route path="/support"><Support account={account} session={session}/></Route>,
            <Route path="/refinement"><Refinement account={account} session={session}/></Route>,
            <Route path="/freeBagSlots"><Free_Bag_Slots account={account} session={session}/></Route>,

            <Route path="/verification_management"><Account_Verification account={account} session={session}/></Route>,
            <Route path="/verification"><Verification account={account} session={session}/></Route>,

            <Route path="/buyout"><Buyout account={account} session={session}/></Route>,

            <Route path="/inventory_to_csv"><Inventory_To_CSV account={account} session={session}/></Route>,
            <Route path="/account_to_csv"><Account_To_CSV account={account} session={session}/></Route>,
            <Route path="/farmingTracker"><Farming_Tracker account={account} session={session}/></Route>,

            <Route path="/mc_standard"><MC_Standard account={account} session={session}/></Route>,
            <Route path="/alt_standard"><Alt_Standard account={account} session={session}/></Route>,

            <Route path="/story_tracker"><Story_Tracker account={account} session={session}/></Route>,
            <Route path="/raid_currency"><Raid_Currency account={account} session={session}/></Route>,
            <Route path="/extracting"><Extracting account={account} session={session}/></Route>,
            <Route path="/vendor_trash"><Vendor_Trash account={account} session={session}/></Route>,
            <Route path="/gemstore_notifier"><Gemstore account={account} session={session}/></Route>,
            <Route path="/festival_winds"><Festival_Winds account={account} session={session}/></Route>,
            <Route path="/fractal_encryption"><Fractal_Encryption account={account} session={session}/></Route>,
      ]

      return <Switch>
            {routes}
      </Switch>
}