import React, {Component} from 'react';
import { Button, Table } from 'react-bootstrap'
import JSONConverter from "silvers_json_converter"
import { urlBase } from '../_utiilities/data.json'
import { Documentation, GetCurrency, Loading, SilverTable, } from '../_utiilities/functions_react'
import { filterGeneral, loadData, sortGeneral } from '../_utiilities/functions'
import {TextImg} from "../loggedOut/tp_textImg";

export class Inventory_To_CSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: props.session,
      accountMain: props.account,
      userIDs: [],
      currencies: [],
      characterDropdown: "Select Character",
      apiKey: undefined,
      itemData:{},
      firstBags:"",
      filter:[],
      firstSnapshotTime: "",
      defaultAccountID: undefined,
      characters: [],
      box_api: undefined
      //playerID: undefined
    };

    this.handleCharacterSelection = this.handleCharacterSelection.bind(this);
    this.handleCharacterSelection2 = this.handleCharacterSelection2.bind(this);
    this.handleFirstSnapshot = this.handleFirstSnapshot.bind(this);
  }

  // will need this in all sub components
  async componentDidUpdate(prevProps ) {
    if (typeof prevProps.session === "undefined" && typeof this.props.session !== "undefined"){
      this.setState({ session: this.props.session })
    }
    if (typeof prevProps.account === "undefined" && typeof this.props.account !== "undefined"){
      this.setState(await this.processAccount(this.props.account))
    }
  }

  async componentDidMount() {
    let tmp = {};
    let rawItemData = await loadData(urlBase.parser + "/v1/items/json?fields=type,img,id,name,level,rarity,marketable,binding,sell_price,weaponType,statName,charm&beautify=human&filter=id:gt:0");

    tmp.itemData = {};
    for(let i=0;i<rawItemData.length;i++){
      tmp.itemData[rawItemData[i].id] = rawItemData[i];
    }

    tmp = await this.processAccount (this.state.accountMain, tmp)

    this.setState(tmp);
  }

  processAccount = async (account, tmp = {}) =>{
    if(!account){return tmp}

    tmp.accountMain = account;
    let accounts = account.gameAccounts;
    tmp.userIDs = Object.keys(accounts);
    if(tmp.userIDs.length >0){
      tmp.defaultAccountID = tmp.userIDs[0];
      tmp.apiKey = account.gameAccounts[tmp.userIDs[0]].key;
    }

    if (typeof tmp.apiKey !== "undefined") {
      tmp = await this.onApiKey(tmp,tmp.apiKey);
    }

    return tmp
  }

  onApiKey = async (tmp,apiKey) =>  {
    let accountData = await loadData("https://api.guildwars2.com/v2/account?access_token=" + apiKey);
    if (accountData.result === "error"){return tmp}

    tmp.defaultAccountID = accountData.name;

    let characters = await loadData("https://api.guildwars2.com/v2/characters?access_token=" + apiKey);
    if (characters.result === "error"){return tmp}

    tmp.characters = characters;
    return tmp;
  }

  async handleFirstSnapshot() {
    let tmp = {};
    tmp.firstSnapshotTime = new Date().toLocaleString();
    tmp.firstWallet = await loadData("https://api.guildwars2.com/v2/account/wallet?access_token=" + this.state.apiKey);
    tmp.character = await loadData("https://api.guildwars2.com/v2/characters/"+this.state.characterDropdown+"?access_token=" + this.state.apiKey);
    let bags = tmp.character.bags;
    bags = bags.filter(function (el) {return el != null;});
    let tempBag0 = {};
    for(let i=0;i<bags.length;i++){
      let bag = bags[i].inventory;
      for(let ii=0;ii<bag.length;ii++){
        if(bag[ii] !== null){
          let itemID = bag[ii].id;
          let itemCount = bag[ii].count;

          if(typeof tempBag0[itemID] === "undefined"){
            tempBag0[itemID] = 0;
          }
          tempBag0[itemID] += itemCount;
        }
      }
    }
    tmp.firstBags = tempBag0;
    this.setState(tmp)
  }

  async handleCharacterSelection(event){
    let tmp = {};
    tmp.characterDropdown = event.target.value;
    this.setState(tmp)
  }

  getDataFromID = (id, items) =>{
    id = id-0;
    let data = {};
    let result = items[id];
    if(typeof result !== "undefined"){
      data = result
    }
    return data;
  }

  async handleCharacterSelection2(event) {
    // clear existing ones
    this.setState({characters: [], characterDropdown: "Select Character"});

    let tmp = {};
    tmp.defaultAccountID = event.target.value;
    tmp.apiKey = this.state.accountMain.gameAccounts[event.target.value].key;
    tmp = await this.onApiKey(tmp,tmp.apiKey);
    this.setState(tmp);
  }


  bagsToTable(bags){
    let tableData = []
    let ids = Object.keys(bags)
    for(let i=0;i<ids.length;i++){
      let tmp = {}
      tmp.id= ids[i]
      let itemData = this.getDataFromID(tmp.id, this.state.itemData)
      tmp.name = itemData.name
      tmp.quantity = bags[tmp.id]
      tmp.rarity = itemData.rarity
      tmp.img = itemData.img
      tmp.type = itemData.type
      tmp.level = itemData.level
      tmp.binding = itemData.binding
      tmp.weaponType = itemData.weaponType
      tmp.statName = itemData.statName
      tmp.charm = itemData.charm
      let price = itemData.sell_price || itemData.buy_price || 0
      tmp.value_taxed = tmp.quantity * price * 0.85
      tmp.value = tmp.quantity * price
      tableData.push(tmp)
    }
    return tableData
  }

  processFilter = (tmp, filter) => {
    for(let i=0;i<filter.length;i++){
      if(filter[i].id === "name"
        || filter[i].id === "id"
        || filter[i].id === "type"
        || filter[i].id === "rarity"
        || filter[i].id === "binding"
        || filter[i].id === "subType"
        || filter[i].id === "stat"
        || filter[i].id === "charm"
      ){
        tmp = tmp.filter((item)=>{
          let searchItem = item[filter[i].id] || ""
          searchItem  = searchItem.toString().toLowerCase()
          let searchTerm = filter[i].value || ""
          searchTerm = searchTerm.toString().toLowerCase()
          return searchItem.indexOf(searchTerm) !== -1
        })
      }else{
        tmp = tmp.filter((item)=> filterGeneral (item, filter[i].value))
      }
    }
    return tmp;
  }

  getSelectedDetails = (input) => {
    let value = 0
    let value_taxed = 0
    let quantity = 0
    for (let i = 0; i < input.length; i++) {
      if (!isNaN(input[i].value_taxed)) {
        value_taxed += input[i].value_taxed
      }
      if (!isNaN(input[i].value)) {
        value += input[i].value
      }
      if (!isNaN(input[i].quantity)) {
        quantity += input[i].quantity
      }
    }
    return [value, value_taxed, quantity]
  }

  tableManager = (data) => {
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "text": {
          className: "left",
        },
        "textImg": {
          contents: (item) => <TextImg item={item}/>,
          className: "left",
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "percent": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "gold": {
          className: "right",
          contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
          sort: sortGeneral,
          filter: (item, filter) => filterGeneral(item, filter, 10000)
        }
      },
      colsToDisplay:20,
      filter: {active:true},
      sort:{col:"name", desc:true},
      headers: {
        "Details": {
          className:"left",
          cols: [
            { template: "textImg", header: "Item", accessor: "name" },
            { template: "number", header: "Quantity", accessor: "quantity" },
            { template: "gold", header: "Value", accessor: "value" },
            { template: "number", header: "ID", accessor: "id" }
          ]
        },
        "Filters": {
          collapse:true,
          className:"left",
          cols: [
            { template: "text", header: "Binding", accessor: "binding" },
            { template: "text", header: "Rarity", accessor: "rarity" },
            { template: "number", header: "Level", accessor: "level" },
            { template: "text", header: "Type", accessor: "type" },
            { template: "text", header: "Sub-Type", accessor: "weaponType" },
            { template: "text", header: "Stat", accessor: "statName" },
            { template: "text", header: "Charm", accessor: "charm" },

          ]
        }
      },
      headerOrder:["Details","Filters"]
    }

    return this.createTable(data, config)
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
      callbackToParent={this.callbackToParent}
    />
  }

  callbackToParent = (item) =>{
    let filter = []
    let newFilters = Object.keys(item.filter)
    for(let i=0;i<newFilters.length;i++){
      filter.push({
        id:newFilters[i],
        value:item.filter[newFilters[i]]
      })
    }
    this.setState({filter:filter})
  }

  handleChange = (event, field) => {
    this.setState({[field]: event.target.value});
  }


  get_account_select = () =>{
    let {session, userIDs, defaultAccountID, apiKey, accountMain, box_api} = this.state;
    // if logged in give this
    if(typeof session !== "undefined"){
      let characterDropdown = []
      if(userIDs.length >0){
        characterDropdown.push(<option key={-1} value={defaultAccountID} >{defaultAccountID}</option>);
        for(let i=0;i<userIDs.length;i++){
          if(userIDs[i] !== defaultAccountID){
            characterDropdown.push(<option key={i} value={userIDs[i]}>{userIDs[i]}</option>)
          }
        }
        return <select value={defaultAccountID} onChange={this.handleCharacterSelection2}>{characterDropdown}</select>
      }else{
        return <Loading />
      }

    }
    // if apikey is undefined return an input box
    if (
      typeof apiKey === "undefined" &&
      typeof session === "undefined" &&
      typeof accountMain === "undefined"
    ){
      return <form onSubmit={async(event) => {
        event.preventDefault();

        this.setState({box_api: "Loading"});

        let new_state = await this.onApiKey({},box_api);

        // set the new values
        new_state.apiKey = box_api;
        new_state.box_api = undefined;

        this.setState(new_state)
      }}>
        <input
          type="text"
          //required
          value={this.state.box_api}
          onChange={(e)=> this.handleChange( e, "box_api")}
          placeholder={'API Key with "account" "inventories" permissions'}
        />
        &nbsp;
        <input type="submit" value="Submit"/>
      </form>
    }

    // if apikey exists return teh name of the account
    if (
      typeof session === "undefined" &&
      typeof accountMain === "undefined"
    ){
      return <span>{defaultAccountID} - Create account to save key.</span>
    }
  }

  get_character_select = () =>{
    let { apiKey,session, accountMain,  characters, characterDropdown, userIDs} = this.state;

    // basically check if its a non account user
    if (
      typeof apiKey === "undefined" &&
      typeof session === "undefined" &&
      typeof accountMain === "undefined"
    ){
      return null
    }
    // this is for folks with accounts
    if (
      typeof session !== "undefined" &&
      userIDs.length === 0
    ){
      return null
    }

    let characterDropdown_array ;
    if (characters.length === 0 ){
      characterDropdown_array = [<option key="-1" value="">Loading</option>]
    }else{
      characterDropdown_array = [<option key="-1" value="">Select Character</option>]
    }

    for(let i=0;i<characters.length; i++){
      characterDropdown_array.push(<option key={i} value={characters[i]}>{characters[i]}</option>)
    }
    return <form><select style={{width: 200}} value={characterDropdown} onChange={this.handleCharacterSelection}>{characterDropdown_array}</select></form>
  }

  get_first_snapshot = () =>{
    let { characterDropdown} = this.state;
    if(characterDropdown !== "Select Character" && characterDropdown !== "Loading"){
      return <div><Button variant="primary" onClick={this.handleFirstSnapshot}>Snapshot</Button></div>
    }
  }

  get_results = () =>{
    let { firstBags, filter, characterDropdown, firstSnapshotTime} = this.state;

    let outputTable,infoTable;

    if (firstBags !== "") {
      let tableData = this.bagsToTable(firstBags)
      outputTable = this.tableManager(tableData)

      let headers = [
        {title:"ID",key:"id",type:"number"},
        {title:"Name",key:"name",type:"string"},
        {title:"Quantity",key:"quantity",type:"number"}]

      let csvData =  this.processFilter(tableData,filter)
      let name = `${new Date().toISOString()}_${characterDropdown}`;

      let [value, value_taxed,  quantity] = this.getSelectedDetails(csvData)

      infoTable = <Table
        striped
        bordered
        size="sm"
        style={{ "table-layout":"auto", width:"300px", align:"center", margin: "0 auto" }}
        //style={{display: "inline-table"}}

      >
        <thead>
        <tr>
          <th className={"left"}>Value</th>
          <th className={"left"}>Tax</th>
          <th className={"left"}>Value-Tax</th>
          <th className={"left"}>Quantity</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><GetCurrency number={value} size={25} /></td>
          <td><GetCurrency number={value-value_taxed} size={25} /></td>
          <td><GetCurrency number={value_taxed} size={25} /></td>
          <td>{quantity}</td>
        </tr>
        <tr>
          <td className={"left"}>Date</td>
          <td colSpan="3">{firstSnapshotTime}</td>
        </tr>
        <tr>
          <td className={"left"}>Download</td>
          <td className={"left"}><JSONConverter data={csvData} type={"JSON"} beautify={true} headers={headers} text={"Json"} name={name}/></td>
          <td className={"left"}><JSONConverter data={csvData} type={"JSON"} beautify={false} headers={headers} text={"Json-compact"} name={name}/></td>
          <td className={"left"}><JSONConverter data={csvData} type={"CSV"} headers={headers} separator={","} text={"CSV"} name={name}/></td>
        </tr>
        </tbody>
      </Table>
    }

    return {outputTable: outputTable, infoTable:infoTable}
  }

  render() {
    //console.log(this.state)

    let {outputTable,infoTable} = this.get_results();

    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/to_csv#inventory"} />
      <br/>
      {this.get_account_select()}
      <br />
      <br />
      {this.get_character_select()}
      <br />
      {this.get_first_snapshot()}
      <br />
      <div>{infoTable}</div>
      <br />
      {outputTable}
    </div>
  }
}