import React, { Component } from 'react'
import { urlBase } from '../_utiilities/data.json'
import {Table, ProgressBar} from 'react-bootstrap'
import Octicon, {Check, X} from '@githubprimer/octicons-react'
import { Documentation, GetCurrency, getQueryObject, } from '../_utiilities/functions_react'

export class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: getQueryObject(window.location.hash),
      flag:false,
      progress:{current:1,total:4},
      data:{items:undefined, currency:undefined, professions:undefined},
      dataTypes:["items","currency","professions"]
    }
  }

  async loadData(url) {return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))}

  async componentDidMount() {
    let rawItemData = await this.loadData(urlBase.parser + "/v1/items/json?fields=img,id,name&beautify=min&filter=");
    this.setState({progress:{current:2,total:4}})
    let rawCurrenciesData = await this.loadData(urlBase.parser + "/v1/currencies?beautify=min&fields=id,img,name");
    this.setState({progress:{current:3,total:4}})
    let professionsRaw = [{"id":1,"name":"Armorsmith","img":"https://render.guildwars2.com/file/2952B92FA93C03A5281F94D223A4CE4C7E0B0906/102461.png"}, {"id":2,"name":"Artificer","img":"https://render.guildwars2.com/file/0D75999D6DEA1FDFF9DB43BBC2054B62764EB9A0/102463.png"}, {"id":3,"name":"Chef","img":"https://render.guildwars2.com/file/424E410B90DE300CEB4A1DE2AB954A287C7A5419/102465.png"}, {"id":4,"name":"Huntsman","img":"https://render.guildwars2.com/file/0C91017241F016EF35A2BCCE183CA9F7374023FC/102462.png"}, {"id":5,"name":"Jeweler","img":"https://render.guildwars2.com/file/F97F4D212B1294052A196734C71BCE42E199735B/102458.png"}, {"id":6,"name":"Leatherworker","img":"https://render.guildwars2.com/file/192D1D0D73BA7899F1745F32BAC1634C1B4671BF/102464.png"}, {"id":7,"name":"Scribe","img":"https://render.guildwars2.com/file/F95DFA3FBDCC9E9F317551A903E5A2A6DF1CC7E3/1293677.png"}, {"id":8,"name":"Tailor","img":"https://render.guildwars2.com/file/0EB64958BE48AB9605DD56807713215095B8BEED/102459.png"}, {"id":9,"name":"Weaponsmith","img":"https://render.guildwars2.com/file/AEEF1CF774EE0D5917D5E1CF3AAC269FEE5EC03A/102460.png"}]

    let tmp = {};
    tmp.data = this.state.data

    tmp.data.items = {}
    for(let i=0;i<rawItemData.length;i++){
      tmp.data.items[rawItemData[i].id] = rawItemData[i]
    }

    tmp.data.currency = {}
    for(let i=0;i<rawCurrenciesData.length;i++){
      tmp.data.currency[rawCurrenciesData[i].id] = rawCurrenciesData[i]
    }

    tmp.data.professions = {}
    for(let i=0;i<professionsRaw.length;i++){
      tmp.data.professions[professionsRaw[i].id] = professionsRaw[i]
    }


    let data = await this.loadData(urlBase.account + "/v1/verification/"+ this.state.query.list)
    tmp.progress = {current:4,total:4}
    if(data.result === "error"){
      tmp.error = data.error
      tmp.list = undefined
    }
    if(data.result === "success"){
      tmp.list = data.success
      tmp.error = undefined
    }
    tmp.flag = true
    this.setState(tmp)
  }

  getTable = (group) =>{
    let list = this.state.list
    if(list[group].length === 0){return null}

    let groupData = this.state.data[group]
    let square = 25

    let tableRows = []
    let table
    if(group === "items"){
      for(let i=0;i<list[group].length;i++){
        let id = list[group][i].id
        let itemData = getDataFromID(id, groupData)
        let soul = <Octicon icon={Check}/>
        let account = <Octicon icon={Check}/>
        let none = <Octicon icon={Check}/>
        let verified = <Octicon icon={X}/>
        if(list[group][i].binding.character === 0 ){ soul = <Octicon icon={X}/>}else{verified = <Octicon icon={Check}/>}
        if(list[group][i].binding.account === 0 ){ account = <Octicon icon={X}/>}else{verified = <Octicon icon={Check}/>}
        if(list[group][i].binding.none === 0 ){ none = <Octicon icon={X}/>}else{verified = <Octicon icon={Check}/>}

        tableRows.push(
          <tr key={i + list[group][i].id}>
            <td className={"left"} style={{width:"30px"}} >
              <img
                key={itemData.name}
                style={{width: square,height:square}}
                src={itemData.img}
                title={itemData.name}
                alt={itemData.name}
              />
            </td>
            <td className={"left"}>{list[group][i].qty.toLocaleString()}</td>
            <td className={"left"}>{itemData.name}</td>
            <td className={"left"} style={{width:"70px"}}>{soul}</td>
            <td className={"left"} style={{width:"70px"}}>{account}</td>
            <td className={"left"} style={{width:"70px"}}>{none}</td>
            <td className={"left"} style={{width:"70px"}}>{verified}</td>
          </tr>)
      }

      table = <Table responsive size={"sm"} striped={true} style={{width:"700px", align:"left", margin:"auto"}} >
        <thead>
        <tr>
          <th colSpan="2">{this.capitalize(group)}</th>
          <th colSpan="1"/>
          <th colSpan="3">Binding</th>
          <th colSpan="1"/>
        </tr>
        <tr>
          <th className={"left"} style={{width:"30px"}}>Img</th>
          <th className={"left"} style={{width:"70px"}}>Quantity</th>
          <th className={"left"}>Name</th>
          <th className={"left"} style={{width:"70px"}}>Soul</th>
          <th className={"left"} style={{width:"70px"}}>Account</th>
          <th className={"left"} style={{width:"70px"}}>None</th>
          <th className={"left"} style={{width:"70px"}}>Verified</th>
        </tr>
        </thead>
        <tbody>
        {tableRows}
        </tbody>
      </Table>
    }else{
      for(let i=0;i<list[group].length;i++){
        let id = list[group][i].id
        let itemData = getDataFromID(id, groupData)
        let none

        let quantity = list[group][i].qty
        if(group === "currency" && itemData.name === "Coin"){
          quantity = <GetCurrency number={quantity} size={25} />
        }else{
          quantity = quantity.toLocaleString()
        }


        if(list[group][i].valid === 0 ){
          none = <Octicon icon={X}/>
        }else{
          none = <Octicon icon={Check}/>
        }

        tableRows.push(
          <tr key={i + list[group][i].id}>
            <td className={"left"} style={{width:"30px"}} >
              <img
                key={itemData.name}
                style={{width: square,height:square}}
                src={itemData.img}
                title={itemData.name}
                alt={itemData.name}
              />
            </td>
            <td className={"left"}>{quantity}</td>
            <td className={"left"}>{itemData.name}</td>
            <td className={"left"} style={{width:"70px"}}>{none}</td>
          </tr>)
      }

      table = <Table responsive size={"sm"} striped={true} style={{width:"700px", align:"left", margin:"auto"}} >
        <thead>
        <tr>
          <th colSpan="2">{this.capitalize(group)}</th>
          <th colSpan="2">&nbsp;</th>
        </tr>
        <tr>
          <th className={"left"} style={{width:"30px"}}>Img</th>
          <th className={"left"} style={{width:"70px"}}>Quantity</th>
          <th className={"left"}>Name</th>
          <th className={"left"} style={{width:"70px"}}>Verified</th>
        </tr>
        </thead>
        <tbody>
        {tableRows}
        </tbody>
      </Table>
    }

    return <div>
      {table}
      <hr />
    </div>
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  render() {
    let progress = (this.state.progress.current/this.state.progress.total)*100
    let label = this.state.progress.current + "/" + this.state.progress.total + "\t" + progress.toFixed(2)
    let progressBar = <ProgressBar now={progress} variant="success"  label={`${label}%`}/>;
    if(this.state.progress.current !== this.state.progress.total){return progressBar}

    if(typeof this.state.error !== "undefined"){
      return <div style={{align:"center"}}>
        <h5>{this.state.error}</h5>
      </div>
    }

    let list = this.state.list
    let account = list.user
    let lastUpdate = list.lastUpdate
    let listID = list.id

    let information = <Table responsive size={"sm"} striped={true} style={{width:"300px", align:"left", margin:"auto"}} >
      <tbody>
        <tr>
          <td className={"left"}>List ID</td>
          <td className={"left"}>{listID}</td>
        </tr>
        <tr>
          <td className={"left"}>User</td>
          <td className={"left"}>{account}</td>
        </tr>
        <tr>
          <td className={"left"}>Last Updated</td>
          <td className={"left"} title={lastUpdate}>{((new Date() - new Date(lastUpdate))/(1000*60)).toFixed(0)} min ago</td>
        </tr>
      </tbody>
    </Table>

    let explanation = <div>
      Binding: Shows what type of binding the item(s) have.
      <br />
      Verified: Summary of if they have it or not, regardless of binding.
      <br />
      <Octicon icon={Check}/>: They have meet the requirements.
      <br />
      <Octicon icon={X}/>: They do not meet the requirements.
    </div>

    let tables = []
    for(let i=0;i<this.state.dataTypes.length;i++){
      tables.push(this.getTable(this.state.dataTypes[i]))
    }

    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/management#verification-lists"} />
      <br />
      {information}
      <hr />
      {explanation}
      <br />
      {tables}
    </div>
  }
}


function getDataFromID(id, items){
  id = id-0
  let data = {}
  let result = items[id]
  if(typeof result !== "undefined"){
    data = result
  }
  return data;
}