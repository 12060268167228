import React, { Component } from 'react';

function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let supportTypes = []

        supportTypes.push(<div key={"Monetary"}>
            <h4>Monetary</h4>
            <p>
                Basically if you want to support my server costs you can help fun the costs.
                <br />
                I have a
                <a href={"https://www.patreon.com/Silver_Golden"} target={"_blank"} rel={"noopener noreferrer"} > Patreon </a>
                and also accept donations via
                <a href={"https://paypal.me/BrendanGolden"} target={"_blank"} rel={"noopener noreferrer"} > Paypal</a>,
                benefits for you is that this reduces crawl times so your data updates more often.

                <br />
                More details can be found on my <a href={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/patreon"} target={"_blank"} rel={"noopener noreferrer"} >documentation</a>.
            </p>
        </div>)

        supportTypes.push(<div key={"Socially"}>
            <h4>Socially</h4>
            <p>
                <s>Like, Share, Subscribe</s>
                <br />
                Feck, this isnt youtube...  But ye get the gist.
                <br />
                Tell folks about the site, simple right?
            </p>
        </div>)

        supportTypes.push(<div key={"Intellectually"}>
            <h4>Intellectually</h4>
            <p>
                Let me know any ideas and improvements.
                <br />
                If ye have a particular tool that ye want to see I will see if I cna make it.
                <br />
                Likewise with bugs please let me know.
                <br />
                <br />
                For bonus points you can put them down as an issue on my gitlab page (where all teh code lives)
                <br />
                <a href={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/issues"} target={"_blank"} rel={"noopener noreferrer"} >Here, ye can also browse past and present ideas/bugs</a>.
            </p>
        </div>)

        supportTypes.push(<div key={"Randomly"}>
            <h4>Randomly</h4>
            <p>
                Ping me on the /r/gw2economy discord or PM or something, I dont know, is that random enough?
            </p>
        </div>)

        return <div style={{align:"center"}}>
            <br />
            <h3>Supporting me</h3>
            <p>
                So why do I have this page if I have a wiki for it? Because I cant put a direct link in the top menu bar.
                <br />
                In no particular order (literally, refresh the page to see)
            </p>
            <br />
            {shuffle(supportTypes)}
        </div>;
    }
}
