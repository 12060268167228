import React, { Component } from 'react'
import { gw2APIVersion, urlBase } from '../_utiilities/data.json'
import { Form, ProgressBar, Table } from 'react-bootstrap'
import JSONConverter from 'silvers_json_converter'
import {
  Documentation,
  GetCurrency,
  Loading,
  SilverTable,
} from '../_utiilities/functions_react'
import { filterGeneral, filterGeneralText, loadData, sortGeneral } from '../_utiilities/functions'
import {TextImg} from "../loggedOut/tp_textImg";

function arrayFilter(item) {
  return item.account !== "config"
    && typeof item.name !== "undefined"
    && (
      item.noBinding !== 0
      || item.soulbound !== 0
      || item.accountBound !== 0
    )
}

const sort_ignore_case = (a, b) => {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}
const sort_ignore_case_field = (a, b, field) => {
  if(typeof a[field] === "undefined"){return -1}
  if(typeof b[field] === "undefined"){return 1}

  return a[field].toLowerCase().localeCompare(b[field].toLowerCase());
}

export class Account_To_CSV extends Component {
  constructor(props) {
    super(props)
    this.state = {
      session: props.session,
      error: undefined,
      itemData: undefined,
      siteAccount: props.account,
      accounts: [],
      details: {},
      status: {
        initial: { accounts: { total: 0, complete: 0 },total: { total: 0, complete: 0}},
        secondary: { accounts: { total: 0, complete: 0 },total: { total: 0, complete: 0}},
        difference: { accounts: { total: 0, complete: 0 },total: { total: 0, complete: 0}}
      },
      snapshot: { initial: {}, secondary: {}, difference: {} },
      table: {
        initial: { show: true, expanded: [], compact: [], nano: [] },
        secondary: { show: true, expanded: [], compact: [], nano: [] },
        difference: { show: true, expanded: [], compact: [], nano: [] }
      },
      compact:{ initial: 0, secondary: 0 , difference: 0 },
      filter:{ initial: [], secondary: [] , difference: [] },
      settings: {
        initial: { show: false },
        secondary: { show: false },
        difference: { show: false }
      }
    }
  }

  // will need this in all sub components
  async componentDidUpdate(prevProps ) {
    if (typeof prevProps.session === "undefined"  && typeof this.props.session !== "undefined"){
      this.setState({ session: this.props.session })
    }
    if (typeof prevProps.account === "undefined"  && typeof this.props.account !== "undefined"){
      this.setState(await this.processAccount(this.props.account))
    }
  }

  async componentDidMount() {
    let tmp = {};
    let rawItemData = await loadData(urlBase.parser + "/v1/items/json?fields=type,img,id,name,level,rarity,marketable,binding,sell_price&beautify=human&filter=id:gt:0");

    tmp.itemData = {};
    for (let i = 0; i < rawItemData.length; i++) {
      tmp.itemData[rawItemData[i].id] = rawItemData[i]
    }

    tmp = await this.processAccount (this.state.siteAccount, tmp)

    this.setState(tmp);
  }

  processAccount = async (account, tmp = {}) =>{
    if(!account){return tmp}
    
    tmp.siteAccount = account;
    tmp.accounts = Object.keys(account.gameAccounts)

    tmp.details = {}
    tmp.details["All"] = {
      selected: false,
      apiKey: "",
      characters: {
        "All": {
          selected: true,
          equipment: true,
          name: "All"
        }
      },
      guilds: {
        "All": {
          selected: true,
          name: "All",
          id: ""
        }
      },
      sharedInventory: true,
      bank: true,
      materials: true,
      deliveryBox: true,
      tpSells: true
    }
    for (let i = 0; i < tmp.accounts.length; i++) {
      let apiKey = tmp.siteAccount.gameAccounts[tmp.accounts[i]].key
      let [characters, guilds] = await this.onApiKey(apiKey)
      tmp.details[tmp.accounts[i]] = {
        selected: false,
        apiKey: apiKey,
        characters: characters,
        guilds: guilds,
        sharedInventory: true,
        bank: true,
        materials: true,
        deliveryBox: true,
        tpSells: true
      }
    }
    return tmp
  }
  
  async onApiKey(apiKey) {
    let charactersRaw = await loadData("https://api.guildwars2.com/v2/characters?access_token=" + apiKey + '&v=' + gw2APIVersion) || []
    let characters = {}
    for (let i = 0; i < charactersRaw.length; i++) {
      characters[charactersRaw[i]] = {
        selected: true,
        equipment: true,
        name: charactersRaw[i]
      }
    }

    let account = await loadData("https://api.guildwars2.com/v2/account?access_token=" + apiKey + '&v=' + gw2APIVersion) || { guild_leader: [] }
    let guildsIDs = account.guild_leader || []
    let guilds = {}
    for (let i = 0; i < guildsIDs.length; i++) {

      let guild = await loadData("https://api.guildwars2.com/v2/guild/" + guildsIDs[i] + "?access_token=" + apiKey + '&v=' + gw2APIVersion) || { name: "" }

      guilds[guildsIDs[i]] = {
        selected: true,
        name: guild.name,
        id: guildsIDs[i]
      }
    }
    return [characters, guilds];
  }

  // Reusable for all inputs
  onChange = e => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name
    let path = e.target.dataset.path.split(",") || []

    if (path.length <= 1) {
      this.setState({ [name]: value })
    } else {
      let element = this.state[path[0]]
      if(path[1]=== "All"){
        let subElements = Object.keys(element)
        if (path.length === 3) {
          for(let i=0;i<subElements.length;i++){
            element[subElements[i]][path[2]] = value
          }
        }
        if (path.length === 4) {
          for(let i=0;i<subElements.length;i++){
            if(path[3] === "All"){
              let subSubElements = Object.keys(element[subElements[i]][path[2]])
              for(let j=0;j<subSubElements.length;j++){
                element[subElements[i]][path[2]][subSubElements[j]] = value
              }
            }
          }
        }
        if (path.length === 5) {
          for(let i=0;i<subElements.length;i++){
            if(path[3] === "All"){
              let subSubElements = Object.keys(element[subElements[i]][path[2]])
              for(let j=0;j<subSubElements.length;j++){
                element[subElements[i]][path[2]][subSubElements[j]][path[4]] = value
              }
            }
          }
        }
      }else{
        if (path.length === 2) {
          element[path[1]] = value
        }
        if (path.length === 3) {
          element[path[1]][path[2]] = value
        }
        if (path.length === 4) {
          element[path[1]][path[2]][path[3]] = value
        }
        if (path.length === 5) {
          element[path[1]][path[2]][path[3]][path[4]] = value
        }
      }
      this.setState({ [path[0]]: element })
    }
  }

  accountChecklist = () => {
    let checkboxes = [
      <Form.Check
        key={"AllaccountChecklist"}
        style={{ whiteSpace: "nowrap" }}
        type={"checkbox"}
        name={"All checkbox"}
        data-path={["details", "All", "selected"]}
        label={"All"}
        checked={this.state.details["All"].selected}
        onChange={this.onChange}
      />
    ]
    let ids = this.state.accounts
    ids.sort(sort_ignore_case)
    for (let i = 0; i < ids.length; i++) {
      checkboxes.push(
        <Form.Check
          key={i+"accountChecklist"}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={ids[i] + " checkbox"}
          data-path={["details", ids[i], "selected"]}
          label={ids[i]}
          checked={this.state.details[ids[i]].selected}
          onChange={this.onChange}
        />
      )
    }
    return <div>{checkboxes}</div>
  }

  guildChecklist = () => {
    let accounts = []
    let ids = this.state.accounts

    // iterate through each account
    for (let i = 0; i < ids.length; i++) {
      if (!this.state.details[ids[i]].selected) {continue}

      let guildsData = this.state.details[ids[i]].guilds
      let guilds = Object.keys(guildsData)

      for (let j = 0; j < guilds.length; j++) {
        let id = guildsData[guilds[j]].id
        let name = guildsData[guilds[j]].name
        let selected = guildsData[guilds[j]].selected

        accounts.push({id: ids[i],guild:id, name: name, label: ids[i] + " - " + name, selected: selected})
      }
    }

    accounts.sort((a,b)=> sort_ignore_case_field(a,b,"label"))

    let checkboxes = []
    if(accounts.length > 0){
      checkboxes.push(
        <Form.Check
          key={"Allcheckboxes_guild"}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={"All checkbox"}
          data-path={["details", "All", "guilds", "All", "selected"]}
          label={"All"}
          checked={this.state.details["All"]["guilds"]["All"].selected}
          onChange={this.onChange}
        />
      )

    }

    for(let i=0;i<accounts.length;i++){
      checkboxes.push(
        <Form.Check
          key={i+"_"+i+"checkboxes_guild"}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={accounts[i].guild + " checkbox"}
          data-path={["details", accounts[i].id, "guilds", accounts[i].guild, "selected"]}
          label={accounts[i].label}
          checked={accounts[i].selected}
          onChange={this.onChange}
        />
      )
    }

    return <div>{checkboxes}</div>
  }

  characterChecklist = () => {
    let accounts = []
    let ids = this.state.accounts

    // iterate through each account
    for (let i = 0; i < ids.length; i++) {
      if (!this.state.details[ids[i]].selected) {continue}

      let characterData = this.state.details[ids[i]].characters
      let characters = Object.keys(characterData)

      for (let j = 0; j < characters.length; j++) {
        let name = characterData[characters[j]].name
        let selected = characterData[characters[j]].selected

        accounts.push({id: ids[i], name: name, label: ids[i] + " - " + name, selected: selected})
      }
    }

    accounts.sort((a,b)=> sort_ignore_case_field(a,b,"label"))

    let checkboxes = []
    if(accounts.length > 0){
      checkboxes.push(
        <Form.Check
          key={"Allcheckboxes_character"}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={"All checkbox"}
          data-path={["details", "All", "characters", "All", "selected"]}
          label={"All"}
          checked={this.state.details["All"]["characters"]["All"].selected}
          onChange={this.onChange}
        />
        )

    }

    for(let i=0;i<accounts.length;i++){
      checkboxes.push(
        <Form.Check
          key={i+"_"+i+"checkboxes_character"}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={accounts[i].name + " checkbox"}
          data-path={["details", accounts[i].id, "characters", accounts[i].name, "selected"]}
          label={accounts[i].label}
          checked={accounts[i].selected}
          onChange={this.onChange}
        />
      )
    }

    return <div>{checkboxes}</div>
  }

  equipmentChecklist = () => {
    let accounts = []
    let ids = this.state.accounts

    // iterate through each account
    for (let i = 0; i < ids.length; i++) {
      if (!this.state.details[ids[i]].selected) {continue}

      let characterData = this.state.details[ids[i]].characters
      let characters = Object.keys(characterData)

      for (let j = 0; j < characters.length; j++) {
        let name = characterData[characters[j]].name
        let selected = characterData[characters[j]].equipment

        accounts.push({id: ids[i], name: name, label: ids[i] + " - " + name, selected: selected})
      }
    }

    accounts.sort((a,b)=> sort_ignore_case_field(a,b,"label"))

    let checkboxes = []
    if(accounts.length > 0){
      checkboxes.push(
        <Form.Check
          key={"Allcheckboxes_equipment"}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={"All checkbox"}
          data-path={["details", "All", "characters", "All", "equipment"]}
          label={"All"}
          checked={this.state.details["All"]["characters"]["All"].equipment}
          onChange={this.onChange}
        />
      )

    }

    for(let i=0;i<accounts.length;i++){
      checkboxes.push(
        <Form.Check
          key={i+"_"+i+"checkboxes_equipment"}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={accounts[i].name + " checkbox"}
          data-path={["details", accounts[i].id, "characters", accounts[i].name, "equipment"]}
          label={accounts[i].label}
          checked={accounts[i].selected}
          onChange={this.onChange}
        />
      )
    }

    return <div>{checkboxes}</div>
  }

  generalAccountChecklist = (selector, text) => {
    let accounts = []
    let ids = this.state.accounts

    // iterate through each account
    for (let i = 0; i < ids.length; i++) {
      if (!this.state.details[ids[i]].selected) {continue}

      accounts.push(ids[i])
    }

    accounts.sort(sort_ignore_case)

    let checkboxes = []
    if(accounts.length > 0){
      checkboxes.push(
        <Form.Check
          key={"All_0checkboxes_"+selector}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={"All- " + text}
          data-path={["details", "All", selector]}
          label={"All"}
          checked={this.state.details["All"][selector]}
          onChange={this.onChange}
        />
      )

    }

    for(let i=0;i<accounts.length;i++){
      checkboxes.push(
        <Form.Check
          key={i+"_0checkboxes_"+selector}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={accounts[i] + "- " + text}
          data-path={["details", accounts[i], selector]}
          label={accounts[i] + " - " + text}
          checked={this.state.details[accounts[i]][selector]}
          onChange={this.onChange}
        />
      )
    }

    return <div>{checkboxes}</div>
  }

  getData = async(type) => {
    // if neither is supplied
    if (type !== "initial" && type !== "secondary") {return null}

    let tmp = this.state.status
    // set to zero at teh start
    tmp[type].accounts.total = 0
    tmp[type].total.total = 0
    tmp[type].accounts.complete = 0
    tmp[type].total.complete = 0

    let ids = this.state.accounts
    for (let i = 0; i < ids.length; i++) {
      // break it
      if (!this.state.details[ids[i]].selected) {continue}

      // add one to teh accounts
      if (this.state.details[ids[i]].selected) {tmp[type].accounts.total += 1}

      // character
      let characterData = this.state.details[ids[i]].characters
      let characters = Object.keys(characterData)
      for (let j = 0; j < characters.length; j++) {
        if (characterData[characters[j]].selected) {tmp[type].total.total += 1}
        if (characterData[characters[j]].equipment) {tmp[type].total.total += 1}
      }

      // guild
      let guildsData = this.state.details[ids[i]].guilds
      let guilds = Object.keys(guildsData)
      for (let j = 0; j < guilds.length; j++) {
        if (guildsData[guilds[j]].selected) {tmp[type].total.total += 1}
      }

      // general
      let selectors = ["bank", "materials", "deliveryBox", "sharedInventory", "tpSells"]
      for (let j = 0; j < selectors.length; j++) {
        if (this.state.details[ids[i]][selectors[j]]) {tmp[type].total.total += 1}
      }
    }

    await this.setState({ status: tmp })

    // now go through each account and get teh data
    let snapshot = this.state.snapshot
    snapshot[type] = {}
    let startTime = new Date().toISOString()
    for (let i = 0; i < ids.length; i++) {
      // break it
      if (!this.state.details[ids[i]].selected) {continue}

      // if the snapshot data for teh account dose not exist
      if (typeof snapshot[type][ids[i]] === "undefined") {
        snapshot[type][ids[i]] = {}
      }

      // get the api key for this run
      let apiKey = this.state.siteAccount.gameAccounts[ids[i]].key

      let url, data
      // Inventory
      let characterData = this.state.details[ids[i]].characters
      let characters = Object.keys(characterData)
      for (let j = 0; j < characters.length; j++) {
        // now get bag data
        if (characterData[characters[j]].selected) {
          // add entry if it does not exist
          snapshot = this.createEntry(snapshot, type, ids[i], ["inventory", characters[j]])
          url = 'https://api.guildwars2.com/v2/characters/' + characters[j] + '/inventory?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime
          data = await loadData(url)
          let items = []
          let bags = data.bags
          for (let k = 0; k < bags.length; k++) {
            let bag = bags[k]
            if (bag === null) {continue}
            for (let l = 0; l < bag.inventory.length; l++) {
              let item = bag.inventory[l]
              if (item === null) {continue}
              items.push(item)
            }
          }
          // add the item data
          snapshot = await this.addItems(snapshot, type, ids[i], ["inventory", characters[j]], items)
        }

        if (characterData[characters[j]].equipment) {
          // add entry if it does not exist
          snapshot = this.createEntry(snapshot, type, ids[i], ["equipment", characters[j]])

          url = 'https://api.guildwars2.com/v2/characters/' + characters[j] + '/equipment?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime
          data = await loadData(url)
          let items = []
          let equipment = data.equipment
          for (let k = 0; k < equipment.length; k++) {
            let item = equipment[k]
            if (item === null) {continue}
            items.push(item)
          }
          // add the item data
          snapshot = await this.addItems(snapshot, type, ids[i], ["equipment", characters[j]], items)
        }
      }

      // <td className={"left"}>{this.generalAccountChecklist("bank", "Bank")}</td>
      if (this.state.details[ids[i]].bank) {
        url = 'https://api.guildwars2.com/v2/account/bank?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime
        snapshot = await this.generalData(snapshot, type, ids[i], "bank", url, [])
      }

      //  <td className={"left"}>{this.generalAccountChecklist("materials", "Material Storage")}</td>
      if (this.state.details[ids[i]].materials) {
        url = 'https://api.guildwars2.com/v2/account/materials?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime
        snapshot = await this.generalData(snapshot, type, ids[i], "materials", url, [])
      }

      //  <td className={"left"}>{this.generalAccountChecklist("deliveryBox", "Delivery Box")}</td>
      if (this.state.details[ids[i]].deliveryBox) {
        url = 'https://api.guildwars2.com/v2/commerce/delivery?access_token=' + apiKey + '&v=' + gw2APIVersion
        snapshot = await this.generalData(snapshot, type, ids[i], "deliveryBox", url, ["items"])
      }
      // <td className={"left"}>{this.generalAccountChecklist("sharedInventory", "Shared Inventory")}</td>
      if (this.state.details[ids[i]].sharedInventory) {
        url = 'https://api.guildwars2.com/v2/account/inventory?access_token=' + apiKey + '&v=' + gw2APIVersion
        snapshot = await this.generalData(snapshot, type, ids[i], "sharedInventory", url, [])
      }
      // tradepostSells
      if (this.state.details[ids[i]].tpSells) {
        // add entry if it does not exist
        snapshot = this.createEntry(snapshot, type, ids[i], ["tpSells"])

        let allData = []
        url = 'https://api.guildwars2.com/v2/commerce/transactions/current/sells?page_size=200&access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime
        let total = await fetch(url)
        .then( async(response) =>{
          let resolved = await response.json() || []
          let pages = response.headers.get('x-page-total') || 0
          return [resolved, pages]
        })
        .then(([data, total])=>{
          for(let k=0;k<data.length;k++){allData.push(data[k])}
          return total
        })
        .catch(function(error) { console.log(error); return 0 });

        for (let j = 1; j < total; j++) {
          url = 'https://api.guildwars2.com/v2/commerce/transactions/current/sells?page_size=200&page=' + j + '&access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime
          data = await loadData(url)
          for(let k=0;k<data.length;k++){allData.push(data[k])}
        }

        let items = []
        let sells = allData
        for (let k = 0; k < sells.length; k++) {
          let item = sells[k]
          if (item === null) {continue}
          item.id = item.item_id
          item.count = item.quantity
          items.push(item)
        }

        // add the item data
        snapshot = await this.addItems(snapshot, type, ids[i], ["tpSells"], items)
      }

      let guildData = this.state.details[ids[i]].guilds
      let guilds = Object.keys(guildData)
      for(let j=0;j<guilds.length;j++) {
        // now get bag data
        if(!guildData[guilds[j]].selected){continue}
        snapshot = this.createEntry(snapshot, type, ids[i], ["guild", guilds[j]])

        url = 'https://api.guildwars2.com/v2/guild/'+guilds[j]+'/stash?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime
        data = await loadData(url)
        let items = []
        let stash = data
        for (let k = 0; k < stash.length; k++) {
          let subStash = stash[k]
          if (subStash === null) {continue}

          for(let l=0;l<subStash.inventory.length;l++){
            let item = subStash.inventory[l]
            if (item === null) {continue}
            items.push(item)
          }
        }
        // add the item data
        snapshot = await this.addItems(snapshot, type, ids[i], ["guild", guilds[j]], items)
      }

      // update status accounts
      let tmp = this.state.status
      tmp[type].accounts.complete += 1
      await this.setState({ status: tmp })
    }

    let config = {
      state: this.state.details,
      start: startTime,
      end: new Date().toISOString()
    }
    snapshot[type].config = config
    if(type === "secondary"){
      snapshot["difference"] = this.calculateDifference(this.state.snapshot.initial, snapshot[type])
      snapshot["difference"].config = config
    }
    await this.setState({ snapshot: snapshot })
  }

  createEntry = (snapshot, type, gameID, path) => {
    if (path.length === 0) {return snapshot}
    if (path.length > 2) {return snapshot}
    if (path.length >= 1) {
      if (typeof snapshot[type][gameID][path[0]] === "undefined") {
        snapshot[type][gameID][path[0]] = {}
      }
    }
    if (path.length === 2) {
      if (typeof snapshot[type][gameID][path[0]][path[1]] === "undefined") {
        snapshot[type][gameID][path[0]][path[1]] = {}
      }
    }
    return snapshot
  }

  addItems = async (snapshot, type, gameID, path, items) => {
    if (path.length === 0) {return snapshot}
    if (path.length > 2) {return snapshot}

    for (let i = 0; i < items.length; i++) {
      let item = items[i]
      let quantity = 1
      if(typeof item.count !== "undefined"){
        quantity = item.count
      }
      let defaultBinding = this.state.itemData[item.id]
      if(typeof defaultBinding !== "undefined"){
        defaultBinding = defaultBinding.binding
      }else{
        // default to none, no idea what to do elsewise
        defaultBinding = "None"
      }

      if (path.length === 1) {
        if (typeof snapshot[type][gameID][path[0]][item.id] === "undefined") {
          snapshot[type][gameID][path[0]][item.id] = {
            character: 0,
            account: 0,
            none: 0
          }
        }
        if (typeof item.binding === "undefined") {
          item.binding = defaultBinding
        }
        if (item.binding === "None") {
          snapshot[type][gameID][path[0]][item.id].none += quantity
        }
        if (item.binding === "Account") {
          snapshot[type][gameID][path[0]][item.id].account += quantity
        }
        if (item.binding === "Character") {
          snapshot[type][gameID][path[0]][item.id].character += quantity
        }
      }

      if (path.length === 2) {
        if (typeof snapshot[type][gameID][path[0]][path[1]][item.id] === "undefined") {
          snapshot[type][gameID][path[0]][path[1]][item.id] = {
            character: 0,
            account: 0,
            none: 0
          }
        }
        if (typeof item.binding === "undefined") {
          item.binding = defaultBinding
        }
        if (item.binding === "None") {
          snapshot[type][gameID][path[0]][path[1]][item.id].none += quantity
        }
        if (item.binding === "Account") {
          snapshot[type][gameID][path[0]][path[1]][item.id].account += quantity
        }
        if (item.binding === "Character") {
          snapshot[type][gameID][path[0]][path[1]][item.id].character += quantity
        }
      }
    }

    // update status total for bag data
    let tmp = this.state.status
    tmp[type].total.complete += 1
    await this.setState({ status: tmp })

    return snapshot
  }

  generalData = async (snapshot, type, gameID, endpoint, url, path) => {
    // create entry for the type
    snapshot = this.createEntry(snapshot, type, gameID, [endpoint])

    let data = await loadData(url)
    let dataArray
    if (path.length === 0) {
      dataArray = data
    }
    if (path.length === 1) {
      dataArray = data[path[0]]
    }
    let items = []
    for (let i = 0; i < dataArray.length; i++) {
      let item = dataArray[i]
      if (item === null) {continue}
      items.push(item)
    }
    // add the item data
    snapshot = await this.addItems(snapshot, type, gameID, [endpoint], items)

    return snapshot
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  buttonBar = (type) => {

    // ensurtes that only these three types make it through
    if (type !== "initial" && type !== "secondary" && type !== "difference") {return null}


    let settings = []
    if(type !== "difference"){
      if(typeof this.state.snapshot[type].config === "undefined"){
        settings.push(
          <tr key={"Snapshot"}>
            <td className={"left"}>Snapshot</td>
            <td>
              <span
                className={"customHover"}
                onClick={async () => {await this.getData(type)}}
              >
                Snapshot
              </span>
            </td>
          </tr>
        )
      }
    }

    if(typeof this.state.snapshot[type].config !== "undefined"){
      let accounts = Object.keys(this.state.snapshot[type])
      accounts = accounts.filter(item => item !== "config")
      let [expandedTable, compactTable, nanoTable] = this.processData(this.state.snapshot[type] )
      let compactLevel = this.state.compact
      let compactText, csvData, csvHeaders, csvName
      if(compactLevel[type] === 0){
        compactText = "Normal"

        csvName = new Date().toISOString() +"_expanded_" + accounts.join(",")
        csvData = this.processForCSV(expandedTable, this.state.filter[type])
        csvHeaders = [
          {title:"ID",key:"id",type:"number"},
          {title:"Name",key:"name",type:"string"},
          {title:"Unbound",key:"noBinding",type:"number"},
          {title:"Soulbound",key:"soulbound",type:"number"},
          {title:"AccountBound",key:"accountBound",type:"number"},
          {title:"Total",key:"total",type:"number"},
          {title:"Account",key:"account",type:"string"},
          {title:"Location",key:"location",type:"string"},
          {title:"Sublocation",key:"subLocation",type:"string"}
        ]
      }
      if(compactLevel[type] === 1){
        compactText = "Micro"

        csvName = new Date().toISOString() +"_compact_" + accounts.join(",")
        csvData = this.processForCSV(compactTable, this.state.filter[type])
        csvHeaders = [
          {title:"ID",key:"id",type:"number"},
          {title:"Name",key:"name",type:"string"},
          {title:"Unbound",key:"noBinding",type:"number"},
          {title:"Soulbound",key:"soulbound",type:"number"},
          {title:"AccountBound",key:"accountBound",type:"number"},
          {title:"Total",key:"total",type:"number"},
          {title:"Account",key:"account",type:"string"}
        ]
      }
      if(compactLevel[type] === 2){
        compactText = "Nano"

        csvName = new Date().toISOString() +"_nano_" + accounts.join(",")
        csvData = this.processForCSV(nanoTable, this.state.filter[type])
        csvHeaders = [
          {title:"ID",key:"id",type:"number"},
          {title:"Name",key:"name",type:"string"},
          {title:"Unbound",key:"noBinding",type:"number"},
          {title:"Soulbound",key:"soulbound",type:"number"},
          {title:"AccountBound",key:"accountBound",type:"number"},
          {title:"Total",key:"total",type:"number"}
        ]
      }


      // for snapshot
      if(type !== "difference"){
        settings.push(
          <tr key={"Snapshot"}>
            <td className={"left"}>Snapshot</td>
            <td>
              <span
                className={"customHover"}
                onClick={async () => {await this.getData(type)}}
              >
                Refresh
              </span>
            </td>
          </tr>
        )
      }

      // show/hide settigns
      settings.push(
        <tr key={"Settings"}>
          <td className={"left"}>Settings</td>
          <td>
            <span
              className={"customHover"}
              onClick={() => {
                let table = this.state.settings
                table[type].show = !table[type].show
                this.setState({settings:table})}
              }
            >
              {this.state.settings[type].show ? "Visible" : "Hidden"}
            </span>
          </td>
        </tr>
      )

      // only show these settings if the above box is ticked
      if(this.state.settings[type].show){
        // snapshot time
        settings.push(
          <tr key={"Time"}>
            <td className={"left"}>Time</td>
            <td>
              {new Date(this.state.snapshot[type].config.end).toLocaleTimeString()}
            </td>
          </tr>
        )

        // show/hide teh tables
        settings.push(
          <tr key={"Table"}>
            <td className={"left"}>Table</td>
            <td>
            <span
              className={"customHover"}
              onClick={() => {
                let table = this.state.table
                table[type].show = !table[type].show
                this.setState({table:table})}
              }
            >
              {this.state.table[type].show ? "Visible" : "Hidden"}
            </span>
            </td>
          </tr>
        )

        // compacting
        settings.push(
          <tr key={"Compact"}>
            <td className={"left"}>Compact</td>
            <td>
            <span
              className={"customHover"}
              onClick={() => {
                let current = this.state.compact
                current[type] += 1
                if(current[type] >= 3){current[type] = 0}
                this.setState({compact:current})}
              }
            >
              {compactText}
            </span>
            </td>
          </tr>
        )

        // csv
        settings.push(
          <tr key={"CSV"}>
            <td className={"left"}>CSV</td>
            <td>
              <JSONConverter
                data={csvData}
                type={"CSV"}
                headers={csvHeaders}
                separator={","}
                text={"Download"}
                name={csvName}
              />
            </td>
          </tr>
        )

        // CSV Enko
        let csvName_enko = new Date().toISOString() +"_enko_" + accounts.join(",")
        let csvData_enko  = this.processForCSV(expandedTable, this.state.filter[type])
        csvData_enko.sort((a,b)=> sort_ignore_case_field(a,b,"name" ))
        let csvHeaders_enko  = [
          {title:"ID",key:"id",type:"number"},
          {title:"Name",key:"name",type:"string"},
          {title:"Total",key:"total",type:"number"},
          {title:"Account",key:"account",type:"string"},
          {title:"Location",key:"location",type:"string"},
          {title:"Sublocation",key:"subLocation",type:"string"}
        ]
        settings.push(
          <tr key={"CSV_Enko"}>
            <td className={"left"}>CSV - Enko</td>
            <td>
              <JSONConverter
                data={csvData_enko}
                type={"CSV"}
                headers={csvHeaders_enko}
                separator={","}
                text={"Download"}
                name={csvName_enko}
              />
            </td>
          </tr>
        )

        // for value and quantity
        let [value, quantity] = this.getSelectedDetails(csvData)
        settings.push(
          <tr key={"Value"}>
            <td className={"left"}>Value</td>
            <td>
              <GetCurrency number={value} size={25} />
            </td>
          </tr>
        )
        settings.push(
          <tr key={"Quantity"}>
            <td className={"left"}>Quantity</td>
            <td>
              {quantity}
            </td>
          </tr>
        )
      }
    }

    let buttons, progressBar
    if(this.state.status[type].total.total === this.state.status[type].total.complete){
      buttons = <Table
        striped
        bordered
        size="sm"
        style={{ "table-layout":"auto", width:"300px", align:"center", margin: "0 auto" }}
      >
        <thead>
        <tr>
          <th colSpan="2">{this.capitalize(type)}</th>
        </tr>
        </thead>
        <tbody>
        {settings}
        </tbody>
      </Table>
    }

    if(this.state.status[type].total.total !== this.state.status[type].total.complete){
      let complete = this.state.status[type].total.complete
      let total = this.state.status[type].total.total
      let progress = (complete/total)*100
      let label = complete + "/" + total + "\t" + progress.toFixed(2)
      progressBar = <ProgressBar now={progress} variant="success"  label={`${label}%`}/>;
    }

    return <div style={{align:"center"}}>
      {buttons}
      {progressBar}
    </div>
  }

  processData = (data) => {
    // breakout per account and each location/sublocation
    let expanded = []
    // for each account
    let compact = []
    // everythign merged together
    let nano = []

    let accounts = Object.keys(data)
    // iterate through the account

    let tmpItems = {}
    let tmpItemsNano = {}
    for(let i=0;i<accounts.length;i++){
      let account = accounts[i]
      let categories = Object.keys(data[account])

      for(let j=0;j<categories.length;j++){
        let category = categories[j]
        if(category === "config"){continue}

        //inventory and equipment have a  breakdown per character
        if(category === "inventory" || category === "equipment" || categories[j] === "guild"){
          let characters = Object.keys(data[account][category])
          for(let k=0;k<characters.length;k++){
            let character = characters[k]
            let guildName
            if(categories[j] === "guild"){
              // character = guild ID not the name,
              guildName = data[account][category][character]["name"]
            }
            let items = Object.keys(data[account][category][character])
            for(let l=0;l<items.length;l++){
              let id = items[l]
              let item = data[account][category][character][id]
              let itemData = this.state.itemData[id] || {name:undefined, type:undefined, img:undefined, level:undefined, marketable:undefined, rarity:undefined}

              let value = 0
              let price = 0
              if(item.none >0){
                value = itemData.sell_price * item.none
                price = itemData.sell_price
              }
              let tmp = {
                id: id,
                name: itemData.name,
                type: itemData.type,
                rarity: itemData.rarity,
                img: itemData.img,
                level: itemData.level,
                marketable: itemData.marketable,
                sell_price: price,
                value: value,
                valueRaw: value,
                account: account,
                location: category,
                subLocation: character,
                noBinding: item.none,
                soulbound: item.character,
                accountBound: item.account,
                total: item.none + item.character + item.account
              }
              if(categories[j] === "guild"){
                tmp.subLocation = guildName
              }
              expanded.push(tmp)

              // now for compact
              if(typeof tmpItems[id] === "undefined"){
                tmpItems[id] = {}
              }
              if(typeof tmpItems[id][account]=== "undefined"){
                tmpItems[id][account] = {
                  id: id,
                  name: itemData.name,
                  type: itemData.type,
                  rarity: itemData.rarity,
                  img: itemData.img,
                  level: itemData.level,
                  marketable: itemData.marketable,
                  sell_price: price,
                  value: 0 ,
                  account: account,
                  noBinding: 0,
                  soulbound: 0,
                  accountBound: 0,
                  total: 0
                }
              }

              tmpItems[id][account].noBinding += item.none
              tmpItems[id][account].soulbound += item.character
              tmpItems[id][account].accountBound += item.account
              tmpItems[id][account].total += item.none + item.character + item.account
              tmpItems[id][account].value = tmpItems[id].noBinding * price

              // and time for nano
              if(typeof tmpItemsNano[id] === "undefined"){
                tmpItemsNano[id] = {
                  id: id,
                  name: itemData.name,
                  type: itemData.type,
                  rarity: itemData.rarity,
                  img: itemData.img,
                  level: itemData.level,
                  marketable: itemData.marketable,
                  sell_price: price,
                  value: 0 ,
                  noBinding: 0,
                  soulbound: 0,
                  accountBound: 0,
                  total:0
                }
              }
              tmpItemsNano[id].noBinding += item.none
              tmpItemsNano[id].soulbound += item.character
              tmpItemsNano[id].accountBound += item.account
              tmpItemsNano[id].total += item.none + item.character + item.account
              tmpItemsNano[id].value = tmpItemsNano[id].noBinding * price

            }
          }
        }

        // for everythign else
        if(categories[j] !== "inventory" && categories[j] !== "equipment" && categories[j] !== "guild"){
          let items = Object.keys(data[account][category])
          for(let l=0;l<items.length;l++){
            let id = items[l]
            let item = data[account][category][id]
            let itemData = this.state.itemData[id] || {name:undefined, type:undefined, img:undefined, level:undefined, marketable:undefined, rarity:undefined}

            let value = 0
            let price = 0
            if(item.none >0){
              value = itemData.sell_price * item.none
              price = itemData.sell_price
            }
            let tmp = {
              id: id,
              name: itemData.name,
              type: itemData.type,
              rarity: itemData.rarity,
              img: itemData.img,
              level: itemData.level,
              marketable: itemData.marketable,
              sell_price: price,
              value: value,
              valueRaw: value,
              account: account,
              location: category,
              subLocation: undefined,
              noBinding: item.none,
              soulbound: item.character,
              accountBound: item.account,
              total: item.none+ item.character + item.account
            }
            expanded.push(tmp)

            // now for compact
            if(typeof tmpItems[id] === "undefined"){
              tmpItems[id] = {}
            }
            if(typeof tmpItems[id][account]=== "undefined"){
              tmpItems[id][account] = {
                id: id,
                name: itemData.name,
                type: itemData.type,
                rarity: itemData.rarity,
                img: itemData.img,
                level: itemData.level,
                marketable: itemData.marketable,
                sell_price: price,
                value: 0 ,
                account: account,
                noBinding: 0,
                soulbound: 0,
                accountBound: 0,
                total:0
              }
            }

            tmpItems[id][account].noBinding += item.none
            tmpItems[id][account].soulbound += item.character
            tmpItems[id][account].accountBound += item.account
            tmpItems[id][account].total += item.none + item.character + item.account
            tmpItems[id][account].value = tmpItems[id].noBinding * price

            // and time for nano
            if(typeof tmpItemsNano[id] === "undefined"){
              tmpItemsNano[id] = {
                id: id,
                name: itemData.name,
                type: itemData.type,
                rarity: itemData.rarity,
                img: itemData.img,
                level: itemData.level,
                marketable: itemData.marketable,
                sell_price: price,
                value: 0 ,
                noBinding: 0,
                soulbound: 0,
                accountBound: 0,
                total: 0
              }
            }
            tmpItemsNano[id].noBinding += item.none
            tmpItemsNano[id].soulbound += item.character
            tmpItemsNano[id].accountBound += item.account
            tmpItemsNano[id].total += item.none + item.character + item.account
            tmpItemsNano[id].value = tmpItemsNano[id].noBinding * price
          }
        }
      }

    }

    // process tmpItems into Compact
    let compactIDs = Object.keys(tmpItems)
    for(let i=0;i<compactIDs.length;i++){
      let id = compactIDs[i]
      let characters = Object.keys(tmpItems[id])
      for(let j=0;j<characters.length;j++){
        let character = characters[j]
        if(character === "config"){continue}
        let itemData = tmpItems[id][character]
        let sell = itemData.sell_price  || 0
        let value = itemData.sell_price * itemData.noBinding || 0
        itemData.value = value
        itemData.valueRaw = value
        itemData.sell_price = sell
        compact.push(itemData)
      }
    }

    // process tmpItems into nano
    let nanoIDs = Object.keys(tmpItemsNano)
    for(let i=0;i<nanoIDs.length;i++){
      let itemData = tmpItemsNano[nanoIDs[i]]
      let sell = itemData.sell_price  || 0
      let value = itemData.sell_price * itemData.noBinding || 0
      itemData.value = value
      itemData.valueRaw = value
      itemData.sell_price = sell
      nano.push(itemData)
    }

    expanded = expanded.filter(arrayFilter)
    compact = compact.filter(arrayFilter)
    nano = nano.filter(arrayFilter)
    return [expanded, compact, nano]
  }

  calculateDifference = (initial, secondary) => {
    let difference = {}

    let accounts = Object.keys(initial)
    for(let i=0;i<accounts.length;i++){
      let account = accounts[i]
      if(typeof difference[account]=== "undefined"){
        difference[account] = {}
      }

      let categories = Object.keys(initial[account])
      for(let j=0;j<categories.length;j++){
        let category = categories[j]

        if(typeof difference[account][category] === "undefined"){
          difference[account][category] = {}
        }

        //inventory and equipment have a  breakdown per character
        if(category === "inventory" || category === "equipment" || categories[j] === "guild"){
          // get characters
          let characters = Object.keys(initial[account][category])
          for(let k=0;k<characters.length;k++){
            let character = characters[k]

            if(typeof difference[account][category][character] === "undefined"){
              difference[account][category][character] = {}
            }
            // get all the items
            let allItems = {}
            let itemsInitial = Object.keys(initial[account][category][character])
            for(let l=0;l<itemsInitial.length;l++){
              allItems[itemsInitial[l]] = ""
            }
            if(
              typeof secondary[account] !== "undefined"
              && typeof secondary[account][category] !== "undefined"
              && typeof secondary[account][category][character] !== "undefined"
            ){
              let itemsSecondary = Object.keys(secondary[account][category][character])
              for(let l=0;l<itemsSecondary.length;l++){
                allItems[itemsSecondary[l]] = ""
              }
            }
            let items = Object.keys(allItems)
            for(let l=0;l<items.length;l++){
              let id = items[l]

              if(typeof difference[account][category][character][id] === "undefined"){
                difference[account][category][character][id]  = { character:0, account:0, none:0 }
              }

              let itemInitial
              if(typeof initial[account] === "undefined"){
                itemInitial =  { character:0, account:0, none:0 }
              }else if(typeof initial[account][category] === "undefined"){
                itemInitial =  { character:0, account:0, none:0 }
              }else if(typeof initial[account][category][character] === "undefined"){
                itemInitial =  { character:0, account:0, none:0 }
              }else if(typeof initial[account][category][character][id] === "undefined"){
                itemInitial =  { character:0, account:0, none:0 }
              }else{
                itemInitial =  initial[account][category][character][id]
              }
              let itemSecondary
              if(typeof secondary[account] === "undefined"){
                itemSecondary =  { character:0, account:0, none:0 }
              }else if(typeof secondary[account][category] === "undefined"){
                itemSecondary =  { character:0, account:0, none:0 }
              }else if(typeof secondary[account][category][character] === "undefined"){
                itemSecondary =  { character:0, account:0, none:0 }
              }else if(typeof secondary[account][category][character][id] === "undefined"){
                itemSecondary =  { character:0, account:0, none:0 }
              }else{
                itemSecondary =  secondary[account][category][character][id]
              }

              difference[account][category][character][id].none = itemSecondary.none - itemInitial.none
              difference[account][category][character][id].account = itemSecondary.account - itemInitial.account
              difference[account][category][character][id].character = itemSecondary.character - itemInitial.character
            }
          }
        }

        if(categories[j] !== "inventory" && categories[j] !== "equipment" && categories[j] !== "guild"
          && categories[j] !== "config" && categories[j] !== "state" && categories[j] !== "start" && categories[j] !== "end"
        ){
          // get all the items
          let allItems = {}
          let itemsInitial = Object.keys(initial[account][category])
          for(let l=0;l<itemsInitial.length;l++){
            allItems[itemsInitial[l]] = ""
          }
          if(
            typeof secondary[account] !== "undefined"
            && typeof secondary[account][category] !== "undefined"
          ){
            let itemsSecondary = Object.keys(secondary[account][category])
            for(let l=0;l<itemsSecondary.length;l++){
              allItems[itemsSecondary[l]] = ""
            }
          }
          let items = Object.keys(allItems)
          for(let l=0;l<items.length;l++){
            let id = items[l]

            if(typeof difference[account][category][id] === "undefined"){
              difference[account][category][id]  = { character:0, account:0, none:0 }
            }

            let itemInitial
            if(typeof initial[account] === "undefined"){
              itemInitial =  { character:0, account:0, none:0 }
            }else if(typeof initial[account][category] === "undefined"){
              itemInitial =  { character:0, account:0, none:0 }
            }else if(typeof initial[account][category][id] === "undefined"){
              itemInitial =  { character:0, account:0, none:0 }
            }else{
              itemInitial =  initial[account][category][id]
            }
            let itemSecondary
            if(typeof secondary[account] === "undefined"){
              itemSecondary =  { character:0, account:0, none:0 }
            }else if(typeof secondary[account][category] === "undefined"){
              itemSecondary =  { character:0, account:0, none:0 }
            }else if(typeof secondary[account][category][id] === "undefined"){
              itemSecondary =  { character:0, account:0, none:0 }
            }else{
              itemSecondary =  secondary[account][category][id]
            }

            difference[account][category][id].none = itemSecondary.none - itemInitial.none
            difference[account][category][id].account = itemSecondary.account - itemInitial.account
            difference[account][category][id].character = itemSecondary.character - itemInitial.character
          }
        }
      }

    }
    return difference
  }

  generateChecklist= () =>{
    return <Table
      responsive
      variant="dark"
      style={{"table-layout": "auto"}}
    >
      <thead>
      <tr>
        <th className={"left"}>Accounts</th>
        <th className={"left"}>Inventory</th>
        <th className={"left"}>Equipment</th>
        <th className={"left"}>Bank</th>
        <th className={"left"}>Materials</th>
        <th className={"left"}>TradePost&nbsp;Sells</th>
        <th className={"left"}>Delivery&nbsp;Box</th>
        <th className={"left"}>Shared&nbsp;Inventory</th>
        <th className={"left"}>Guilds</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td className={"left"}>{this.accountChecklist()}</td>
        <td className={"left"}>{this.characterChecklist()}</td>
        <td className={"left"}>{this.equipmentChecklist()}</td>
        <td className={"left"}>{this.generalAccountChecklist("bank", "Bank")}</td>
        <td className={"left"}>{this.generalAccountChecklist("materials", "Material Storage")}</td>
        <td className={"left"}>{this.generalAccountChecklist("tpSells", "Sale Offers")}</td>
        <td className={"left"}>{this.generalAccountChecklist("deliveryBox", "Delivery Box")}</td>
        <td className={"left"}>{this.generalAccountChecklist("sharedInventory", "Shared Inventory")}</td>
        <td className={"left"}>{this.guildChecklist()}</td>
      </tr>
      </tbody>
    </Table>
  }

  tableController = (type, data) =>{
    if(this.state.table[type].show){
      return this.tableManager(data, this.state.compact[type], type)
    }
  }

  processForCSV = (tmp, filter) =>{
    for(let i=0;i<filter.length;i++){
      let template = this.state.templates[filter[i].template]
      let accessor = filter[i].id
      if(typeof template.filter !== "undefined"){
        tmp = tmp.filter((item) => template.filter(item[accessor], filter[i].value))
      }else{
        tmp = tmp.filter((item)=> filterGeneralText(item[accessor],filter[i].value))
      }
    }
    return tmp;
  }

  getSelectedDetails = (input) => {
    let value = 0
    let quantity = 0
    for(let i=0;i<input.length;i++){
      if(!isNaN(input[i].valueRaw)){
        value += input[i].valueRaw
      }
      if(!isNaN(input[i].total)){
        quantity += input[i].total
      }
    }
    return [value, quantity]
  }

  tableManager = (data, compactLevel, type) => {
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "text": {
          className: "left",
        },
        "textImg": {
          contents: (item) => <TextImg item={item}/>,
          className: "left",
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "percent": {
          className: "right",
          ssort: sortGeneral,
          filter: filterGeneral
        },
        "gold": {
          className: "right",
          contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
          sort: sortGeneral,
          filter: (item, filter) => filterGeneral(item, filter, 10000)
        }
      },
      colsToDisplay:20,
      filter: {active:true},
      sort:{col:"name", desc:false},
      headers: {
        "Details": {
          className:"left",
          cols: [
            { template: "textImg", header: "Item", accessor: "name" },
          ]
        },
        "Location": {
          collapse:true,
          className:"left",
          cols: [
            { template: "text", header: "Account", accessor: "account" },
            { template: "text", header: "Location", accessor: "location" },
            { template: "text", header: "Sub-Location", accessor: "subLocation" },
          ]
        },
        "Quantities": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "UnBound", accessor: "noBinding" },
            { template: "number", header: "SoulBound", accessor: "soulbound" },
            { template: "number", header: "AccBound", accessor: "accountBound" },
            { template: "number", header: "Total", accessor: "total" },
            { template: "gold", header: "Sell", accessor: "sell_price" },
            { template: "gold", header: "Value", accessor: "value" },
          ]
        },
        "Filters": {
          collapse:true,
          className:"left",
          cols: [
            { template: "text", header: "Type", accessor: "binding" },
            { template: "text", header: "Rarity", accessor: "rarity" },
            { template: "number", header: "Level", accessor: "level" },
          ]
        }
      },
      headerOrder:["Details","Location","Quantities","Filters"]
    }

    let tableData
    let [expanded, compact, nano] = this.processData(data)
    if(compactLevel === 0){tableData = expanded}
    if(compactLevel === 1){
      tableData = compact
      config.headers["Location"].cols = [ { template: "text", header: "Account", accessor: "binding" }]
    }
    if(compactLevel === 2){
      tableData = nano
      delete config.headers["Location"]
      config.headerOrder = ["Details","Quantities","Filters"]
    }

    if(typeof this.state.templates === "undefined"){
      this.setState({templates:config.templates})
    }
    return this.createTable(tableData, config, type)
  }

  createTable = (data, config, identifier) => {
    return <SilverTable
      data={data}
      config={config}
      callbackToParent={this.callbackToParent}
      identifier={identifier}
    />
  }

  callbackToParent = (item) =>{
    let filterArray = []
    let newFilters = Object.keys(item.filter)
    let colOrder = {}
    for(let i=0;i<item.colOrder.length;i++){colOrder[item.colOrder[i].accessor] = item.colOrder[i]}

    for(let i=0;i<newFilters.length;i++){
      filterArray.push({
        id:colOrder[newFilters[i]].accessor,
        value:item.filter[newFilters[i]],
        template:colOrder[newFilters[i]].template
      })
    }
    let filter = this.state.filter
    filter[item.identifier] = filterArray
    this.setState({filter:filter})
  }

  render() {
    // pop up loading mesage if the item data isnt down
    if (typeof this.state.itemData === "undefined") {return <Loading/>}
    // an error happened, pop it up and break
    if (typeof this.state.error !== "undefined") {return <div>{this.state.error}</div>}
    // if there are no game accounts on the website account
    if (this.state.accounts.length === 0) {return <div>No Game available</div>}

    let checklist = this.generateChecklist()

    // initial stuff
    let initialButtons = this.buttonBar("initial")

    let initialTable, secondaryButtons, secondaryTable, differenceTable, differenceButtons

    if(typeof this.state.snapshot["initial"].config !== "undefined"){
      initialTable = this.tableController("initial",this.state.snapshot["initial"] )
      secondaryButtons = this.buttonBar("secondary")
    }

    if(typeof this.state.snapshot["secondary"].config !== "undefined"){
      secondaryTable = this.tableController("secondary", this.state.snapshot["secondary"])
      differenceTable = this.tableController("difference", this.state.snapshot["difference"])
      differenceButtons = this.buttonBar("difference")
    }

    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/management#verification-lists"} />
      <br/>
      {checklist}
      <br/>
      {initialButtons}
      <br/>
      {initialTable}
      <br/>
      {secondaryButtons}
      <br/>
      {secondaryTable}
      <br/>
      {differenceButtons}
      <br/>
      {differenceTable}
    </div>

  }
}