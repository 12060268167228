import React, { Component } from 'react';

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
    }

    render() {
        return <div align="center">
            <h3>Welcome!</h3>
            <p>
                For using this site please refer to my <a href={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/home"} target={"_blank"} rel={"noopener noreferrer"}>Documentation</a>
                <br />
                It is quite comprehensive and aught to cover most questions ye may have.
            </p>

        </div>;
    }
}
