import React , { Component }from 'react';
import { Redirect } from 'react-router-dom'
import { urlBase } from '../_utiilities/data.json'
import {
    AutoComplete,
    Documentation,
    Loading,
    SilverTable,
} from '../_utiilities/functions_react'
import { filterGeneral, loadData, sortGeneral } from '../_utiilities/functions'
import {TextImg} from "../loggedOut/tp_textImg";


export class Gemstore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            session: props.session,
            list:undefined,
            error:false,
            errorCode:undefined,
            nameBox:undefined,
            defaultList: undefined,
            bulk:{items:""},
            number:1,
            selected:{name:"Select GemStore Items", uuid:""},
            dropdown:undefined,
            data:{items:undefined}
        }
    }

    // will need this in all sub components
    async componentDidUpdate(prevProps ) {
        if (typeof prevProps.session === "undefined" && typeof this.props.session !== "undefined"){
            this.setState({ session: this.props.session })
        }
    }

    async componentDidMount() {
        let rawItemData = await loadData(urlBase.parser + "/v1/gemstore/catalogue/json?fields=uuid,name,type,latestEndDate,latestStartDate,gemPrice,imageHash");

        let tmp = {};
        tmp.dropdown = this.formatDropdown(rawItemData)

        tmp.itemData = {}
        for(let i=0;i<rawItemData.length;i++){
            tmp.itemData[rawItemData[i].uuid] = rawItemData[i]
        }
        let existingList = await loadData(urlBase.account + "/v1/lists/gemstore", {method: 'GET', headers: {'Content-Type': 'application/json', session: this.state.session}});
        if(typeof existingList.success !== "undefined"){
            tmp.list = existingList.success.items
        }else{
            tmp.error = true
            tmp.errorCode = "Something went wrong getting the list"
        }
        this.setState(tmp)
    }

    getDataFromID = (id, items) =>{
        if(typeof items[id] !== "undefined"){
            return items[id]
        }else{
            return false
        }
    }

    async submitData(list){
        let submitUrl = urlBase.account + "/v1/lists/gemstore"
        let options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', session:this.state.session},
            body:JSON.stringify(list)
        }

        await fetch(submitUrl, options)
        .then(async (response)=> {
            let body = await response.json()
            if (body.result === "error") {
                alert(body.error)
            }
        })
        .catch((err) => {alert(err)})
    }

    myCallback = async([,, result]) => {
        let list = this.state.list
        let alreadyExists = list.filter(item => item.uuid === result.uuid);
        if(alreadyExists.length ===0){
            list.push({
                uuid: result.uuid,
                lastDate: new Date().toISOString()
            })
            await this.submitData({items:list})
            this.setState({list:list})
        }
    }

    formatDropdown = (dropdown) => {
        let dropdownArray = []
        for(let i=0;i<dropdown.length; i++){
            // if this becomes trouble in teh future it cna be uncommented
            //if(dropdown[i].latestStartDate === null){continue}

            let tmp = "";
            if(typeof dropdown[i].type !== "undefined"){
                tmp = tmp +"\t Type: "+ dropdown[i].type;
            }
            if(typeof dropdown[i].name !== "undefined"){
                tmp = tmp +"\t Name: "+ dropdown[i].name;
            }
            dropdown[i].merged = tmp;
            dropdown[i].img = "https://services.staticwars.com/gw2/img/content/" + dropdown[i].imageHash +"_large.png";
            dropdownArray.push(dropdown[i])
        }
        return dropdownArray;
    }

    getIDFromMerged = (merged, group) => {
        let dataArray = this.state.dropdown[group]
        for(let i=0;i<dataArray.length;i++){
            if(merged === dataArray[i].merged){
                return [dataArray[i].uuid,dataArray[i].name]
            }
        }
        return ["","Error"];
    }

    handleDeleteItem = async(deleteID) => {
        let tmp = {}
        tmp.list = this.state.list
        tmp.list = tmp.list.filter(item => item.uuid !== deleteID);
        await this.submitData(tmp.list)
        this.setState(tmp)
    }

    getDropdown = () =>{
        return <AutoComplete
          identifier={"temp"}
          array={this.state.dropdown}
          placeholder={this.state.selected.name}
          callbackToParent={this.myCallback}
          sorter={"merged"}
          startRenderString={-1}
          limit={100}
          imgSize={50}
        />
    }

    processList = () =>{
        let {list, itemData} = this.state
        let tableData = []
        for(let i=0;i<list.length;i++){
            let item = itemData[list[i].uuid]
            tableData.push({
                uuid:list[i].uuid,
                name: item.name,
                type: item.type,
                latestStartDate: item.latestStartDate,
                latestEndDate: item.latestEndDate,
                gemPrice: item.gemPrice,
                imageHash: item.imageHash,
            })

        }
        return tableData
    }

    createTable = (tableData) => {
        let config = {
            className: {
                table: "centerTable table-primary table-striped table-highlight",
            },
            templates: {
                "text": {
                    className: "left",
                },
                "textImg": {
                    contents: (item) => <TextImg item={item}/>,
                    className: "left",
                },
                "number": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "delete": {
                    contents:(item)=>{
                        return <button
                          title={"Delete: "+ item.name}
                          onClick={async()=>{await this.handleDeleteItem(item.uuid)}}
                          style={{"background-color":"#ff5154", "border": "none"}}
                        >
                            Delete
                        </button>
                    },
                    className: "left",
                },
            },
            colsToDisplay:20,
            filter: {active:true},
            sort:{col:"name", desc:false},
            headers: {
                cols: [
                    { template: "textImg", header: "Item", accessor: "name" },
                    { template: "text", header: "Type", accessor: "type" },
                    { template: "text", header: "Last Price", accessor: "gemPrice" },
                    { template: "text", header: "Last Start", accessor: "latestStartDate" },
                    { template: "text", header: "Last End", accessor: "latestEndDate" },
                    { template: "delete", header: "Delete", accessor: "type" },
                ]
            },
            //headerOrder:["Details","Quantities","Location","Filters"]
        }

        return <SilverTable
          data={tableData}
          config={config}
          callbackToParent={this.callbackToParent}
        />
    }

    render() {
        if(typeof this.state.session === "undefined"){return <Redirect to='/login'/>}
        if(typeof this.state.itemData === "undefined"){return <Loading />}
        if(typeof this.state.list === "undefined"){return <Loading />}
        if(this.state.error){return  <p>Error: {this.state.errorCode}</p>}

        let dropDown = this.getDropdown()
        let tableData = this.processList()
        let table = this.createTable(tableData)

        return <div>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#gemstore-notifier"} />
            <br/>
            <h2>Gemstore Notifier</h2>
            <h4>When an item returns to the Gemstore this tool will send you an email.</h4>
            <h5>Requires an email to be set for this account.</h5>
            <h6>Gemstore prices are from Gw2Efficiency's "gw2e-item-value" project.</h6>
            <h6>There are some duplicate items that have an older soulbound version and a current account bound version, for example Bone Pick.</h6>
            <br />
            {dropDown}
            <br />
            {table}
        </div>
    }
}