import React, { Component } from 'react'
import { urlBase } from '../_utiilities/data'
import { Documentation, getQueryObject } from '../_utiilities/functions_react'

export class Recover_Password extends Component {
  constructor (props) {
    super(props);
    this.state = {
      email: undefined,
      resultEmail: undefined,
      password: undefined,
      resultPassword: undefined,
      query: getQueryObject(window.location.hash)
    }
  }

  componentDidMount () {}

  onChange = e => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name
    this.setState({ [name]: value })
  }

  info = () => {
    return <div>
      <h3>Reset Password</h3>
      <p>
        This is for if you have forgotten your password.
        <br/>
        If you added an email to your account then please use the form below.
        <br/>
        If you did not add an email please contact <a
        href="mailto:support@datawars2.ie">support@datawars2.ie</a>, I will
        probably ask you to prove ownership of a GuildWars2 account linked to
        the DataWars2.
      </p>
    </div>
  }

  formEmail = () => {
    let result = this.state.resultEmail
    let resultText
    if (result) {resultText = "Successfully Submitted"}

    return <form onSubmit={this.handleSubmitEmail}>
      <label>Email: </label>
      <input
        style={{ margin: 0, width: 300 }}
        type={"text"}
        value={this.state.email}
        onChange={this.onChange}
        name={"email"}
      />
      <input type="submit" value="Submit"/>
      <br/>
      {resultText}
    </form>
  }

  handleSubmitEmail = async (event) => {
    event.preventDefault();
    if (typeof this.state.email === "undefined") {return}

    let submitUrl = urlBase.account + "/v1/recovery/password";
    await fetch(submitUrl, { method: 'POST', headers: { 'Content-Type': 'application/json', email: this.state.email } })
    .then((response) => {
      if (response.status === 200) {
        this.setState({ email: "", resultEmail: true })
      }
    })
    .catch((err) => {alert(err)});
  }

  formPassword = () =>{
    let result = this.state.resultPassword
    let resultText
    if (result) {resultText = "Successfully Submitted"}

    return <form onSubmit={this.handleSubmitPassword}>
      <label>New Password: </label>
      <input
        style={{ margin: 0, width: 300 }}
        type={"password"}
        value={this.state.password}
        onChange={this.onChange}
        name={"password"}
      />
      <input type="submit" value="Submit"/>
      <br/>
      {resultText}
    </form>

  }

  handleSubmitPassword = async (event) => {
    event.preventDefault();
    if (typeof this.state.password === "undefined") {return}

    let submitUrl = urlBase.account + "/v1/recovery/password/reset"
    await fetch(submitUrl, { method: 'POST', headers: { 'Content-Type': 'application/json', key:this.state.query.auth, newPassword: this.state.password } })
    .then((response) => {
      if (response.status === 200) {
        this.setState({ password: "", resultPassword: true })
      }
    })
    .catch((err) => {alert(err)});
  }

  render () {
    return <div style={{ align: "center" }}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/management#password-reset"} />
      <br/>
      {this.info()}
      <br/>
      {typeof this.state.query.auth === "undefined" ? this.formEmail() : this.formPassword()}
    </div>
  }
}
