import React, { Component } from 'react';
import {Redirect} from "react-router-dom";
import { urlBase } from '../_utiilities/data.json'
import { Documentation, GetCurrency, Loading, SilverTable, } from '../_utiilities/functions_react'
import { filterGeneral, loadData, sortGeneral } from '../_utiilities/functions'
import {TextImg} from "../loggedOut/tp_textImg";

function difference(a1, a2) {
    let a2Set = new Set(a2);
    return a1.filter(function(x) { return !a2Set.has(x); });
}

export class Skin_2_Win extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marketPrices: [],
            skinsList: [],
            playerSkins: [],
            missingSkills: [],
            userIDs: [],
            session: props.session,
            accountMain: props.account
        };

        this.handleCharacterSelection2 = this.handleCharacterSelection2.bind(this);
    }

    // will need this in all sub components
    async componentDidUpdate(prevProps ) {
        if (typeof prevProps.session === "undefined" && typeof this.props.session !== "undefined"){
            this.setState({ session: this.props.session })
        }
        if (typeof prevProps.account === "undefined" && typeof this.props.account !== "undefined"){
            let { skinsList, marketPrices, skinsInfo } = this.state;
            this.setState(await this.processAccount(this.props.account, {},skinsList, marketPrices, skinsInfo))
        }
    }

    async componentDidMount() {
        let tmp = {};
        tmp.skinsList = await loadData("https://api.guildwars2.com/v2/skins");
        tmp.marketPrices = await loadData(urlBase.parser + "/v1/items/json?fields=id,name,default_skin,buy_price,sell_price,img&filter=default_skin:gt:0,marketable:TRUE&beautify=min");
        tmp.skinsInfo = await loadData(urlBase.parser + "/v1/skins/json?beautify=min&fields=type,id,name,icon");

        tmp = await this.processAccount (this.state.accountMain, tmp, tmp.skinsList, tmp.marketPrices, tmp.skinsInfo)
        this.setState(tmp);
    }

    processAccount = async (account, tmp = {}, skinsList, marketPrices, skinsInfo) =>{
        if(!account){return tmp}

        tmp.accountMain = account;
        let accounts = account.gameAccounts;
        tmp.userIDs = Object.keys(accounts);
        if(tmp.userIDs.length >0){
            tmp.defaultAccountID = tmp.userIDs[0];
            tmp.apiKey = account.gameAccounts[tmp.userIDs[0]].key;
        }

        if(typeof tmp.apiKey !== "undefined"){
            tmp = await this.onApiKey(tmp,tmp.apiKey,skinsList,marketPrices,skinsInfo);
        }

        return tmp
    }

    async onApiKey(tmp,apiKey,skinsList,marketPrices,skinsInfo){
        tmp.playerSkins = await loadData("https://api.guildwars2.com/v2/account/skins?access_token="+ apiKey);
        tmp.missingSkills = difference(skinsList, tmp.playerSkins);
        tmp.table = [];
        tmp.totalBuy = 0;
        tmp.totalSell = 0;

        for(let i=0;i<tmp.missingSkills.length;i++){
            let temp = {};
            temp.id = tmp.missingSkills[i];
            temp.minBuyPrice = 999999999;
            temp.minBuyName = "";
            temp.minSellPrice = 999999999;
            temp.minSellName = "";

            for(let j=0;j<skinsInfo.length;j++){
                if(skinsInfo[j].id === temp.id){
                    temp.name = skinsInfo[j].name;
                    temp.icon = skinsInfo[j].icon;
                    temp.type = skinsInfo[j].type;
                }
            }
            for(let j=0;j<marketPrices.length;j++){
                if(marketPrices[j].default_skin === temp.id){
                    temp.img = marketPrices[j].img
                    if(marketPrices[j].buy_price < temp.minBuyPrice && marketPrices[j].buy_price !== 0 && marketPrices[j].sell_price){
                        temp.minBuyPrice = marketPrices[j].buy_price;
                        temp.minBuyName = marketPrices[j].name;
                    }
                    if(marketPrices[j].sell_price < temp.minSellPrice && marketPrices[j].buy_price !== 0 && marketPrices[j].sell_price){
                        temp.minSellPrice = marketPrices[j].sell_price;
                        temp.minSellName = marketPrices[j].name;
                    }
                }
            }
            if((temp.minSellPrice + temp.minBuyPrice) !== 1999999998){
                tmp.totalBuy += temp.minBuyPrice;
                tmp.totalSell += temp.minSellPrice;
                temp.difference ="0c";
                if(temp.minBuyName !== temp.minSellName){
                    temp.difference = temp.minSellPrice - temp.minBuyPrice;
                }

                tmp.table.push(temp);
            }
        }
        return tmp;
    }

    async handleCharacterSelection2(event) {
        let tmp = {};
        tmp.defaultAccountID = event.target.value;
        tmp.apiKey = this.state.accountMain.gameAccounts[event.target.value].key;
        tmp = await this.onApiKey(tmp,tmp.apiKey,this.state.skinsList,this.state.marketPrices,this.state.skinsInfo);
        this.setState(tmp);
    }

    tableManager = (data) => {
        let config = {
            className: {
                table: "centerTable table-primary table-striped table-highlight",
            },
            templates: {
                "text": {
                    className: "left",
                },
                "textImg": {
                    contents: (item) => <TextImg item={item}/>,
                    className: "left",
                },
                "number": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "gold": {
                    className: "right",
                    contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
                    sort: sortGeneral,
                    filter: (item, filter) => filterGeneral(item, filter, 10000)
                }
            },
            colsToDisplay:20,
            filter: {active:true},
            sort:{col:"minBuyPrice", desc:false},
            headers: {
                "Skin": {
                    className:"left",
                    cols: [
                        { template: "textImg", header: "Skin", accessor: "name" },
                        { template: "number", header: "Type", accessor: "type" },
                    ]
                },
                "Buy Order": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "text", header: "Name", accessor: "minBuyName" },
                        { template: "gold", header: "Price", accessor: "minBuyPrice" },
                    ]
                },
                "Instant Buy": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "text", header: "Name", accessor: "minSellName" },
                        { template: "gold", header: "Price", accessor: "minSellPrice" },
                    ]
                },
                "Difference": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "gold", header: "Difference", accessor: "difference" },
                    ]
                }
            },
            headerOrder:["Skin","Buy Order","Instant Buy","Difference"]
        }

        return this.createTable(data, config)
    }

    createTable = (data, config) => {
        return <SilverTable
          data={data}
          config={config}
        />
    }

    render() {
        if(typeof this.state.session === "undefined"){return <Redirect to='/login'  />;}
        if(typeof this.state.accountMain === "undefined"){return <Loading/>}

        let characterDropdown = [];
        if(this.state.userIDs.length >0){
            characterDropdown.push(<option key={-1} value={this.state.defaultAccountID} >{this.state.defaultAccountID}</option>);
            for(let i=0;i<this.state.userIDs.length;i++){
                if(this.state.userIDs[i] !== this.state.defaultAccountID){
                    characterDropdown.push(<option key={i} value={this.state.userIDs[i]}>{this.state.userIDs[i]}</option>)
                }
            }
        }

        let dropdown = <select value={this.state.defaultAccountID} onChange={this.handleCharacterSelection2}>{characterDropdown}</select>

        let table
        if(typeof this.state.apiKey !== "undefined"){
            table = this.tableManager(this.state.table)
        }

        return <div>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#skin-to-win"} />
            <br/>
            {dropdown}
            {table}
        </div>
    }
}