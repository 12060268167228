import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {ProgressBar} from 'react-bootstrap'
import { gw2APIVersion } from '../_utiilities/data.json'
import {
  Documentation,
  Loading,
  SilverTable,
} from '../_utiilities/functions_react'
import { filterGeneral, loadData, sortGeneral } from '../_utiilities/functions'

export class Story_Tracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: props.session,
      accountMain: props.account,
      userIDs: [],
      defaultAccountID: "",
      stories: {},
      quests: {},
      seasons: [],
      characterDropdown: "Choose Character",
      apiKey: undefined,
      filter:[],
      freeSlots:[],
      characters:{total:0,completed:0}
    }
  }

  async componentDidUpdate(prevProps ) {
    if (typeof prevProps.session === "undefined" && typeof this.props.session !== "undefined"){
      this.setState({ session: this.props.session })
    }
    if (typeof prevProps.account === "undefined" && typeof this.props.account !== "undefined"){
      let tmp = await this.processAllQuests()
      tmp = await this.processAccount(this.props.account, tmp, tmp.seasons, tmp.stories, tmp.quests, "componentDidMount")

      this.setState(tmp);
    }
  }

  async componentDidMount() {
    if (!this.state.accountMain){return}

    let tmp = await this.processAllQuests()
    tmp = await this.processAccount(this.state.accountMain, tmp, tmp.seasons, tmp.stories, tmp.quests, "componentDidMount")

    this.setState(tmp);
  }


  processAccount = async (account, tmp = {}, seasons, stories, quests) =>{
    if(!account){return tmp}

    tmp.allKeys = []
    tmp.accountMain = account;
    let accounts = account.gameAccounts;
    tmp.userIDs = Object.keys(accounts);
    if(tmp.userIDs.length >0){
      tmp.defaultAccountID = tmp.userIDs[0];
      tmp.apiKey = account.gameAccounts[tmp.userIDs[0]].key;
    }
    for(let i=0;i<tmp.userIDs.length;i++){
      tmp.allKeys.push(account.gameAccounts[tmp.userIDs[i]].key)
    }

    if (typeof tmp.apiKey !== "undefined") {
      tmp = await this.onApiKey(tmp,tmp.apiKey, seasons, stories, quests);
    }

    return tmp
  }

  processAllQuests = async ()=>{
    let seasons = await loadData("https://api.guildwars2.com/v2/stories/seasons?ids=all&lang=en&v="+gw2APIVersion)
    let stories = await loadData("https://api.guildwars2.com/v2/stories?ids=all&lang=en&v="+gw2APIVersion)
    let quests = await loadData("https://api.guildwars2.com/v2/quests?ids=all&lang=en&v="+gw2APIVersion)

    let tmpStories = {}
    for(let i=0;i<stories.length;i++){
      tmpStories[stories[i].id] = stories[i]
      tmpStories[stories[i].id].quests = []
    }
    let tmpQuests = {}
    for(let i=0;i<quests.length;i++){
      let id = quests[i].id
      tmpQuests[id] = quests[i]

      let story = quests[i].story
      tmpStories[story].quests.push(id)

      tmpStories[story].required = tmpStories[story].quests.length
    }

    return { seasons: seasons, stories: tmpStories, quests: tmpQuests }
  }

  async onApiKey(tmp,apiKey, seasons, stories, quests) {
    let accountData = await loadData("https://api.guildwars2.com/v2/account?access_token=" + apiKey + "&lang=en&v="+gw2APIVersion);
    tmp.playerID = accountData.name;
    let characters = await loadData("https://api.guildwars2.com/v2/characters?access_token=" + apiKey + "&lang=en&v="+gw2APIVersion);
    this.setState({characters:{total:characters.length,completed:0}})
    tmp = await this.getCharacterProgression(tmp, apiKey, characters, seasons, stories, quests)
    return tmp
  }

  getCharacterProgression = async (tmp,apiKey, characters, seasons, stories, quests) => {
    tmp.tableData = []
    for(let i=0;i<characters.length;i++){
      let url = "https://api.guildwars2.com/v2/characters/"+characters[i]+"/quests?access_token="+apiKey + "&lang=en&v="+ gw2APIVersion
      let core = "https://api.guildwars2.com/v2/characters/"+characters[i]+"/core?access_token="+apiKey + "&lang=en&v="+ gw2APIVersion
      // this is an array of quest id's
      let characterQuests = await loadData(url)
      let coreData = await loadData(core)
      let characterData = this.processCharacterProgression(coreData, characterQuests, stories, quests )
      tmp.tableData.push(characterData)

      let tmpCharacters = this.state.characters
      tmpCharacters.completed +=1
      this.setState({characters:tmpCharacters})
    }
    return tmp
  }

  processCharacterProgression = (progression,characterQuests, stories, quests )=>{
    let tmp = {}
    for(let i=0;i<characterQuests.length;i++){
      let quest = quests[characterQuests[i]]
      if(!quest){continue}
      let storyID = quest.story
      if(typeof tmp[storyID] === "undefined"){
        tmp[storyID] = 0
      }
      tmp[storyID] += 1


      if(typeof progression["quests"] === "undefined"){
        progression["quests"]  = 0
      }
      progression["quests"]  += 1

      if(storyID <= 10){
        let level = quest.level
        if(typeof tmp["0_"+ level] === "undefined"){
          tmp["0_"+ level] = 0
        }
        tmp["0_"+ level] += 1
      }
    }

    let storyIDs = Object.keys(stories)
    for(let i=0;i<storyIDs.length;i++){
      let story = stories[storyIDs[i]]
      if(story.name === "My Story"){continue}

      progression[story.id] = {required: story.required,completed: 0}
      if(typeof tmp[story.id] === "undefined"){continue}
      progression[story.id].completed = tmp[story.id]
    }

    let coreStory = [
      {name:"0_1", required:1},
      {name:"0_10", required:5},
      {name:"0_20", required:4},
      {name:"0_30", required:5},
      {name:"0_40", required:5},
      {name:"0_50", required:3},
      {name:"0_60", required:7},
      {name:"0_70", required:6},
      {name:"0_80", required:13},
    ]
    for(let i=0;i<coreStory.length;i++){
      progression[coreStory[i].name] = {required: coreStory[i].required,completed: 0}
      if(typeof tmp[coreStory[i].name] === "undefined"){continue}
      progression[coreStory[i].name].completed = tmp[coreStory[i].name]
    }

    return progression
  }

  handleAccountSelection  = async (event) =>{
    if(this.state.tableData !== "undefined") {
      this.setState({tableData:undefined,defaultAccountID:event.target.value})
    }

    let tmp = {};
    tmp.defaultAccountID = event.target.value
    tmp.apiKey = this.state.accountMain.gameAccounts[event.target.value].key
    tmp = await this.onApiKey(tmp,tmp.apiKey, this.state.seasons, this.state.stories, this.state.quests)
    this.setState(tmp)
  }

  tableManager = () => {
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "": {
          className: "left",
          width: 15,
        },
        "text": {
          className: "left",
        },
        "progress": {
          className: "left",
          width: 60,
          contents:(item, accessor)=>{
            let progress = item[accessor]
            if(typeof progress === "undefined"){return null}
            let completed = progress.completed
            let required = progress.required

            let style = {}
            let colour
            if(completed >0){
              if(completed === required){
                colour = "green"
              }else{
                colour = "red"
              }
              style["background-color"] = colour
            }
            return {style:style, contents: <span title={completed+"/"+required}>{completed}/{required}</span>}
          },
          sort: (a, b) => sortGeneral(a.completed, b.completed),
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
      },
      colsToDisplay:20,
      filter: {active:true},
      sort:{col:"name", desc:false},
      headers: {
        "Character": {
          className:"left",
          cols: [
            { template: "text", header: "Character", accessor: "name" }
          ]
        },
        "Details": {
          collapse: true,
          className:"left",
          cols: [
            { template: "text", header: "Race", accessor: "race" },
            { template: "text", header: "Gender", accessor: "gender" },
            { template: "text", header: "Profession", accessor: "profession" },
            { template: "number", header: "Level", accessor: "level" },
            { template: "text", header: "Created", accessor: "created" },
            { template: "number", header: "Quests", accessor: "quests" },
          ]
        },
        "": {
          className:"left",
          cols: [
            { template: "", header: "", accessor: "" },
          ]
        },
      },
      headerOrder:["Character","Details"],
      collapse:{
        "Details": true
      }
    }
    // character
    let data = this.state.tableData
    let seasons = this.state.seasons
    let stories = this.state.stories
    seasons.sort(function (a, b) {return a.order - b.order;})

    for(let i=0;i<seasons.length;i++) {
      let name = seasons[i].name
      config.headerOrder.push("")
      config.headerOrder.push(name)
      if (name === "My Story") {
        seasons[i].stories = ["0_1","0_10","0_20","0_30","0_40","0_50","0_60","0_70","0_80"]
      }

      if (typeof config.headers[name] === "undefined") {
        config.headers[name] = {
          collapse: true,
          className: "left",
          cols: []
        }
      }
      let temp = []
      for(let j=0;j<seasons[i].stories.length;j++){
        let selectedStory = seasons[i].stories[j]
        let story, ep0, ep,  name, id, order, key
        let goldStyle = {color:"#FFBA01"}
        let key0 = <span className="rotateMinus45" style={goldStyle}>&#128477;</span>
        if(typeof selectedStory === "string"){
          name = selectedStory.split("_")[1]
          ep = name
          id = selectedStory
          if(name === "1"){
            order = 0
          }else{
            order = (name-0)/10

            if(order === 1){key = key0; ep = <span style={goldStyle}>{ep}</span>}
            if(order === 4){key = key0; ep = <span style={goldStyle}>{ep}</span>}
            if(order === 6){key = key0; ep = <span style={goldStyle}>{ep}</span>}
          }
        }else{
          story = stories[selectedStory]
          name = story.name
          id = story.id
          order = story.order
          ep0 = story.name.split(".")[0] || ""
          ep = "EP: "+ ep0
          if(ep0.length > 2){
            ep = story.name
          }
          if(name === "6. Tangled Paths"){key = key0; ep = <span style={goldStyle}>{ep}</span>}
          if(name === "11. Roots of Terror"){key = key0; ep = <span style={goldStyle}>{ep}</span>}
        }

        let tmp = {
          template: "progress",
          header: name,
          accessor: id.toString(),
          customHeader: ()=>{return <span className={"center"}  title={name}>{ep}{key}</span>},
          order: order
        }
        temp.push(tmp)
      }
      temp.sort(function (a, b) {return a.order - b.order;})
      config.headers[name].cols = temp
    }

    return this.createTable(data, config)
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
    />
  }

  getAccountDropdown = () => {
    let accountDropdown = []
    if(this.state.userIDs.length >0){
      accountDropdown.push(<option key={-1} value={this.state.defaultAccountID} >{this.state.defaultAccountID}</option>);
      for(let i=0;i<this.state.userIDs.length;i++){
        if(this.state.userIDs[i] !== this.state.defaultAccountID){accountDropdown.push(<option key={i} value={this.state.userIDs[i]}>{this.state.userIDs[i]}</option>)}
      }
    }
    return <select value={this.state.defaultAccountID} onChange={this.handleAccountSelection}>{accountDropdown}</select>
  }

  render() {
    if(typeof this.state.session === "undefined"){return <Redirect to='/login'  />;}

    let progressBarAccount
    let characters = this.state.characters
    if(characters.total !== characters.completed){
      let progress = (characters.completed/characters.total)*100
      let label = characters.completed + "/" + characters.total + "\t" + progress.toFixed(2)
      progressBarAccount = <ProgressBar now={progress} variant="success"  label={`${label}%`}/>;
    }

    let documentation = <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#story-tracker"} />
    if(typeof this.state.accountMain === "undefined"){return <div>{documentation}<br />{progressBarAccount}<br /><Loading/></div>}

    let accountSelect = this.getAccountDropdown()

    if(typeof this.state.tableData === "undefined"){return <div>{documentation}<br />{progressBarAccount}<br />{accountSelect}</div>}

    let outputTable = this.tableManager()

    return <div>
      {documentation}
      <br/>
      {accountSelect}
      <br />
      <br />
      {outputTable}
    </div>
  }
}