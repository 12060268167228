import React, { Component } from 'react'
import { urlBase } from '../_utiilities/data.json'
import {Documentation, GetCurrency, Loading} from '../_utiilities/functions_react'
import { convertToGold } from '../_utiilities/functions'

interface Objects extends Object{
  [propName: string]: any;
}


interface Alt_StandardProps {}
interface Alt_StandardState {
  itemData: Objects | undefined;
}
export class Alt_Standard extends Component<any, Alt_StandardState> {
  constructor(props: any) {
    super(props)
    this.state = {
      itemData: undefined
    }
  }

  // load up base data
  async componentDidMount() {
    let tmp:{ itemData: Objects } = { itemData: {} }

    let url = `${urlBase.parser}/v1/items/json?fields=id,name,sell_price,img&beautify=min`
    let itemDataRaw = await fetch(url).then(response => response.json()).catch(err => {console.error(url, err.toString()); return []})
    for(let i=0;i<itemDataRaw.length;i++){
      tmp.itemData[itemDataRaw[i]["id"]] = itemDataRaw[i]
    }
    this.setState(tmp)
  }

  render() {
    if(typeof this.state.itemData === "undefined"){return <Loading />}

    return <div
       // style={{"align":"center"}}
    >
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#alt-standard"} />
      <br/>
      <div><h3>Alternative currencies to Gold/MC</h3></div>
      <br />
      <Converter itemData={this.state.itemData}/>
    </div>
  }
}


interface ConverterProps {
  itemData: object;
}
interface ConverterState {
  itemData: Objects;
  gold: number;
  [propName: string]: any;
}
class Converter extends Component<ConverterProps, ConverterState>{
  constructor(props:ConverterProps) {
    super(props)
    this.state = {
      itemData: props.itemData || {},
      gold: 0
    }
  }

  onChange = (e:any) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name
    this.setState({ [name]: value })
  }

  render() {
    let state = this.state
    let gold = state.gold || 0
    let select_quantity = 2

    let left_align:React.CSSProperties = {textAlign: "left"}
    let right_align:React.CSSProperties = {textAlign: "left"}

    let conversion  = []
    let text = []

    // raw gold
    conversion.push(<tr style={left_align}><td>Gold</td><td style={right_align}><GetCurrency number={gold} size={25} /></td></tr>)
    text.push(convertToGold(gold))

    // tp items - pick two
    // MC, ecto, Fractal Encryptions, eyes of kormir, asparagas, cauliflower, ascalonian lumber cores, leaf fossel
    let selected_mixed = []
    const items_main = [19976, 75919, 83103, 12505, 12532, 71398, 74328, 83757]

    const shuffled_main = items_main.sort(() => 0.5 - Math.random())
    let selected_main = shuffled_main.slice(0, select_quantity)
    selected_mixed.push(...selected_main)

    // randoim TP items, pick two
    let items_random = Object.keys(state.itemData)
    const shuffled_random = items_random.sort(() => 0.5 - Math.random())
    let selected_random = shuffled_random.slice(0, select_quantity)
    selected_mixed.push(...selected_random)

    for (let i=0;i<selected_mixed.length;i++){
      let id = selected_mixed[i]
      let item = state.itemData[id]
      if(!item){continue}

      let quantity:any = gold/item["sell_price"] || 0
      quantity = quantity.toFixed(3)
      conversion.push(<tr style={left_align}><td>{item.name}</td><td style={right_align}>{quantity}</td></tr>)

      text.push(`${quantity} ${item.name}`)
    }

    // fun stuff, pick two
    // uwu, owo, Fixed x server crashes, + years to WvW Alliances, Reclaimed Metal Plates
    let random_stuff = ["uwu", "owo", "Fixed Server Crashes","Additional years for WvW Alliances", "Reclaimed Metal Plates"]
    const shuffled_stuff  = random_stuff.sort(() => 0.5 - Math.random())
    let selected_stuff = shuffled_stuff.slice(0, select_quantity)

    for (let i=0;i<selected_stuff.length;i++){
      let item = selected_stuff[i]

      let quantity:any = (gold/10000)*Math.random() || 0
      quantity = quantity.toFixed(3)
      conversion.push(<tr style={left_align}><td>{item}</td><td style={right_align}>{quantity}</td></tr>)

      text.push(`${quantity} ${item}`)
    }

    let width = 400
    return <table style={{margin: "0 auto" }}>
      <tr>
        <td colSpan={2}>
          <input style={{margin:0, width:width}} type={"number"} value={gold} onChange={this.onChange} name={"gold"}/>
        </td>
      </tr>
      <tr><td colSpan={2}>&nbsp;</td></tr>
      {conversion}
      <tr><td colSpan={2}>&nbsp;</td></tr>
      <tr>
        <td colSpan={2}>
          <textarea style={{margin:0, width:width}} value={text.join("/ ")} rows={5}/>
        </td>
      </tr>
    </table>
  }
}