import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {ProgressBar} from 'react-bootstrap'
import { Documentation, Loading, SilverTable} from '../_utiilities/functions_react'
import { filterGeneral, loadData, sortGeneral } from '../_utiilities/functions'

export class Free_Bag_Slots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: props.session,
      accountMain: props.account,
      userIDs: [],
      currencies: [],
      characterDropdown: "Choose Character",
      apiKey: undefined,
      itemData:{},
      firstBags:"",
      filter:[],
      folded:{},
      freeSlots:[],
      totalCharacters:0,
      charactersComplete:0,
      totalAccounts:0,
      accountsComplete:0
    }

    this.handleAccountSelection = this.handleAccountSelection.bind(this)
  }

  // will need this in all sub components
  async componentDidUpdate(prevProps ) {
    if (typeof prevProps.session === "undefined" && typeof this.props.session !== "undefined"){
      this.setState({ session: this.props.session })
    }
    if (typeof prevProps.account === "undefined" && typeof this.props.account !== "undefined"){
      this.setState(await this.processAccount(this.props.account))
    }
  }

  async componentDidMount() {
    this.setState(await this.processAccount (this.state.accountMain));
  }

  processAccount = async (account, tmp = {}) =>{
    if(!account){return tmp}

    tmp.allKeys = []
    tmp.accountMain = account;
    let accounts = account.gameAccounts;
    tmp.userIDs = Object.keys(accounts);
    if(tmp.userIDs.length >0){
      tmp.defaultAccountID = tmp.userIDs[0];
      tmp.apiKey = account.gameAccounts[tmp.userIDs[0]].key;
    }
    for(let i=0;i<tmp.userIDs.length;i++){
      tmp.allKeys.push(account.gameAccounts[tmp.userIDs[i]].key)
    }
    if (typeof tmp.apiKey !== "undefined") {
      tmp = await this.onApiKey(tmp,tmp.apiKey);
    }

    return tmp
  }

  async onApiKey(tmp,apiKey, allKeys) {
    if(typeof allKeys === "undefined"){
      let accountData = await loadData("https://api.guildwars2.com/v2/account?access_token=" + apiKey);
      tmp.playerID = accountData.name;
      tmp.characters = await loadData("https://api.guildwars2.com/v2/characters?access_token=" + apiKey);
      this.setState({totalCharacters:tmp.characters.length})
      tmp = await this.getBags(tmp,apiKey, tmp.characters)
    }else{
      let freeSlots = []
      let totalSlots = 0
      let filledSlots = 0
      let characterCount =0

      this.setState({totalAccounts:allKeys.length})
      for(let i=0;i<allKeys.length;i++){
        let accountData = await loadData("https://api.guildwars2.com/v2/account?access_token=" + allKeys[i]);
        let playerID = accountData.name;
        let characters = await loadData("https://api.guildwars2.com/v2/characters?access_token=" + allKeys[i]);
        this.setState({totalCharacters:characters.length})
        let result = await this.getBags({},allKeys[i], characters)

        totalSlots += result.freeSlotsTotal.totalSlots
        filledSlots += result.freeSlotsTotal.filledSlots
        characterCount += result.freeSlotsTotal.characters

        for(let j=0;j<result.freeSlots.length;j++){
          result.freeSlots[j].name = playerID +"_"+ result.freeSlots[j].name
          freeSlots.push(result.freeSlots[j])
        }
        this.setState({accountsComplete:this.state.accountsComplete+1})
      }
      tmp.freeSlots = freeSlots
      tmp.freeSlotsTotal = {
        totalSlots:totalSlots,
        filledSlots:filledSlots,
        characters:characterCount
      }
    }
    tmp.totalCharacters = 0
    tmp.charactersComplete = 0
    tmp.totalAccounts = 0
    tmp.accountsComplete = 0
    return tmp;
  }

  async getBags(tmp,apiKey, characters){
    tmp.freeSlots = []
    tmp.freeSlotsTotal = {
      totalSlots:0,
      filledSlots:0,
      characters:0
    }

    let requests = characters.map(character => {
      let url = `https://api.guildwars2.com/v2/characters/${character}/inventory?access_token=${apiKey}`
      return fetch(url).then(async(response) => {
        let json = await response.json()
        json.character = character
        return json
      }).catch((err) => {
        console.log(this.props.url, err.toString());
        return {
          character:character,
          bags: []
        }
      })
    })

    await Promise.all(requests)
    .then(responses =>{
      // loop through teh responses
      for(let i=0;i<responses.length;i++){
        tmp.freeSlotsTotal.characters += 1
        let total = 0
        let filled = 0
        let characterBags = responses[i]
        if(typeof characterBags.bags === "undefined"){
          this.setState({charactersComplete:this.state.charactersComplete+1})
          continue
        }

        for(let j=0;j<characterBags.bags.length;j++){
          if(characterBags.bags[j] === null){continue}
          tmp.freeSlotsTotal.totalSlots += characterBags.bags[j].size
          total += characterBags.bags[j].size

          for(let k=0;k<characterBags.bags[j].inventory.length;k++){
            if(characterBags.bags[j].inventory[k] !== null){
              tmp.freeSlotsTotal.filledSlots += 1
              filled += 1
            }
          }
        }
        tmp.freeSlots.push({ name:characterBags.character, total:total, used:filled, free:total-filled})
        this.setState({charactersComplete:this.state.charactersComplete+1})
      }
    })
    .catch(err => console.log(err))

    this.setState({charactersComplete:0})
    return tmp
  }

  async handleAccountSelection(event) {
    let tmp = {};
    if(event.target.value === "All Accounts"){
      tmp.defaultAccountID = event.target.value;
      tmp = await this.onApiKey(tmp,null,this.state.allKeys);
    }else{
      tmp.defaultAccountID = event.target.value;
      tmp.apiKey = this.state.accountMain.gameAccounts[event.target.value].key;
      tmp = await this.onApiKey(tmp,tmp.apiKey);
    }

    this.setState(tmp);
  }

  tableManager = (data) => {
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "text": {
          className: "left",
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
      },
      colsToDisplay:20,
      filter: {active:true},
      sort:{col:"name", desc:false},
      headers: {
        cols: [
          { template: "text", header: "Character", accessor: "name" },
          { template: "number", header: "Total", accessor: "total" },
          { template: "number", header: "Used", accessor: "used" },
          { template: "number", header: "Free", accessor: "free" },
        ]
      }
    }

    return this.createTable(data, config)
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
    />
  }

  render() {
    if(typeof this.state.session === "undefined"){return <Redirect to='/login'  />;}
    if(typeof this.state.accountMain === "undefined"){return <Loading/>}
    if(typeof this.state.apiKey !== "undefined" && typeof this.state.characters === "undefined"){return <Loading/>}
    let characterDropdown = [];
    if(this.state.userIDs.length >0){
      characterDropdown.push(<option key={-1} value={this.state.defaultAccountID} >{this.state.defaultAccountID}</option>);
      for(let i=0;i<this.state.userIDs.length;i++){
        if(this.state.userIDs[i] !== this.state.defaultAccountID){
          characterDropdown.push(<option key={i} value={this.state.userIDs[i]}>{this.state.userIDs[i]}</option>)
        }
      }
      characterDropdown.push(<option key={this.state.userIDs.length + 1} value={"All Accounts"} >All Accounts</option>);
    }
    let accountSelect = <select value={this.state.defaultAccountID} onChange={this.handleAccountSelection}>{characterDropdown}</select>

    let outputTable, total
    if (this.state.freeSlots.length > 0 ) {
      outputTable = this.tableManager(this.state.freeSlots)

      let freeSlotsTotal = this.state.freeSlotsTotal
      total = <span>Characters: {freeSlotsTotal.characters}. Total: {freeSlotsTotal.totalSlots}. Used: {freeSlotsTotal.filledSlots}. Free:{freeSlotsTotal.totalSlots -freeSlotsTotal.filledSlots}</span>
    }

    let progressBarCharacter, progressBarAccount
    if(this.state.totalAccounts !== 0){
      let progress = (this.state.accountsComplete/this.state.totalAccounts)*100
      let label = this.state.accountsComplete + "/" + this.state.totalAccounts + "\t" + progress.toFixed(2)
      progressBarAccount = <ProgressBar now={progress} variant="warning"  label={`${label}%`}/>;
    }
    if(this.state.totalCharacters !== 0){
      let progress = (this.state.charactersComplete/this.state.totalCharacters)*100
      let label = this.state.charactersComplete + "/" + this.state.totalCharacters + "\t" + progress.toFixed(2)
      progressBarCharacter = <ProgressBar now={progress} variant="success"  label={`${label}%`}/>;
    }

    return <div>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#free-bag-slots"} />
      <br/>
      {accountSelect}
      <br />
      {progressBarAccount}
      {progressBarCharacter}
      <br />
      {total}
      <br />
      {outputTable}
    </div>
  }
}