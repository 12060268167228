import React, { Component } from 'react'
import { urlBase } from '../_utiilities/data.json'
import {
  filterGeneral,
  getRarityColour,
  sortGeneral,
} from '../_utiilities/functions'
import {
  Documentation,
  GetCurrency,
  Loading,
  SilverTable,
} from '../_utiilities/functions_react'
import {TextImgTP} from "./tp_textImg";

let startTimer = Date.now()
let timer = {}
timer.start = (Date.now() - startTimer) / 1000

function timeSince(date) {

  let seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export class TP extends Component {
  constructor(props) {
    super(props)
    this.state = {
      siteAddress: window.location.protocol + "//" + window.location.host
    }
  }

  async loadData(url) {return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))}

  async componentDidMount() {
    timer.mounted = (Date.now() - startTimer) / 1000
    // used in all
    let baseData = "id,name,img,rarity,chat_link,lastUpdate,firstAdded,"

    let price = "sell_price,buy_price,"
    let priceChange = "1m_sell_price_avg,1m_buy_price_avg,"

    let quantity = "sell_quantity,buy_quantity,"
    let quantityChange = "1m_sell_quantity_avg,1m_buy_quantity_avg,"

    let sold = "1d_sell_sold,1d_buy_sold,"
    let soldChange = "1m_sell_sold,1m_buy_sold,"

    let listed = "1d_sell_listed,1d_buy_listed,"
    let delisted = "1d_sell_delisted,1d_buy_delisted,"
    
    let tmp = {}
    let itemData = await this.loadData(urlBase.parser + "/v1/items/json?fields=" +
      baseData +
      price + priceChange +
      quantity + quantityChange +
      sold + soldChange +
      listed + delisted +
      "&beautify=min")
    timer.itemData = (Date.now() - startTimer) / 1000;

    let newItems = await this.loadData(urlBase.parser + "/v1/items/json/new?fields="+baseData+"&limit=20&filter=&beautify=min");
    timer.newItems = (Date.now() - startTimer) / 1000;

    [tmp.tableData, tmp.dates] = this.processData(itemData, newItems)
    timer.tableData = (Date.now() - startTimer) / 1000
    this.setState(tmp)
  }
  
  processData = (itemData, newItems) =>{
    let data = []
    let dates = []
    for(let i=0;i<itemData.length;i++){
      if(typeof itemData[i].lastUpdate !== "undefined"){
        dates.push(itemData[i].lastUpdate)
      }

      let sellSoldChange = (itemData[i]["1m_sell_sold"] - itemData[i]["1d_sell_sold"])/29
      let buySoldChange = (itemData[i]["1m_buy_sold"] - itemData[i]["1d_buy_sold"])/29
      itemData[i].sell_sold_change = (((itemData[i]["1d_sell_sold"]- sellSoldChange)/sellSoldChange)*100).toFixed(2) + "%"
      itemData[i].buy_sold_change = (((itemData[i]["1d_buy_sold"]- buySoldChange)/buySoldChange)*100).toFixed(2) + "%"

      let sellQuantityChange = itemData[i]["1m_sell_quantity_avg"]
      let buyQuantityChange = itemData[i]["1m_buy_quantity_avg"]
      itemData[i].sell_quantity_change = (((itemData[i]["sell_quantity"]- sellQuantityChange)/sellQuantityChange)*100).toFixed(2) + "%"
      itemData[i].buy_quantity_change = (((itemData[i]["buy_quantity"]- buyQuantityChange)/buyQuantityChange)*100).toFixed(2) + "%"

      let sellPriceChange = itemData[i]["1m_sell_price_avg"]
      let buyPriceChange = itemData[i]["1m_buy_price_avg"]
      itemData[i].sell_price_change = (((itemData[i]["sell_price"]- sellPriceChange)/sellPriceChange)*100).toFixed(2) + "%"
      itemData[i].buy_price_change = (((itemData[i]["buy_price"]- buyPriceChange)/buyPriceChange)*100).toFixed(2) + "%"

      let allFields = Object.keys(itemData[i])
      for(let j=0;j<allFields.length;j++){
        if(itemData[i][allFields[j]] === "NaN%"){
          itemData[i][allFields[j]] = "0%"
        }
      }
      data.push(itemData[i])
    }

    for(let i=0;i<newItems.length;i++){
      newItems[i].timeSinceAdded = timeSince(new Date(newItems[i].firstAdded))
    }
    let tableData = {}
    
    // new
    tableData["Added"] = newItems;

    data = data.filter((item)=>{return typeof item.sell_quantity !== "undefined"})

    let entries = [
      { entry: "Sell Quantity", sortField:"sell_quantity", filter:{name:"sell_quantity", quantity:0} },
      { entry: "Sell Sold", sortField:"1d_sell_sold", filter:{name:"1d_sell_sold", quantity:0} },
      { entry: "Sell Listed", sortField:"1d_sell_delisted", filter:{name:"1d_sell_listed", quantity:0} },
      { entry: "Sell Delisted", sortField:"1d_sell_delisted", filter:{name:"1d_sell_delisted", quantity:0} },
      { entry: "Sell Quantity Δ", sortField:"sell_quantity_change", filter:{name:"sell_quantity", quantity:0} },
      { entry: "Sell Sold Δ", sortField:"sell_sold_change", filter:{name:"1d_sell_sold", quantity:5000} },
      { entry: "Sell Price Δ", sortField:"sell_price_change", filter:{name:"", quantity:0} },

      { entry: "Buy Quantity", sortField:"buy_quantity", filter:{name:"buy_quantity", quantity:0} },
      { entry: "Buy Sold", sortField:"1d_buy_sold", filter:{name:"1d_buy_sold", quantity:0} },
      { entry: "Buy Listed", sortField:"1d_buy_delisted", filter:{name:"1d_buy_listed", quantity:0} },
      { entry: "Buy Delisted", sortField:"1d_buy_delisted", filter:{name:"1d_buy_delisted", quantity:0} },
      { entry: "Buy Quantity Δ", sortField:"buy_quantity_change", filter:{name:"buy_quantity", quantity:0} },
      { entry: "Buy Sold Δ", sortField:"buy_sold_change", filter:{name:"1d_buy_sold", quantity:5000} },
      { entry: "Buy Price Δ", sortField:"buy_price_change", filter:{name:"", quantity:0} },
    ]

    for(let i=0;i<entries.length;i++){
      tableData = this.addItemsToElement(tableData,data, entries[i].entry, entries[i].sortField, entries[i].filter)
    }

    return [tableData, dates.sort().reverse()]
  }

  addItemsToElement = (tableData,data, entry, sortField, filter) =>{
    if(typeof filter === "undefined"){return tableData}
    if(typeof filter.name === "undefined"){return tableData}
    if(typeof filter.quantity === "undefined"){filter.quantity = 0}
    
    const tmp = data.sort((a, b) => a[sortField] - b[sortField])
    let tmpArray = []
    for(let i=0;i<tmp.length;i++){
      if(i < 10){tmpArray.push(tmp[i])}
      if((tmp.length - i) <= 10){tmpArray.push(tmp[i])}
    }

    if(filter.name === ""){
      tableData[entry] = tmpArray
    }else{
      tableData[entry] = tmpArray.filter((item)=> item[filter.name] > filter.quantity)
    }
    
    return tableData
  }

  tableManager = (data) => {
    let output = []
    let order = [
      {name:"Added", accessor:"timeSinceAdded", template:"text", header:"Newest Items", link:"#/tradepost/detailed?sort=firstAdded&order=desc"},

      {name:"Sell Quantity", accessor:"sell_quantity", template:"number", header:"Top Supplied Items", link:"#/tradepost/detailed?sort=sell_quantity&order=desc"},
      {name:"Buy Quantity", accessor:"buy_quantity", template:"number", header:"Top Demanded Items", link:"#/tradepost/detailed?sort=buy_quantity&order=desc"},

      {name:"Sell Sold", accessor:"1d_sell_sold", template:"number", header:"Top Sold Items - 24h", link:"#/tradepost/detailed?sort=sell_sold&order=desc"},
      {name:"Buy Sold", accessor:"1d_buy_sold", template:"number", header:"Top Bought Items - 24h", link:"#/tradepost/detailed?sort=buy_sold&order=desc"},

      {name:"Sell Quantity Δ", accessor:"sell_quantity_change", template:"percent", header:"Change in Sell Quantity - 24h/30d", link:"#/tradepost/detailed?sort=sell_quantity_avg_month&order=desc"},
      {name:"Buy Quantity Δ", accessor:"buy_quantity_change", template:"percent", header:"Change in Buy Quantity - 24h/30d", link:"#/tradepost/detailed?sort=buy_quantity_avg_month&order=desc"},

      {name:"Sell Sold Δ", accessor:"sell_sold_change", template:"percent", header:"Change in Sell Sold - 24h/30d", link:"#/tradepost/detailed?sort=sell_sold_month&order=desc"},
      {name:"Buy Sold Δ", accessor:"buy_sold_change", template:"percent", header:"Change in Buy Sold - 24h/30d", link:"#/tradepost/detailed?sort=buy_sold_month&order=desc"},

      {name:"Sell Price Δ", accessor:"sell_price_change", template:"percent", header:"Change in Sell Price - 24h/30d", link:"#/tradepost/detailed?sort=sell_price_avg_month&order=desc"},
      {name:"Buy Price Δ", accessor:"buy_price_change", template:"percent", header:"Change in Buy Price - 24h/30d", link:"#/tradepost/detailed?sort=buy_price_avg_month&order=desc"},

      {name:"Sell Listed", accessor:"1d_sell_listed", template:"number", header:"Sell Offers Listed - 24h", link:"#/tradepost/detailed?sort=sell_listed&order=desc"},
      {name:"Buy Listed", accessor:"1d_buy_listed", template:"number", header:"Buy Orders Listed - 24h", link:"#/tradepost/detailed?sort=buy_listed&order=desc"},

      {name:"Sell Delisted", accessor:"1d_sell_delisted", template:"number", header:"Sell Offers Delisted - 24h", link:"#/tradepost/detailed?sort=sell_delisted&order=desc"},
      {name:"Buy Delisted", accessor:"1d_buy_delisted", template:"number", header:"Buy Orders Delisted - 24h", link:"#/tradepost/detailed?sort=buy_delisted&order=desc"},

    ]

    let textWidth = 270
    let endWidth = 80
    for(let i=0;i<order.length;i++){
      let config = {
        className: {
          table: "centerTable table-primary table-striped table-highlight",
        },
        templates: {
          "text": {
            className: "right",
            width: endWidth,
          },
          "textImg": {
            contents:(item)=> <TextImgTP item={item} siteAddress={this.state.siteAddress}/>,
            className: "left",
            width: textWidth,
          },
          "number": {
            className: "right",
            width: endWidth,
            sort: sortGeneral,
            filter: filterGeneral
          },
          "percent": {
            className: "right",
            width: endWidth,
            sort: sortGeneral,
            filter: filterGeneral
          },
          "gold": {
            className: "right",
            width: endWidth,
            contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
            sort: sortGeneral,
            filter: (item, filter) => filterGeneral(item, filter, 10000)
          }
        },
        sort:{col:order[i].accessor, desc:true},
        showButtons: false,
        headers: {},
        colsToDisplay: 10
      }

      if(order[i].name === "Added"){
        config.sort.desc = false
      }

      config.headers = {
        cols: [
          { template: "textImg", header: "", accessor: "name" },
        ]
      }

      // class = grid-results
      config.headers.cols.push({ template: order[i].template, header: order[i].name, accessor: order[i].accessor })

      let table = <div>
        <h4 style={{color:"#ffffff"}}>
          <a
            href={this.state.siteAddress + order[i].link}
            target={"_blank"} rel={"noreferrer noopener"} title={"Detailed view for " + order[i].name }
          >
            {order[i].header}
          </a>
        </h4>
        {this.createTable(data[order[i].name], config)}
      </div>
      output.push(table)
    }

    return <div className={"grid-results"}>
      {output}
    </div>
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
      callbackToParent={this.callbackToParent}
    />
  }

  render() {
    if(typeof this.state.tableData === "undefined"){return <Loading />}

    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/tradepost#overview"} />
      <br/>
      <h2>Tradepost</h2>
      <span>
        Latest Update: {new Date(this.state.dates[0]).toLocaleString()}
        {console.log(timer)}
      </span>
      <br />
      <br />
      {this.tableManager(this.state.tableData)}
    </div>
  }
}