import React, { Component } from 'react';
import { urlBase } from '../_utiilities/data.json'
import { Documentation, GetCurrency, Loading, SilverTable, } from '../_utiilities/functions_react'
import {
  filterGeneral,
  getProfessionIcon,
  sortGeneral,
} from '../_utiilities/functions'

export class Refinement extends Component {
  constructor (props) {
    super(props);
    this.state = {
      filter: []
    }
  }

  async componentDidMount () {
    let tmp = {}
    let rawItemData = await this.loadData(urlBase.parser + "/v1/items/json?fields=buy_price,sell_price,name,img,id,AccountBound,7d_sell_sold&beautify=min")
    tmp.priceData = {}
    for (let i = 0; i < rawItemData.length; i++) {
      tmp.priceData[rawItemData[i].id] = rawItemData[i]
    }
    tmp.recipeData = await this.loadData(urlBase.parser + "/v2/recipes?filter=type:Refinement&beautify=min")
    this.setState(tmp)
  }

  async loadData (url) {return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))}

  getPriceData = (id, items) =>{
    id = id - 0
    let data = { buy_price: 0, sell_price: 0, name: "", img: undefined, AccountBound: true }
    let result = items[id]
    if (typeof result !== "undefined") {
      data = result
    }
    return data
  }

  calculateCrafting = (priceData, recipes) => {
    let tmp = []
    for (let i = 0; i < recipes.length; i++) {
      let temp = {}
      let outputItem = this.getPriceData(recipes[i].output_item_id, priceData)

      temp.name = outputItem.name
      temp.img = outputItem.img
      temp.containsAccountBound = "False"
      temp.ingredients = ""

      temp.professions = recipes[i].disciplines
      temp.level = recipes[i].min_rating

      temp.cost = 0
      for (let j = 0; j < recipes[i].ingredients.length; j++) {
        let recipeItem = this.getPriceData(recipes[i].ingredients[j].item_id, priceData)

        temp.ingredients += recipeItem.name + " x" + recipes[i].ingredients[j].count + " \n"
        if (recipeItem.AccountBound) {
          temp.containsAccountBound = "True"
        } else {
          switch(this.state.craftingPrice){
            case "Sell":{
              temp.cost += recipeItem.sell_price * recipes[i].ingredients[j].count
              break
            }
            case "Buy":{
              temp.cost += recipeItem.buy_price * recipes[i].ingredients[j].count
              break
            }
            default:{
              temp.cost += recipeItem.buy_price * recipes[i].ingredients[j].count
            }
          }
        }
      }
      switch(this.state.sellPrice){
        case "Sell":{
          temp.profit = ((outputItem.sell_price * recipes[i].output_item_count * 0.85) - temp.cost) || 0
          break
        }
        case "Buy":{
          temp.profit = ((outputItem.buy_price * recipes[i].output_item_count * 0.85) - temp.cost) || 0
          break
        }
        default:{
          temp.profit = ((outputItem.sell_price * recipes[i].output_item_count * 0.85) - temp.cost) || 0
        }
      }
      temp.profitPerMin = temp.profit / (recipes[i].time_to_craft_ms / 60000) || 0
      temp.profitPerHour = temp.profitPerMin * 60
      temp.velocity = outputItem["7d_sell_sold"]/7 || 0
      temp.velocity = temp.velocity.toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })

      if (outputItem.sell_price > 0) {
        tmp.push(temp)
      }
    }
    return tmp
  }

  tableManager = (data) => {
    let square = 25
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "text": {
          className: "left",
        },
        "textImg": {
          contents: (item) => {
            let name = item.name;
            let ingredients = item.ingredients
            let image = <img
              key={name}
              style={{ width: square, height: square }}
              src={item.img}
              title={name}
              alt={name}
            />;
            return <span title={ingredients}>{image} {name}</span>
          },
          className: "left",
        },
        "profession": {
          contents: (item) => {
            let icons = []
            for(let i=0;i<item.professions.length;i++){
              let name = item.professions[i]
              let src = getProfessionIcon(item.professions[i]);

              let img = <img
                key={name}
                style={{ width: square, height: square }}
                src={src}
                title={name}
                alt={name}
              />
              icons.push(img)
            }
            return <span title={item.professions.join()}>{icons}</span>
          },
          className: "left",
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "percent": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "gold": {
          className: "right",
          contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
          sort: sortGeneral,
          filter: (item, filter) => filterGeneral(item, filter, 10000)
        }
      },
      colsToDisplay: 20,
      filter: { active: true },
      sort: { col: "name", desc: false },
      headers: {
        cols: [
          {
            template: "textImg",
            header: "Name (Hover for Ingredients)",
            accessor: "name"
          },
          {
            template: "profession",
            header: "Profession",
            accessor: "professions"
          },
          { template: "number", header: "Level", accessor: "level" },
          {
            template: "text",
            header: "AccBound",
            accessor: "containsAccountBound"
          },
          {
            template: "number",
            header: "Velocity/day (week)",
            accessor: "velocity"
          },
          { template: "gold", header: "Cost", accessor: "cost" },
          { template: "gold", header: "Profit", accessor: "profit" },
          { template: "gold", header: "Profit/min", accessor: "profitPerMin" },
          { template: "gold", header: "Profit/h", accessor: "profitPerHour" },
        ]
      }
    }

    return this.createTable(data, config)
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
    />
  }

  toggleCreator = (title,entry,states, defaultState) =>{
    if(typeof entry !== "string"){return null}
    if(states.length === 0){return null}

    let selected, old
    if(states.length >0){
      if(typeof this.state[entry] === "undefined"){
        if(typeof defaultState === "undefined"){
          old = states[0]
          let tmp = {}
          tmp[entry] = states[0]
          this.setState(tmp)
        }else{
          old = defaultState
          let tmp = {}
          tmp[entry] = defaultState
          this.setState(tmp)
        }

      }else{
        old = this.state[entry]
      }
      let position = states.indexOf(old) +1
      if(position >= states.length){
        selected = states[0]
      }else{
        selected = states[position]
      }
    }

    let text = []
    for(let i=0;i<states.length;i++){
      if(text.length >0){
        text.push(" / ")
      }
      if(old === states[i]){
        text.push(<b>{states[i]}</b>)
      }else{
        text.push(states[i])
      }
    }

    return <span
      style={{cursor: "pointer"}}
      onClick={
        ()=>{
          let tmp = {}
          tmp[entry] = selected
          this.setState(tmp)
        }
      }
    >{title}: {text}</span>
  }

  render () {
    if (typeof this.state.priceData === "undefined") {return <Loading/>}
    if (typeof this.state.recipeData === "undefined") {return <Loading/>}

    return <div>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#refinement"} />
      <br/>
      <h3>Refining</h3>
      <div>
        {this.toggleCreator("Crafting Prices","craftingPrice", ["Buy", "Sell"], "Buy")}
        &emsp;
        {this.toggleCreator("Sell Prices","sellPrice", ["Buy", "Sell"], "Sell")}
      </div>
      <br />
      {this.tableManager(this.calculateCrafting(this.state.priceData, this.state.recipeData))}
    </div>;
  }
}