import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import cookie from "react-cookies";
import {Redirect} from "react-router-dom";
import { urlBase } from '../_utiilities/data.json'
import { Documentation, GetCurrency, Loading, } from '../_utiilities/functions_react'

export class Stats_Collection extends Component {
    constructor(props) {
        super(props);
        this.state = {session: cookie.load('session'),currencies: [], characterDropdown: "Choose Character", apiKey: undefined, magicFind:"", activityDropdown:"", delayDropdown:"", firstBags:"", secondBags:"", salvageKitDropdown:""};

        this.handleChangeAPI = this.handleChangeAPI.bind(this);
        this.handleChangeMF = this.handleChangeMF.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCharacterSelection = this.handleCharacterSelection.bind(this);
        this.handleCharacterSelection2 = this.handleCharacterSelection2.bind(this);
        this.handleActivitySelection = this.handleActivitySelection.bind(this);
        this.handleDelaySelection = this.handleDelaySelection.bind(this);
        this.handleFirstSnapshot = this.handleFirstSnapshot.bind(this);
        this.handleSecondSnapshot = this.handleSecondSnapshot.bind(this);
        this.submitResults = this.submitResults.bind(this);
        this.handleSalvageKitSelection = this.handleSalvageKitSelection.bind(this);
    }

    async loadData(url,extra) {return await fetch(url,extra).then(response => {return response.json()}).catch((err) => {console.log(this.props.url, err.toString()); return {result:"error", error:"could not fetch"};})}

    async loadData2(url, headers,setParms) {
        if(typeof setParms === "undefined"){
            setParms = {}
        }
        if(typeof headers === "undefined"){
            return await fetch(url,setParms).then(response => response.json()).then(data => {
                return data;
            }).catch(err => console.error(this.props.url, err.toString()))
        }
        if(typeof headers !== "undefined"){
            return await fetch(url,setParms)
                .then(async(response) =>  {
                    let tmp = {};
                    tmp.data = await response.json();
                    tmp.headers = {};
                    for (let pair of response.headers.entries()) {
                        tmp.headers[pair[0]] = pair[1];
                    }
                    return tmp})
                .then(tmp => {return tmp})
                .catch(err => console.error(this.props.url, err.toString()))
        }

    }

    async componentDidMount() {
        let tmp = {};
        tmp.currenciesIDs = await this.loadData("https://api.guildwars2.com/v2/currencies");
        tmp.currencies = await this.loadData( "https://api.guildwars2.com/v2/currencies?ids=" + tmp.currenciesIDs.join());
        let rawItemData = await this.loadData(urlBase.parser + "/v1/items/json?fields=type,img,id,name,buy_price,sell_price&beautify=min&filter=");

        tmp.marketPrices = {};
        for(let i=0;i<rawItemData.length;i++){
            tmp.marketPrices[rawItemData[i].id] = rawItemData[i];
        }

        let account = await this.loadData(urlBase.account + "/v1/website/account",{method: 'GET', headers: {'Content-Type': 'application/json', session: this.state.session}});
        tmp.defaultAccountID = "";
        if(account.result === "success"){
            tmp.accountMain = account.account;
            let accounts = tmp.accountMain.gameAccounts;
            tmp.userIDs = Object.keys(accounts);
            if(tmp.userIDs.length >0){
                tmp.defaultAccountID = tmp.userIDs[0];
                tmp.apiKey = tmp.accountMain.gameAccounts[tmp.userIDs[0]].key;
            }
        }
        if(account.result ==="error"){
            if(account.error ==="Invalid session"){
                cookie.remove('session');
                tmp.session = undefined;
            }
        }

        if (typeof tmp.apiKey !== "undefined") {
            tmp = await this.onApiKey(tmp,tmp.apiKey);
        }
        this.setState(tmp);
    }

    handleChangeAPI(event) {
        this.setState({apiBox: event.target.value});
    }

    handleChangeMF(event) {
        this.setState({magicFind: event.target.value-0});
    }

    // gets account ID and characters
    async onApiKey(tmp,apiKey) {
        let accountData = await this.loadData("https://api.guildwars2.com/v2/account?access_token=" + apiKey);
        tmp.playerID = accountData.name;
        tmp.characters = await this.loadData("https://api.guildwars2.com/v2/characters?access_token=" + apiKey);
        return tmp;
    }

    async handleSubmit(event) {
        let apiKey = this.state.apiBox;
        event.preventDefault();
        let response = await fetch("https://api.guildwars2.com/v2/tokeninfo?access_token=" + apiKey);
        if (response.status === 500) {
            alert('API Failure');
        }
        if (response.status === 200) {
            let result = await response.json();
            if (result.permissions.includes("unlocks")) {
                let tmp = await this.onApiKey({},apiKey);
                tmp.apiKey = apiKey;
                tmp.apiBox = "";
                this.setState(tmp);
            } else {
                alert('Does not have "unlocks" permission');
            }
        }
    }

    async handleFirstSnapshot() {
        console.log("Handling first snapshot");
        let tmp = {};
        tmp.firstSnapshotTime = new Date();
        tmp.firstWallet = await this.loadData("https://api.guildwars2.com/v2/account/wallet?access_token=" + this.state.apiKey);
        let character   = await this.loadData2("https://api.guildwars2.com/v2/characters/"+this.state.characterDropdown+"?access_token=" + this.state.apiKey,"yes");
        tmp.firstExpires = new Date(character.headers.expires);

        let bags = character.data.bags;
        bags = bags.filter(function (el) {return el != null;});
        let tempBag0 = {};
        for(let i=0;i<bags.length;i++){
            let bag = bags[i].inventory;
            for(let ii=0;ii<bag.length;ii++){
                if(bag[ii] !== null){
                    let itemID = bag[ii].id;
                    let itemCount = bag[ii].count;

                    if(typeof tempBag0[itemID] === "undefined"){
                        tempBag0[itemID] = 0;
                    }
                    tempBag0[itemID] += itemCount;
                }
            }
        }
        tmp.firstBags = tempBag0;
        tmp.character = character.data;
        this.setState(tmp)
    }

    async handleSecondSnapshot() {
        console.log("Handling second snapshot");
        let tmp = {};
        tmp.secondSnapshotTime = new Date();
        tmp.secondWallet = await this.loadData("https://api.guildwars2.com/v2/account/wallet?access_token=" + this.state.apiKey);
        let character   = await this.loadData2("https://api.guildwars2.com/v2/characters/"+this.state.characterDropdown+"?access_token=" + this.state.apiKey,"yes");
        tmp.secondExpires = new Date(character.headers.expires);

        let bags = character.data.bags;
        let tempBag0 = {};
        for(let i=0;i<bags.length;i++){
            let bag = bags[i].inventory;
            for(let ii=0;ii<bag.length;ii++){
                if(bag[ii] !== null){
                    let itemID = bag[ii].id;
                    let itemCount = bag[ii].count;

                    if(typeof tempBag0[itemID] === "undefined"){
                        tempBag0[itemID] = 0;
                    }
                    tempBag0[itemID] += itemCount;
                }
            }
        }
        tmp.secondBags = tempBag0;
        this.setState(tmp)
    }

    async handleCharacterSelection(event){
        let tmp = {};
        tmp.characterDropdown = event.target.value;
        this.setState(tmp)
    }

    async handleActivitySelection(event){
        let tmp = {};
        tmp.activityDropdown = event.target.value;
        this.setState(tmp)
    }
    async handleDelaySelection(event){
        let tmp = {};
        tmp.delayDropdown = event.target.value;
        this.setState(tmp)
    }

    async handleSalvageKitSelection(event){
        let tmp = {};
        tmp.salvageKitDropdown = event.target.value;
        this.setState(tmp)
    }

    getDataFromID = (id, items) => {
        id = id-0;
        let data = {};
        let result = items[id];
        if(typeof result !== "undefined"){
            data = result
        }
        return data;
    }

    generateIcons(bagArray,walletArray,cache,initialMsg,expireMsg,extra){
        let images = [];
        let keys = Object.keys(bagArray);
        let allowedTypes = ["Armor","Container","Weapon","Trophy"];
        for(let i=0;i<keys.length;i++) {
            let id = keys[i];
            let data = this.getDataFromID(id,this.state.marketPrices);
            if(allowedTypes.indexOf(data.type) !== -1 || extra === "diff"){
                images.push(
                    <img
                        key={data.id +"-"+ bagArray[id]}
                        style={{width: 40,height:40}}
                        src={data.img}
                        title={data.name +"-"+ bagArray[id]}
                        alt={data.name +"-"+ bagArray[id]}
                    />
                )
            }
        }

        let wallet = walletArray;
        let walletString = "";

        for(let i=0;i<wallet.length;i++){
            let id = wallet[i].id;
            let value = wallet[i].value;
            if(id ===1){
                value = <GetCurrency number={value} size={25} />
            }
            let data = this.getDataFromID(id,this.state.currencies);
            walletString += data.name +"-"+ value + "\u000A";
        }

        let expiration = expireMsg + cache;

        return (
            <div>
                <p>{initialMsg}</p>
                <p>{expiration}</p>
                <br/>
                <p title={walletString}>Wallet - Hover</p>
                {images}
                <br/>
                <br/>
            </div>
        );
    }

    getCurrencyItem =(id, list) => {
        let result = 0;
        for(let i=0;i<list.length;i++){
            if(id === list[i].id){
                result = list[i].value;
                i = list.length +1;
            }
        }
        return result;
    }

    async handleCharacterSelection2(event) {
        let tmp = {};
        tmp.defaultAccountID = event.target.value;
        tmp.apiKey = this.state.accountMain.gameAccounts[event.target.value].key;
        tmp = await this.onApiKey(tmp,tmp.apiKey);
        this.setState(tmp);
    }

    getDifference(beforeWallet, afterWallet, beforeBag, afterBag){
        let difference = {};
        difference.wallet = [];
        let currenciesList = this.state.currenciesIDs;
        for(let i=0;i<currenciesList.length;i++){
            let tmp = {};
            tmp.id = currenciesList[i];
            let beforeWalletResult = this.getCurrencyItem(tmp.id ,beforeWallet);
            let afterWalletResult = this.getCurrencyItem(tmp.id ,afterWallet);
            tmp.value = afterWalletResult - beforeWalletResult;
            if(tmp.value !== 0){
                difference.wallet.push(tmp);
            }
        }

        let allKeys = Object.keys(beforeBag);
        let keysAfter = Object.keys(afterBag);
        for(let i=0;i<keysAfter.length;i++){
            if(allKeys.indexOf(keysAfter[i]) === -1){
                allKeys.push(keysAfter[i])
            }
        }
        difference.bags = {};
        difference.items = [];
        difference.consumed = [];
        for(let i=0;i<allKeys.length;i++){
            let item = allKeys[i]-0;
            let beforeBagQuantity = beforeBag[item] || 0;
            let afterBagQuantity = afterBag[item] || 0;
            let diff = afterBagQuantity - beforeBagQuantity;

            if(diff !==0){
                difference.bags[item] = diff;

                let itemOut = {
                    id:item,
                    quantity:diff,
                    price: this.getDataFromID(item,this.state.marketPrices).sell_price
                };
                if(diff > 0){
                    difference.items.push(itemOut);
                }
                if(diff < 0){
                    difference.consumed.push(itemOut);
                }
            }
        }

        return difference;
    }

    async submitResults(event){
        event.preventDefault();

        let url = urlBase.stats + "/v1/general";
        let sending = this.getDifference(this.state.firstWallet, this.state.secondWallet, this.state.firstBags, this.state.secondBags);

        sending.key = this.state.apiKey;
        sending.account = this.state.playerID;
        sending.character = this.state.characterDropdown;
        sending.level = this.state.character.level;
        sending.mf = this.state.magicFind;
        sending.activity = this.state.activityDropdown;
        sending.iso = new Date().toISOString();
        sending.delay = this.state.delayDropdown;
        sending.salvagekit = this.state.salvageKitDropdown;

        let headers = {'Content-Type': 'application/json'};
        let result = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(sending)
        })
            .then(function (response) {
                let tmp = {};
                if (response.status === 200) {
                    console.log("Success");
                    tmp.magicFind = "";
                    tmp.activityDropdown= "";
                    tmp.characterDropdown = "Choose Character";
                    tmp.firstBags = "";
                    tmp.secondBags = "";
                    tmp.secondBagsImg = "";
                    tmp.delayDropdown = "";
                    tmp.salvageKitDropdown = "";
                }
                return tmp;
            })
            .catch(err => {return {error:err}});

        if(typeof result.error === "undefined"){
            this.setState(result)
        }else{
            console.log(result)
        }
    };
    render() {
        if(typeof this.state.session === "undefined"){
            return <Redirect to='/login'  />;
        }
        let characterSelect2 = <Loading/>;
        let info = <div>
            <p>
                <b>Please read the documentation (above) before starting. If ye need help you can contact me <a href={"https://slate.silveress.ie/gw2_site#contact"} target={"_blank"}  rel={"noopener noreferrer"}>here</a>.</b>
            </p>
        </div>;
            if(typeof this.state.accountMain !== "undefined"){
                let characterDropdown = [];
                if(this.state.userIDs.length >0){
                    characterDropdown.push(<option key={-1} value={this.state.defaultAccountID} >{this.state.defaultAccountID}</option>);
                    for(let i=0;i<this.state.userIDs.length;i++){
                        if(this.state.userIDs[i] !== this.state.defaultAccountID){
                            characterDropdown.push(
                                <option key={i} value={this.state.userIDs[i]}>{this.state.userIDs[i]}</option>
                            )
                        }
                    }
                }
                characterSelect2 = (
                    <select
                        value={this.state.defaultAccountID}
                        onChange={this.handleCharacterSelection2}>
                        {characterDropdown}
                    </select>
                );
            }

        let apiInput = <div>

        </div>;

        let characterSelect = "";

        let researchType = "";
        let firstSnapshot = "";
        let firstBagsImg = "";
        let secondSnapshot = "";
        let secondBagsImg = "";
        let differenceImg = "";
        let magicFind = "";
        let activitySelect = "";
        let submit = "";
        let delay = "";
        let salvageKit = "";
        let error = "";
        if (typeof this.state.apiKey !== "undefined" && typeof this.state.characters !== "undefined") {


            let characterDropdown = [
                <option key="-1" value="">Select Character</option>
            ];

            for(let i=0;i<this.state.characters.length; i++){
                characterDropdown.push(
                    <option key={i} value={this.state.characters[i]}>{this.state.characters[i]}</option>
                )
            }

            characterSelect = (
                <form>
                    <div>
                        <select style={{width: 200}} value={this.state.characterDropdown} onChange={this.handleCharacterSelection}>
                            {characterDropdown}
                        </select>
                    </div>
                </form>
            );

            magicFind =         <div>
                <label
                    style={{width: 150}}
                    htmlFor="MF"
                >Magic find:&nbsp;</label>
                <input
                    style={{width: 50}}
                    type="text"
                    required
                    value={this.state.magicFind}
                    onChange={this.handleChangeMF}
                />
            </div>;

                let activityOptions = [
                    <option key={-1} value="">Select Activity</option>,
                    <option key={-2} value={"Opening"}>Opening Containers</option>,
                    <option key={-3} value={"Salvaging"}>Salvaging stack of items</option>,
                    <option key={-4} value={"BLTC"}>Black Lion Chests</option>
                ];

            activitySelect =  <form>
                    <div>
                        <select style={{width: 200}} value={this.state.activityDropdown} onChange={this.handleActivitySelection}>
                            {activityOptions}
                        </select>
                    </div>
                </form>;

            if(this.state.activityDropdown === "Salvaging"){
                salvageKit = <form>
                    <div>
                        <select style={{width: 200}} value={this.state.salvageKitDropdown} onChange={this.handleSalvageKitSelection}>
                            <option key={-21} value={""}>Salvage Kit</option>
                            <option key={-22} value={"Silver-Fed Salvage-o-Matic"}>Silver-Fed Salvage-o-Matic</option>
                            <option key={-23} value={"Copper-Fed Salvage-o-Matic"}>Copper-Fed Salvage-o-Matic</option>
                            <option key={-24} value={"Mystic Salvage Kit"}>Mystic Salvage Kit</option>
                            <option key={-25} value={""}> </option>
                            <option key={-26} value={"Black Lion Salvage Kit"}>Black Lion Salvage Kit</option>
                            <option key={-27} value={"Ascended Salvage Kit"}>Ascended Salvage Kit</option>
                            <option key={-28} value={"Master's Salvage Kit"}>Master's Salvage Kit</option>
                            <option key={-29} value={"Journeyman's Salvage Kit"}>Journeyman's Salvage Kit</option>
                            <option key={-30} value={"Fine Salvage Kit"}>Fine Salvage Kit</option>
                            <option key={-31} value={"Basic Salvage Kit"}>Basic Salvage Kit</option>
                            <option key={-32} value={"Crude Salvage Kit"}>Crude Salvage Kit</option>
                        </select>
                    </div>
                </form>;
            }


            delay = <form>
                <div>
                    <select style={{width: 200}} value={this.state.delayDropdown} onChange={this.handleDelaySelection}>
                        <option key={-11} value={""}>Delay: Not recommended</option>
                        <option key={-12} value={"Day"}>Day</option>
                        <option key={-13} value={"Week"}>Week</option>
                        <option key={-14} value={"Month"}>Month</option>
                        <option key={-15} value={"3Month"}>Three Months</option>
                    </select>
                </div>
            </form>;
        }

        if(this.state.magicFind !== "" && this.state.characterDropdown !== "Choose Character"){
            if (this.state.activityDropdown === "Opening" || this.state.activityDropdown === "BLTC" ) {
                firstSnapshot = (
                    <div>
                        <Button variant="primary" onClick={this.handleFirstSnapshot}>Initial Snapshot</Button>
                    </div>
                );
            }

            if (this.state.activityDropdown === "Salvaging" && this.state.salvageKitDropdown !== "") {
                firstSnapshot = (
                    <div>
                        <Button variant="primary" onClick={this.handleFirstSnapshot}>Initial Snapshot</Button>
                    </div>
                );
            }
        }


        if (this.state.firstBags !== "") {
            firstBagsImg = this.generateIcons(this.state.firstBags,this.state.firstWallet,this.state.firstExpires,"Please verify that this is your starting position","Initial cache expires on ","diff");
            secondSnapshot = (
                <div>
                    <Button variant="primary" onClick={this.handleSecondSnapshot}>Second Snapshot</Button>
                </div>
            );
        }

        if (this.state.secondBags !== "") {
            secondBagsImg = this.generateIcons(this.state.secondBags,this.state.secondWallet,this.state.secondExpires,"Please verify that this is what ye have now, else refresh after the cache expires","Cache expires on ","diff");
            let difference = this.getDifference(this.state.firstWallet, this.state.secondWallet, this.state.firstBags, this.state.secondBags);

            differenceImg = this.generateIcons(difference.bags,difference.wallet,"","Difference","","diff");
            error = <p>
                No Errors, feel free to submit.
            </p>;
            let errors = 0;
            if(difference.consumed.length === 0){
                errors++;
                error = <p>
                    Nothing Consumed between snapshots.
                </p>
            }
            if(difference.consumed.length > 1){
                errors++;
                let consumedNamesArray = [];
                for(let i=0;i<difference.consumed.length;i++){
                    let data = this.getDataFromID(difference.consumed[i].id,this.state.marketPrices);
                    consumedNamesArray.push(data.name)
                }
                let consumedNames = consumedNamesArray.join(", ");
                error = <p>
                    Multiple types of items consumed: {consumedNames}
                </p>
            }
            if(difference.consumed.length === 2 && this.state.activityDropdown === "BLTC"){
                let isBLTC = {chest:false,key:false, chests:0, keys:0};
                for(let i=0;i<difference.consumed.length;i++){
                    let data = this.getDataFromID(difference.consumed[i].id,this.state.marketPrices);
                    if(data.name === "Black Lion Chest"){isBLTC.chest = true; isBLTC.chests = difference.consumed[i].quantity;}
                    if(data.name === "Black Lion Chest Key"){isBLTC.key = true; isBLTC.keys = difference.consumed[i].quantity;}
                }

                if(isBLTC.chest === true && isBLTC.key === true){
                    errors--;
                    error = <p>
                        No Errors, feel free to submit.
                        <br />
                        Black Lion Chest and Key
                    </p>;
                }else{
                    errors++;
                    error = <p>
                        Not a Black Lion Chest and Key
                    </p>;
                }
                if(isBLTC.chests !==  isBLTC.keys){
                    errors++;
                    error = <p>
                        Different number of Chests and Keys consumed
                    </p>;
                }
            }

            if(errors === 0){
                submit = <div>
                    <Button variant="primary" onClick={this.submitResults}>Submit</Button>
                </div>;
            }
        }
        let loading = "";
        if(typeof this.state.apiKey !== "undefined" && typeof this.state.characters === "undefined"){loading =  <Loading/>}
        return <div>
            <Documentation url={"https://slate.silveress.ie/gw2_site#collection"} />
            {info}
            {characterSelect2}
            {apiInput}
            <br />
            {loading}
            {characterSelect}
            <br />
            {magicFind}
            <br />
            {activitySelect}
            <br />
            {salvageKit}
            <br />
            {delay}
            <br />
            {researchType}
            {firstSnapshot}
            <br />
            {firstBagsImg}
            {secondSnapshot}
            <br />
            {secondBagsImg}
            {differenceImg}
            <br />
            {error}
            <br />
            {submit}
            <br />
            <br />
        </div>
    }
}