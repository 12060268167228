import React, { Component } from 'react';
import { urlBase } from '../_utiilities/data'
import { Documentation } from '../_utiilities/functions_react'

export class Recover_Username extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:undefined,
      result: undefined
    }
  }
  componentDidMount() {
  }

  onChange = e => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name
    this.setState({ [name]: value })
  }

  info = () =>{
    return <div>
      <h3>Recover Username</h3>
      <p>
        This is for if you have forgotten your username.
        <br />
        If you added an email to your account then please use the form below.
        <br />
        If you did not add an email please contact <a href="mailto:support@datawars2.ie">support@datawars2.ie</a>, I will probably ask you to prove ownership of a GuildWars2 account linked to the DataWars2.
      </p>
    </div>
  }

  form = () =>{
    let result = this.state.result
    let resultText
    if(result){resultText = "Successfully Submitted"}

    return <form onSubmit={this.handleSubmit}>
      <label>Email: </label>
      <input
        style={{margin:0, width:300}}
        type={"text"}
        value={this.state.email}
        onChange={this.onChange}
        name={"email"}
      />
      <input type="submit" value="Submit"/>
      <br />
      {resultText}
    </form>
  }

   handleSubmit = async(event)=> {
     event.preventDefault();
     if (typeof this.state.email === "undefined") {return}

     let submitUrl = urlBase.account + "/v1/recovery/account";
     await fetch(submitUrl, { method: 'POST', headers: { 'Content-Type': 'application/json', email: this.state.email } })
     .then((response) => {
       if(response.status === 200){
         this.setState({ email:"", result: true })
       }
     })
     .catch((err) => {alert(err)});
   }

  render() {
    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/management#username-reminder"} />
      <br/>
      {this.info()}
      <br />
      {this.form()}
    </div>
  }
}