import React, { Component } from 'react';
import { urlBase } from '../_utiilities/data.json'
import { Documentation, GetCurrency, Loading, SilverTable, } from '../_utiilities/functions_react'
import { filterGeneral, sortGeneral } from '../_utiilities/functions'

export class Gemstones extends Component {
    constructor(props) {
        super(props);
        this.state = { forgingData: [], priceData: []}
    }

    async componentDidMount() {
        let tmp = {};
        let rawItemData = await this.loadData(urlBase.parser + "/v1/items/json?beautify=min&filter=AccountBound:FALSE,marketable:TRUE");
        tmp.priceData = {};
        for(let i=0;i<rawItemData.length;i++){
            tmp.priceData[rawItemData[i].id] = rawItemData[i];
        }
        tmp.recipeData = await this.loadData(urlBase.parser + "/v1/recipes/json?beautify=min");
        let crafting = this.calculateCrafting(tmp.priceData, tmp.recipeData);
        tmp.allCraftingData = crafting.allCraftingData;
        tmp.sortedData = crafting.sortedData;
        tmp.lodestoneData = crafting.lodestones
        this.setState(tmp);
    }

    async loadData(url) {
        return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))
    }

    getPriceData = (id, items) =>{
        id = id-0;
        let data = {buy_price:0,sell_price:0,name:""};
        let result = items[id];
        if(typeof result !== "undefined"){
            data = result
        }
        return data;
    }

    getCraftingCost = (id, recipes, priceData) => {
        let recipeOutput = { craftBuy: 0, craftSell: 0 };
        for (let ij = 0; ij < recipes.length; ij++) {
            if (id === recipes[ij].output) {
                let item1Buy = this.getPriceData(recipes[ij].item_1_id, priceData).buy_price * recipes[ij].item_1_quantity || 0;
                let item2Buy = this.getPriceData(recipes[ij].item_2_id, priceData).buy_price * recipes[ij].item_2_quantity || 0;
                let item3Buy = this.getPriceData(recipes[ij].item_3_id, priceData).buy_price * recipes[ij].item_3_quantity || 0;
                let item4Buy = this.getPriceData(recipes[ij].item_4_id, priceData).buy_price * recipes[ij].item_4_quantity || 0;

                let item1Sell = this.getPriceData(recipes[ij].item_1_id, priceData).sell_price * recipes[ij].item_1_quantity || 0;
                let item2Sell = this.getPriceData(recipes[ij].item_2_id, priceData).sell_price * recipes[ij].item_2_quantity || 0;
                let item3Sell = this.getPriceData(recipes[ij].item_3_id, priceData).sell_price * recipes[ij].item_3_quantity || 0;
                let item4Sell = this.getPriceData(recipes[ij].item_4_id, priceData).sell_price * recipes[ij].item_4_quantity || 0;

                recipeOutput.craftSell = (item1Sell + item2Sell + item3Sell + item4Sell) / recipes[ij].outputQuantity;
                recipeOutput.craftBuy = (item1Buy + item2Buy + item3Buy + item4Buy) / recipes[ij].outputQuantity;

                return recipeOutput
            }
        }
        return recipeOutput
    }

    calculateCrafting(priceData, recipes){
        const gemstoneInfo  = this.getPriceData(68063,priceData);
        const ectoInfo      = this.getPriceData(19721,priceData);
        const dustInfo      = this.getPriceData(24277,priceData);

        const gemCoreIngrediants = [24514,24518,24532,24533,24524,72436,42010,24520,76491,24512,24510,75654,24515,74988,76179,72315,70957,72504,24522,24508,24516,24884,24502,24772,24773];

        function gemstoneCalcBulk(baseItemPrice,outputQuantity){
            let gemstoneOutput = {buy:0,sell:0};
           // const outputQuantity = 11.5;
            gemstoneOutput.buy = ((baseItemPrice * 75) + (ectoInfo.buy_price * 5))/outputQuantity;
            gemstoneOutput.sell = ((baseItemPrice * 75) + (ectoInfo.sell_price * 5))/outputQuantity;
            return gemstoneOutput;
        }
        function gemstoneCalcSmall(baseItemPrice,outputQuantity){
            let gemstoneOutput = {buy:0,sell:0};
            gemstoneOutput.buy = ((baseItemPrice * 9) + (dustInfo.buy_price))/outputQuantity;
            gemstoneOutput.sell = ((baseItemPrice * 9) + (dustInfo.sell_price))/outputQuantity;
            return gemstoneOutput;
        }
        function setValueBulk(itemInfo, original,newValue){
            let  accessor = "";
            if (itemInfo[original] === 0) {
                accessor = "Small";
                itemInfo["gemstone" + accessor + newValue] = "0c";
                itemInfo["profit" + accessor + newValue] = "0c";
                itemInfo["profitPercent" + accessor + newValue] = "0%";

                accessor = "Bulk";
                itemInfo["gemstone" + accessor + newValue] = "0c";
                itemInfo["profit" + accessor + newValue] = "0c";
                itemInfo["profitPercent" + accessor + newValue] = "0%";

                accessor = "Unlucky";
                itemInfo["gemstone" + accessor + newValue] = "0c";
                itemInfo["profit" + accessor + newValue] = "0c";
                itemInfo["profitPercent" + accessor + newValue] = "0%";

                accessor = "UnluckySmall";
                itemInfo["gemstone" + accessor + newValue] = "0c";
                itemInfo["profit" + accessor + newValue] = "0c";
                itemInfo["profitPercent" + accessor + newValue] = "0%";
            }else {
                accessor = "Small";
                itemInfo["gemstone" + accessor + newValue] = gemstoneCalcBulk(itemInfo[original],11.5).buy;
                itemInfo["profit" + accessor + newValue] = (0.85* gemstoneInfo.sell_price) - itemInfo["gemstone" + accessor + newValue];
                itemInfo["profitPercent" + accessor + newValue] = itemInfo["profit" + accessor + newValue]/itemInfo["gemstone" + accessor + newValue];

                itemInfo["profitPercent" + accessor + newValue] = (itemInfo["profitPercent" + accessor + newValue]).toLocaleString("en", {style: "percent",minimumFractionDigits:2});

                accessor = "Bulk";
                itemInfo["gemstone" + accessor + newValue] = gemstoneCalcSmall(itemInfo[original],1.4).buy;
                itemInfo["profit" + accessor + newValue] = (0.85* gemstoneInfo.sell_price) - itemInfo["gemstone" + accessor + newValue];
                itemInfo["profitPercent" + accessor + newValue] = itemInfo["profit" + accessor + newValue]/itemInfo["gemstone" + accessor + newValue];

                itemInfo["profitPercent" + accessor + newValue] = (itemInfo["profitPercent" + accessor + newValue]).toLocaleString("en", {style: "percent",minimumFractionDigits:2});

                accessor = "Unlucky";
                itemInfo["gemstone" + accessor + newValue] = gemstoneCalcBulk(itemInfo[original],10).buy;
                itemInfo["profit" + accessor + newValue] = (0.85* gemstoneInfo.sell_price) - itemInfo["gemstone" + accessor + newValue];
                itemInfo["profitPercent" + accessor + newValue] = itemInfo["profit" + accessor + newValue]/itemInfo["gemstone" + accessor + newValue];

                itemInfo["profitPercent" + accessor + newValue] = (itemInfo["profitPercent" + accessor + newValue]).toLocaleString("en", {style: "percent",minimumFractionDigits:2});

                accessor = "UnluckySmall";
                itemInfo["gemstone" + accessor + newValue] = gemstoneCalcSmall(itemInfo[original],1).buy;
                itemInfo["profit" + accessor + newValue] = (0.85* gemstoneInfo.sell_price) - itemInfo["gemstone" + accessor + newValue];
                itemInfo["profitPercent" + accessor + newValue] = itemInfo["profit" + accessor + newValue]/itemInfo["gemstone" + accessor + newValue];

                itemInfo["profitPercent" + accessor + newValue] = (itemInfo["profitPercent" + accessor + newValue]).toLocaleString("en", {style: "percent",minimumFractionDigits:2});

            }
        }

        let allCraftingData = [];

        for(let i=0;i<gemCoreIngrediants.length;i++) {
            let itemInfo = {};
            let id = gemCoreIngrediants[i];

            const itemPriceData = this.getPriceData(id,priceData);
            const recipePriceData = this.getCraftingCost(id, recipes, priceData);

            itemInfo.id = id;
            itemInfo.name = itemPriceData.name;
            itemInfo.buy = itemPriceData.buy_price;
            itemInfo.sell = itemPriceData.sell_price;

            itemInfo.craftBuy = recipePriceData.craftBuy;
            itemInfo.craftSell = recipePriceData.craftSell;

            setValueBulk(itemInfo,"buy","Buy");
            setValueBulk(itemInfo,"sell","Sell");
            setValueBulk(itemInfo,"craftBuy","CraftBuy");
            setValueBulk(itemInfo,"craftSell","CraftSell");


            if(itemInfo.craftBuy !== 0) {
                itemInfo.orbProfit = (itemInfo.sell * 0.85) - itemInfo.craftBuy;
                itemInfo.orbProfitPercent = itemInfo.orbProfit / itemInfo.craftBuy;

                itemInfo.orbProfitPercent = (itemInfo.orbProfitPercent).toLocaleString("en", { style: "percent", minimumFractionDigits: 2 });
            }else{
                itemInfo.orbProfit = "0c";
                itemInfo.orbProfitPercent = "0%";

            }

            itemInfo.craftBuyFormatted = itemInfo.craftBuy
            allCraftingData.push(itemInfo);
        }

        let sortedData = [];

        for(let i=0;i<allCraftingData.length;i++) {
            const item = allCraftingData[i];

            sortedData.push({name: item.name, method: "Buy Order    | Buy Orb   | 10x Recipe", cost: item.gemstoneBulkBuy, profit: item.profitBulkBuy, profitPercentage: item.profitPercentBulkBuy, profitPercentageUnlucky: item.profitPercentUnluckyBuy});
            sortedData.push({name: item.name, method: "Instant Buy  | Buy Orb   | 10x Recipe", cost: item.gemstoneBulkSell, profit: item.profitBulkSell, profitPercentage: item.profitPercentBulkSell, profitPercentageUnlucky: item.profitPercentUnluckySell});
            sortedData.push({name: item.name, method: "Buy Order    | Craft Orb | 10x Recipe", cost: item.gemstoneBulkCraftBuy, profit: item.profitBulkCraftBuy, profitPercentage: item.profitPercentBulkCraftBuy, profitPercentageUnlucky: item.profitPercentUnluckyCraftBuy});
            sortedData.push({name: item.name, method: "Instant Buy  | Craft Orb | 10x Recipe", cost: item.gemstoneBulkCraftSell, profit: item.profitBulkCraftSell, profitPercentage: item.profitPercentBulkCraftSell, profitPercentageUnlucky: item.profitPercentUnluckyCraftSell});


            sortedData.push({name: item.name, method: "Buy Order    | Buy Orb    | 1x Recipe", cost: item.gemstoneSmallBuy, profit: item.profitSmallBuy, profitPercentage: item.profitPercentSmallBuy, profitPercentageUnlucky: item.profitPercentUnluckySmallBuy});
            sortedData.push({name: item.name, method: "Instant Buy  | Buy Orb    | 1x Recipe", cost: item.gemstoneSmallSell, profit: item.profitSmallSell, profitPercentage: item.profitPercentSmallSell, profitPercentageUnlucky: item.profitPercentUnluckySmallSell});
            sortedData.push({name: item.name, method: "Buy Order    | Craft Orb  | 1x Recipe", cost: item.gemstoneSmallCraftBuy, profit: item.profitSmallCraftBuy, profitPercentage: item.profitPercentSmallCraftBuy, profitPercentageUnlucky: item.profitPercentUnluckySmallCraftBuy});
            sortedData.push({name: item.name, method: "Instant Buy  | Craft Orb  | 1x Recipe", cost: item.gemstoneSmallCraftSell, profit: item.profitSmallCraftSell, profitPercentage: item.profitPercentSmallCraftSell, profitPercentageUnlucky: item.profitPercentUnluckySmallCraftSell});

        }
        return {allCraftingData: allCraftingData,sortedData:sortedData, lodestones: this.lodestones(priceData)}
    }

    lodestones = (priceData) => {
        let result = []
        let name = "Amalgamated Draconic Lodestone"

        let buy = {"single":0, "bulk":0}
        let sell = {"single":0, "bulk":0}

        let items = [70842, 24315, 24330,24340];
        for(let i=0;i<items.length;i++){
            let item = priceData[items[i]]
            buy.single += (item.buy_price * 10) || 0
            buy.bulk += (item.buy_price * 250) || 0

            sell.single += (item.sell_price * 10) || 0
            sell.bulk += (item.sell_price * 250) || 0
        }

        result.push({name: name, method: "Buy Order | 1x Recipe", cost: buy.single, each:buy.single/1})
        result.push({name: name, method: "Buy Order | 25x Recipe", cost: buy.bulk, each:buy.bulk/25})
        result.push({name: name, method: "Instant Buy | 1x Recipe", cost: sell.single, each:sell.single/1})
        result.push({name: name, method: "Instant Buy | 25x Recipe", cost: sell.bulk, each:sell.bulk/25})

        return result
    }

    tableManager = (gemstones, orbs, lodestoneData) => {
        let base = {
            className: {
                table: "centerTable table-primary table-striped table-highlight",
            },
            templates: {
                "text": {
                    className: "left",
                },
                "gold": {
                    className: "right",
                    contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
                    sort: sortGeneral,
                    filter: (item, filter) => filterGeneral(item, filter, 10000)
                },
                "percent": {
                    className: "right",
                    sort: sortGeneral,
                }
            },
        }

        let gemConfig = {
            headers:{
                cols: [
                    { template: "text", header: "Name", accessor: "name" },
                    { template: "text", header: "Method", accessor: "method" },
                    { template: "gold", header: "Cost", accessor: "cost" },
                    { template: "gold", header: "Profit", accessor: "profit" },
                    { template: "percent", header: "Profit %", accessor: "profitPercentage" },
                    { template: "percent", header: "Unlucky Profit %", accessor: "profitPercentageUnlucky" }
                ]
            },
            sort:{ col: "profit", desc: true },
            colsToDisplay: 20
        }

        let orbConfig = {
            headers:{
                cols: [
                    { template: "text", header: "Name", accessor: "name" },
                    { template: "gold", header: "Craft", accessor: "craftBuyFormatted" },
                    { template: "gold", header: "Profit", accessor: "orbProfit" },
                    { template: "percent", header: "Profit %", accessor: "orbProfitPercent" },
                ],
            },
            sort:{ col: "profit", desc: true },
            colsToDisplay:10
        }

        let lodestoneConfig = {
            headers:{
                cols: [
                    { template: "text", header: "Name", accessor: "name" },
                    { template: "text", header: "Method", accessor: "method" },
                    { template: "gold", header: "Cost", accessor: "cost" },
                    { template: "gold", header: "Each", accessor: "each" }
                ],
            },
            sort:{ col: "each", desc: false },
            colsToDisplay:10
        }

        gemConfig.className = base.className
        gemConfig.templates = base.templates
        orbConfig.className = base.className
        orbConfig.templates = base.templates

        lodestoneConfig.className = base.className
        lodestoneConfig.templates = base.templates
        let gemTable = this.createTable(gemstones, gemConfig)
        let orbTable = this.createTable(orbs, orbConfig)
        let lodestones = this.createTable(lodestoneData, lodestoneConfig)

        return [gemTable, orbTable, lodestones]
    }

    createTable = (data, config) => {
        return <SilverTable
          data={data}
          config={config}
        />
    }

    render() {

        if(typeof this.state.allCraftingData === "undefined"){return <Loading/>}

        let [gemTable, orbTable, lodestones] = this.tableManager(this.state.sortedData,this.state.allCraftingData, this.state.lodestoneData )
        return <div style={{align:"center"}}>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/forging#gemstones"} />
            <br/>
            <h3>Crafting gemstones.</h3>
            {gemTable}
            <br />
            <br />

            <p>Craft Orbs for sale</p>
            {orbTable}

            <br />
            <br />

            <p>Amalgamated Draconic Lodestones</p>
            {lodestones}
        </div>;
    }
}