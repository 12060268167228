import React, {Component} from "react";
import cookie from "react-cookies";
import {HashRouter} from "react-router-dom";

import { urlBase } from './_utiilities/data.json'

import {Routes} from "./routes";
import {Header} from "./header";
import {Account} from "./_utiilities/entities"

interface App_State {
    account?: Account;
    session?: string;
}

async function loadData(url: string, extra?: any) {
    return await fetch(url,extra).then(response => {return response.json()}).catch((err) => {console.log(url, err.toString()); return {result:"error", error:"could not fetch"};})
}

export class App extends Component <any, App_State>{
    constructor(props: any) {
        super(props);

        this.state = {
            account: undefined,
            session: cookie.load('session')
        }
    }

    getUser = async() =>{
        let account = await loadData(`${urlBase.account}/v1/website/account`,{method: 'GET', headers: {'Content-Type': 'application/json', session: this.state.session}});
        if(account.result === "success"){
            this.setState({account: account.account})
        }else{
            if(account.error ==="Invalid session"){
                cookie.remove('session');
                this.setState({session: undefined})
            }
        }
    }

    componentDidMount = async () => {
        // then if the user is logged in get teh user data
        if(typeof this.state.session !== "undefined" && typeof this.state.account === "undefined"){
            await this.getUser()
        }
    }

    logout = () =>{
        cookie.remove('session')
        this.setState({session: undefined, account: undefined})
    }

    render() {
        let {account, session} = this.state

        return <HashRouter>
            <div className='App container'>
                {Header(account, this.logout)}
                {Routes(account, session)}
            </div>
        </HashRouter>
    }
}

