import React, { Component } from 'react'
import { gw2APIVersion, urlBase } from '../_utiilities/data.json'
import { Form, ProgressBar, Table } from 'react-bootstrap'
import JSONConverter from 'silvers_json_converter'
import {
  capitalize,
  convertToGold,
  filterGeneral,
  filterGeneralText,
  getDarthsCDN,
  sortGeneral,
} from '../_utiilities/functions'
import { Chart, HighchartsStockChart, Legend, LineSeries, Navigator, RangeSelector, Title, Tooltip, withHighcharts, XAxis, YAxis, } from 'react-jsx-highstock'
import { graphOptions } from '../_utiilities/data'
import dateFormat from 'dateformat'
import Highcharts from 'highcharts/highstock'
import {
  Documentation,
  GetCurrency,
  Loading,
  SilverTable,
} from '../_utiilities/functions_react'
import { loadData } from '../_utiilities/functions'
import {TextImg} from "../loggedOut/tp_textImg";

function arrayFilter(item) {
  return item.account !== "config"
    && typeof item.name !== "undefined"
    && item.total !== 0
}
function seconds2time (seconds) {
  let hours   = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - (hours * 3600)) / 60);
  seconds = (seconds - (hours * 3600) - (minutes * 60)).toFixed(0)-0;
  let time = "";

  if (hours !== 0) {
    time = hours+":";
  }
  if (minutes !== 0 || time !== "") {
    minutes = (minutes < 10 && time !== "") ? "0"+minutes : String(minutes);
    time += minutes+":";
  }
  if (time === "") {
    time = seconds+"s";
  }
  else {
    time += (seconds < 10) ? "0"+seconds : String(seconds);
  }
  return time;
}

class FarmingTracker_Component extends Component {
  constructor(props) {
    super(props)
    let date = new Date().getTime()
    this.state = {
      session: props.session,
      error: undefined,
      itemData: undefined,
      siteAccount: props.account,
      accounts: [],
      details: {},
      status: {
        initial: { accounts: { total: 0, complete: 0 },total: { total: 0, complete: 0}},
        secondary: { accounts: { total: 0, complete: 0 },total: { total: 0, complete: 0}},
        difference: { accounts: { total: 0, complete: 0 },total: { total: 0, complete: 0}}
      },
      snapshot: { initial: {}, secondary: {}, difference: {} },
      table: {
        initial: { show: true, expanded: [], compact: [], nano: [] },
        secondary: { show: true, expanded: [], compact: [], nano: [] },
        difference: { show: true, expanded: [], compact: [], nano: [] }
      },
      compact:{ initial: 0, secondary: 0 , difference: 0 },
      filter:{ initial: [], secondary: [] , difference: [] },
      settings: {
        initial: { show: false },
        secondary: { show: false },
        difference: { show: false }
      },
      running: false,
      elapsed: 0,
      lastStartTime: undefined,
      graph: {
        total: { quantity: [[date,0]], value: [[date,0]] },
        inventory: { quantity: [[date,0]], value: [[date,0]] },
        equipment: { quantity: [[date,0]], value: [[date,0]] },
        wallet: { quantity: [[date,0]], value: [[date,0]] },
        bank: { quantity: [[date,0]], value: [[date,0]] },
        materials: { quantity: [[date,0]], value: [[date,0]] },
        deliveryBox: { quantity: [[date,0]], value: [[date,0]] },
        sharedInventory: { quantity: [[date,0]], value: [[date,0]] },
        tpSells: { quantity: [[date,0]], value: [[date,0]] },
        guild: { quantity: [[date,0]], value: [[date,0]] },
      },
      timeoutID: undefined,
    }
  }

  // will need this in all sub components
  async componentDidUpdate(prevProps ) {
    if (typeof prevProps.session === "undefined" && typeof this.props.session !== "undefined"){
      this.setState({ session: this.props.session })
    }
    if (typeof prevProps.account === "undefined" && typeof this.props.account !== "undefined"){
      this.setState(await this.processAccount(this.props.account))
    }
  }

  async componentDidMount() {
    let tmp = {};
    let rawItemData = await loadData(urlBase.parser + "/v1/items/json?fields=type,img,id,name,level,rarity,marketable,binding,sell_price,buy_price,vendor_value,NoSell&beautify=human&filter=id:gt:0");

    tmp.itemData = {};
    for (let i = 0; i < rawItemData.length; i++) {
      tmp.itemData[rawItemData[i].id] = rawItemData[i]
    }

    let rawCurrenciesData = await loadData("https://api.guildwars2.com/v2/currencies?ids=all");
    tmp.currenciesData = {};
    for (let i = 0; i < rawCurrenciesData.length; i++) {
      tmp.currenciesData[rawCurrenciesData[i].id] = rawCurrenciesData[i]
    }

    tmp = await this.processAccount (this.state.siteAccount, tmp)

    this.setState(tmp);
  }

  processAccount = async (account, tmp = {}) =>{
    if(!account){return tmp}

    tmp.siteAccount = account;
    tmp.accounts = Object.keys(account.gameAccounts)

    tmp.details = {}
    tmp.details["All"] = {
      selected: false,
      apiKey: "",
      characters: {
        "All": {
          selected: true,
          equipment: true,
          name: "All"
        }
      },
      guilds: {
        "All": {
          selected: true,
          name: "All",
          id: ""
        }
      },
      sharedInventory: true,
      bank: true,
      materials: true,
      deliveryBox: true,
      tpSells: true
    }
    for (let i = 0; i < tmp.accounts.length; i++) {
      let apiKey = tmp.siteAccount.gameAccounts[tmp.accounts[i]].key
      let [characters, guilds] = await this.onApiKey(apiKey)
      tmp.details[tmp.accounts[i]] = {
        selected: false,
        apiKey: apiKey,
        characters: characters,
        guilds: guilds,
        sharedInventory: true,
        bank: true,
        materials: true,
        deliveryBox: true,
        tpSells: true
      }
    }
    return tmp
  }

  async onApiKey(apiKey) {
    let charactersRaw = await loadData("https://api.guildwars2.com/v2/characters?access_token=" + apiKey + '&v=' + gw2APIVersion) || []
    let characters = {}
    for (let i = 0; i < charactersRaw.length; i++) {
      characters[charactersRaw[i]] = {
        selected: true,
        equipment: true,
        name: charactersRaw[i]
      }
    }

    let account = await loadData("https://api.guildwars2.com/v2/account?access_token=" + apiKey + '&v=' + gw2APIVersion) || { guild_leader: [] }
    let guildsIDs = account.guild_leader || []
    let guilds = {}
    for (let i = 0; i < guildsIDs.length; i++) {

      let guild = await loadData("https://api.guildwars2.com/v2/guild/" + guildsIDs[i] + "?access_token=" + apiKey + '&v=' + gw2APIVersion) || { name: "" }

      guilds[guildsIDs[i]] = {
        selected: false,
        name: guild.name,
        id: guildsIDs[i]
      }
    }
    return [characters, guilds];
  }

    // Reusable for all inputs
  onChange = e => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name
    let path = e.target.dataset.path.split(",") || []

    if (path.length <= 1) {
      this.setState({ [name]: value })
    } else {
      let element = this.state[path[0]]
      if(path[1]=== "All"){
        let subElements = Object.keys(element)
        if (path.length === 3) {
          for(let i=0;i<subElements.length;i++){
            element[subElements[i]][path[2]] = value
          }
        }
        if (path.length === 4) {
          for(let i=0;i<subElements.length;i++){
            if(path[3] === "All"){
              let subSubElements = Object.keys(element[subElements[i]][path[2]])
              for(let j=0;j<subSubElements.length;j++){
                element[subElements[i]][path[2]][subSubElements[j]] = value
              }
            }
          }
        }
        if (path.length === 5) {
          for(let i=0;i<subElements.length;i++){
            if(path[3] === "All"){
              let subSubElements = Object.keys(element[subElements[i]][path[2]])
              for(let j=0;j<subSubElements.length;j++){
                element[subElements[i]][path[2]][subSubElements[j]][path[4]] = value
              }
            }
          }
        }
      }else{
        if (path.length === 2) {
          element[path[1]] = value
        }
        if (path.length === 3) {
          element[path[1]][path[2]] = value
        }
        if (path.length === 4) {
          element[path[1]][path[2]][path[3]] = value
        }
        if (path.length === 5) {
          element[path[1]][path[2]][path[3]][path[4]] = value
        }
      }
      this.setState({ [path[0]]: element })
    }
  }

  accountChecklist = () => {
    let checkboxes = [
      <Form.Check
        key={"AllaccountChecklist"}
        style={{ whiteSpace: "nowrap" }}
        type={"checkbox"}
        name={"All checkbox"}
        data-path={["details", "All", "selected"]}
        label={"All"}
        checked={this.state.details["All"].selected}
        onChange={this.onChange}
      />
    ]
    let ids = this.state.accounts
    for (let i = 0; i < ids.length; i++) {
      checkboxes.push(
        <Form.Check
          key={i+"accountChecklist"}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={ids[i] + " checkbox"}
          data-path={["details", ids[i], "selected"]}
          label={ids[i]}
          checked={this.state.details[ids[i]].selected}
          onChange={this.onChange}
        />
      )
    }
    return <div>{checkboxes}</div>
  }

  guildChecklist = () => {
    let checkboxes = []
    let ids = this.state.accounts

    // iterate through each account
    for (let i = 0; i < ids.length; i++) {
      if (!this.state.details[ids[i]].selected) {continue}

      let guildsData = this.state.details[ids[i]].guilds
      let guilds = Object.keys(guildsData)

      for (let j = 0; j < guilds.length; j++) {
        let id = guildsData[guilds[j]].id
        let name = guildsData[guilds[j]].name
        let selected = guildsData[guilds[j]].selected

        if(checkboxes.length === 0){
          checkboxes.push(
            <Form.Check
              key={"Allcheckboxes_guild"}
              style={{ whiteSpace: "nowrap" }}
              type={"checkbox"}
              name={"All checkbox"}
              data-path={["details", "All", "guilds", "All", "selected"]}
              label={"All"}
              checked={this.state.details["All"]["guilds"]["All"].selected}
              onChange={this.onChange}
            />
          )
        }
        checkboxes.push(
          <Form.Check
            key={i+"_"+j+"checkboxes_guild"}
            style={{ whiteSpace: "nowrap" }}
            type={"checkbox"}
            name={id + " checkbox"}
            data-path={["details", ids[i], "guilds", id, "selected"]}
            label={ids[i] + "-" + name}
            checked={selected}
            onChange={this.onChange}
          />
        )
      }
    }
    return <div>{checkboxes}</div>
  }

  characterChecklist = () => {
    let checkboxes = []
    let ids = this.state.accounts

    // iterate through each account
    for (let i = 0; i < ids.length; i++) {
      if (!this.state.details[ids[i]].selected) {continue}

      let characterData = this.state.details[ids[i]].characters
      let characters = Object.keys(characterData)

      for (let j = 0; j < characters.length; j++) {
        let name = characterData[characters[j]].name
        let selected = characterData[characters[j]].selected

        if(checkboxes.length === 0){
          checkboxes.push(
            <Form.Check
              key={"Allcheckboxes_character"}
              style={{ whiteSpace: "nowrap" }}
              type={"checkbox"}
              name={"All checkbox"}
              data-path={["details", "All", "characters", "All", "selected"]}
              label={"All"}
              checked={this.state.details["All"]["characters"]["All"].selected}
              onChange={this.onChange}
            />
          )
        }
        checkboxes.push(
          <Form.Check
            key={i+"_"+j+"checkboxes_character"}
            style={{ whiteSpace: "nowrap" }}
            type={"checkbox"}
            name={name + " checkbox"}
            data-path={["details", ids[i], "characters", name, "selected"]}
            label={ids[i] + " - " + name}
            checked={selected}
            onChange={this.onChange}
          />
        )
      }
    }
    return <div>{checkboxes}</div>
  }

  equipmentChecklist = () => {
    let checkboxes = []
    let ids = this.state.accounts

    // iterate through each account
    for (let i = 0; i < ids.length; i++) {
      if (!this.state.details[ids[i]].selected) {continue}

      let characterData = this.state.details[ids[i]].characters
      let characters = Object.keys(characterData)

      for (let j = 0; j < characters.length; j++) {
        let name = characterData[characters[j]].name
        let selected = characterData[characters[j]].equipment

        if(checkboxes.length === 0){
          checkboxes.push(
            <Form.Check
              key={"Allcheckboxes_equipment"}
              style={{ whiteSpace: "nowrap" }}
              type={"checkbox"}
              name={"All equipment"}
              data-path={["details", "All", "characters", "All", "equipment"]}
              label={"All"}
              checked={this.state.details["All"]["characters"]["All"].equipment}
              onChange={this.onChange}
            />
          )
        }
        checkboxes.push(
          <Form.Check
            key={i+"_"+j+"checkboxes_equipment"}
            style={{ whiteSpace: "nowrap" }}
            type={"checkbox"}
            name={name + " equipment"}
            data-path={["details", ids[i], "characters", name, "equipment"]}
            label={ids[i] + " - " + name}
            checked={selected}
            onChange={this.onChange}
          />
        )
      }
    }
    return <div>{checkboxes}</div>
  }

  generalAccountChecklist = (selector, text) => {
    let checkboxes = []
    let ids = this.state.accounts
    for (let i = 0; i < ids.length; i++) {
      if (!this.state.details[ids[i]].selected) {continue}

      if(checkboxes.length === 0){
        checkboxes.push(
          <Form.Check
            key={"All_0checkboxes_"+selector}
            style={{ whiteSpace: "nowrap" }}
            type={"checkbox"}
            name={"All- " + text}
            data-path={["details", "All", selector]}
            label={"All"}
            checked={this.state.details["All"][selector]}
            onChange={this.onChange}
          />
        )
      }
      checkboxes.push(
        <Form.Check
          key={i+"_0checkboxes_"+selector}
          style={{ whiteSpace: "nowrap" }}
          type={"checkbox"}
          name={ids[i] + "- " + text}
          data-path={["details", ids[i], selector]}
          label={ids[i] + " - " + text}
          checked={this.state.details[ids[i]][selector]}
          onChange={this.onChange}
        />
      )
    }
    return <div>{checkboxes}</div>
  }

  getData = async(type, restart) => {
    let start = new Date().toISOString()
    // if neither is supplied
    if (type !== "initial" && type !== "secondary") {return null}
    if(typeof this.state.lastStartTime === "undefined" || restart){
      this.setState({lastStartTime:start})
    }

    if (type ===  "secondary" && !this.state.running && typeof restart === "undefined") {return null}

    let tmp = this.state.status
    // set to zero at teh start
    tmp[type].accounts.total = 0
    tmp[type].total.total = 0
    tmp[type].accounts.complete = 0
    tmp[type].total.complete = 0

    let ids = this.state.accounts
    for (let i = 0; i < ids.length; i++) {
      // break it
      if (!this.state.details[ids[i]].selected) {continue}

      // add one to teh accounts
      if (this.state.details[ids[i]].selected) {tmp[type].accounts.total += 1}

      // character
      let characterData = this.state.details[ids[i]].characters
      let characters = Object.keys(characterData)
      for (let j = 0; j < characters.length; j++) {
        if (characterData[characters[j]].selected) {tmp[type].total.total += 1}
        if (characterData[characters[j]].equipment) {tmp[type].total.total += 1}
      }

      // guild
      let guildsData = this.state.details[ids[i]].guilds
      let guilds = Object.keys(guildsData)
      for (let j = 0; j < guilds.length; j++) {
        if (guildsData[guilds[j]].selected) {tmp[type].total.total += 1}
      }

      // general
      let selectors = ["bank", "materials", "deliveryBox", "sharedInventory", "tpSells", "wallet"]
      for (let j = 0; j < selectors.length; j++) {
        if (this.state.details[ids[i]][selectors[j]]) {tmp[type].total.total += 1}
      }
    }

    await this.setState({ status: tmp })

    // now go through each account and get teh data
    let snapshot = this.state.snapshot
    snapshot[type] = {}

    let startTime = new Date().toISOString()
    let config = {
      state: this.state.details,
      start: startTime,
      end: new Date().toISOString()
    }
    snapshot[type].config = config
    snapshot["secondary"].config = config
    snapshot["difference"].config = config

    let urls = []
    let urlTemp
    for (let i = 0; i < ids.length; i++) {
      // break it
      if (!this.state.details[ids[i]].selected) {continue}

      // if the snapshot data for teh account dose not exist
      if (typeof snapshot[type][ids[i]] === "undefined") {
        snapshot[type][ids[i]] = {}
      }

      // get the api key for this run
      let apiKey = this.state.siteAccount.gameAccounts[ids[i]].key

      // Inventory
      let characterData = this.state.details[ids[i]].characters
      let characters = Object.keys(characterData)
      for (let j = 0; j < characters.length; j++) {
        // now get bag data
        if (characterData[characters[j]].selected) {
          urlTemp = {
            type: type,
            category: "inventory",
            id: ids[i],
            character: characters[j],
            url: 'https://api.guildwars2.com/v2/characters/' + characters[j] + '/inventory?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime,
            path: ["inventory", characters[j]],
            dataPath: [],
          }
          urls.push(urlTemp)
        }

        if (characterData[characters[j]].equipment) {
          urlTemp = {
            type: type,
            category: "equipment",
            id: ids[i],
            character: characters[j],
            url: 'https://api.guildwars2.com/v2/characters/' + characters[j] + '/equipment?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime,
            path: ["equipment", characters[j] ],
            dataPath: [],
          }
          urls.push(urlTemp)
        }
      }

      if (this.state.details[ids[i]].wallet) {
        urlTemp = {
          type: type,
          category: "wallet",
          id: ids[i],
          character: undefined,
          url: 'https://api.guildwars2.com/v2/account/wallet?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime,
          path: ["wallet"],
          dataPath: [],
        }
        urls.push(urlTemp)
      }

      if (this.state.details[ids[i]].bank) {
        urlTemp = {
          type: type,
          category: "bank",
          id: ids[i],
          character: undefined,
          url: 'https://api.guildwars2.com/v2/account/bank?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime,
          path: ["bank"],
          dataPath: [],
        }
        urls.push(urlTemp)
      }

      if (this.state.details[ids[i]].materials) {
        urlTemp = {
          type: type,
          category: "materials",
          id: ids[i],
          character: undefined,
          url: 'https://api.guildwars2.com/v2/account/materials?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime,
          path: ["materials"],
          dataPath: [],
        }
        urls.push(urlTemp)
      }

      if (this.state.details[ids[i]].deliveryBox) {
        urlTemp = {
          type: type,
          category: "deliveryBox",
          id: ids[i],
          character: undefined,
          url: 'https://api.guildwars2.com/v2/commerce/delivery?access_token=' + apiKey + '&v=' + gw2APIVersion,
          path: ["deliveryBox"],
          dataPath: ["items"],
        }
        urls.push(urlTemp)
      }

      if (this.state.details[ids[i]].sharedInventory) {
        urlTemp = {
          type: type,
          category: "sharedInventory",
          id: ids[i],
          character: undefined,
          url: 'https://api.guildwars2.com/v2/account/inventory?access_token=' + apiKey + '&v=' + gw2APIVersion,
          path: ["sharedInventory"],
          dataPath: [],
        }
        urls.push(urlTemp)
      }
      // tradepostSells
      if (this.state.details[ids[i]].tpSells) {
        urlTemp = {
          type: type,
          category: "tpSells",
          id: ids[i],
          character: undefined,
          url: 'https://api.guildwars2.com/v2/commerce/transactions/current/sells?page_size=200&access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime,
          path: ["tpSells"],
        }
        urls.push(urlTemp)
      }

      let guildData = this.state.details[ids[i]].guilds
      let guilds = Object.keys(guildData)
      for(let j=0;j<guilds.length;j++) {
        if(!guildData[guilds[j]].selected){continue}

        urlTemp = {
          type: type,
          category: "guild",
          id: ids[i],
          character: guilds[j],
          url: 'https://api.guildwars2.com/v2/guild/'+guilds[j]+'/stash?access_token=' + apiKey + '&v=' + gw2APIVersion + "&noCache=" + startTime,
          path: ["guild", guilds[j]],
        }
        urls.push(urlTemp)
      }
    }

    let promises = urls.map(async (urlDetails) => {
      let [data, headers] = await fetch(urlDetails.url).then(async (response) => {if(response.ok){let result = await response.json().catch(err => {console.log(err); return undefined}); return [result, response.headers]}else{return [undefined, undefined]}}).catch(err =>{console.log(err);return [undefined, undefined]})

      if(typeof data === "undefined"){
        return [undefined , undefined]
      }

      let items = []
      switch(urlDetails.category) {
        case "inventory":{

          if(typeof data.bags === "undefined"){break}

          for (let i = 0; i < data.bags.length; i++) {
            if (data.bags[i] === null) {continue}
            for (let j = 0; j < data.bags[i].inventory.length; j++) {
              if (data.bags[i].inventory[j] === null) {continue}
              items.push(data.bags[i].inventory[j])
            }
          }

          break
        }
        case "equipment":{
          for (let i = 0; i < data.equipment.length; i++) {
            if (data.equipment[i] === null) {continue}
            items.push(data.equipment[i])
          }

          break
        }
        case "guild":{

          for (let k = 0; k < data.length; k++) {
            if (data[k] === null) {continue}
            for(let l=0;l<data[k].inventory.length;l++){
              if (data[k].inventory[l] === null) {continue}
              items.push(data[k].inventory[l])
            }
          }

          break
        }
        case "tpSells":{

          let allData = []
          for(let i=0;i<data.length;i++){
            allData.push(data[i])
          }

          let salesUrls = []
          for(let i=1;i<headers.get('x-page-total');i++){
            salesUrls.push(urlDetails.url + "&page=" + i)
          }

          let salesPromises = salesUrls.map(async (salesUrl) => {
            let salesData = await loadData(salesUrl)
            for(let i=0;i<salesData.length;i++){
              allData.push(salesData[i])
            }
          })
          await Promise.all(salesPromises)

          for (let k = 0; k < allData.length; k++) {
            if (allData[k] === null) {continue}
            allData[k].id = allData[k].item_id
            allData[k].count = allData[k].quantity
            items.push(allData[k])
          }

          break
        }
        default:{

          let path = urlDetails.dataPath
          let dataArray
          if (path.length === 0) {
            dataArray = data
          }
          if (path.length === 1) {
            dataArray = data[path[0]]
          }

          for (let i = 0; i < dataArray.length; i++) {
            let item = dataArray[i]
            if (item === null) {continue}
            items.push(item)
          }

          break
        }
      }

      // update status total for bag data
      let tmp = this.state.status
      tmp[type].total.complete += 1
      this.setState({ status: tmp })

      return [urlDetails, items]
    })

    await Promise.all(promises)
    .then((results)=>{
      for(let i=0;i<results.length;i++){
        let [urlDetails, items] = results[i]
        if(typeof urlDetails === "undefined"){continue}
        if(typeof items === "undefined"){continue}
        ///*
        // add entry if it does not exist
        snapshot = this.createEntry(snapshot, urlDetails.type, urlDetails.id,  urlDetails.path)

        // add the item data
        snapshot = this.addItems(snapshot, urlDetails.type, urlDetails.id, urlDetails.path, items)
        //*/
      }
    })
    .catch(err => console.log(err))

    // update status accounts
    let tmpStatus = this.state.status
    tmpStatus[type].accounts.complete = tmpStatus[type].accounts.total
    await this.setState({ status: tmpStatus })

    let now = new Date()
    config = {
      state: this.state.details,
      start: startTime,
      end: now.toISOString()
    }

    snapshot[type].config = config
    snapshot["difference"].config = config

    let elapsed = this.state.elapsed

    elapsed += new Date(now - new Date(this.state.lastStartTime)).getTime()/1000
    if(type === "initial"){
      snapshot["difference"] = this.calculateDifference(snapshot[type], snapshot[type])

      snapshot["secondary"].config = config

      await this.setState({ snapshot: snapshot, elapsed:elapsed, lastStartTime:start, running:true })
    }

    if(type === "secondary"){
      snapshot["difference"] = this.calculateDifference(this.state.snapshot.initial, snapshot[type])
      await this.setState({ snapshot: snapshot, elapsed:elapsed, lastStartTime:start})
    }

    this.generateGraphData(snapshot["difference"])

    let timeoutID = window.setTimeout(()=>{this.getData("secondary")}, 1000 * 60 * 5);
    this.setState({timeoutID:timeoutID})
  }

  generateGraphData = (difference) =>{
    let graph = this.state.graph

    let date = new Date().getTime()
    let tmp = {
      total:  { quantity:0, value:0 }
    }

    let characters =Object.keys(difference)
    for(let i=0;i<characters.length;i++){
      let account = characters[i]
      if (account === "config"){continue}
      let categories = Object.keys(difference[account])
      for(let j=0;j<categories.length;j++){
        let category = categories[j]

        if(category === "inventory" || category === "equipment" || category === "guild"){
          let subcategorys = Object.keys(difference[account][category])

          for(let k=0;k<subcategorys.length;k++){
            let subcategory = subcategorys[k]

            let items = Object.keys(difference[account][category][subcategory])
            for(let l=0;l<items.length;l++){
              let id = items[l]
              let details = difference[account][category][subcategory][id]

              let total = details.character + details.account + details.none
              if(total === 0 ){continue}

              if(typeof tmp[category] === "undefined"){tmp[category] = { quantity:0, value:0 }}

              tmp[category].quantity += total

              let itemData = this.state.itemData[id] || {name:undefined, type:undefined, img:undefined, level:undefined, marketable:undefined, rarity:undefined, sell_price:0, buy_price:0, vendor_value:0 }

              let value = 0
              if(typeof itemData.vendor_value !== "undefined"){value = itemData.vendor_value}
              if(typeof itemData.sell_price !== "undefined"){value = itemData.sell_price}
              tmp[category].value += (total*value)

              tmp["total"].quantity += total
              tmp["total"].value += (total*value)
            }
          }
        }else{
          let items = Object.keys(difference[account][category])
          for(let l=0;l<items.length;l++){
            let id = items[l]
            let details = difference[account][category][id]

            let total = details.character + details.account + details.none
            if(total === 0 ){continue}

            if(typeof tmp[category] === "undefined"){tmp[category] = { quantity:0, value:0 }}

            tmp[category].quantity += total


            let itemData = this.state.itemData[id] || {name:undefined, type:undefined, img:undefined, level:undefined, marketable:undefined, rarity:undefined, sell_price:0, buy_price:0, vendor_value:0 }

            let value = 0
            if(typeof itemData.vendor_value !== "undefined"){value = itemData.vendor_value}
            if(typeof itemData.sell_price !== "undefined"){value = itemData.sell_price}
            tmp[category].value += (total*value)

            tmp["total"].quantity += total
            tmp["total"].value += (total*value)
          }
        }
      }
    }

    let categories = Object.keys(tmp)
    for(let i=0;i<categories.length;i++){
      let category = categories[i]
      if(typeof graph[category] === "undefined"){continue}
      graph[category].quantity.push([date, tmp[category].quantity])
      graph[category].value.push([date, tmp[category].value])
    }
    this.setState({graph:graph})
    return graph
  }

  generateGraph = () => {
    let navigator= []
    let prices = []
    let quantities = []
    let fields = Object.keys(this.state.graph)
    for(let i=0;i<fields.length;i++){
      navigator.push(<Navigator.Series seriesId={fields[i]+"Quantity"} />)
      navigator.push(<Navigator.Series seriesId={fields[i]+"Value"} />)

      prices.push(<LineSeries id={fields[i]+"Value"} name={capitalize(fields[i])+ " Value"} data={[...this.state.graph[fields[i]].value]} />)

      quantities.push(<LineSeries id={fields[i]+"Quantity"} name={capitalize(fields[i])+ " Quantity"} data={[...this.state.graph[fields[i]].quantity]} visible={false} />)
    }

    return <HighchartsStockChart
      plotOptions={graphOptions.plotOptions}
      styledMode
    >
      <Chart
        height={600}
      />
      <Title>Quantity/Value over time</Title>
      <Legend/>

      <Tooltip
        shared={true}
        formatter={function () {
          let s = '<b> ' + dateFormat(new Date(this.x), "ddd yyyy-mm-dd hh:MM") + '</b>';
          for (let i = 0; i < this.points.length; i++) {
            let nameArray = this.points[i].series.name.split(' ');
            let tmp = "";
            if (nameArray[1] === "Value") {
              tmp = '<br/><span style="color:' + this.points[i].color + '">\u25CF</span>' + this.points[i].series.name + ': ' + convertToGold(this.points[i].y);
            } else {
              tmp = '<br/><span style="color:' + this.points[i].color + '">\u25CF</span>' + this.points[i].series.name + ': ' + Math.round(this.points[i].y);
            }
            s += tmp;
          }
          return s;
        }}
      />

      <XAxis>
        <XAxis.Title>DateTime</XAxis.Title>
      </XAxis>

      <YAxis opposite>
        <YAxis.Title>Quantity</YAxis.Title>
        {quantities}
      </YAxis>

      <YAxis>
        <YAxis.Title>Price</YAxis.Title>
        {prices}
      </YAxis>

      <Navigator>
        {navigator}
      </Navigator>

      <RangeSelector>
        <RangeSelector.Button type="all">All</RangeSelector.Button>
        <RangeSelector.Input boxBorderColor="#7cb5ec"/>
      </RangeSelector>

    </HighchartsStockChart>
  }

  createEntry = (snapshot, type, gameID, path) => {
    if (path.length === 0) {return snapshot}
    if (path.length > 2) {return snapshot}
    if (path.length >= 1) {
      if (typeof snapshot[type][gameID][path[0]] === "undefined") {
        snapshot[type][gameID][path[0]] = {}
      }
    }
    if (path.length === 2) {
      if (typeof snapshot[type][gameID][path[0]][path[1]] === "undefined") {
        snapshot[type][gameID][path[0]][path[1]] = {}
      }
    }
    return snapshot
  }

  addItems = (snapshot, type, gameID, path, items) => {
    if (path.length === 0) {return snapshot}
    if (path.length > 2) {return snapshot}

    for (let i = 0; i < items.length; i++) {
      let item = items[i]
      let quantity = 1
      if(typeof item.count !== "undefined"){
        quantity = item.count
      }
      if(typeof item.value !== "undefined"){
        quantity = item.value
      }
      let defaultBinding = this.state.itemData[item.id]
      if(typeof defaultBinding !== "undefined"){
        defaultBinding = defaultBinding.binding
      }else{
        // default to none, no idea what to do elsewise
        defaultBinding = "None"
      }

      if (path.length === 1) {
        if (typeof snapshot[type][gameID][path[0]][item.id] === "undefined") {
          snapshot[type][gameID][path[0]][item.id] = {
            character: 0,
            account: 0,
            none: 0
          }
        }
        if (typeof item.binding === "undefined") {
          item.binding = defaultBinding
        }
        if(path[0] === "wallet"){
          item.binding = "Account"
        }
        if (item.binding === "None") {
          snapshot[type][gameID][path[0]][item.id].none += quantity
        }
        if (item.binding === "Account") {
          snapshot[type][gameID][path[0]][item.id].account += quantity
        }
        if (item.binding === "Character") {
          snapshot[type][gameID][path[0]][item.id].character += quantity
        }
      }

      if (path.length === 2) {
        if (typeof snapshot[type][gameID][path[0]][path[1]][item.id] === "undefined") {
          snapshot[type][gameID][path[0]][path[1]][item.id] = {
            character: 0,
            account: 0,
            none: 0
          }
        }
        if (typeof item.binding === "undefined") {
          item.binding = defaultBinding
        }
        if (item.binding === "None") {
          snapshot[type][gameID][path[0]][path[1]][item.id].none += quantity
        }
        if (item.binding === "Account") {
          snapshot[type][gameID][path[0]][path[1]][item.id].account += quantity
        }
        if (item.binding === "Character") {
          snapshot[type][gameID][path[0]][path[1]][item.id].character += quantity
        }
      }
    }

    return snapshot
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  buttonBar = (type) => {
    let settings = []

    if(typeof this.state.snapshot[type].config === "undefined") {
      settings.push(
        <tr key={"Snapshot"}>
          <td className={"left"}>Initial</td>
          <td colSpan="2">
            <span
              className={"customHover"}
              onClick={async () => {await this.getData(type)}}
            >
              Start
            </span>
          </td>
        </tr>
      )
    } else {
      let difference = "difference"
      let accounts = Object.keys(this.state.snapshot[type])
      accounts = accounts.filter(item => item !== "config")

      let expandedTable = this.processData(this.state.snapshot[difference])
      let csvData, csvName, csvHeaders

      csvName = new Date().toISOString() +"_expanded_" + accounts.join(",")
      csvData = this.processForCSV(expandedTable, this.state.filter[difference])
      csvHeaders = [
        {title:"ID",key:"id",type:"number"},
        {title:"Name",key:"name",type:"string"},
        {title:"Unbound",key:"noBinding",type:"number"},
        {title:"Soulbound",key:"soulbound",type:"number"},
        {title:"AccountBound",key:"accountBound",type:"number"},
        {title:"Total",key:"total",type:"number"},
        {title:"Account",key:"account",type:"string"},
        {title:"Location",key:"location",type:"string"},
        {title:"Sublocation",key:"subLocation",type:"string"}
      ]

      // for snapshot
      settings.push(
        <tr key={"Snapshot"}>
          <td className={"left"}>Tracker</td>
          <td colSpan="2">
            <span
              className={"customHover"}
              onClick={async () => {
                let newRunning = !this.state.running
                this.setState({running: newRunning})

                if(newRunning){
                  this.getData("secondary", true)
                }else{
                  window.clearTimeout(this.state.timeoutID)
                }
              }}
            >
              {this.state.running ? "Pause": "Resume"}
            </span>
          </td>
        </tr>
      )

      // secondary
      // snapshot time
      settings.push(
        <tr key={"Time"}>
          <td className={"left"}>Start</td>
          <td colSpan="2">
            {new Date(this.state.snapshot[type].config.end).toLocaleTimeString()}
          </td>
        </tr>
      )

      // elapsed tim,e
      settings.push(
        <tr key={"Time"}>
          <td className={"left"}>Elapsed</td>
          <td colSpan="2">
            {seconds2time(this.state.elapsed)}
          </td>
        </tr>
      )

      // for value and quantity
      let [buyValue, sellValue, quantity] = this.getSelectedDetails(csvData)
      let hours = this.state.elapsed/(60*60)
      settings.push(
        <tr key={"Value"}>
          <td className={"left"}>Value - Buy</td>
          <td><GetCurrency number={buyValue} size={25} /></td>
          <td><GetCurrency number={buyValue/hours} size={25} /> /hr</td>
        </tr>
      )
      settings.push(
        <tr key={"Value"}>
          <td className={"left"}>Value - Sell</td>
          <td><GetCurrency number={sellValue} size={25} /></td>
          <td><GetCurrency number={sellValue/hours} size={25} /> /hr</td>
        </tr>
      )
      settings.push(
        <tr key={"Quantity"}>
          <td className={"left"}>Quantity</td>
          <td>{quantity}</td>
          <td>{(quantity/hours).toFixed(2)} /hr</td>
        </tr>
      )

      // csv
      settings.push(
        <tr key={"CSV"}>
          <td className={"left"}>CSV</td>
          <td colSpan="2">
            <JSONConverter
              data={csvData}
              type={"CSV"}
              headers={csvHeaders}
              separator={","}
              text={"Download"}
              name={csvName}
            />
          </td>
        </tr>
      )
    }

    let buttons, progressBar

    if(this.state.status[type].total.total === this.state.status[type].total.complete) {
      buttons = <Table
        striped
        bordered
        size="sm"
        style={{ "table-layout":"auto", width: "350px", align: "center", margin: "0 auto" }}
      >
        <thead>
        <tr>
          <th colSpan="3">{this.capitalize(type)}</th>
        </tr>
        </thead>
        <tbody>
        {settings}
        </tbody>
      </Table>

      if(this.state.status["secondary"].total.total !== this.state.status["secondary"].total.complete){
        let complete = this.state.status["secondary"].total.complete
        let total = this.state.status["secondary"].total.total
        let progress = (complete/total)*100
        let label = complete + "/" + total + "\t" + progress.toFixed(2)
        progressBar = <ProgressBar now={progress} variant="success"  label={`${label}%`}/>;
      }
    }

    if(this.state.status[type].total.total !== this.state.status[type].total.complete){
      let complete = this.state.status[type].total.complete
      let total = this.state.status[type].total.total
      let progress = (complete/total)*100
      let label = complete + "/" + total + "\t" + progress.toFixed(2)
      progressBar = <ProgressBar now={progress} variant="success"  label={`${label}%`}/>;
    }

    return <div style={{align:"center"}}>
      {progressBar}
      {buttons}
    </div>
  }

  processData = (data) => {
    // breakout per account and each location/sublocation
    let expanded = []

    let accounts = Object.keys(data)
    // iterate through the account

    for(let i=0;i<accounts.length;i++){
      let account = accounts[i]
      let categories = Object.keys(data[account])

      for(let j=0;j<categories.length;j++){
        let category = categories[j]
        if(category === "config"){continue}

        if(category === "wallet"){
          // currenciesData
          let currencies = Object.keys(data[account][category])

          for(let l=0;l<currencies.length;l++){
            let id = currencies[l]
            let currency = data[account][category][id]
            let currencyData = this.state.currenciesData[id] || {id:undefined, name:undefined, icon:undefined}

            let tmp, value, valueRaw, total

            if(currencyData.name === "Coin"){
              value = currency.account
              valueRaw = currency.account
            }else{
              value = 0
              valueRaw = 0
            }

            if(currency.account > 0 ){
              total = currency.account
            }else{
              total = 0
            }


            tmp = {
              id: id,
              name: currencyData.name,
              type: "Currency",
              rarity: undefined,
              img: getDarthsCDN(currencyData.icon),
              level: undefined,
              marketable: undefined,
              value: value,
              buy_value: value,
              valueRaw: valueRaw,
              buy_valueRaw: valueRaw,
              account: account,
              location: category,
              subLocation: undefined,
              total: total
            }
            expanded.push(tmp)
          }
          continue
        }

        //inventory and equipment have a  breakdown per character
        if(category === "inventory" || category === "equipment" || category === "guild"){
          let characters = Object.keys(data[account][category])
          for(let k=0;k<characters.length;k++){
            let character = characters[k]
            let guildName
            if(categories[j] === "guild"){
              // character = guild ID not the name,
              guildName = data[account][category][character]["name"]
            }
            let items = Object.keys(data[account][category][character])
            for(let l=0;l<items.length;l++){
              let id = items[l]
              let item = data[account][category][character][id]
              let itemData = this.state.itemData[id] || {name:undefined, type:undefined, img:undefined, level:undefined, marketable:undefined, rarity:undefined, sell_price:0, buy_price:0,vendor_value:0, NoSell:true}

              // buy_value
              let value = 0
              let buy_value = 0

              let total = item.none + item.character + item.account
              if(total !== 0) {
                // overwrites default of 0
                if (typeof itemData.vendor_value !== "undefined" && itemData.vendor_value > 0 && typeof itemData.NoSell === "undefined") {
                  value = itemData.vendor_value * total
                  buy_value = itemData.vendor_value * total
                }

                // overwrites teh vendor vvalue
                if (typeof itemData.sell_price !== "undefined" && itemData.sell_price > 0) {
                  value = itemData.sell_price * total
                }
                if (typeof itemData.buy_price !== "undefined" && itemData.buy_price > 0) {
                  buy_value = itemData.buy_price * total
                }
              }

              let tmp = {
                id: id,
                name: itemData.name,
                type: itemData.type,
                rarity: itemData.rarity,
                img: itemData.img,
                level: itemData.level,
                marketable: itemData.marketable,
                value: value,
                buy_value: buy_value,
                valueRaw: value,
                buy_valueRaw: buy_value,
                account: account,
                location: category,
                subLocation: character,
                total: total
              }
              if(categories[j] === "guild"){
                tmp.subLocation = guildName
              }
              expanded.push(tmp)
            }
          }
        continue
        }

        // for everythign else
        if(category !== "inventory" && category !== "equipment" && category !== "guild"){
          let items = Object.keys(data[account][category])
          for(let l=0;l<items.length;l++){
            let id = items[l]
            let item = data[account][category][id]
            let itemData = this.state.itemData[id] || {name:undefined, type:undefined, img:undefined, level:undefined, marketable:undefined, rarity:undefined, sell_price:0, buy_price:0,vendor_value:0, NoSell:true }

            // buy_value
            let value = 0
            let price = 0
            let buy_value = 0

            let total = item.none + item.character + item.account
            if(total !== 0) {
              // overwrites default of 0
              if (typeof itemData.vendor_value !== "undefined" && itemData.vendor_value > 0 && typeof itemData.NoSell === "undefined") {
                value = itemData.vendor_value * total
                price = itemData.vendor_value
                buy_value = itemData.vendor_value * total
              }

              // overwrites teh vendor vvalue
              if (typeof itemData.sell_price !== "undefined" && itemData.sell_price > 0) {
                value = itemData.sell_price * total
                price = itemData.sell_price
              }
              if (typeof itemData.buy_price !== "undefined" && itemData.buy_price > 0) {
                buy_value = itemData.buy_price * total
              }
            }

            let tmp = {
              id: id,
              name: itemData.name,
              type: itemData.type,
              rarity: itemData.rarity,
              img: itemData.img,
              level: itemData.level,
              marketable: itemData.marketable,
              sell_price: price,
              value: value,
              buy_value: buy_value,
              valueRaw: value,
              buy_valueRaw: buy_value,
              account: account,
              location: category,
              subLocation: undefined,
              noBinding: item.none,
              total: total
            }
            expanded.push(tmp)
          }
        }
      }

    }

    let hours = this.state.elapsed/(60*60)
    expanded = expanded.map(item => {
      item.hour_total = (item.total/hours).toFixed(2) -0
      item.hour_buy = item.buy_valueRaw/hours
      item.hour_sell = item.valueRaw/hours
      return item
    })

    return expanded.filter(arrayFilter)
  }

  calculateDifference = (initial, secondary) => {
    let difference = {}

    let accounts = Object.keys(initial)
    for(let i=0;i<accounts.length;i++){
      let account = accounts[i]
      if(typeof difference[account]=== "undefined"){
        difference[account] = {}
      }

      let categories = Object.keys(initial[account])
      for(let j=0;j<categories.length;j++){
        let category = categories[j]

        if(typeof difference[account][category] === "undefined"){
          difference[account][category] = {}
        }

        //inventory and equipment have a  breakdown per character
        if(category === "inventory" || category === "equipment" || categories[j] === "guild"){
          // get characters
          let characters = Object.keys(initial[account][category])
          for(let k=0;k<characters.length;k++){
            let character = characters[k]

            if(typeof difference[account][category][character] === "undefined"){
              difference[account][category][character] = {}
            }
            // get all the items
            let allItems = {}
            let itemsInitial = Object.keys(initial[account][category][character])
            for(let l=0;l<itemsInitial.length;l++){
              allItems[itemsInitial[l]] = ""
            }
            if(
              typeof secondary[account] !== "undefined"
              && typeof secondary[account][category] !== "undefined"
              && typeof secondary[account][category][character] !== "undefined"
            ){
              let itemsSecondary = Object.keys(secondary[account][category][character])
              for(let l=0;l<itemsSecondary.length;l++){
                allItems[itemsSecondary[l]] = ""
              }
            }
            let items = Object.keys(allItems)
            for(let l=0;l<items.length;l++){
              let id = items[l]

              if(typeof difference[account][category][character][id] === "undefined"){
                difference[account][category][character][id]  = { character:0, account:0, none:0 }
              }

              let itemInitial
              if(typeof initial[account] === "undefined"){
                itemInitial =  { character:0, account:0, none:0 }
              }else if(typeof initial[account][category] === "undefined"){
                itemInitial =  { character:0, account:0, none:0 }
              }else if(typeof initial[account][category][character] === "undefined"){
                itemInitial =  { character:0, account:0, none:0 }
              }else if(typeof initial[account][category][character][id] === "undefined"){
                itemInitial =  { character:0, account:0, none:0 }
              }else{
                itemInitial =  initial[account][category][character][id]
              }
              let itemSecondary
              if(typeof secondary[account] === "undefined"){
                itemSecondary =  { character:0, account:0, none:0 }
              }else if(typeof secondary[account][category] === "undefined"){
                itemSecondary =  { character:0, account:0, none:0 }
              }else if(typeof secondary[account][category][character] === "undefined"){
                itemSecondary =  { character:0, account:0, none:0 }
              }else if(typeof secondary[account][category][character][id] === "undefined"){
                itemSecondary =  { character:0, account:0, none:0 }
              }else{
                itemSecondary =  secondary[account][category][character][id]
              }

              difference[account][category][character][id].none = itemSecondary.none - itemInitial.none
              difference[account][category][character][id].account = itemSecondary.account - itemInitial.account
              difference[account][category][character][id].character = itemSecondary.character - itemInitial.character
            }
          }
        }

        if(categories[j] !== "inventory" && categories[j] !== "equipment" && categories[j] !== "guild"
          && categories[j] !== "config" && categories[j] !== "state" && categories[j] !== "start" && categories[j] !== "end"
        ){
          // get all the items
          let allItems = {}
          let itemsInitial = Object.keys(initial[account][category])
          for(let l=0;l<itemsInitial.length;l++){
            allItems[itemsInitial[l]] = ""
          }
          if(
            typeof secondary[account] !== "undefined"
            && typeof secondary[account][category] !== "undefined"
          ){
            let itemsSecondary = Object.keys(secondary[account][category])
            for(let l=0;l<itemsSecondary.length;l++){
              allItems[itemsSecondary[l]] = ""
            }
          }
          let items = Object.keys(allItems)
          for(let l=0;l<items.length;l++){
            let id = items[l]

            if(typeof difference[account][category][id] === "undefined"){
              difference[account][category][id]  = { character:0, account:0, none:0 }
            }

            let itemInitial
            if(typeof initial[account] === "undefined"){
              itemInitial =  { character:0, account:0, none:0 }
            }else if(typeof initial[account][category] === "undefined"){
              itemInitial =  { character:0, account:0, none:0 }
            }else if(typeof initial[account][category][id] === "undefined"){
              itemInitial =  { character:0, account:0, none:0 }
            }else{
              itemInitial =  initial[account][category][id]
            }
            let itemSecondary
            if(typeof secondary[account] === "undefined"){
              itemSecondary =  { character:0, account:0, none:0 }
            }else if(typeof secondary[account][category] === "undefined"){
              itemSecondary =  { character:0, account:0, none:0 }
            }else if(typeof secondary[account][category][id] === "undefined"){
              itemSecondary =  { character:0, account:0, none:0 }
            }else{
              itemSecondary =  secondary[account][category][id]
            }

            difference[account][category][id].none = itemSecondary.none - itemInitial.none
            difference[account][category][id].account = itemSecondary.account - itemInitial.account
            difference[account][category][id].character = itemSecondary.character - itemInitial.character
          }
        }
      }

    }
    return difference
  }

  generateChecklist= () =>{
    return <Table
      responsive
      variant="dark"
      style={{"table-layout": "auto"}}
    >
      <thead>
      <tr>
        <th className={"left"}>Accounts</th>
        <th className={"left"}>Inventory</th>
        <th className={"left"}>Equipment</th>
        <th className={"left"}>Wallet</th>
        <th className={"left"}>Bank</th>
        <th className={"left"}>Materials</th>
        <th className={"left"}>TradePost&nbsp;Sells</th>
        <th className={"left"}>Shared&nbsp;Inventory</th>
        <th className={"left"}>Delivery&nbsp;Box</th>
        <th className={"left"}>Guilds</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td className={"left"}>{this.accountChecklist()}</td>
        <td className={"left"}>{this.characterChecklist()}</td>
        <td className={"left"}>{this.equipmentChecklist()}</td>
        <td className={"left"}>{this.generalAccountChecklist("wallet", "Wallet")}</td>
        <td className={"left"}>{this.generalAccountChecklist("bank", "Bank")}</td>
        <td className={"left"}>{this.generalAccountChecklist("materials", "Material Storage")}</td>
        <td className={"left"}>{this.generalAccountChecklist("tpSells", "Sale Offers")}</td>
        <td className={"left"}>{this.generalAccountChecklist("sharedInventory", "Shared Inventory")}</td>
        <td className={"left"}>{this.generalAccountChecklist("deliveryBox", "Delivery Box")}</td>
        <td className={"left"}>{this.guildChecklist()}</td>
      </tr>
      </tbody>
    </Table>
  }

  tableController = (type, data) =>{
    if(this.state.table[type].show){
      return this.tableManager(data, this.state.compact[type], type)
    }
  }

  processForCSV = (tmp, filter) =>{
    for(let i=0;i<filter.length;i++){
      let template = this.state.templates[filter[i].template]
      let accessor = filter[i].id
      if(typeof template.filter !== "undefined"){
        tmp = tmp.filter((item) => template.filter(item[accessor], filter[i].value))
      }else{
        tmp = tmp.filter((item)=> filterGeneralText(item[accessor],filter[i].value))
      }
    }
    return tmp;
  }

  getSelectedDetails = (input) => {
    let buyValue = 0
    let sellValue = 0
    let quantity = 0
    for(let i=0;i<input.length;i++){
      if(!isNaN(input[i].valueRaw)){
        sellValue += input[i].valueRaw
      }
      if(!isNaN(input[i].buy_valueRaw)){
        buyValue += input[i].buy_valueRaw
      }
      if(input[i].type === "Currency"){continue}
      if(!isNaN(input[i].total)){
        quantity += input[i].total
      }
    }
    return [buyValue, sellValue, quantity]
  }

  tableManager = (data, compactLevel, type) => {
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "text": {
          className: "left",
        },
        "textImg": {
          contents: (item) => <TextImg item={item}/>,
          className: "left",
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "percent": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "gold": {
          className: "right",
          contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
          sort: sortGeneral,
          filter: (item, filter) => filterGeneral(item, filter, 10000)
        }
      },
      colsToDisplay:20,
      filter: {active:true},
      sort:{col:"name", desc:false},
      headers: {
        "Details": {
          className:"left",
          cols: [
            { template: "textImg", header: "Item", accessor: "name" },
          ]
        },
        "Location": {
          collapse:true,
          className:"left",
          cols: [
            { template: "text", header: "Account", accessor: "account" },
            { template: "text", header: "Location", accessor: "location" },
            { template: "text", header: "Sub-Location", accessor: "subLocation" },
          ]
        },
        "Quantities": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Total", accessor: "total" },
            { template: "gold", header: "Buy Value", accessor: "buy_value" },
            { template: "gold", header: "Sell Value", accessor: "value" },

            { template: "number", header: "Total/hr", accessor: "hour_total" },
            { template: "gold", header: "Buy Value/hr", accessor: "hour_buy" },
            { template: "gold", header: "Sell Value/hr", accessor: "hour_sell" },
          ]
        },
        "Filters": {
          collapse:true,
          className:"left",
          cols: [
            { template: "text", header: "Type", accessor: "binding" },
            { template: "text", header: "Rarity", accessor: "rarity" },
            { template: "number", header: "Level", accessor: "level" },
          ]
        }
      },
      headerOrder:["Details","Quantities","Location","Filters"]
    }

    let tableData = this.processData(data)

    if(typeof this.state.templates === "undefined"){
      this.setState({templates:config.templates})
    }
    return this.createTable(tableData, config, type)
  }

  createTable = (data, config, identifier) => {
    return <SilverTable
      data={data}
      config={config}
      callbackToParent={this.callbackToParent}
      identifier={identifier}
    />
  }

  callbackToParent = (item) =>{
    let filterArray = []
    let newFilters = Object.keys(item.filter)
    for(let i=0;i<newFilters.length;i++){
      filterArray.push({
        id:item.colOrder[newFilters[i]].accessor,
        value:item.filter[newFilters[i]],
        template:item.colOrder[newFilters[i]].template
      })
    }
    let filter = this.state.filter
    filter[item.identifier] = filterArray
    this.setState({filter:filter})
  }

  render() {
    //console.log(this.state)
    // pop up loading mesage if the item data isnt down
    if (typeof this.state.itemData === "undefined") {return <Loading/>}
    // an error happened, pop it up and break
    if (typeof this.state.error !== "undefined") {return <div>{this.state.error}</div>}
    // if there are no game accounts on the website account
    if (this.state.accounts.length === 0) {return <div>No API Key available</div>}

    let checklist = this.generateChecklist()

    // initial stuff
    let initialButtons = this.buttonBar("initial")

    let differenceTable, graph
    if(typeof this.state.snapshot["initial"].config !== "undefined"){
      differenceTable = this.tableController("difference", this.state.snapshot["difference"])
      graph = this.generateGraph()
    }

    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/to_csv#farming-tracker"} />
      <br/>
      {checklist}
      <br/>
      {initialButtons}
      <br/>
      {graph}
      <br />
      {differenceTable}
    </div>
  }
}

export const Farming_Tracker = withHighcharts(FarmingTracker_Component, Highcharts)