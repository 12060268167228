import React, { Component } from 'react'
import { graphOptions, urlBase } from '../_utiilities/data.json'

import dateFormat from 'dateformat'
import Highcharts from 'highcharts/highstock'
import { Chart, ColumnSeries, HighchartsStockChart, Legend, LineSeries, Navigator, RangeSelector, Title, Tooltip, withHighcharts, XAxis, YAxis, } from 'react-jsx-highstock'
import {
  convertToGold,
  filterGeneral,
  getRarityColour,
  sortGeneral,
} from '../_utiilities/functions'
import {
  Documentation,
  GetCurrency,
  Loading,
  SilverTable,
} from '../_utiilities/functions_react'

let startTimer = Date.now()
let timer = {}
timer.start = (Date.now() - startTimer) / 1000


class TP_Item_Component extends Component {
  constructor(props) {
    super(props)

    console.log(this.props.match)
    let id
    if(
      typeof props.match !== "undefined"
      && typeof props.match.params !== "undefined"
      && typeof props.match.params.id !== "undefined"
      && !isNaN(props.match.params.id -0)
    ){
      id = props.match.params.id - 0
    }
    this.state = {
      siteAddress: window.location.protocol + "//" + window.location.host,
      id: id
    }
  }

  async loadData(url) {return await fetch(url).then(response => {return response.json()}).catch(err => console.log(this.props.url, err.toString()))}

  async componentDidMount() {
    timer.mounted = (Date.now() - startTimer) / 1000
    if(typeof this.state.id === "undefined"){return null}
    let id = this.state.id
    this.itemData(id)
    this.history(id)
    this.listings(id)
  }

  // function for general item data
  itemData = async(id) =>{
    let result = await this.loadData(urlBase.parser+ "/v1/items/json?fields=id,name,img,rarity,buy_price,sell_price,buy_quantity,sell_quantity,chat_link,type,weaponType,1d_buy_sold,1d_sell_sold&beautify=min&filter=id:eq:" + id)
    timer.itemData = (Date.now() - startTimer) / 1000;
    if(result.length === 0){
      this.setState({itemData: undefined})
    }else{
      this.setState({itemData: result[0]})
    }
  }

  history = async (id) =>{
    let result = await this.loadData(urlBase.parser+ "/v2/history/json?itemID=" + id)
    timer.historyData = (Date.now() - startTimer) / 1000;
    if(result.length === 0){
      this.setState({historyData: undefined})
    }else{
      this.setState({historyData: result})
    }
  }

  listings = async (id) =>{
    let result = await this.loadData("https://api.guildwars2.com/v2/commerce/listings/" + id)
    timer.listings = (Date.now() - startTimer) / 1000;
    if(result.length === 0){
      this.setState({listings: undefined})
    }else{
      this.setState({listings: result})
    }
  }

  historyGraph = (history, itemData) =>{
    let chartData = {}
    for(let i=0;i<history.length;i++){
      if(history[i].type === "hour"){
        let date = new Date(history[i].date)
        date.setUTCHours(date.getUTCHours()-1)
        date = date.toISOString()
        history[i].date = date
      }
    }
    history = history.sort((a,b) =>{return (a.date < b.date) ? -1 : ((a.date > b.date) ? 1 : 0);})
    let elements = [
      "sellPrice", "sellQuantity","sellSold", "sellSoldValue", "sellListed", "sellDeListed",
      "buyPrice", "buyQuantity","buySold", "buySoldValue", "buyListed", "buyDeListed",
    ]
    let flags = {minute:false, hour:false}
    for(let i=0;i<history.length;i++){
      let date = new Date(history[i].date).getTime()

      let tmp = {
        // old
        sellPrice:undefined,
        sellQuantity:undefined,
        buyPrice:undefined,
        buyQuantity:undefined,
        
        // new
        sellSold:undefined,
        sellSoldValue:undefined,
        sellListed:undefined,
        sellDeListed:undefined,

        buySold:undefined,
        buySoldValue:undefined,
        buyListed:undefined,
        buyDeListed:undefined,
      }

      if(typeof history[i].type === "undefined"){
        tmp.sellPrice = history[i].sell_price_max
        tmp.sellQuantity = history[i].sell_quantity_max

        tmp.buyPrice = history[i].buy_price_max
        tmp.buyQuantity = history[i].buy_quantity_max
      }

      if(history[i].type === "day" && !flags.hour){
        tmp.sellPrice = history[i].sell_price_avg
        tmp.sellQuantity = history[i].sell_quantity_avg

        tmp.buyPrice = history[i].buy_price_avg
        tmp.buyQuantity = history[i].buy_quantity_avg

        // new 
        tmp.sellSold = history[i].sell_sold
        tmp.sellSoldValue = history[i].sell_value
        tmp.sellListed = history[i].sell_listed
        tmp.sellDeListed = history[i].sell_delisted

        tmp.buySold = history[i].buy_sold
        tmp.buySoldValue = history[i].buy_value
        tmp.buyListed = history[i].buy_listed
        tmp.buyDeListed = history[i].buy_delisted
      }

      if(history[i].type === "hour" && !flags.minute){
        flags.hour = true
        tmp.sellPrice = history[i].sell_price_avg
        tmp.sellQuantity = history[i].sell_quantity_avg

        tmp.buyPrice = history[i].buy_price_avg
        tmp.buyQuantity = history[i].buy_quantity_avg

        // new
        tmp.sellSold = history[i].sell_sold
        tmp.sellSoldValue = history[i].sell_value
        tmp.sellListed = history[i].sell_listed
        tmp.sellDeListed = history[i].sell_delisted

        tmp.buySold = history[i].buy_sold
        tmp.buySoldValue = history[i].buy_value
        tmp.buyListed = history[i].buy_listed
        tmp.buyDeListed = history[i].buy_delisted
      }

      if(history[i].type === "minute"){
        flags.minute = true
        tmp.sellPrice = history[i].sell_price
        tmp.sellQuantity = history[i].sell_quantity

        tmp.buyPrice = history[i].buy_price
        tmp.buyQuantity = history[i].buy_quantity

        // new
        tmp.sellSold = history[i].sell_sold
        tmp.sellSoldValue = history[i].sell_value
        tmp.sellListed = history[i].sell_listed
        tmp.sellDeListed = history[i].sell_delisted

        tmp.buySold = history[i].buy_sold
        tmp.buySoldValue = history[i].buy_value
        tmp.buyListed = history[i].buy_listed
        tmp.buyDeListed = history[i].buy_delisted
      }

      for(let j=0;j<elements.length;j++){
        chartData = this.addToObject(chartData,tmp, date, elements[j])
      }

    }
    timer.historyTable_chartData = (Date.now() - startTimer) / 1000;

    let navigator= [
      <Navigator.Series seriesId="sellPrice" />,
      <Navigator.Series seriesId="buyPrice" />,
      <Navigator.Series seriesId="sellSoldValue" />,
      <Navigator.Series seriesId="buySoldValue" />,

      <Navigator.Series seriesId="sellQuantity" />,
      <Navigator.Series seriesId="sellSold" />,
      <Navigator.Series seriesId="sellListed" />,
      <Navigator.Series seriesId="sellDeListed" />,

      <Navigator.Series seriesId="buyQuantity" />,
      <Navigator.Series seriesId="buySold" />,
      <Navigator.Series seriesId="buyListed" />,
      <Navigator.Series seriesId="buyDeListed" />,
    ]
    let prices = [
      <LineSeries id="sellPrice" name="Sell Price" data={chartData["sellPrice"]} />,
      <LineSeries id="buyPrice" name="Buy Price" data={chartData["buyPrice"]} />,
      <LineSeries id="sellSoldValue" name="Sell Sold Value" data={chartData["sellSoldValue"]} visible={false} />,
      <LineSeries id="buySoldValue" name="Buy Sold Value" data={chartData["buySoldValue"]} visible={false} />,
    ]
    let quantities = [
      <ColumnSeries id="sellQuantity" name="Sell Quantity" data={chartData["sellQuantity"]} dataGrouping={{approximation: "average"}}   visible={false}/>,
      <ColumnSeries id="buyQuantity" name="Buy Quantity" data={chartData["buyQuantity"]}  dataGrouping={{approximation: "average"}}   visible={false}/>,
    ]

    let quantities2 = [
      <ColumnSeries id="sellSold" name="Sell Sold" data={chartData["sellSold"]}  visible={false}/>,
      <ColumnSeries id="sellListed" name="Sell Listed" data={chartData["sellListed"]} visible={false} />,
      <ColumnSeries id="sellDeListed" name="Sell Delisted" data={chartData["sellDeListed"]} visible={false}/>,

      <ColumnSeries id="buySold" name="Buy Sold" data={chartData["buySold"]} visible={false}/>,
      <ColumnSeries id="buyListed" name="Buy Listed" data={chartData["buyListed"]} visible={false} />,
      <ColumnSeries id="buyDeListed" name="Buy Delisted" data={chartData["buyDeListed"]} visible={false}/>,
    ]

    return <HighchartsStockChart
      plotOptions={graphOptions.plotOptions}
      styledMode
    >
      <Chart height={600}/>
      <Title>{itemData.name}</Title>
      <Legend/>

      <Tooltip
        shared={true}
        formatter={function () {
          let s = '<b> ' + dateFormat(new Date(this.x), "ddd yyyy-mm-dd hh:MM") + '</b>';
          for (let i = 0; i < this.points.length; i++) {
            let nameArray = this.points[i].series.name.split(' ');
            let tmp = "";
            if (
              nameArray[1] === "Price"
              || (nameArray[1] === "Sold" && nameArray[2] === "Value")
            ) {
              tmp = '<br/><span style="color:' + this.points[i].color + '">\u25CF</span>' + this.points[i].series.name + ': ' + convertToGold(this.points[i].y);
            } else {
              tmp = '<br/><span style="color:' + this.points[i].color + '">\u25CF</span>' + this.points[i].series.name + ': ' + Math.round(this.points[i].y);
            }
            s += tmp;
          }
          return s;
        }}
      />


      <XAxis>
        <XAxis.Title>DateTime</XAxis.Title>
      </XAxis>

      <YAxis opposite>
        <YAxis.Title>Quantity</YAxis.Title>
        {quantities2}
      </YAxis>
      <YAxis opposite>
        {quantities}
      </YAxis>

      <YAxis>
        <YAxis.Title>Price</YAxis.Title>
        {prices}
      </YAxis>
      <RangeSelector
        //selected={3}
      >
        <RangeSelector.Button count={2} type="hour">2h</RangeSelector.Button>
        <RangeSelector.Button count={6} type="hour">6h</RangeSelector.Button>
        <RangeSelector.Button count={1} type="day">1d</RangeSelector.Button>
        <RangeSelector.Button count={7} type="day">7d</RangeSelector.Button>
        <RangeSelector.Button count={1} type="month">1m</RangeSelector.Button>
        <RangeSelector.Button type="all">All</RangeSelector.Button>
        <RangeSelector.Input boxBorderColor="#7cb5ec"/>
      </RangeSelector>

      <Navigator>
        {navigator}
      </Navigator>
    </HighchartsStockChart>
  }

  addToObject = (chartData, tmp, date, element) => {
    if(typeof tmp[element] !== "undefined"){
      if(typeof chartData[element] === "undefined"){
        chartData[element] = []
      }
      chartData[element].push([date,Math.round(tmp[element])])
    }
    return chartData
  }

  listingsTables = (listings) =>{
    return [this.tableManager(listings.buys, "Buys"), this.tableManager(listings.sells, "Sells"),]
  }

  tableManager = (data, type) => {
    if(typeof data === "undefined"){return null}
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "number": {
          className: "right",
          width: 62,
          sort: sortGeneral,
          filter: filterGeneral
        },
        "gold": {
          className: "right",
          width: 165,
          contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
          sort: sortGeneral,
          filter: (item, filter) => filterGeneral(item, filter, 10000)
        }
      },
      showButtons: false,
      filter: {active:false},
      sort:{col:"unit_price", desc:false},
      headers: {
        cols: [
          { template: "number", header: "Quantity", accessor: "quantity" },
          { template: "number", header: "Listings", accessor: "listings" },
          { template: "gold", header: "Price", accessor: "unit_price" },
        ]
      },
    }

    if(type === "Buys"){
      config.sort.desc = !config.sort.desc
    }

    return <div>
      <h4 style={{color:"#ffffff"}}>
        {type}
      </h4>
      <div
        //style={{height: "600px",width: "180px", "overflow-y": "scroll"}}
        style={
          {width: "100%",
          height: "500px",
          overflow: "hidden"
          }
        }
      >
        <div
          style={
            {
              width: "100%",
            height: "100%",
            "overflow-y": "scroll",
            "padding-right": "17px", /* Increase/decrease this value for cross-browser compatibility */
            "box-sizing": "content-box" /* So the width will be 100% + 17px */
            }
          }
        >
          {this.createTable(data, config)}
        </div>
      </div>
    </div>
  }

  itemDataToTable = (itemData) =>{
    let square = 25
    let name = itemData.name;
    let rarity = itemData.rarity;
    let rarityColour = getRarityColour(rarity)

    let image = <img
      key={name}
      style={{ width: square,height:square, border: "1px solid "+ rarityColour }}
      src={itemData.img}
      title={name}
      alt={name}
    />

    let style0 = {}
    style0["min-width"] = 100 + "px"
    style0["width"] = 100 + "px"
    style0["max-width"] = 100 + "px"
    style0["text-overflow"] = "ellipsis"
    style0["overflow"] = "hidden"
    style0["white-space"] = "nowrap"
    style0["text-align"] = "left"

    let style1 = {}
    style1["min-width"] = 140 + "px"
    style1["width"] = 140 + "px"
    style1["max-width"] = 140 + "px"
    style1["text-overflow"] = "ellipsis"
    style1["overflow"] = "hidden"
    style1["white-space"] = "nowrap"
    style1["text-align"] = "right"

    let style2 = {}
    style2["min-width"] = 100 + "px"
    style2["width"] = 100 + "px"
    style2["max-width"] = 100 + "px"
    style2["text-overflow"] = "ellipsis"
    style2["overflow"] = "hidden"
    style2["white-space"] = "nowrap"
    style2["text-align"] = "right"


    return  <div>
      <h4 title={name + " - " + rarity} style={{color: rarityColour}}>
        {image} {name}
      </h4>
      <table className={"centerTable table-primary table-striped table-highlight"} >
        <tbody>
        <tr>
          <td style={style0} ><a href={"http://wiki.guildwars2.com/wiki/?search=" + encodeURIComponent(itemData.chat_link)} target={"_blank"} rel={"noreferrer noopener"} title={"Wiki page for " + itemData.name }>𝐖iki</a></td>
          <td style={style1} ><a href={"https://api.guildwars2.com/v2/items?ids=" + itemData.id} target={"_blank"} rel={"noreferrer noopener"} title={"API page for " + itemData.name }>API</a></td>
          <td style={style2} ><a href={"https://www.gw2bltc.com/en/item/" + itemData.id} target={"_blank"} rel={"noreferrer noopener"} title={"Gw2BLTC page for " + itemData.name }>Gw2BLTC</a></td>
        </tr>
        <tr>
          <td style={style0} >{rarity}</td>
          <td style={style1} >{itemData.type}</td>
          <td style={style2} >{itemData.weaponType}</td>
        </tr>
        <tr>
          <td style={style0} />
          <td style={style1} />
          <td style={style2} />
        </tr>
        <tr>
          <td style={style0}  ><b>Last 24h</b></td>
          <td style={style1}><b>Buy</b></td>
          <td style={style2}  ><b>Sell</b></td>
        </tr>
        <tr>
          <td style={style0} >Price</td>
          <td style={style1} ><GetCurrency number={itemData.buy_price} size={25} /></td>
          <td style={style2} ><GetCurrency number={itemData.sell_price} size={25} /></td>
        </tr>
        <tr>
          <td style={style0} >Quantity</td>
          <td style={style1} >{itemData.buy_quantity}</td>
          <td style={style2} >{itemData.sell_quantity}</td>
        </tr>
        <tr>
          <td style={style0} >Sold</td>
          <td style={style1} >{itemData["1d_buy_sold"]}</td>
          <td style={style2} >{itemData["1d_sell_sold"]}</td>
        </tr>
        </tbody>
      </table>
    </div>
  }


  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
    />
  }

  render() {
    if(typeof this.state.id === "undefined"){return <h2>No ID selected</h2>}

    // checks if anything is laoded, if no returns the loading
    if(
      typeof this.state.itemData === "undefined"
      && typeof this.state.historyData === "undefined"
      && typeof this.state.listings === "undefined"
    ){
      return <Loading/>
    }
    let graph, buy, sell, name, info

    if(
      typeof this.state.historyData !== "undefined"
      && typeof this.state.itemData !== "undefined"
    ){
      graph = this.historyGraph(this.state.historyData, this.state.itemData)
    }

    if(typeof this.state.listings !== "undefined"){
      [buy, sell] = this.listingsTables(this.state.listings)
    }

    if(typeof this.state.itemData !== "undefined"){
      name = this.state.itemData.name
      info = this.itemDataToTable(this.state.itemData)
    }

    let dataRow = <div
      className={"grid-results"}
      style={{  margin: "0 auto", "max-width":"1250px" }}
    >
      {buy}
      {sell}
      {info}
    </div>

    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/tradepost#gnashblade"} />
      <br/>
      <h2>TradePost - {name}</h2>
      <br />
      {dataRow}
      <br />
      {graph}
    </div>
  }
}

export const TP_Item = withHighcharts(TP_Item_Component, Highcharts)