import React , { Component }from 'react';
import Autosuggest from 'react-autosuggest'
import { Modal } from 'react-bootstrap'
import GoogleAnalytics from 'react-ga'
import { darth_base } from "./data.json"
import { convertToGold, filterGeneralText } from './functions'

export class Documentation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      url: props.url || "https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/home"
    }
  }

  render() {
    return <div>Documentation for this page can be found <a href={this.state.url} target={"_blank"}  rel={"noopener noreferrer"}>here</a>.</div>
  }
}

export class AutoComplete extends Component {
  constructor(props) {
    super(props);

    let tmp = {};
    tmp.value =  '';
    tmp.suggestions =  props.array;
    tmp.autoSuggest =  props.array;
    tmp.sorter =  props.sorter;
    tmp.placeholder =  props.placeholder || "Search Here";
    tmp.startRenderString =  props.startRenderString - 0 || 2;
    tmp.limit =  props.limit - 0 || 5;
    tmp.buttonText =  props.buttonText  || "Submit";
    tmp.identifier = props.identifier || undefined;
    tmp.imgSize = props.imgSize || 15

    this.state = tmp;
  }

  componentWillReceiveProps(nextProps) {
    let tmp = {};
    tmp.value =  '';
    tmp.suggestions =  nextProps.array;
    tmp.autoSuggest =  nextProps.array;
    tmp.sorter =  nextProps.sorter;
    tmp.placeholder =  nextProps.placeholder || "Search Here";
    tmp.startRenderString =  nextProps.startRenderString - 0 || 2;
    tmp.limit =  nextProps.limit - 0 || 5;
    tmp.buttonText =  nextProps.buttonText  || "Submit";
    tmp.identifier = nextProps.identifier || undefined;
    tmp.imgSize = nextProps.imgSize || 15
    this.setState(tmp);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  shouldRenderSuggestions = (value) => {
    return value.trim().length > this.state.startRenderString;
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions(value){
    value = value.toLowerCase()
    let search = this.state.autoSuggest;
    let accessor = this.state.sorter
    return search.filter((item)=>filterGeneralText(item[accessor].toLowerCase(),value));
  };

  render() {
    const {value, suggestions} = this.state;
    const inputProps = {
      placeholder: this.state.placeholder,
      value,
      onChange: this.onChange,
      type: "text"
    };

    let sorter = this.state.sorter;
    const getSuggestionValue = suggestion => suggestion[sorter];
    const renderSuggestion = (suggestion) => {
      let square = this.state.imgSize
      if(typeof suggestion.img !== "undefined" ){
        let image =  <img
          key={suggestion.name}
          style={{width: square,height:square}}
          src={suggestion.img}
          title={suggestion.name}
          alt={suggestion.name}
        />
        return <div style={{align:"left"}}>{image} {suggestion[sorter]}</div>
      }else{
        return <div style={{align:"left"}}>{suggestion[sorter]}</div>
      }
    }

    let theme = {
      container:                'react-autosuggest__container',
      containerOpen:            'react-autosuggest__container--open',
      input:                    'input',
      inputOpen:                'input',
      inputFocused:             'input',
      suggestionsContainer:     'react-autosuggest__suggestions-container',
      suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
      suggestionsList:          'react-autosuggest__suggestions-list',
      suggestion:               'react-autosuggest__suggestion',
      suggestionFirst:          'react-autosuggest__suggestion--first',
      suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
      sectionContainer:         'react-autosuggest__section-container',
      sectionContainerFirst:    'react-autosuggest__section-container--first',
      sectionTitle:             'react-autosuggest__section-title'
    };
    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions.slice(0, this.state.limit)}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={theme}
        onSuggestionSelected={(event, { suggestionValue, suggestion}) => {
          if(typeof this.state.identifier === "undefined"){
            this.props.callbackToParent(suggestionValue);
          }else{
            this.props.callbackToParent([suggestionValue,this.state.identifier, suggestion]);
          }
          this.setState({value: ""});
        }}
      />
    );
  }
}

export class Loading extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <div>
      <p>Loading, Please Wait.</p>
    </div>;
  }
}

export class VerticallyCenteredModal extends Component {
  constructor(props) {
    super(props);

    let tmp = {};
    tmp.header =  props.header || "Default Header"
    tmp.body =  props.body || "Default Body"
    tmp.closeButton =  props.closeButton || true
    tmp.size =  props.size || "sm"
    tmp.centered =  props.centered || true
    tmp.show =  props.show || true
    tmp.buttons =  props.buttons || undefined


    this.state = tmp;
  }

  componentWillReceiveProps(nextProps) {
    let tmp = {};

    tmp.header =  nextProps.header || "Default Header"
    tmp.body =  nextProps.body || "Default Body"
    tmp.closeButton =  nextProps.closeButton || true
    tmp.size =  nextProps.size || "sm"
    tmp.centered =  nextProps.centered || true
    tmp.show =  nextProps.show || true
    tmp.buttons =  nextProps.buttons || undefined

    this.setState(tmp);
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        size={this.state.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered={this.state.centered}
      >
        <Modal.Header closeButton={this.state.closeButton} >
          <Modal.Title id="contained-modal-title-vcenter">
            {this.state.header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.body}
        </Modal.Body>
        <Modal.Footer>
          {this.state.buttons}
        </Modal.Footer>
      </Modal>
    );
  }
}

/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */
GoogleAnalytics.initialize('UA-111731792-5');
export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  return class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export class SilverTable extends Component {
  constructor(props) {
    super(props)

    let config = props.config || {}
    let data = props.data || []
    let callbackToParent = props.callbackToParent || undefined
    this.state = {
      data: data,
      config: config,
      callbackToParent: callbackToParent,
      page:0,
      colsToDisplay: config.colsToDisplay || data.length+1,
      sort:config.sort || {col:"", desc:true},
      filter:{},
      collapse:config.collapse || {},
      colOrder: [],
      identifier: props.identifier || undefined,
    }
  }

  //*
  componentWillReceiveProps(props) {
    let config = props.config || {}
    let currentState = this.state

    let colsToDisplay = config.colsToDisplay || currentState.colsToDisplay
    if (colsToDisplay === 1){
      colsToDisplay = props.data.length + 1
    }

    let tmp = {
      data: props.data,
      config: config,
      callbackToParent: props.callbackToParent,
      colsToDisplay: colsToDisplay,
      collapse:currentState.collapse || config.collapse,
      colOrder: [],
      identifier: props.identifier || currentState.identifier,
    }
    this.setState(tmp)
  }
//*/
  getHowManyLevel = (obj) => {
    let res = JSON.stringify(obj).replace(/[^{|^}]/g, '')
    while (/}{/g.test(res)) {
      res = res.replace(/}{/g, '')
    }
    return res.replace(/}/g, '').length
  }

  getHeaders = (config) =>{
    if(typeof config === "undefined"){return [[],[],[]]}
    if(typeof config.headers === "undefined"){return [[],[],[]]}
    if(typeof config.templates === "undefined"){return [[],[],[]]}

    let headersTmp = {}
    headersTmp.count = this.getHowManyLevel(config.headers) - 1
    for(let i=1;i<headersTmp.count+1;i++){
      headersTmp[i] = []
    }
    if(typeof config.filter !== "undefined"){
      headersTmp["search"] = []
    }
    let headersRaw = Object.keys(config.headers)
    if(
      typeof config.headerOrder !== "undefined"
      && typeof config.headerOrder  === "object"
      && config.headerOrder.length >0
    ){
      headersRaw = config.headerOrder
    }
    let colOrder = []
    let colAll = []
    for(let i=0;i<headersRaw.length;i++){
      if(headersTmp.count === 1){
        let cols = config.headers.cols

        for(let j=0;j<cols.length;j++){
          let templateName = cols[j].template
          if(typeof templateName === "undefined"){continue}
          let template = config.templates[templateName]
          if(typeof template === "undefined"){continue}

          // pushing it into the col order
          colOrder.push(cols[j])
          colAll.push(cols[j])

          let headerText = cols[j].header
          if(typeof cols[j].custom2Header !== "undefined"){
            headerText = cols[j].custom2Header(cols[j].header)
          }
          // now add to teh second header array
          headersTmp["1"].push(
            <th
              style={{cursor: "pointer"}}
              className={template.className}
              onClick={()=>{this.setSort(cols[j].accessor)}}
            >
              {headerText}
            </th>
          )

          if(typeof config.filter !== "undefined"){
            if(cols[j].accessor !== ""){
              headersTmp["search"].push(
                <th
                  className={config.filter.className}
                  style={{padding:"0px"}}
                >
                  <input
                    style={{width:"100%",margin:0 }}
                    type={"text"}
                    value={this.state.filter[cols[j].accessor]}
                    onChange={this.onChange}
                    data-path={["filter", cols[j].accessor]}
                  />
                </th>
              )
            }else{
              headersTmp["search"].push(
                <th
                  className={config.filter.className}
                  style={{ padding: '0px' }}
                />
              )
            }
          }
        }
      }
      if(headersTmp.count === 2){
        // header is always a row 1
        let header = headersRaw[i]
        let colSpan = 1
        let className = config.headers[header].className
        let collapsable = config.headers[header].collapse
        let collapsed = false

        if(collapsable){
          let collapse = this.state.collapse[header]
          if(typeof collapse !== "undefined"){
            collapsed = collapse
          }
        }
        if(!collapsed){
          // these go into teh second row
          let cols = config.headers[header].cols
          // set the colspan width of the superheader
          colSpan = cols.length
          for(let j=0;j<cols.length;j++){
            let templateName = cols[j].template
            if(typeof templateName === "undefined"){continue}
            let template = config.templates[templateName]
            if(typeof template === "undefined"){continue}

            // pushing it into the col order
            colOrder.push(cols[j])
            colAll.push(cols[j])

            let headerText = cols[j].header
            if(typeof cols[j].custom2Header !== "undefined"){
              headerText = cols[j].custom2Header(cols[j].header)
            }
            // now add to teh second header array
            headersTmp["2"].push(
              <th
                style={{cursor: "pointer", padding:5}}
                className={template.className}
                onClick={()=>{this.setSort(cols[j].accessor)}}
              >
                {headerText}
              </th>
            )


            if(typeof config.filter !== "undefined"){
              if(cols[j].accessor !== ""){
                headersTmp["search"].push(
                  <th
                    className={config.filter.className}
                    style={{padding:"0px"}}
                  >
                    <input
                      style={{width:"100%",margin:0 }}
                      type={"text"}
                      value={this.state.filter[cols[j].accessor]}
                      onChange={this.onChange}
                      data-path={["filter", cols[j].accessor]}
                    />
                  </th>
                )
              }else{
                headersTmp["search"].push(
                  <th
                    className={config.filter.className}
                    style={{ padding: '0px' }}
                  />
                )
              }
            }
          }
        }else{
          colOrder.push({
            "template": "",
            "header": "",
            "accessor": ""
          })

          // these go into teh second row
          let cols = config.headers[header].cols
          for(let j=0;j<cols.length;j++){
            let templateName = cols[j].template
            if(typeof templateName === "undefined"){continue}
            let template = config.templates[templateName]
            if(typeof template === "undefined"){continue}

            colAll.push(cols[j])
          }
        }

        // now pop the overall header into the first array
        if(collapsable){
          if(collapsed){
            headersTmp["1"].push(
              <th
                style={{cursor: "pointer", padding:5}}
                className={className}
                colSpan={colSpan}
                onClick={()=>{this.setCollapse(header)}}
                title={header}
              >
                &#8250;
              </th>
            )

            headersTmp["2"].push(
              <th/>
            )
            if(typeof config.filter !== "undefined"){
              headersTmp["search"].push(
                <th/>
              )
            }
          }else{
            // not collapsed
            headersTmp["1"].push(
              <th
                style={{cursor: "pointer", padding:5}}
                className={className}
                colSpan={colSpan}
                onClick={()=>{this.setCollapse(header)}}
                title={header}
              >
                &#8249; {header}
              </th>
            )
          }
        }else{
          headersTmp["1"].push(
            <th
              style={{cursor: "pointer"}}
              className={className}
              colSpan={colSpan}
              onClick={()=>{this.setCollapse(header)}}

            >
              {header}
            </th>
          )
        }
      }
    }

    // now build the headers for teh table
    let headers = []
    for(let i=1;i<headersTmp.count+1;i++){
      headers.push(
        <tr>
          {headersTmp[i]}
        </tr>
      )
    }

    if(typeof config.filter !== "undefined") {
      if (config.filter.active) {
        headers.push(
          <tr>
            {headersTmp["search"]}
          </tr>
        )
      }
    }

    if(this.state.colOrder.length !== colOrder.length){
      this.setState({colOrder:colOrder})
    }

    return [headers, colOrder, colAll]
  }

  getRows = (colOrder, data, config, page, colsToDisplay) =>{
    if(typeof colOrder === "undefined"){return []}
    if(colOrder.length === 0){return []}
    if(typeof data === "undefined"){return []}
    if(typeof config === "undefined"){return []}
    if(typeof config.templates === "undefined"){return []}

    let body = []
    let max = data.length
    let start = page * colsToDisplay
    let end = (page+1) * colsToDisplay
    if(end > max){
      end = max
    }
    for(let i=start;i<end;i++){
      let rowArray = []
      let dataRow = data[i]
      // iterate through the colOrder to get the ordering right

      for(let j=0;j<colOrder.length;j++){
        let col = colOrder[j]

        if(col.template === ""){
          rowArray.push(<td
            style={{"max-width": "5px", margin:0, padding:0}}
          />)
          continue
        }
        let templateName = col.template
        if(typeof templateName === "undefined"){continue}
        let template = config.templates[templateName]
        if(typeof template === "undefined"){continue}

        let accessor = col.accessor
        let className = template.className
        let style = template.style || {}
        if(typeof style.padding === "undefined"){
          style.padding = 5
        }
        if(typeof template.width !== "undefined"){
          style["min-width"] = template.width + "px"
          style["width"] = template.width + "px"
          style["max-width"] = template.width + "px"
          style["text-overflow"] = "ellipsis"
          style["overflow"] = "hidden"
          style["white-space"] = "nowrap"

          // overflow: hidden;
          // white-space: nowrap;

          // text-overflow: ellipsis;
        }
        if(typeof dataRow[accessor]=== "undefined"){
          rowArray.push(<td
            className={className}
            style={style}
          />)
          continue
        }
        if(typeof template.contents === "undefined"){
          rowArray.push(
            <td
              className={className}
              style={style}
            >
              {dataRow[accessor]}
            </td>
          )
        }else{
          let result = template.contents(dataRow, accessor)
          let cell
          if(typeof result.contents !== "undefined"){
            cell = result.contents
          }else{
            cell = result
          }
          if(typeof result.style !== "undefined"){
            let keys = Object.keys(result.style)
            for(let k=0;k<keys.length;k++){
              style[keys[k]] = result.style[keys[k]]
            }
          }

          rowArray.push(
            <td
              className={className}
              style={style}
            >
              {cell}
            </td>
          )
        }

      }

      body.push(
        <tr>
          {rowArray}
        </tr>
      )
    }
    return body
  }

  getStyle = (config) =>{
    if(typeof config === "undefined"){return {}}
    let style = {}
    style["overflow-x"] = "auto"
    style["white-space"] = "nowrap"
    style["margin"] = "0 auto"
    if(typeof config.width  !== "undefined"){
      style["width"] = config.width
    }
    return style
  }

  setSort = (accessor) =>{
    let sort = this.state.sort
    if(accessor === sort.col){
      sort = {col:accessor, desc:!sort.desc}
    }
    if(accessor !== sort.col){
      sort = {col:accessor, desc:true}
    }
    if(typeof this.props.callbackToParent !== "undefined"){
      this.props.callbackToParent(
        {
          sort:sort,
          filter: this.state.filter,
          collapse: this.state.collapse,
          colOrder: this.state.colOrder,
          identifier: this.state.identifier,
        }
      )
    }
    this.setState({sort:sort})
  }

  getSort = (data, config, sort, colOrder, colAll) =>{
    data = [...data]

    if(typeof config === "undefined"){return data}
    if(typeof sort === "undefined"){return data}
    if(typeof colAll === "undefined"){return data}

    let accessor = sort.col
    if(typeof accessor === "undefined"){return data}
    if(accessor === ""){return data}

    let templateObject = colAll.filter(item => item.accessor === accessor)
    if(templateObject.length === 0){return data}

    let templateName = templateObject[0].template
    if(typeof templateName === "undefined"){return data}

    let template = config.templates[templateName]
    if(typeof template === "undefined"){return data}

    // see if that col has a custom2 sort
    // if it does not use the default
    if(typeof template.sort === "undefined"){
      data.sort((a, b) => a[accessor] !== b[accessor] ? a[accessor] < b[accessor] ? -1 : 1 : 0)
    }
    // if it has then use the custom2
    if(typeof template.sort !== "undefined"){
      data.sort((a, b) => template.sort(a[accessor], b[accessor]))
    }
    if(sort.desc){
      data.reverse()
    }
    return data
  }

  getFilter = (data, config, filter, colOrder) => {
    if(typeof config === "undefined"){return data}
    if(typeof config.filter === "undefined"){return data}
    if(typeof config.filter.active === "undefined"){return data}
    if(typeof config.templates === "undefined"){return data}
    if(typeof colOrder === "undefined"){return data}
    if(!config.filter.active){return data}
    let filters = Object.keys(filter)

    if(filters.length === 0){return data}
    for(let i=0;i<filters.length;i++){
      let accessor = filters[i]

      if(filter[accessor] === ""){continue}

      let selected = colOrder.filter(item => item.accessor === accessor)
      if(selected.length === 0){continue}

      let templateName = selected[0].template
      if(typeof templateName === "undefined"){continue}

      let template = config.templates[templateName]
      if(typeof template === "undefined"){continue}

      if(typeof template.filter === "undefined"){
        data = data.filter((item)=> filterGeneralText(item[accessor],filter[accessor]))
      }

      if(typeof template.filter !== "undefined"){
        data = data.filter((item) => template.filter(item[accessor], filter[accessor]))
      }
    }
    return data
  }

  createTable = () =>{
    let data = this.state.data || []
    let config = this.state.config || {}
    let className = config.className || {}
    let page = this.state.page
    let colsToDisplay = this.state.colsToDisplay
    let sort = this.state.sort
    let filter = this.state.filter
    let showButtons
    if(typeof config.showButtons === "undefined"){
      showButtons =  true
    }else{
      showButtons = config.showButtons
    }
    let [headers, colOrder,colAll] = this.getHeaders(config)
    data = this.getFilter(data, config, filter, colOrder)
    data = this.getSort(data, config, sort, colOrder, colAll)

    let body = this.getRows(colOrder, data, config, page, colsToDisplay)
    let style = this.getStyle(config)
    let buttons
    if(showButtons){
      buttons = this.getButtons(data, page, colsToDisplay, config, className)
    }

    return <div>
      <div
        style={style}
      >
        <table
          className={className.table}
        >
          <thead>
          {headers}
          </thead>
          <tbody>
          {body}
          </tbody>
        </table>
      </div>
      {buttons}
    </div>
  }

  setCollapse = (header) => {
    let collapse = this.state.collapse
    if(typeof collapse[header] !== "undefined"){
      collapse[header] = !collapse[header]
    }
    if(typeof collapse[header] === "undefined"){
      collapse[header] = true
    }
    if(typeof this.props.callbackToParent !== "undefined"){
      this.props.callbackToParent({
        sort:this.state.sort,
        filter: this.state.filter,
        collapse: collapse,
        colOrder: this.state.colOrder,
        identifier: this.state.identifier,
      })
    }
    this.setState({collapse:collapse})
  }

  getButtons =(data, page, colsToDisplay, config, className)=>{

    // btn btn-primary
    let display = <input
      className={className.input}
      style={{width:"50px"}}
      type={"number"}
      value={this.state.colsToDisplay}
      onChange={this.onChange}
      data-path={["colsToDisplay"]}
      name={"colsToDisplay"}
    />

    let back = <input
      className={className.button}
      //style={{width:"100%",margin:0 }}
      disabled={(page <= 0)}
      type={"submit"}
      value={"Back"}
      onClick={()=>{this.setState({page:page-1})}}
    />

    let maxPage = Math.floor(data.length/colsToDisplay)
    let forward = <input
      className={className.button}
      //style={{width:"100%",margin:0 }}
      disabled={!(page < maxPage)}
      type={"submit"}
      value={"Forward"}
      onClick={()=>{this.setState({page:page+1})}}
    />
    // style["margin"] = "0 auto"
    return <div
      style={{margin:"0 auto"}}
    >
      {back}
      {display}
      {forward}
    </div>
  }

  // Reusable for all inputs
  onChange = e => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name
    let path = e.target.dataset.path.split(",") || []

    if (path.length <= 1) {
      this.setState({ [name]: value })
    } else {
      let element = this.state[path[0]]
      if (path.length === 2) {
        element[path[1]] = value
      }
      if (path.length === 3) {
        element[path[1]][path[2]] = value
      }
      if (path.length === 4) {
        element[path[1]][path[2]][path[3]] = value
      }
      if (path.length === 5) {
        element[path[1]][path[2]][path[3]][path[4]] = value
      }

      if(path[0] === "filter"){
        if(typeof this.props.callbackToParent !== "undefined"){
          this.props.callbackToParent({
            sort:this.state.sort,
            filter: element,
            collapse: this.state.collapse,
            colOrder: this.state.colOrder,
            identifier: this.state.identifier,
          })
        }
      }
      this.setState({ [path[0]]: element })
    }
  }

  render() {
    return this.createTable()
  }
}

export class GetCurrency extends Component {
  constructor(props) {
    super(props);

    this.state = { number:props.number, size:props.size };
  }

  componentWillReceiveProps(props) {
    let tmp = {
      number: props.number,
      size: props.size,
    }
    this.setState(tmp)
  }

  getCurrency = (number,size) => {
    // set defaults
    if (typeof number === "undefined") {number = 0}
    if (typeof size === "undefined") {size = 25}

    // check if its a string
    if (!isNaN(number - 0)) {number = number - 0}
    // if it isnt a number at all set it to 0
    if (isNaN(number)) {number = 0}

    // format it into gsc
    let formatted = convertToGold(number)

    let minus, gold, silver, copper
    let goldImg, silverImg, copperImg
    let currencyArray = formatted.split(/([0-9]+)/).filter(Boolean)
    for (let i = 0; i < currencyArray.length; i += 2) {
      if (currencyArray[i] === "-") {
        minus = "-"
        i -= 1
        continue
      }
      if (currencyArray[i + 1] === "g") {
        gold = currencyArray[i]
        goldImg = <img
          alt={"g"}
          style={{ width: size, height: size }}
          src={`${darth_base}/090A980A96D39FD36FBB004903644C6DBEFB1FFB/156904.png`}
        />
      }
      if (currencyArray[i + 1] === "s") {
        silver = currencyArray[i]
        silverImg = <img
          alt={"s"}
          style={{ width: size, height: size }}
          src={`${darth_base}/E5A2197D78ECE4AE0349C8B3710D033D22DB0DA6/156907.png`}
        />
      }
      if (currencyArray[i + 1] === "c") {
        copper = currencyArray[i]
        copperImg = <img
          alt={"c"}
          style={{ width: size, height: size }}
          src={`${darth_base}/6CF8F96A3299CFC75D5CC90617C3C70331A1EF0E/156902.png`}
        />
      }
    }
    return <span title={formatted}>{minus}{gold}{goldImg} {silver}{silverImg} {copper}{copperImg}</span>
  }

  render() {
    return this.getCurrency(this.state.number,this.state.size);
  }
}


export function useQuery(hash) {
  let query = hash.substring(hash.indexOf("?"));
  return new URLSearchParams(query);
}

export const getQueryObject = (search) => {
  let tmp = {};
  let query = useQuery(search);
  for (const [key, value] of query.entries()) {
    tmp[key] = value
  }

  return tmp
}