import React, { Component } from 'react';
import { urlBase } from '../_utiilities/data.json'
import { filterGeneral, getRarityColour, sortGeneral } from '../_utiilities/functions'
import { Documentation, GetCurrency, Loading, SilverTable, } from '../_utiilities/functions_react'

export class Extracting extends Component {
  constructor (props) {
    super(props);
    this.state = {
      craftingPrice:undefined,
      sellPrice: undefined
    }
  }

  async componentDidMount () {
    let tmp = {}
    tmp.rawItemData = await this.loadData(urlBase.parser + "/v1/items/json?fields=id,img,name,rarity,level,buy_price,sell_price,upgrade1&beautify=min")
    tmp.priceData = {}
    for (let i = 0; i < tmp.rawItemData.length; i++) {
      tmp.priceData[tmp.rawItemData[i].id] = tmp.rawItemData[i]
    }
    this.setState(tmp)
  }

  async loadData (url) {return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))}

  getPriceData = (id, items) =>{
    id = id - 0
    let data = { buy_price: 0, sell_price: 0, name: "", img: undefined, AccountBound: true }
    let result = items[id]
    if (typeof result !== "undefined") {
      data = result
    }
    return data
  }

  processData = (rawItemData,priceData)=>{
    let data = []
    for(let i=0;i<rawItemData.length;i++){
      if(typeof rawItemData[i].upgrade1 === "undefined"){continue}
      let tmp = Object.assign({}, rawItemData[i])
      let upgrade = priceData[rawItemData[i].upgrade1]

      if(typeof upgrade === "undefined"){
        continue
      }

      tmp.upgradeName = upgrade.name
      tmp.upgradeRarity = upgrade.rarity
      tmp.upgradeImg = upgrade.img

      tmp.upgradeSell = upgrade.sell_price || 0
      tmp.upgradeBuy = upgrade.buy_price || 0

      let buyPrice = tmp.buy_price || 0
      if(this.state.craftingPrice === "Sell"){
        buyPrice = tmp.sell_price || 0
      }

      let sellPrice = upgrade.sell_price || 0
      if(this.state.sellPrice === "Buy"){
        sellPrice = upgrade.buy_price || 0
      }
      tmp.profit = (sellPrice *0.85) - buyPrice
      tmp.profitPer = (tmp.profit/buyPrice).toFixed(2) +"%"

      data.push(tmp)
    }
    return data
  }


  tableManager = (data) => {
    let square = 25
    //let textWidth = 300

    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "text": {
          className: "left",
        },
        "textImg": {
          contents:(item, accessor)=>{
            let name, rarity, img
            if(accessor === "name"){
              name = item.name;
              rarity = item.rarity
              img = item.img
            }
            if(accessor === "upgradeName"){
              name = item.upgradeName;
              rarity = item.upgradeRarity
              img = item.upgradeImg
            }

            let rarityColour = getRarityColour(rarity)

            let image = <img
              key={name}
              style={{ width: square,height:square, border: "1px solid "+ rarityColour }}
              src={img}
              title={name}
              alt={name}
            />

            return <span title={name + " - " + rarity} style={{color: rarityColour}} >{image} {name}</span>
          },
          className: "left",
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "percent": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "gold": {
          className: "right",
          contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
          sort: sortGeneral,
          filter: (item, filter) => filterGeneral(item, filter, 10000)
        }
      },
      colsToDisplay: 20,
      filter: { active: true },
      sort: { col: "name", desc: false },
      headers: {
        "Item": {
          className:"left",
          cols: [
            { template: "textImg", header: "Name", accessor: "name" },
            { template: "number", header: "Level", accessor: "level" },
            { template: "text", header: "Rarity", accessor: "rarity" },
            { template: "gold", header: "Buy", accessor: "buy_price" },
            { template: "gold", header: "Sell", accessor: "sell_price" },
          ]
        },
        "Upgrade": {
          className:"left",
          cols: [
            { template: "textImg", header: "Name", accessor: "upgradeName" },
            { template: "gold", header: "Buy", accessor: "upgradeBuy" },
            { template: "gold", header: "Sell", accessor: "upgradeSell" },
          ]
        },
        "Profit": {
          className:"left",
          cols: [
            { template: "gold", header: "Profit", accessor: "profit" },
            { template: "percent", header: "Profit %", accessor: "profitPer" },
          ]
        },
      },
      headerOrder:["Item","Upgrade","Profit"],
    }

    return this.createTable(data, config)
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
    />
  }

  toggleCreator = (title,entry,states, defaultState) =>{
    if(typeof entry !== "string"){return null}
    if(states.length === 0){return null}

    let selected, old
    if(states.length >0){
      if(typeof this.state[entry] === "undefined"){
        if(typeof defaultState === "undefined"){
          old = states[0]
          let tmp = {}
          tmp[entry] = states[0]
          this.setState(tmp)
        }else{
          old = defaultState
          let tmp = {}
          tmp[entry] = defaultState
          this.setState(tmp)
        }

      }else{
        old = this.state[entry]
      }
      let position = states.indexOf(old) +1
      if(position >= states.length){
        selected = states[0]
      }else{
        selected = states[position]
      }
    }

    let text = []
    for(let i=0;i<states.length;i++){
      if(text.length >0){
        text.push(" / ")
      }
      if(old === states[i]){
        text.push(<b>{states[i]}</b>)
      }else{
        text.push(states[i])
      }
    }

    return <span
      style={{cursor: "pointer"}}
      onClick={
        ()=>{
          let tmp = {}
          tmp[entry] = selected
          this.setState(tmp)
        }
      }
    >{title}: {text}</span>
  }

  render () {
    if (typeof this.state.rawItemData === "undefined") {return <Loading/>}
    if (typeof this.state.priceData === "undefined") {return <Loading/>}

    return <div>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#extracting"} />
      <br/>
      <h3>Extracting</h3>
      <div>
        {this.toggleCreator("Item Price","craftingPrice", ["Buy", "Sell"], "Buy")}
        &emsp;
        {this.toggleCreator("Upgrade Price","sellPrice", ["Buy", "Sell"], "Sell")}
      </div>
      <br />
      {
        this.tableManager(this.processData(this.state.rawItemData,this.state.priceData))
      }
    </div>;
  }
}