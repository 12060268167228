import React, { Component } from 'react';
import { urlBase } from '../_utiilities/data.json'
import { Documentation, GetCurrency, Loading, SilverTable, } from '../_utiilities/functions_react'
import { filterGeneral, sortGeneral } from '../_utiilities/functions'

export class Snatch_It extends Component {
    constructor(props) {
        super(props);
        this.state = {apiData: [], tableData:[]};
    }

    async loadData(url) {
        return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))
    }

    async componentDidMount() {
        let baseData = {};
        baseData.apiData = await this.loadData(urlBase.parser + "/v1/items/json?fields=id,name,1m_sell_sold,snatchIt&beautify=min");
        baseData.tableData = this.apiToTable(baseData.apiData);
        this.setState(baseData);
    }

      apiToTable = (apiData) =>{
        let tableData = []
        for(let i=0;i<apiData.length;i++){
            if(typeof apiData[i].snatchIt !== "undefined"){
                let tmp = {};
                tmp.name        = apiData[i].name;
                tmp.buy         = apiData[i].snatchIt.buy;
                tmp.sell        = apiData[i].snatchIt.sell;
                tmp.quantity    = apiData[i].snatchIt.quantity;
                tmp.profit      = ((tmp.sell * 0.85) - tmp.buy) * tmp.quantity;
                tmp.profitPer   = tmp.profit/(tmp.buy * tmp.quantity);
                tmp.eta         = tmp.quantity/(apiData[i]["1m_sell_sold"]/30);
                tmp.profitDay   = tmp.profit/tmp.eta;

                if(tmp.profitPer > 0 && tmp.eta !== Infinity){
                    tmp.profitPer   = tmp.profitPer.toFixed(2) + '%'
                    tmp.eta = tmp.eta.toFixed(3)
                    tableData.push(tmp)
                }
            }
        }
        return tableData;
    }

    tableManager = (data) => {
        let config = {
            className: {
                table: "centerTable table-primary table-striped table-highlight",
            },
            templates: {
                "text": {
                    className: "left",
                },
                "number": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "percent": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "gold": {
                    className: "right",
                    contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
                    sort: sortGeneral,
                    filter: (item, filter) => filterGeneral(item, filter, 10000)
                }
            },
            colsToDisplay:20,
            filter: {active:true},
            sort:{ col: "profit", desc: true },
            headers: {
                "": {
                    cols: [
                        { template: "text", header: "Name", accessor: "name" },
                    ]
                },
                "Buy Info": {
                    cols: [
                        { template: "gold", header: "Buy", accessor: "buy" },
                        { template: "number", header: "Quantity", accessor: "quantity" },
                        { template: "gold", header: "Sell", accessor: "sell" },
                    ]
                },
                "Sell Time": {
                    cols: [
                        { template: "number", header: "Days", accessor: "eta" },
                    ]
                },
                "Profits": {
                    cols: [
                        { template: "gold", header: "Profit", accessor: "profit" },
                        { template: "percent", header: "Profit %", accessor: "profitPer" },
                        { template: "gold", header: "Profit/Day", accessor: "profitDay" },
                    ]
                }
            },
            headerOrder:["","Buy Info", "Sell Time", "Profits"]
        }
        return this.createTable(data, config)
    }

    createTable = (data, config) => {
        return <SilverTable
          data={data}
          config={config}
        />
    }

    render() {
        if(this.state.tableData.length === 0){return <Loading/>}
        return <div>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#snatch-it"} />
            <br/>
            <div><h3>Silveress's Snatch-it</h3></div>
            {this.tableManager(this.state.tableData)}
        </div>
    }
}
