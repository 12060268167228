import React, {Component} from 'react';
import cookie from "react-cookies";
import {Redirect} from 'react-router-dom'
import { urlBase } from '../_utiilities/data.json'
import { Documentation } from '../_utiilities/functions_react'

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user:undefined,
            pass:undefined,
            email:undefined,
            session:cookie.load('session'),
            error:undefined
        };
    }

    onChange = e => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        let name = e.target.name
        this.setState({ [name]: value })
    }

    handleSubmit = async (event) =>{
        event.preventDefault();
        if(
          typeof this.state.user === "undefined" ||
          typeof this.state.pass === "undefined"
        ){return}

        let submitUrl = urlBase.account + "/v1/website/login";
        await fetch(submitUrl, {method: 'GET', headers: {'Content-Type': 'application/json', password: this.state.pass, user:this.state.user}})
        .then(async (response)=> {
            let body = await response.json();

            if (body.session) {
                cookie.save('session', body.session, {maxAge: 2147483647 })
                location.reload();
            }
            if (body.error) {
                alert(`45: ${body.error}`)
            }
        })
        .catch((err) => {alert(`49: ${err}`)});
    }

    form = () => {
        return <form onSubmit={this.handleSubmit} style={{align:"center", margin:"0 auto", display: "table"}}>
            <p>
                <label>Username: </label>
                <input
                  style={{margin:0, width:300}}
                  type="text"
                  required
                  value={this.state.user}
                  onChange={this.onChange}
                  name={"user"}
                  placeholder={"Username"}
                />
            </p>

            <p>
                <label>Password: </label>
                <input
                  style={{margin:0, width:300}}
                  type="password"
                  required
                  value={this.state.pass}
                  onChange={this.onChange}
                  name={"pass"}
                  placeholder={"Password"}
                />
            </p>

            <p>
                <label/>
                <input type="submit" value="Login"/>
            </p>

        </form>
    }

    info = () =>{
        return <div>
            <p>
                Login to your DataWars2 Account.
            </p>
        </div>
    }

    error = () => {
        let error
        if (typeof this.state.error !== "undefined") {
            error = <p>
                Error: {this.state.error}
            </p>
        }
        return error
    }

    render() {
        if(typeof this.state.session !== "undefined"){return <Redirect to='/account' />}

        return <div style={{align:"center"}}>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/management#login"} />
            <br/>
            {this.info()}
            {this.form()}
            <br />
            {this.error()}
        </div>
    }
}