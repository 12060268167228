import React, { Component } from 'react';
import { urlBase } from '../_utiilities/data.json'
import { Documentation, GetCurrency, Loading, SilverTable, } from '../_utiilities/functions_react'
import { loadData, sortGeneral, filterGeneral } from '../_utiilities/functions'
import {Account} from "../_utiilities/entities";

interface ItemData {
    id: number;
    name: string;
    buy_price: number;
    buy_quantity: number;
    sell_price: number;
    sell_quantity: number;
    "12m_buy_price_min": number;
    "12m_sell_price_min": number;
    [index: string]: string | number;
}
interface SkinData {
    id: number;
    name: string;
    [index: string]: string | number;
}
interface ProcessedAccountSkins {
    [index: string]: boolean
}

interface Output {
    collection: string;
    count: number;
    skins: string[];
    orderBuy: number;
    orderBuyMin: number;
    orderSellMin: number;
    instantBuy: number;

    orderAvg: number;
    instantAvg: number;
    tickets: number;
    ticketBuy: number;
    ticketSell: number;

    // for if the user is logged in and select an account
    accountBuy: number;
    accountSell: number;

    accountBuyTicket: number;
    accountSellTicket: number;
}
interface BLTC_Tickets_props {
    account?: Account;
    session?: string;
}
interface BLTC_Tickets_state {
    itemData: ItemData[];
    skinData: SkinData[];
    account?: Account;
    account_skins: number[];
    selectedAccount: SelectedAccount;
}
interface SelectedAccount {
    name: string;
    key: string;
}

interface TableConfig {
    className: {
        table: string;
    };
    templates: {
        [propName: string]: {
            className?: string;
            sort?: any;
            contents?: any;
            filter?: any;
        }
    };
    headers: {
        [propName: string]: {
            className: string;
            collapse? : boolean;
            cols: TableConfigCols[]
        }
    };
    filter: {
        active:boolean;
    };
    sort: {
        col: string;
        desc: boolean;
    };
    headerOrder: string[]
}
interface TableConfigCols {
    template: string;
    header: string;
    accessor: string;
}

export class BLTC_Tickets extends Component <BLTC_Tickets_props, BLTC_Tickets_state> {
    constructor (props: BLTC_Tickets_props) {
        super(props);
        this.state = {
            itemData: [],
            skinData: [],
            account: props.account,
            account_skins: [],
            selectedAccount: {
                name: "",
                key: ""
            }
        }
    }

    // will need this in all sub components
    async componentDidUpdate(prevProps: Readonly<BLTC_Tickets_props>, prevState: Readonly<BLTC_Tickets_state> ) {
        if (typeof prevProps.account === "undefined"  && typeof this.props.account !== "undefined"){
            this.setState({ account: this.props.account })
        }
        // when the selectedAccount is updated, excluding going back to the title one
        let { selectedAccount } = this.state
        if(
            selectedAccount.name !== prevState.selectedAccount.name &&
            selectedAccount.name !== ""
        ){
            let account_skins: number[] = await loadData( `https://api.guildwars2.com/v2/account/skins?access_token=${selectedAccount.key}`, undefined, []);
            this.setState({account_skins: account_skins})
        }
    }

    async componentDidMount () {
        let itemData: ItemData[] = await loadData(urlBase.parser + "/v1/items/json?beautify=min&fields=description,12m_buy_price_min,12m_sell_price_min,id,name,buy_quantity,buy_price,sell_quantity,sell_price&filter=marketable:TRUE,type:Consumable,rarity:Rare,weaponType:Transmutation",undefined,  []);
        let skinData: SkinData[] = await loadData( `${urlBase.parser}/v1/skins/json?filter=type:Weapon&fields=id,name`, undefined, []);
        this.setState({itemData: itemData, skinData: skinData})
    }

    reduceForgingData (): Output[] {
        let {itemData} = this.state;
        let processedAccountSkins = this.processAccountSkins()
        const exceptionNames = [
            "Lightning Catcher",
            "Toxic Spore",
            "Sun Catcher",
            "Desert Rose",
            "Wind Catcher",
            "Tiki Totem",
            "Shark's Tooth",
            "Consortium Clipper",
            "Zephyr Rucksack",
            "Chiroptophobia",
            "Araneae",
            "Dead Stop",
            "Bloody Prince",
            "Silly Scimitar",
            "Kaiser Snake",
            "Super",
            "Decorative Molten Jetpack",
            "Holographic Dragon Wing Cover",
            "Southsun Mask",
            "Winter's"
        ];
        const toybox = [
            "Silly Scimitar Skin",
            "Dead Stop Shield Skin",
            "Bloody Prince Staff Skin",
            "Chiroptophobia Greatsword Skin",
            "Araneae Longbow Skin"
        ];
        const weapons = [
            "Greatsword",
            "Staff",
            "Longbow",
            "Warhorn",
            "Dagger",
            "Torch",
            "Focus",
            "Hammer",
            "Scepter",
            "Axe",
            "Short",
            "Long",
            "Bow",
            "Rifle",
            "Mace",
            "Pistol",
            "Sword",
            "Shield",
            "Trident",
            "Aspect",
            "Spear",
            "Harpoon",
            "Gun",
            "Blunderbuss",
            "Cudgel",
            "Call",
            "Avenger",
            "Truncheon",
            "Warhammer",
            "Needler",
            "Anlace",
            "Flintlock",
            "Flame",
            "Hornbow",
            "Kris",
            "Sting",
            "Slice",
            "Brunt",
            "Edge",
            "Timber",
            "Harbinger",
            "Protector",
            "Cutter",
            "Shelter",
            "Wall",
            "Lacerator",
            "Reaver",
            "Needle",
            "Quarterstaff",
            "Reach",
            "Arc"
        ];

        let output: Output[] = [];

        for (let i = 0; i < itemData.length; i++) {
            let name = itemData[i].name;
            //if (name.includes("Skin") || name.includes("skin")) {
                let collection0 = name.split(" ");
                let collection = [];

                for (let ii = 0; ii < collection0.length; ii++) {
                    let item = collection0[ii];
                    if (item !== "Skin" && item !== "skin" ) {
                        // Ice Reaver

                        if(weapons.indexOf(item) === -1){
                            collection.push(item);
                        }else{
                            // this weapon name is used as a colelction name too
                            if(item === "Reaver"){
                                if(collection.length >0){
                                    if(collection.indexOf("Ice") !== -1){
                                        collection.push(item);
                                    }
                                }
                            }

                        }




                    }
                }

                if (exceptionNames.indexOf(collection.join(" ")) === -1) {
                    output = this.addToOutput(output, itemData[i], collection.join(" "), processedAccountSkins)
                }
                if (toybox.indexOf(name) !== -1) {
                    output = this.addToOutput(output, itemData[i], "Bloody Prince's Toybox", processedAccountSkins)
                }
            //}
        }
        return output;
    }

    processAccountSkins = (): ProcessedAccountSkins =>{
        let {skinData, account_skins} = this.state;

        let skinData_processed: {[index: number]: any} = {}
        for (let skinData_item of skinData){
            let name = skinData_item.name;

            if (skinData_item.name == "Dead Stop"){
                name = "Dead Stop Shield"
            }
            if (skinData_item.name == "Araneae"){
                name = "Araneae Longbow"
            }
            if (skinData_item.name == "Chiroptophobia"){
                name = "Chiroptophobia Greatsword"
            }

            skinData_processed[skinData_item.id] = name
        }

        let processedAccountSkins: ProcessedAccountSkins = {}
        for (let account_skin of account_skins){
            let name = skinData_processed[account_skin]
            processedAccountSkins[name] = true
        }

        return processedAccountSkins
    }

    addToOutput = (output: Output[], item: ItemData, collection: string, processedAccountSkins: ProcessedAccountSkins): Output[] =>  {
        let objIndex = output.findIndex((x => x.collection === collection));

        if (objIndex === -1) {
            output.push({
                collection: collection,
                count: 0,
                skins: [],
                orderBuy: 0,
                orderBuyMin: 0,
                orderSellMin: 0,
                instantBuy: 0,
                orderAvg: 0,
                instantAvg: 0,
                tickets: 0,
                ticketBuy: 0,
                ticketSell: 0,
                accountBuy: 0,
                accountSell: 0,
                accountBuyTicket: 0,
                accountSellTicket: 0

            });
            objIndex = output.findIndex((x => x.collection === collection));
        }

        output[objIndex].count = output[objIndex].count + 1;
        output[objIndex].skins.push(item.name);
        output[objIndex].orderBuy = output[objIndex].orderBuy + item.buy_price;

        if (item["12m_buy_price_min"] <= item.buy_price) {
            output[objIndex].orderBuyMin = output[objIndex].orderBuyMin + item["12m_buy_price_min"];
        } else {
            output[objIndex].orderBuyMin = output[objIndex].orderBuyMin + item.buy_price;
        }
        if (item["12m_sell_price_min"] <= item.sell_price) {
            output[objIndex].orderSellMin = output[objIndex].orderSellMin + item["12m_sell_price_min"];
        } else {
            output[objIndex].orderSellMin = output[objIndex].orderSellMin + item.sell_price;
        }

        output[objIndex].instantBuy = output[objIndex].instantBuy + item.sell_price;

        output[objIndex].orderAvg = output[objIndex].orderBuy / output[objIndex].count;
        output[objIndex].instantAvg = output[objIndex].instantBuy / output[objIndex].count;

        output[objIndex].tickets = 7;

        if (output[objIndex].count === 10) {
            output[objIndex].tickets = 5;
        }
        if (output[objIndex].count === 5) {
            output[objIndex].tickets = 5;
        }

        output[objIndex].ticketBuy = output[objIndex].orderBuy / output[objIndex].tickets;
        output[objIndex].ticketSell = output[objIndex].instantBuy / output[objIndex].tickets;

        let name_stripped = item.name.replace(" Skin", "").trim();
        if (typeof processedAccountSkins[name_stripped] === "undefined"){
            // personalised cost
            output[objIndex].accountBuy += item.buy_price;
            output[objIndex].accountSell += item.sell_price;

            // ticket cost
            output[objIndex].accountBuyTicket = output[objIndex].accountBuy / output[objIndex].tickets;
            output[objIndex].accountSellTicket = output[objIndex].accountSell / output[objIndex].tickets;
            //accountBuyTicket

        }

        return output
    }

    createTable = () => {
        let data = this.reduceForgingData()

        let config: TableConfig = {
            className: {
                table: "centerTable table-primary table-striped table-highlight table-hover",
            },
            templates: {
                "text": {
                    className: "left",
                },
                "number": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral,
                },
                "gold": {
                    className: "right",
                    contents: (item: ItemData, accessor: string) => <GetCurrency number={item[accessor]} size={25} />,
                    sort: sortGeneral,
                    filter: (a:any,b:any) => filterGeneral(a,b, 10000),
                }
            },
            headers: {
                "Collection Info": {
                    className: "center",
                    cols: [
                        { template: "text", header: "Collection", accessor: "collection" },
                        { template: "number", header: "Skins", accessor: "count" },
                        { template: "number", header: "Tickets", accessor: "tickets" }
                    ],
                },
                "Lowest Cost (Year)": {
                    collapse: true,
                    className: "center",
                    cols: [
                        { template: "gold", header: "Buy Order", accessor: "orderBuyMin" },
                        { template: "gold", header: "Instant Buy", accessor: "orderSellMin" },
                    ],
                },
                "Skin Cost": {
                    collapse: true,
                    className: "center",
                    cols: [
                        { template: "gold", header: "Buy Order", accessor: "orderBuy" },
                        { template: "gold", header: "Instant Buy", accessor: "instantBuy" },
                    ],
                },
                "Ticket Cost": {
                    collapse: true,
                    className: "center",
                    cols: [
                        { template: "gold", header: "Buy Order", accessor: "ticketBuy" },
                        { template: "gold", header: "Instant Buy", accessor: "ticketSell" },
                    ],
                },
            },
            filter: {active:true},
            sort: { col: "collection", desc: false },
            headerOrder:["Collection Info","Lowest Cost (Year)","Skin Cost","Ticket Cost"],
        }

        let {account, selectedAccount} = this.state;
        if (account && selectedAccount.name !== ""){
            config.headers["Skin Cost"] = {
                collapse: true,
                className: "center",
                cols: [
                    { template: "gold", header: "Buy Order", accessor: "accountBuy" },
                    { template: "gold", header: "Instant Buy", accessor: "accountSell" },
                ],
            };
            //config.headerOrder.push("Skin Cost - Account")

            config.headers["Ticket Cost"] = {
                collapse: true,
                className: "center",
                cols: [
                    { template: "gold", header: "Buy Order", accessor: "accountBuyTicket" },
                    { template: "gold", header: "Instant Buy", accessor: "accountSellTicket" },
                ],
            };
            //config.headerOrder.push("Ticket Cost - Account")



        }

        return <SilverTable
          data={data}
          config={config}
        />
    }

    characterSelection = () => {
        let {account} = this.state;
        if(!account){return null}

        let dropdown = [<option key={""} value={""}>{"Select Account"}</option>];
        for (let name of Object.keys(account.gameAccounts)){
            dropdown.push(<option key={name} value={name}>{name}</option>)
        }
        return <select value={this.state.selectedAccount.name} onChange={this.handleCharacterSelection}>{dropdown}</select>
    }

    handleCharacterSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let {account} = this.state
        if (!account){return}

        let selectedAccount: SelectedAccount = {
            name: "",
            key: ""
        };

        let name = event.target.value
        if (name === ""){
            // also reset account_skins
            return this.setState({selectedAccount: selectedAccount, account_skins: []})
        }
        let key = account.gameAccounts[name].key
        selectedAccount.name = name;
        selectedAccount.key = key;

        this.setState({selectedAccount: selectedAccount})
    }

    render () {
        let { itemData } = this.state;

        if (itemData.length === 0) {return <Loading/>}

        return <div>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#bltc-tickets"} />
            <br/>
            <h3>Completing BLTC weapon collections for tickets.</h3>
            <h5>Use in conjunction with <a href="https://gw2efficiency.com/currencies/claim-tickets" target="_blank" rel="noopener noreferrer">https://gw2efficiency.com/currencies/claim-tickets</a>
            </h5>
            <br/>
            {this.characterSelection()}
            <br/>
            <br/>
            {this.createTable()}
        </div>;
    }
}