import React, { Component } from 'react'
import { urlBase } from '../_utiilities/data.json'
import {
  Documentation,
  GetCurrency,
  Loading,
  SilverTable,
} from '../_utiilities/functions_react'
import { filterGeneral, sortGeneral } from '../_utiilities/functions'
import {TextImg} from "./tp_textImg";

export class MC_Standard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemData: undefined
    }
  }

  async loadData(url) {return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))}

  async componentDidMount() {
    let tmp = {};
    let itemDataRaw = await this.loadData(urlBase.parser + "/v1/items/json?fields=id,name,buy_price,sell_price,img&beautify=min")
    tmp.itemData = {};
    for(let i=0;i<itemDataRaw.length;i++){
      tmp.itemData[itemDataRaw[i].id] = itemDataRaw[i];
    }

    this.setState(tmp)
  }

  processData = () =>{
    let itemData = this.state.itemData
    let ids = Object.keys(itemData)
    let rows = []
    let mcData = itemData["19976"] || {id:19976,name:"Mystic Coin",buy_price:0,sell_price:0,img:""}

    let exists = {}
    for(let i=0;i<ids.length;i++){
      let item = itemData[ids[i]]
      if(typeof item.name === "undefined"){continue}
      if(item.name === ""){continue}
      if(typeof exists[item.name] === "undefined"){
        // not entered before
        exists[item.name] = {
          buy:item.buy_price,
          sell:item.sell_price
        }
      }else{
        if(item.sell_price > exists[item.name].sell){
          exists[item.name].sell = item.sell_price
        }
        if(item.buy_price > exists[item.name].buy){
          exists[item.name].buy = item.buy_price
        }
      }
    }

    let exists2 = {}
    for(let i=0;i<ids.length;i++){
      let item = itemData[ids[i]]
      if(typeof item.name === "undefined"){continue}
      if(item.name === ""){continue}

      let maxPrice = exists[item.name]
      let buy = Math.ceil(item.buy_price/mcData.buy_price)
      let sell = Math.ceil(item.sell_price/mcData.buy_price)

      // if the max is greater than 0 then it means that there are items with a max sell price
      // only display oness above 0
      if(maxPrice.sell >0){
        if(item.sell_price > 0){
          rows.push(
            {
              id:item.id,
              name:item.name,
              img:item.img,
              buy:buy,
              sell:sell,
              recommend: Math.floor(sell * 0.9)
            }
          )
        }
      }
      // if not then display the max
      else{
        if(item.buy_price >= maxPrice.buy && typeof exists2[item.name] === "undefined"){
          rows.push(
            {
              id:item.id,
              name:item.name,
              img:item.img,
              buy:buy,
              sell:sell,
              recommend: Math.floor(sell * 0.9)
            }
          )
          exists2[item.name] = ""
        }
      }
    }
    return rows
  }

  processTable = () => {
    let data = this.processData()
    return this.tableManager(data)
  }

  currentMCPrice = () => {
    let mcData = this.state.itemData["19976"] || {id:19976,name:"Mystic Coin",buy_price:0,sell_price:0,img:""}
    let image = <img
      key={mcData.name}
      style={{width: 25,height:25}}
      src={mcData.img}
      title={mcData.name}
      alt={mcData.name}
    />
    let price = mcData.buy_price
    return <span title={mcData.name}>{image} {mcData.name} - Buy: <GetCurrency number={price} size={25} /></span>;
  }

  tableManager = (data) => {
    let mcData = this.state.itemData["19976"] || {id:19976,name:"Mystic Coin",buy_price:0,sell_price:0,img:""}
    let square = 25
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "textImg": {
          contents: (item) => <TextImg item={item}/>,
          className: "left",
        },
        "numberImg": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral,
          contents:(item, accessor)=>{
            let name = item[accessor];
            let image = <img
              key={name}
              style={{width: square,height:square}}
              src={mcData.img}
              title={name}
              alt={name}
            />;
            return <span title={name}>{name}{image}</span>
          },
        },
      },
      colsToDisplay:20,
      filter: {active:true},
      sort:{col:"buy", desc:true},
      headers: {
        cols: [
          { template: "textImg", header: "Item", accessor: "name" },
          { template: "numberImg", header: "Buy", accessor: "buy" },
          { template: "numberImg", header: "Sell", accessor: "sell" },
          { template: "numberImg", header: "Sell - 90%", accessor: "recommend" },
        ]
      }
    }

    return this.createTable(data, config)
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
      callbackToParent={this.callbackToParent}
    />
  }

  converter = () =>{
    let mcData = this.state.itemData["19976"] || {id:19976,name:"Mystic Coin",buy_price:0,sell_price:0,img:""}
    let style = {}
    style["overflow-x"] = "auto"
    style["white-space"] = "nowrap"
    style["margin"] = "0 auto"
    return <MyInput
      mcData={mcData}
    />
  }

  render() {
    if(typeof this.state.itemData === "undefined"){return <Loading />}

    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#the-mystic-coin-standard"} />
      <br/>
      {this.currentMCPrice()}
      {this.converter()}
      <br />
      {this.processTable()}
    </div>;
  }
}

class MyInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mcData: props.mcData,
      gold: 0,
      mc: 0
    }
  }

  update = (e) => {
    this.setState({gold: e.target.value});
  }

  onChange = e => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name
    this.setState({ [name]: value })
  }

  render() {
    let gold = <input
      style={{margin:0, width:75}}
      type={"number"}
      value={this.state.gold}
      onChange={this.onChange}
      name={"gold"}
    />
    let mc = <input
      style={{margin:0, width:75}}
      type={"number"}
      value={this.state.mc}
      onChange={this.onChange}
      name={"mc"}
    />
    let mcData = this.state.mcData
    let sell = Math.ceil((this.state.gold* 10000)/mcData.buy_price)
    let buy = mcData.buy_price*this.state.mc
    let image = <img
      key={sell}
      style={{width: 25,height:25}}
      src={mcData.img}
      title={sell}
      alt={sell}
    />

    return <table style={{margin: "0 auto" }}>
      <tr>
        <td>
          {gold}
        </td>
        <td>
          Gold is {sell} {image}
        </td>
      </tr>
      <tr>
        <td>
          {mc}
        </td>
        <td>
          {image} is <GetCurrency number={buy} size={25} />
        </td>
      </tr>
      </table>
  }
}