import React, { Component } from 'react'
import { urlBase } from '../_utiilities/data.json'
import { Col, Container, Row } from 'react-bootstrap'
import {
    AutoComplete,
    Documentation,
    GetCurrency,
    Loading,
} from '../_utiilities/functions_react'

export class Buyout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            itemData: undefined,
            selected:{items:{name:"Search for Item", id:0}},
            dropdown:{items:[]},
            priceData: undefined,
            inputs:{gold:0, price:0, percentage:0, quantity:0}
        }
    }
    async componentDidMount() {
        let tmp = {};
        tmp.itemData = await this.loadData(urlBase.parser + "/v1/items/json?beautify=min&fields=id,name")
        tmp.dropdown = this.state.dropdown
        tmp.dropdown.items = this.formatDropdown(tmp.itemData)
        this.setState(tmp)
    }

    async loadData(url) {return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))}

    formatDropdown = (dropdown) => {
        for(let i=0;i<dropdown.length; i++){
            let tmp = "";
            if(typeof dropdown[i].name !== "undefined"){
                tmp = tmp +"Name: "+ dropdown[i].name;
            }
            if(typeof dropdown[i].id !== "undefined"){
                tmp = tmp +"\t ID: "+ dropdown[i].id;
            }
            dropdown[i].merged = tmp;
        }
        return dropdown;
    }

    myCallback = async ([dataFromChild,identifier]) => {
        let tmp = {};
        let [id,name] = this.getIDFromMerged(dataFromChild, identifier)
        tmp.selected = this.state.selected
        tmp.selected[identifier] = {name:name, id:id}
        if(id>0){
            tmp.priceData = await this.loadData("https://api.guildwars2.com/v2/commerce/listings/"+ id)
            this.setState(tmp)
        }
    }
    getIDFromMerged = (merged, group) => {
        let dataArray = this.state.dropdown[group]
        for(let i=0;i<dataArray.length;i++){
            if(merged === dataArray[i].merged){
                return [dataArray[i].id,dataArray[i].name]
            }
        }
        return [0,"Error"];
    }

    getDropdown(group,startRender, limit ){
        return <AutoComplete
          identifier={group}
          array={this.state.dropdown[group]}
          placeholder={this.state.selected[group].name}
          callbackToParent={this.myCallback}
          sorter={"merged"}
          startRenderString={startRender}
          limit={limit}
        />
    }

    // Reusable for all inputs
    onChange = e => {
        let { target: { value, name }, } = e
        if(name.indexOf(".") === -1){
            this.setState({ [name]: value })
        }else{
            let subElements = name.split(".")
            let element = this.state[subElements[0]]

            if(subElements.length === 2 ){
                element[subElements[1]] = value
            }
            if(subElements.length === 3 ){
                element[subElements[1]][subElements[2]] = value
            }
            if(subElements.length === 4 ){
                element[subElements[1]][subElements[2]][subElements[3]] = value
            }
            this.setState({[subElements[0]]: element})
        }
    }


    setTable(group){
        let [value, quantity, price, percentage, breakEven, avgPrice, input] = this.calculateValues(this.state.selected.items.id, group)

        let min = 0
        let max = Infinity
        let step = 1
        let topLine = ""
        if(group === "gold"){
            input =  <GetCurrency number={input} size={25} />
            topLine = "Buy Total Value"
            step = 10000
        }
        if(group === "price"){
            input =  <GetCurrency number={input} size={25} />
            topLine = "Buy to Price"
        }
        if(group === "percentage"){
            input = input - 0
            input = input.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2})
            topLine = "Buy % of supply"
            max = 1
            step = 0.01
        }
        if(group === "quantity"){
            topLine = "Buy Quantity"
        }

        return <Container
          //className={"box"}
          //style={{width:"300px"}}
        >
            <Row>
                <Col>
                    <input
                      name={"inputs."+ group}
                      value={this.state.inputs[group]}
                      style={{width:"270px"}}
                      type="number"
                      onChange={this.onChange}
                      min={min}
                      max={max}
                      step={step}
                    />
                </Col>
            </Row>
            <Row>
                <Col className={"left"}>
                    {topLine}
                </Col>
                <Col className={"right"}>
                    {input}
                </Col>
            </Row>
            <Row>
                <Col className={"left"}>
                    Avg Price
                </Col>
                <Col className={"right"}>
                    <GetCurrency number={avgPrice} size={25} />
                </Col>
            </Row>
            <Row>
                <Col className={"left"} >
                    Max Price
                </Col>
                <Col className={"right"}>
                    <GetCurrency number={price} size={25} />
                </Col>
            </Row>
            <Row>
                <Col className={"left"} >
                    Quantity
                </Col>
                <Col className={"right"}>
                    {quantity}
                </Col>
            </Row>
            <Row>
                <Col className={"left"} >
                    Value
                </Col>
                <Col className={"right"}>
                    <GetCurrency number={value} size={25} />
                </Col>
            </Row>
            <Row>
                <Col className={"left"} >
                    Percentage
                </Col>
                <Col className={"right"}>
                    {percentage.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2})}
                </Col>
            </Row>
            <Row>
                <Col className={"left"} >
                    Break Even Price
                </Col>
                <Col className={"right"}>
                    <GetCurrency number={breakEven} size={25} />
                </Col>
            </Row>
        </Container>
    }


    calculateValues(item, group){
        let sells = this.state.priceData.sells

        // first loop for teh % breakpoint
        let overallQuantity = 0
        for(let i=0;i<sells.length;i++){
            overallQuantity += sells[i].quantity
        }

        // run through everything, break when it reaches the cutoff
        let input = this.state.inputs[group]
        let price = 0
        let totalValue = 0
        let totalQuantity = 0

        for(let i=0;i<sells.length;i++) {
            price = sells[i].unit_price

            if (group === "gold") {
                // breaks it if the price is below the sell price
                if (input <= price) {break}

                // check to see if the next step will go over
                if(input <= (totalValue + (price * sells[i].quantity) )){
                    // if it does go over figure out teh closessed quantity under it
                    let quantity = Math.floor((input - totalValue)/price)
                    totalValue += price * quantity
                    totalQuantity += quantity

                    break
                }
            }
            if (group === "price") {
                // if the price is teh same then we can break
                if (input <= price) {break}
            }
            if (group === "percentage") {
                if (input <= (totalQuantity + sells[i].quantity) / overallQuantity) {
                    console.log("input is less than initial")
                    // figure out where its equal teh percentage
                    for(let j=0;j<sells[i].quantity;j++){
                        if (input <=  (totalQuantity + j) / overallQuantity) {
                            totalValue += price * j
                            totalQuantity += j
                            break
                        }
                    }
                    break
                }
            }
            if(group === "quantity"){
                if(input <= (totalQuantity + sells[i].quantity )){
                    for(let j=0;j<sells[i].quantity;j++){
                        if (input <=  (totalQuantity + j)) {
                            totalValue += price * j
                            totalQuantity += j
                            break
                        }
                    }
                    break
                }
            }

            price = sells[i].unit_price
            totalValue += sells[i].unit_price * sells[i].quantity
            totalQuantity += sells[i].quantity
        }

        let value = totalValue
        let quantity = totalQuantity
        let percentage = totalQuantity/overallQuantity
        let breakEven = (totalValue/totalQuantity)/0.85 || 0
        let avgPrice = totalValue/totalQuantity || 0

        return [value, quantity, price, percentage, breakEven, avgPrice, input]
    }

    tableController(){
        return <Container
          className={"mainBox"}
        >
            <Row>
                <Col style={{width:"400px","padding-top":"15px"}}>{this.setTable("gold")}</Col>
                <Col style={{width:"400px","padding-top":"15px"}}>{this.setTable("price")}</Col>
                <Col style={{width:"400px","padding-top":"15px"}}>{this.setTable("percentage")}</Col>
                <Col style={{width:"400px","padding-top":"15px"}}>{this.setTable("quantity")}</Col>
            </Row>
        </Container>
    }

    render() {
        if(typeof this.state.itemData === "undefined"){return <Loading />}

        let info = "Calculate buy outs of a material here"
        let dropdown = this.getDropdown("items",2, 30 )
        let documentation = <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#buyout"} />

        if(typeof this.state.priceData === "undefined"){return <div style={{align:"center"}}>
            {documentation}
            <br />
            {info}
            {dropdown}
        </div>}

        let table = this.tableController()
        return <div style={{align:"center"}}>
            {documentation}
            <br/>
            {info}
            {dropdown}
            {table}
        </div>;
    }
}