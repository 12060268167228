import {darth_base} from "./data.json";

export const getRarityColour = (rarity: string) => {
  let rarityColour
  switch(rarity){
    case"Junk":{
      rarityColour = "#AAA"
      break
    }
    case"Basic":{
      rarityColour = "#ffffff"
      break
    }
    case"Fine":{
      rarityColour = "#62A4DA"
      break
    }
    case"Masterwork":{
      rarityColour = "#1a9306"
      break
    }
    case"Rare":{
      rarityColour = "#fcd00b"
      break
    }
    case"Exotic":{
      rarityColour = "#ffa405"
      break
    }
    case"Ascended":{
      rarityColour = "#fb3e8d"
      break
    }
    case"Legendary":{
      rarityColour = "#9370DB"
      break
    }
    default:{
      rarityColour = "#AAA"
    }
  }
  return rarityColour
}

export const getDarthsCDN = (url: string) => {
  // using Darth's CDN
  let imgRegex = /https:\/\/render\.guildwars2\.com\/file\/(.*?)\.png/
  let imgDarth = `${darth_base}/$1.png`;
  if (typeof url !== 'undefined') {
    return url.replace(imgRegex, imgDarth)
  } else {
    return ''
  }
}

export const convertToGold = (number:number, shorten?:boolean) => {
  let input = Math.floor(number).toString()
  let minus = ''
  if (input.includes('-')) {
    minus = '-'
  }
  input = input.replace('-', '')

  let copper = input.slice(-2) + 'c'
  let silver = input.slice(-4, -2) + 's'
  let gold = input.slice(0, -4) + 'g'

  if (copper.length === 1) {
    copper = ''
  }
  if (silver.length === 1) {
    silver = ''
  }
  if (gold.length === 1) {
    gold = ''
  }

  let result = minus + gold + silver + copper
  if (shorten) {
    if (number >= 100) {
      // remove trailing copper
      result = result.replace('00c', '')
    }

    if (number >= 10000) {
      // remove trailing silver
      result = result.replace('00s', '')
    }
  }

  return result
}

export function goldToNumber (a: string): number {
  if (typeof a === 'undefined') { return 0; }
  return parseInt(a.toString().replace(/[^\d.-]/g, '')) || 0
}

function stringToNumber (a:string|number|undefined):number {
  if (typeof a === 'undefined') {return 0}
  return parseInt(a.toString().replace(/[^\d.-]/g, ''), 10) || 0
}

export const sortGeneral =(a:string|number|undefined, b:string|number|undefined) =>{
  a = stringToNumber(a)
  b = stringToNumber(b)
  return a > b ? 1 : -1
}

export const filterGeneral = (item: any, filter:string , multiplier = 1 ) => {
  if(typeof item === "undefined"){
    item = "";
  }
  if(typeof filter === "undefined"){
    filter = "";
  }

  let start = 0
  let end = Infinity

  let split = filter.split(',')
  if (split.length > 0) { start = parseInt(split[0],10) * multiplier }
  if (split.length > 1) { end = parseInt(split[1],10) * multiplier }

  let converted = parseInt(item.toString().replace(/[^\d.-]/g, ''), 10) || 0
  return converted >= start && converted <= end
}

export function filterGeneralText (item: any, filter: any) {
  if (typeof item === 'undefined') { return false }
  if (typeof filter === 'undefined') { return false }
  item = item.toString().toLowerCase()
  filter = filter.toString().toLowerCase()
  return item.indexOf(filter) !== -1
}

export const capitalize = ([first,...rest]: string) => first.toUpperCase() + rest.join('').toLowerCase()

export const loadData = async (url: string, extra?:object, errorObject: any = {result: "error", error: "could not fetch"} ) => {
  return await fetch(url, extra)
      .then(response => {
        return response.json()
      })
      .catch((err) => {
        console.log(url, err.toString());
        return errorObject;
      })
}

export const getProfessionIcon = (name: string): string | undefined => {
  let src;
  switch(name){
    case "Artificer":{
      src = `${darth_base}/0D75999D6DEA1FDFF9DB43BBC2054B62764EB9A0/102463.png`
      break
    }
    case "Weaponsmith":{
      src = `${darth_base}/AEEF1CF774EE0D5917D5E1CF3AAC269FEE5EC03A/102460.png`
      break
    }
    case "Scribe":{
      src = `${darth_base}/F95DFA3FBDCC9E9F317551A903E5A2A6DF1CC7E3/1293677.png`
      break
    }
    case "Huntsman":{
      src = `${darth_base}/0C91017241F016EF35A2BCCE183CA9F7374023FC/102462.png`
      break
    }

    case "Leatherworker":{
      src = `${darth_base}/192D1D0D73BA7899F1745F32BAC1634C1B4671BF/102464.png`
      break
    }
    case "Armorsmith":{
      src = `${darth_base}/2952B92FA93C03A5281F94D223A4CE4C7E0B0906/102461.png`
      break
    }
    case "Tailor":{
      src = `${darth_base}/0EB64958BE48AB9605DD56807713215095B8BEED/102459.png`
      break
    }
    case "Jeweler":{
      src = `${darth_base}/F97F4D212B1294052A196734C71BCE42E199735B/102458.png`
      break
    }
    case "Chef":{
      src = `${darth_base}/424E410B90DE300CEB4A1DE2AB954A287C7A5419/102465.png`
      break
    }
    default:{src = undefined}
  }

  return src;
}

// tax is 10%, min 1
export function tax(value: number): number {
  return Math.max(value * 0.1, 1);
}

// listingFee is 10%, min 1
export function listingFee(value: number): number {
  return Math.max(value * 0.5, 1);
}

// value minus tax and listing
export function subFees(value: number): number {
  return value - tax(value) - listingFee(value);
}
