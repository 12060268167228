import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import { urlBase } from '../_utiilities/data.json'
import { Documentation} from '../_utiilities/functions_react'
import {Account} from "../_utiilities/entities"

interface Account_props {
    account?: Account;
    session?: string;
}

export class Account_Page extends Component <Account_props, any> {
    constructor(props: Account_props) {
        super(props);
        this.state = {
            session: props.session,
            error:false,
            errorCode:undefined,
            account: props.account,
            emailBox:"",
            passwordBox:"",
            apiBox:""
        };
    }

    // will need this in all sub components
    componentDidUpdate(prevProps:Account_props ) {
        if (typeof prevProps.session === "undefined" && typeof this.props.session !== "undefined"){
            this.setState({ session: this.props.session })
        }
        if (typeof prevProps.account === "undefined" && typeof this.props.account !== "undefined"){
            this.setState({ account: this.props.account })
        }
    }

    async componentDidMount() {
        //this.setState(tmp)
    }

    handleChange = (event: any, field: string) => {
        this.setState({[field]: event.target.value});
    }

    handleSubmit = (url: string, details: object) =>{
        return fetch(url, details)
            .then(async (response)=> {
                let body = await response.json();
                if (body.result === "success") {
                    alert("Success")
                    window.location.reload();
                }
                if (body.result === "error") {
                    alert(body.error)
                }
            })
            .catch((err) => {alert(err)});
    }

    render() {
        if(typeof this.state.session === "undefined"){
            return <Redirect to='/login'  />;
        }

        let info = <div>
            <p>
                This is where you manage your details
            </p>
        </div>;
        let error,details, user, apiSubmit, apiKeys;
        if(this.state.error === true){
            error =  <p>
                Error: {this.state.errorCode}
            </p>
        }
        if(this.state.account !== undefined){
            user = <div>
                <span>
                    <b>Username: </b>{this.state.account.user}.  <a href={"https://slate.silveress.ie/gw2_site#patreon"} target={"_blank"} rel={"noopener noreferrer"}><b>Account Level: </b>{this.state.account.accountLevel -1}</a>
                    <br />
                </span>
            </div>;
            let email = this.state.account.email;
            if(email === ""){
                email = "Email"
            }

            details = <div>
                <br />
                <h5>
                    Change your details:
                </h5>
                <form onSubmit={() => this.handleSubmit(`${urlBase.account}/v1/website/account/email`, {method: 'POST', headers: {'Content-Type': 'application/json', session:this.state.session, email:this.state.emailBox}})}>
                    <input
                        type="text"
                        //required
                        value={this.state.emailBox}
                        onChange={(e)=> this.handleChange( e, "emailBox")}
                        placeholder={email}
                    />
                    &nbsp;
                    <input type="submit" value="Submit"/>
                </form>
                <br />
                <form onSubmit={() => this.handleSubmit(`${urlBase.account}/v1/website/account/password`, {method: 'POST', headers: {'Content-Type': 'application/json', session:this.state.session, password:this.state.passwordBox}})}>
                    <input
                        type="password"
                        //required
                        value={this.state.passwordBox}
                        onChange={(e)=> this.handleChange( e, "passwordBox")}
                        placeholder={"New Password"}
                    />
                    &nbsp;
                    <input type="submit" value="Submit"/>
                </form>
                <br />
            </div>;

            apiSubmit = <div>
                API Keys
                <br />
                <form onSubmit={() => this.handleSubmit(`${urlBase.account}/v1/website/account/apiKey`, {method: 'POST', headers: {'Content-Type': 'application/json', session:this.state.session, apiKey:this.state.apiBox}})}>
                    <div>
                        <input
                            type="text"
                            //required
                            value={this.state.apiBox}
                            onChange={(e)=> this.handleChange( e, "apiBox")}
                            placeholder={"API Key"}
                        />
                        &nbsp;
                        <input type="submit" value="Submit"/>
                    </div>
                </form>
                <br />
            </div>;

            let accounts = this.state.account.gameAccounts;
            let userIDs = Object.keys(accounts);

            if(userIDs.length > 0){
                let rows = [];
                for(let i=0;i<userIDs.length;i++){
                    let key = accounts[userIDs[i]].key;
                    rows.push(<tr key={key}>
                        <td className={"left"}>{userIDs[i]}</td>
                        <td className={"left"}>&nbsp;&nbsp;</td>
                        <td className={"left"} title={accounts[userIDs[i]].permissions.join("\n")} >{key}</td>
                        <td className={"left"}>&nbsp;&nbsp;</td>
                        <td className={"left"}>
                            <button
                                onClick={() => this.handleSubmit(`${urlBase.account}/v1/website/account/deleteKey`, {method: 'POST', headers: {'Content-Type': 'application/json', session:this.state.session, apiKey:key}})}
                                style={{"backgroundColor":"#ff5154", "border": "none"}}
                            >Delete</button>
                        </td>
                    </tr>)
                }


                apiKeys = <div>
                    <table className={"centerTable table-primary table-striped table-highlight"} style={{ "textAlign":"center", margin:"0 auto" }} >
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>&nbsp;&nbsp;</th>
                            <th>Key</th>
                            <th>&nbsp;&nbsp;</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </table>
                </div>;
            }

        }



        return <div>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/management#edit-details"} />
            <br/>
            {info}
            {error}
            {user}
            {details}
            {apiSubmit}
            {apiKeys}
        </div>
    }
}