import React, { Component } from 'react';

function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export class Friends extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
    }

    render() {
        let friendsArray = [];

        friendsArray.push(<div key={"Gw2Economy"}>
            <h4>Gw2Economy</h4>
            <p>
                /r/gw2economy Discord
                <br />
                These are my, em, vict- Volunteers, yes volunteers, without their help and suggestions half this site would not exist.
                <br />
                <a href={"https://www.reddit.com/r/gw2economy"} target={"_blank"}  rel={"noopener noreferrer"}>https://www.reddit.com/r/gw2economy</a>
            </p>
        </div>);

        friendsArray.push(<div key={"Rediche"}>
            <h4>Rediche</h4>
            <p>
                Owner of Gw2.ninja
                <br />
                Another good friend of mine, like Que he is always willing to answer questions on web development.
                <br />
                <a href={"https://gw2.ninja"} target={"_blank"}  rel={"noopener noreferrer"}>https://gw2.ninja</a>
            </p>
        </div>);

        friendsArray.push(<div key={"Lion’s Plaza"}>
            <h4>Lion’s Plaza</h4>
            <p>
                A second home.
                <br />
                I stumbled in here and was made feel very much at home. It is several guilds that have banded together to create an enjoyable community that expands out from Guildwars2.
                <br />
                <a href={"https://lionsplaza.com"} target={"_blank"} rel={"noopener noreferrer"} >https://lionsplaza.com</a>
            </p>
        </div>);
        friendsArray.push(<div key={"Queicherius"}>
            <h4>Queicherius</h4>
            <p>
                Owner of Gw2Efficency.
                <br />
                He has been a mentor for me in both Node.js and getting this site running, especially in the beginning when I had such basic questions.
                <br />
                <a href={"https://gw2efficiency.com"} target={"_blank"} rel={"noopener noreferrer"} >https://gw2efficiency.com</a>
            </p>
        </div>);

        friendsArray.push(<div key={"Hamster"}>
            <h4>Beer for the Server Hamsters</h4>
            <p>
                Thank you to all who have bought beer for the server hamsters
                <br />
                If it looks like they are getting too drunk I think we will hve to swap it out for coffee or another non alcoholic alternative.
            </p>
        </div>)

        friendsArray = shuffle(friendsArray);
        return <div style={{align:"center"}}>
            <br />
            <h3>Welcome!</h3>
            <p>
                This page is basically where I thank all the folks who have helped me and whatnot.
                <br />
                In no particular order (literally, refresh the page to see)
            </p>
            <br />
            {friendsArray}
        </div>;
    }
}
