import React, { Component } from 'react'
import { urlBase } from '../_utiilities/data.json'
import { filterGeneral, sortGeneral } from '../_utiilities/functions'
import {
  Documentation,
  GetCurrency, getQueryObject,
  Loading,
  SilverTable,
} from '../_utiilities/functions_react'
import {TextImgTP} from "./tp_textImg";

let startTimer = Date.now()
let timer = {}
timer.start = (Date.now() - startTimer) / 1000
export class TP_Detailed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemData: undefined,
      query: getQueryObject(window.location.hash),
      siteAddress: window.location.protocol + "//" + window.location.host
    }
  }

  async loadData(url) {return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))}

  async componentDidMount() {
    timer.mounted = (Date.now() - startTimer) / 1000
    let extraFields = "," +
      "1d_sell_price_avg,1d_sell_sold,1d_sell_listed,1d_sell_delisted,1d_sell_quantity_avg,"+
      "1m_sell_price_avg,1m_sell_sold,1m_sell_listed,1m_sell_delisted,1m_sell_quantity_avg,"+

      "1d_buy_price_avg,1d_buy_sold,1d_buy_listed,1d_buy_delisted,1d_buy_quantity_avg,"+
      "1m_buy_price_avg,1m_buy_sold,1m_buy_listed,1m_buy_delisted,1m_buy_quantity_avg" +

      ""

    let dataFields = "" +
      "id,name,charm,img,rarity,chat_link,level,type,firstAdded,statName,upgradeName,weaponType,lastUpdate" +
      ","
    let tmp = {}
    let itemData = await this.loadData(urlBase.parser + "/v1/items/json?fields="+dataFields+"buy_price,sell_price,sell_quantity,buy_quantity"+extraFields+"&beautify=min")
    timer.itemData = (Date.now() - startTimer) / 1000
    tmp.fields =  [
      "_sold",
      "_price_avg",
      "_quantity_avg",
    ];
    [tmp.tableData, tmp.dates] = this.processData(itemData, tmp.fields)
    timer.tableData = (Date.now() - startTimer) / 1000
    this.setState(tmp)
  }

  processData = (itemData, fields) =>{
    let week = 7
    let month = 30
    let decimalPlaces = 3

    let rows = []
    let dates = []
    for(let i=0;i<itemData.length;i++){
      dates.push(itemData[i].lastUpdate)
      let tmp = {}
      tmp.id = itemData[i].id
      tmp.name = itemData[i].name
      tmp.rarity = itemData[i].rarity
      tmp.img = itemData[i].img

      tmp.charm = itemData[i].charm
      tmp.chat_link = itemData[i].chat_link
      tmp.level = itemData[i].level
      tmp.type = itemData[i].type
      tmp.firstAdded = itemData[i].firstAdded
      tmp.lastUpdate = itemData[i].lastUpdate
      tmp.statName = itemData[i].statName
      tmp.upgradeName = itemData[i].upgradeName
      tmp.weaponType = itemData[i].weaponType


      tmp.profit = (itemData[i].sell_price * 0.85 ) - itemData[i].buy_price

      // sell first, then mirrored for buy
      // main categories
      tmp.sell_sold = itemData[i]["1d_sell_sold"]
      tmp.sell_listed = itemData[i]["1d_sell_listed"]
      tmp.sell_delisted = itemData[i]["1d_sell_delisted"]
      tmp.sell_price = itemData[i]["sell_price"]
      tmp.sell_quantity = itemData[i]["sell_quantity"]

      tmp.buy_sold = itemData[i]["1d_buy_sold"]
      tmp.buy_listed = itemData[i]["1d_buy_listed"]
      tmp.buy_delisted = itemData[i]["1d_buy_delisted"]
      tmp.buy_price = itemData[i]["buy_price"]
      tmp.buy_quantity = itemData[i]["buy_quantity"]

      // calculazte subsections
      tmp = this.processDWMDifferences(tmp, itemData[i],fields, week, month, decimalPlaces)

      rows.push(tmp)
    }
    return [rows, dates.sort().reverse()]
  }

  processTable = () => {
    let {tableData, fields} = this.state
    return this.tableManager(tableData, fields)
  }

  processDWMDifferences = (tmp, itemData, fields, week, month, decimalPlaces, total) =>{
    let selectors = ["buy", "sell"]

    for(let i=0;i<fields.length;i++){
      for(let j=0;j<selectors.length;j++){
        let field = selectors[j] + fields[i]
        if(field.indexOf("_avg")=== -1){
          tmp[field + "_month"] = ((itemData["1d_" + field] - (itemData["1m_" + field]/month))/(itemData["1m_" + field]/month)).toFixed(decimalPlaces) +"%"
        }else{
          tmp[field + "_month"] = ((itemData["1d_" + field] - itemData["1m_" + field])/itemData["1m_" + field]).toFixed(decimalPlaces) +"%"
        }
      }
    }

    let allFields = Object.keys(tmp)
    for(let i=0;i<allFields.length;i++){
      if(tmp[allFields[i]] === "NaN%"){
        tmp[allFields[i]] = "0%"
      }
    }
    return tmp
  }

  capitalize = (str) => {
    return str.replace(/(?:^|\s)\S/g, function(str) { return str.toUpperCase() });
  }

  tableManager = (data, fields) => {
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "text": {
          className: "left",
        },
        "blank": {
          className: "left",
        },
        "textImg": {
          contents:(item)=> <TextImgTP item={item} siteAddress={this.state.siteAddress}/>,
          className: "left",
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "id": {
          className: "right",
          filter: filterGeneral
        },
        "percent": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "gold": {
          className: "right",
          contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
          sort: sortGeneral,
          filter: (item, filter) => filterGeneral(item, filter, 10000)
        }
      },
      colsToDisplay:30,
      filter: {active:true},
      sort:{col:"name", desc:false},
      headers: {
        "Name": {
          className:"left",
          cols: [
            { template: "textImg", header: "", accessor: "name" },
          ]
        },
        "Filters": {
          collapse:true,
          className:"left",
          cols: [
            { template: "id", header: "ID", accessor: "id" },
            { template: "number", header: "Level", accessor: "level" },
            { template: "text", header: "Rarity", accessor: "rarity" },
            { template: "text", header: "Type", accessor: "type" },
            { template: "text", header: "Weapon Type", accessor: "weaponType" },
            { template: "text", header: "Stat", accessor: "statName" },
            { template: "text", header: "Charm", accessor: "charm" },
            { template: "text", header: "Upgrade", accessor: "upgradeName" },
            { template: "text", header: "Chat Link", accessor: "chat_link" },
            { template: "text", header: "First Added", accessor: "firstAdded" },
            { template: "text", header: "Last Update", accessor: "lastUpdate" },
          ]
        },
        "Prices": {
          collapse:true,
          className:"left",
          cols: [
            { template: "gold", header: "Buy", accessor: "buy_price" },
            { template: "gold", header: "Sell", accessor: "sell_price" },
            { template: "gold", header: "Profit", accessor: "profit" },
          ]
        },
        "Quantities": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Buy", accessor: "buy_quantity" },
            { template: "number", header: "Sell", accessor: "sell_quantity" },
          ]
        },
        "": {
          className:"left",
          cols: [
            { template: "blank", header: "", accessor: "" },
          ]
        },
        "Sold": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Buy", accessor: "buy_sold" },
            { template: "number", header: "Sell", accessor: "sell_sold" },
          ]
        },
        "Listed": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Buy", accessor: "buy_listed" },
            { template: "number", header: "Sell", accessor: "sell_listed" },
          ]
        },
        "Delisted": {
          collapse:true,
          className:"left",
          cols: [
            { template: "number", header: "Buy", accessor: "buy_delisted" },
            { template: "number", header: "Sell", accessor: "sell_delisted" },
          ]
        },
        "Change Month": {
          collapse:true,
          className:"left",
          cols: []
        },
      },
      headerOrder:["Name","Filters","Prices","Quantities","","Sold","","Listed","","Delisted", "","Change Month"],
      collapse:{
        "Filters": true,
        "Change Month": true,
      }
    }

    let mainFields = ["buy", "sell"]
    let subFields = ["_month"]
    let stripUnderscore  = /_/gi;

    for(let i=0;i<fields.length;i++){
      for(let j=0;j<mainFields.length;j++){
        for(let k=0;k<subFields.length;k++){
          let accessor = mainFields[j] + fields[i] + subFields[k]

          let title = mainFields[j] + fields[i]
          title = this.capitalize(title.replace(stripUnderscore, ' '))
          config.headers["Change Month"].cols.push({ template: "percent", header: title, accessor: accessor })
        }
      }
    }

    if(
      typeof this.state.query !== "undefined"
      && typeof this.state.query.sort !== "undefined"
    ){
      config.sort.col = this.state.query.sort
      if(typeof this.state.query.order !== "undefined"){
        config.sort.desc = this.state.query.order === "desc"
      }
    }
    return this.createTable(data, config)
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
      callbackToParent={this.callbackToParent}
    />
  }

  render() {
    if(typeof this.state.tableData === "undefined"){return <Loading />}

    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/tradepost#detailed"} />
      <br/>
      <h2>TradePost - Detailed Data</h2>
      <span>
        Latest Update: {new Date(this.state.dates[0]).toLocaleString()}
      </span>
      <br />
      {this.processTable()}
    </div>
  }
}