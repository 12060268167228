import React, { Component } from 'react';
import { urlBase } from '../_utiilities/data.json'
import { Documentation, Loading, SilverTable} from '../_utiilities/functions_react'
import { sortGeneral } from '../_utiilities/functions'

export class Precursor_Stats extends Component {
    constructor(props) {
        super(props);
        this.state = { forgingData: []}
    }

    async loadData(url) {
        return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))
    }

    async componentDidMount() {
        let tmp = {};
        tmp.forgingData = await this.loadData(urlBase.stats + "/v1/forging/precurser?beautify=min");
        tmp.itemData = await this.loadData(urlBase.parser + "/v1/items/json?beautify=min&filter=type:Weapon,rarity:Exotic&fields=name,weaponType,statName,upgradeName");

        let result = this.reduceForgingData(tmp.forgingData,tmp.itemData);
        tmp.weaponTypeArray = result.weaponTypeArray;
        tmp.statTypesArray = result.statTypesArray;
        tmp.upgradeTypesArray = result.upgradeTypesArray;
        tmp.setCountArray = result.setCountArray;
        tmp.total = result.total;
        tmp.overallPreCount = result.overallPreCount;

        this.setState(tmp)
    }

    reduceForgingData(forgingData, itemData){
        let total = forgingData.length;

        const pres = ["Carcharias","Chaos Gun","Dawn","Dusk","Howl","Leaf of Kudzu","Rage","Rodgort's Flame","Spark","Storm","The Bard","The Chosen","The Colossus","The Energizer","The Hunter","The Legend","The Lover","Tooth of Frostfang","Venom","Zap"];
        const sets = ["Pearl","Black Earth","Iron","Soft Wood","Steam","Legionnaire","Primordus","Corrupted","Etched","Tribal","Ceremonial","Orrian","Forged","Eir's","Ã‰ibhear","Droknar's"];

        let weaponNameArray0		= [];
        let weaponTypeArray0 		= [];
        let statTypesArray0 		= [];
        let upgradeTypesArray0 		= [];
        let setCountArray0			= [];

        let weaponNameArray		    = [];
        let weaponTypeArray 		= [];
        let statTypesArray 		    = [];
        let upgradeTypesArray 		= [];
        let setCountArray			= [];
        let overallPreCount         = 0;

        function getItemData(name){
            let info = {"name":"","weaponType":"","statName":""};
            for(let i=0;i<itemData.length;i++){
                if(name === itemData[i].name){
                    info = itemData[i];
                    return info;
                }
            }
            return info;
        }
        function bumpStat(array,name){
            if(typeof array[name] === "undefined"){
                array[name] = 0;
            }
            array[name] = array[name] +1;
        }
        function findSet(name){
            let set = "Unique";
            for(let i =0;i<sets.length;i++){
                if(name.indexOf(sets[i]) !== -1){
                    set = sets[i];
                }
            }
            return set;
        }
        function getpreFromType(weapon){
            for(let i=0;i<pres.length;i++){
                let preData = getItemData(pres[i]);
                if(preData.weaponType === weapon){
                    return pres[i]
                }
            }
            return ""
        }
        function flattenArray(oldArray,newArray,pre){
            let keys = Object.keys(oldArray);
            keys.sort();
            for(let i=0;i<keys.length;i++){
                let name = keys[i];
                let count = oldArray[keys[i]];
                let percentage = (count/total).toLocaleString("en", {style: "percent",minimumFractionDigits:2});

                if(pre === true){
                    let preName = getpreFromType(name);
                    let preCount = weaponNameArray0[preName] || 0;
                    let prePercentage = (preCount/count).toLocaleString("en", {style: "percent",minimumFractionDigits:2});
                    overallPreCount = overallPreCount + preCount;
                    newArray.push({name: name, count: count,percentage:percentage,preName:preName,preCount:preCount,prePercentage:prePercentage});
                }else{
                    newArray.push({name: name, count: count,percentage:percentage});
                }
            }
        }

        for(let i=0;i<forgingData.length;i++){
            let thisItemData = getItemData(forgingData[i].name);
            forgingData[i].weaponType = thisItemData.weaponType;
            forgingData[i].statName = thisItemData.statName;
            forgingData[i].upgradeName = thisItemData.upgradeName;

            bumpStat(weaponNameArray0,forgingData[i].name);
            bumpStat(weaponTypeArray0,forgingData[i].weaponType);
            bumpStat(statTypesArray0,forgingData[i].statName);
            bumpStat(upgradeTypesArray0,forgingData[i].upgradeName);
            bumpStat(setCountArray0,findSet(forgingData[i].name));
        }

        flattenArray(weaponNameArray0,weaponNameArray);
        flattenArray(weaponTypeArray0,weaponTypeArray, true);
        flattenArray(statTypesArray0,statTypesArray);
        flattenArray(upgradeTypesArray0,upgradeTypesArray);
        flattenArray(setCountArray0,setCountArray);

        return {weaponTypeArray: weaponTypeArray, statTypesArray: statTypesArray,upgradeTypesArray:upgradeTypesArray,setCountArray:setCountArray,total:total,overallPreCount:overallPreCount}
    }

    tableManager = (total,pres, overallStat, overallWeapon, overallUpgrade, overallSet) => {
        let base = {
            className: {
                table: "centerTable table-primary table-striped table-highlight",
            },
            templates: {
                "text": {
                    className: "left",
                },
                "number": {
                    className: "right",
                    sort: sortGeneral,
                },
                "percent": {
                    className: "right",
                    sort: sortGeneral,
                }
            },
            sort:{ col: "name", desc: false }
        }

        let percentage = (pres/total).toLocaleString("en", {style: "percent",minimumFractionDigits:2})
        let overallPreStatConfig = {
            headers: {
                Totals: {
                    cols: [
                        { template: "text", header: "Weapon", accessor: "name" },
                        { template: "text", header: "Precursor", accessor: "preName" },
                    ]
                }
            },
            headerOrder:[
                "Totals",
                total,
                pres,
                percentage
            ]
        }
        overallPreStatConfig.headers[total] = {
            cols: [
                { template: "number", header: "Forges", accessor: "count" },
            ]
        }
        overallPreStatConfig.headers[pres] = {
            cols: [
                { template: "number", header: "Count", accessor: "preCount" },
            ]
        }
        overallPreStatConfig.headers[percentage] = {
            cols: [
                { template: "percent", header: "Percentage", accessor: "prePercentage" },
            ]
        }
        overallPreStatConfig.className = base.className
        overallPreStatConfig.templates = base.templates
        overallPreStatConfig.sort = base.sort

        let genericConfig = {
            headers: {
                Totals: {
                    cols: [
                        { template: "text", header: "Stat", accessor: "name" },
                        { template: "number", header: "Count", accessor: "count" },
                        { template: "percent", header: "Percentage", accessor: "percentage" },
                    ]
                }
            },
            headerOrder:["Totals"]
        }
        genericConfig.className = base.className
        genericConfig.templates = base.templates
        genericConfig.sort = base.sort

        return [
            this.createTable(overallWeapon, overallPreStatConfig),
            this.createTable(overallStat, genericConfig),
            this.createTable(overallWeapon, genericConfig),
            this.createTable(overallUpgrade, genericConfig),
            this.createTable(overallSet, genericConfig)
        ]
    }

    createTable = (data, config) => {
        return <SilverTable
          data={data}
          config={config}
        />
    }

    render() {
        if(typeof this.state.total === "undefined"){return <Loading/>}

        let [overallPreStatTable, overallStatTable, overallWeaponTable, overallUpgradeTable, overallSetTable] = this.tableManager(
          this.state.total,
          this.state.overallPreCount,
          this.state.statTypesArray,
          this.state.weaponTypeArray,
          this.state.upgradeTypesArray,
          this.state.setCountArray
        )
        return <div style={{align:"center"}}>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/stats#precursor"} />
            <br/>
            <h3>Detailed breakdown of outputs.</h3>
            <p>Percentage chance of each precursor for each weapon type.</p>
            {overallPreStatTable}
            <br />
            <p>Stat types of outputs.</p>
            {overallStatTable}
             <br />
            <p>Weapon type of outputs.</p>
            {overallWeaponTable}
            <br />
            <p>Upgrade type of outputs</p>
            {overallUpgradeTable}
            <br />
            <p>The sets that the outputs belong to.</p>
            {overallSetTable}
        </div>;
    }
}