import React, { Component } from 'react'
import { urlBase } from '../_utiilities/data.json'
import {
  Documentation,
  GetCurrency,
  Loading,
  SilverTable,
} from '../_utiilities/functions_react'
import { filterGeneral, sortGeneral } from '../_utiilities/functions'
import {TextImg} from "./tp_textImg";

export class Raid_Currency extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemData: undefined
    }
  }

  async loadData(url) {return await fetch(url).then(response => response.json()).then(data => {return data;}).catch(err => console.error(this.props.url, err.toString()))}

  async componentDidMount() {
    let tmp = {};
    let itemDataRaw = await this.loadData(urlBase.parser + "/v1/items/json?fields=id,name,buy_price,sell_price,img&beautify=min&filter=id:gte:77274,marketable:TRUE")
    tmp.itemData = {};
    for(let i=0;i<itemDataRaw.length;i++){
      tmp.itemData[itemDataRaw[i].id] = itemDataRaw[i];
    }
    tmp.raidItems = await this.loadData(urlBase.parser + "/v1/misc/raid/rewards?beautify=min")
    this.setState(tmp)
  }

  processData = () => {
    let rows = []
    let itemData = this.state.itemData
    let raidItems = this.state.raidItems

    for(let i=0;i<raidItems.length;i++){
      let raidItem = raidItems[i]
      let id = raidItem.id
      let item = itemData[id]
      let tmp = {}
      tmp.id = id
      tmp.img = item.img
      tmp.name = item.name
      tmp.currency = raidItem.currency
      tmp.tokens = raidItem.cost
      tmp.gold = raidItem.gold
      tmp.buy = item.buy_price
      tmp.sell = item.sell_price
      tmp.buy_profit = item.buy_price - raidItem.gold
      tmp.sell_profit = item.sell_price - raidItem.gold
      tmp.buy_profit_percent = ((item.buy_price - raidItem.gold)/raidItem.gold).toFixed(2)+"%"
      tmp.sell_profit_percent = ((item.sell_price - raidItem.gold)/raidItem.gold).toFixed(2)+"%"
      tmp.buy_profit_perToken = (item.buy_price - raidItem.gold)/raidItem.cost
      tmp.sell_profit_perToken  = (item.sell_price - raidItem.gold)/raidItem.cost
      rows.push(tmp)
    }
    return rows
  }

  processTable = () => {
    let data = this.processData()
    return this.tableManager(data)
  }

  tableManager = (data) => {
    let square = 25
    let config = {
      className: {
        table: "centerTable table-primary table-striped table-highlight",
      },
      templates: {
        "text": {
          className: "left",
        },
        "textImg": {
          contents: (item) => <TextImg item={item}/>,
          className: "left",
        },
        "number": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "percent": {
          className: "right",
          sort: sortGeneral,
          filter: filterGeneral
        },
        "gold": {
          className: "right",
          contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
          sort: sortGeneral,
          filter: (item, filter) => filterGeneral(item, filter, 10000)
        }
      },
      filter: {active:true},
      sort:{col:"name", desc:false},
      headers: {
        "Details": {
          className:"center",
          cols: [
            { template: "textImg", header: "Item", accessor: "name" },
            { template: "number", header: "ID", accessor: "id" },
            { template: "text", header: "Currency", accessor: "currency" },
            { template: "number", header: "Tokens", accessor: "tokens" },
            { template: "gold", header: "Gold", accessor: "gold" },
          ]
        },
        "Buy": {
          collapse: true,
          className:"left",
          cols: [
            { template: "gold", header: "Price", accessor: "buy" },
            { template: "gold", header: "Profit", accessor: "buy_profit" },
            { template: "percent", header: "Percent", accessor: "buy_profit_percent" },
            { template: "gold", header: "Per Token", accessor: "buy_profit_perToken" },
          ]
        },
        "Sell": {
          collapse: true,
          className:"left",
          cols: [
            { template: "gold", header: "Price", accessor: "sell" },
            { template: "gold", header: "Profit", accessor: "sell_profit" },
            { template: "percent", header: "Percent", accessor: "sell_profit_percent" },
            { template: "gold", header: "Per Token", accessor: "sell_profit_perToken" },
          ]
        },
      },
      headerOrder:["Details","Buy", "Sell"],
      showButtons: false,
    }

    return this.createTable(data, config)
  }

  createTable = (data, config) => {
    return <SilverTable
      data={data}
      config={config}
      callbackToParent={this.callbackToParent}
    />
  }

  render() {
    if(typeof this.state.itemData === "undefined"){return <Loading />}
    return <div style={{align:"center"}}>
      <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/misc#raid-currency"} />
      <br/>
      {this.processTable()}
    </div>
  }
}