import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import dateFormat from 'dateformat'
import {CSVLink} from 'react-csv';
import { graphOptions,urlBase } from '../_utiilities/data.json'
import { Documentation, GetCurrency, Loading, SilverTable, } from '../_utiilities/functions_react'

import Highcharts from 'highcharts/highstock';
import { HighchartsStockChart, Chart, withHighcharts, XAxis, YAxis, Legend, ColumnSeries, Navigator, Tooltip, RangeSelector } from 'react-jsx-highstock';
import { convertToGold, filterGeneral, sortGeneral, goldToNumber, filterGeneralText, tax, listingFee, subFees } from '../_utiilities/functions'
import {TextImg} from "../loggedOut/tp_textImg";


function secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let hDisplay = h > 0 ? h + (m > 9 ? "h" : "h0") : "";
    let mDisplay = m > 0 ? m + (s > 9 ? "m" : "m0") : "";
    let sDisplay = s > 0 ? s + "s" : "";
    return hDisplay + mDisplay + sDisplay;
}
function uniq(a) {
    let seen = {};
    return a.filter(function(item) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
}
function sortCustom (a,b,field){
    if (a[field] < b[field]) {
        return -1
    }
    if (a[field] > b[field]) {
        return 1
    }
    return 0
}

class TransactionViewer_Component extends Component {
    constructor(props) {
        super(props);
        this.state = {
            session: props.session,
            accountMain: props.account,

            accounts_selected: [],
            account_data: {},

            itemData: {},
            compactedBuy: "",
            compactedSell: "",
            processedBuy: "",
            processedSell: "",
            selectTable: "",
            history: true,
            buySell: false,
            fullCompact: false,
            graph:true,
            mode:true,
            modeTmp:true,
            filteredArray:[],
            filter: [],
            graphData:{value:[],transactions:[],quantity:[],avgPrice:[]},
            dateRangeStart: undefined,
            dateRangeEnd: undefined,
            minDate:undefined,
            maxDate:undefined,
            defaultAccountID:"",
            folded:{
                col_0: false,
                col_1: true,
                col_2: false
            }
        }
    }

    // will need this in all sub components
    async componentDidUpdate(prevProps ) {
        if (typeof prevProps.session === "undefined" && typeof this.props.session !== "undefined"){
            this.setState({ session: this.props.session })
        }
        // now logged in, get teh relevent data

        if (typeof prevProps.account === "undefined" && typeof this.props.account !== "undefined"){
            let {accounts_selected, account_data} = await this.processAccount( this.props.account, this.state.itemData );

            let updated_data = await this.setAccountActive(account_data, accounts_selected[0], this.props.account);
            let state = Object.assign({}, {accounts_selected, account_data, accountMain:this.props.account }, updated_data)
            this.setState(state);
        }
    }

    async componentDidMount() {
        let {accountMain} = this.state;

        let itemData = await this.processItemData();

        if(accountMain){
            let {accounts_selected, account_data} = await this.processAccount(accountMain, itemData);

            let updated_data = await this.setAccountActive(account_data, accounts_selected[0], accountMain);
            let state = Object.assign({}, {accounts_selected, account_data}, updated_data, {itemData});

            this.setState(state);
        } else{
            this.setState({itemData});
        }
    }

    processItemData = async () => {
        let itemData = {} ;
        let rawItemData = await this.loadData(urlBase.parser + "/v1/items/json?fields=img,id,name,buy_price,sell_price,type,rarity,level,statName,weaponType,charm&beautify=min");
        for(let i=0;i<rawItemData.length;i++){
            itemData[rawItemData[i].id] = rawItemData[i];
        }
        return itemData
    }

    // this now manages getting all teh account data upfront
    // assumes that if there is an accountMain there is a session
    processAccount = async (accountMain, itemData) =>{
        let { session } = this.state;

        let userIDs = Object.keys(accountMain.gameAccounts);

        // set up vars
        let accounts_selected = [];
        if(userIDs.length > 0){
            accounts_selected.push(userIDs[0]);
        }

        let account_data = {};
        for(let id of userIDs ){
            account_data[id] = {
                //current: this.currentManager(itemData, accountMain.gameAccounts[id].key),
                // going to initially not load this as it can be bonkers big for some folks
                current: undefined,
                history: await this.loadHistory(itemData, session, id , false)
            }
        }
        return { accounts_selected, account_data }
    }

    loadData = async (url,extra, headers) => {
        return await fetch(url,extra)
        .then(
         async (response) => {
              if(headers){
                  return {headers: response.headers, data: await response.json()}
              }else{
                  return await response.json()
              }
          })
        .catch((err) => {console.log(this.props.url, err.toString()); return [];})
    }

    // gett buy and sell of current transactions
    // process like the history
    currentManager = async(itemData,apiKey)=>{
        let tmp = {}
        tmp.sells = await this.currentLoad(apiKey, "sells")
        tmp.buys = await this.currentLoad(apiKey, "buys")

        /*
        if(tmp.sells.length > 0 && tmp.buys.length > 0){
            if(!all){
                tmp.graphBuy = this.convertToTable(tmp.buys, itemData).normal;
                tmp.graphSell = this.convertToTable(tmp.sells, itemData).normal;
            }
        }
         */

        tmp.date = this.getMinMaxDate(tmp.sells, tmp.buys)

        return tmp
    }

    getMinMaxDate = (arrayA,arrayB)=>{
        let tmp = {
            min: 0,
            max: Date.parse(new Date().toISOString())
        }

        if(!arrayA){return tmp}
        if(!arrayB){return tmp}
        if(!arrayA[0]){return tmp}
        if(!arrayB[0]){return tmp}
        if(!arrayA[0].date){return tmp}
        if(!arrayB[0].date){return tmp}
        if(!arrayA[arrayA.length - 1].date){return tmp}
        if(!arrayB[arrayB.length - 1].date){return tmp}

        arrayA.sort((a,b) => sortCustom (a,b,"date"))
        arrayB.sort((a,b) => sortCustom (a,b,"date"))

        if (arrayA[0].date < arrayB[0].date) {
            tmp.min = Date.parse(arrayA[0].date)
        } else {
            tmp.min = Date.parse(arrayB[0].date)
        }

        if (arrayA[arrayA.length - 1].date > arrayB[arrayB.length - 1].date) {
            tmp.max = Date.parse(arrayA[arrayA.length - 1].date)
        } else {
            tmp.max = Date.parse(arrayB[arrayB.length - 1].date)
        }

        return tmp
    }

    currentLoad = async(apiKey, type) =>{
        let result = await this.loadData("https://api.guildwars2.com/v2/commerce/transactions/current/"+type+"?page_size=200&access_token="+ apiKey + "&page=0",undefined, true)
        let dataRaw = []
        for(let i=0;i<result.data.length;i++){dataRaw.push(result.data[i])}

        let requests = []
        for(let i=1;i<result.headers.get("x-page-total");i++){requests.push(i)}

        let promises = requests.map(async(i)=>{
            await this.loadData("https://api.guildwars2.com/v2/commerce/transactions/current/"+type+"?page_size=200&access_token="+ apiKey + "&page="+ i)
            .then(
              (page) => {
                  for(let j=0;j<page.length;j++){
                  dataRaw.push(page[j])
                  }
              }
            ).catch(err => console.log("183",err))
        })
        await Promise.all(promises)

        // convert into compressed format
        return this.currentProcess(dataRaw)
    }

    // copied from backend
    currentProcess = (data) =>{
        let days = {}
        for(let i=0;i<data.length;i++){
            let day = new Date(data[i].created)
            day.setUTCHours(0, 0, 0, 0)
            day = day.toISOString()

            if (typeof days[day] === 'undefined') {
                days[day] = {
                    date: day,
                    ids: [],
                    data: {}
                }
            }
            let id = data[i].item_id
            let price = data[i].price
            let quantity = data[i].quantity
            let orderTime = data[i].created

            if (typeof days[day]['data'][id] === 'undefined') {
                days[day]['data'][id] = {
                    firstTime: orderTime,
                    quantity: 0,
                    totalPrice: 0,
                    transactions: 0,
                    totalTime: 0
                }
                days[day]['ids'].push(id)
            }
            if (days[day]['data'][id]['firstTime'] > orderTime) {
                days[day]['data'][id]['firstTime'] = orderTime
            }
            days[day]['data'][id]['quantity'] += quantity
            days[day]['data'][id]['totalPrice'] += (quantity * price)
            days[day]['data'][id]['transactions'] += 1
        }
        let daysArray = []
        let dates = Object.keys(days)
        for (let i = 0; i < dates.length; i++) {
            daysArray.push(days[dates[i]])
        }
        return daysArray
    }

    loadHistory = async (itemData,session,accountID) => {
        let tmp = {};
        let compactedBuyRaw = await this.loadData(urlBase.account + "/v1/transactions/buy",{method: 'GET', headers: {'Content-Type': 'application/json', session: session, accountID:accountID }});
        let compactedSellRaw = await this.loadData(urlBase.account + "/v1/transactions/sell",{method: 'GET', headers: {'Content-Type': 'application/json', session: session, accountID:accountID }});

        tmp.account = compactedSellRaw.account;
        tmp.sell = compactedSellRaw.result || [];
        tmp.buy = compactedBuyRaw.result || [];

        // sort it
        tmp.sell.sort((a,b)=> sortCustom (a,b,"date"))
        tmp.buy.sort((a,b)=> sortCustom (a,b,"date"))

        tmp.date = this.getMinMaxDate(tmp.sell, tmp.buy)

        return tmp;
    }

    processBuySell = (tmp, itemData, all) =>{
        if(!all){
            tmp.graphBuy = this.convertToTable(tmp.compactedBuy, itemData).normal;
            tmp.graphSell = this.convertToTable(tmp.compactedSell, itemData).normal;
        }

        tmp.error = undefined
        return tmp
    }

    convertToTable =  (compacted, itemData, start, end) => {
        let normal = [];
        let compact = [];
        let compactTmp = {};
        for(let i=0;i<compacted.length;i++){
            let ids = compacted[i].ids;
            for(let j=0;j<ids.length;j++){
                let tmp = {};
                tmp.id = ids[j];
                tmp.date = compacted[i].date;

                if(typeof start !== "undefined" && typeof end !== "undefined") {
                    let currentDate = Date.parse(tmp.date);
                    if(start <= currentDate && currentDate <= end) {}else{continue;}
                }
                tmp.date = compacted[i].date.replace("T00:00:00.000Z","");
                let itemDetails = this.getDataFromID(tmp.id,itemData);
                tmp.name = itemDetails.name;
                tmp.img = itemDetails.img;

                let totalPrice = compacted[i].data[tmp.id].totalPrice;
                let totalTime = compacted[i].data[tmp.id].totalTime;
                let quantity = compacted[i].data[tmp.id].quantity;
                let transactions = compacted[i].data[tmp.id].transactions;

                tmp.raw = {
                    id: tmp.id,
                    date:compacted[i].date,
                    name:tmp.name,
                    img:tmp.img,
                    buy:itemDetails.buy_price,
                    sell:itemDetails.sell_price,
                    rarity:itemDetails.rarity,
                    level:itemDetails.level,
                    type:itemDetails.type,
                    statName: itemDetails.statName,
                    weaponType: itemDetails.weaponType,
                    quantity: quantity,
                    transactions: transactions,
                    totalPrice: totalPrice,
                    totalTaxes: totalPrice * 0.15,
                    avgPrice: totalPrice / quantity,
                    avgTime: totalTime / transactions,
                    currentValueBuy: itemDetails.buy_price * quantity,
                    currentValueSell: itemDetails.sell_price * quantity,
                    differenceValueBuy: subFees(itemDetails.buy_price * quantity) - totalPrice,
                    differenceValueSell: subFees(itemDetails.sell_price * quantity) - totalPrice
                };
                tmp.charm = itemDetails.charm;
                tmp.weaponType = itemDetails.weaponType;
                tmp.statName = itemDetails.statName;
                tmp.rarity = itemDetails.rarity;
                tmp.level = itemDetails.level;
                tmp.type = itemDetails.type;
                tmp.buy = itemDetails.buy_price;
                tmp.sell = itemDetails.sell_price;
                tmp.quantity = tmp.raw.quantity;
                tmp.transactions = tmp.raw.transactions;
                tmp.totalPrice = tmp.raw.totalPrice;
                tmp.totalTaxes = tmp.raw.totalTaxes;
                tmp.avgPrice = tmp.raw.avgPrice;
                tmp.avgTime = secondsToHms(Math.floor(tmp.raw.avgTime));
                tmp.currentValueBuy = tmp.raw.currentValueBuy;
                tmp.currentValueSell = tmp.raw.currentValueSell;
                tmp.differenceValueBuy = tmp.raw.differenceValueBuy;
                tmp.differenceValueSell = tmp.raw.differenceValueSell;
                normal.push(tmp);

                if(typeof compactTmp[tmp.id] === "undefined"){
                    compactTmp[tmp.id] = {
                        name: tmp.name,
                        img: tmp.img,
                        buy:itemDetails.buy_price,
                        sell:itemDetails.sell_price,

                        quantity:0,
                        totalPrice: 0,

                        transactions: 0,
                        totalTime: 0,

                        currentValueBuy:0,
                        currentValueSell:0
                    }
                }
                compactTmp[tmp.id].quantity += tmp.quantity;
                compactTmp[tmp.id].totalPrice += totalPrice;
                compactTmp[tmp.id].transactions += tmp.transactions;
                compactTmp[tmp.id].totalTime += totalTime;
            }
        }
        let ids = Object.keys(compactTmp);
        for(let i=0;i<ids.length;i++){
            let itemDetails = this.getDataFromID(ids[i],itemData);
            compactTmp[ids[i]].id = ids[i];
            compactTmp[ids[i]].charm = itemDetails.charm;
            compactTmp[ids[i]].weaponType = itemDetails.weaponType;
            compactTmp[ids[i]].statName = itemDetails.statName;
            compactTmp[ids[i]].rarity = itemDetails.rarity;
            compactTmp[ids[i]].level = itemDetails.level;
            compactTmp[ids[i]].type = itemDetails.type;
            
            compactTmp[ids[i]].differenceValueBuy = (compactTmp[ids[i]].buy*compactTmp[ids[i]].quantity)-compactTmp[ids[i]].totalPrice;
            compactTmp[ids[i]].differenceValueSell = (compactTmp[ids[i]].sell*compactTmp[ids[i]].quantity)-compactTmp[ids[i]].totalPrice;
            compactTmp[ids[i]].avgPrice = compactTmp[ids[i]].totalPrice/compactTmp[ids[i]].quantity;
            compactTmp[ids[i]].avgTime = compactTmp[ids[i]].totalTime/compactTmp[ids[i]].transactions;
            compactTmp[ids[i]].currentValueBuy = compactTmp[ids[i]].buy*compactTmp[ids[i]].quantity;
            compactTmp[ids[i]].currentValueSell = compactTmp[ids[i]].sell*compactTmp[ids[i]].quantity;

            compactTmp[ids[i]].raw =  compactTmp[ids[i]];
            compactTmp[ids[i]].avgTime = secondsToHms(Math.floor(compactTmp[ids[i]].avgTime));

            compact.push(compactTmp[ids[i]])
        }

        return {normal:normal,compact:compact}
    }

    getDataFromID = (id, items) => {
        id = id-0;
        let data = {};
        let result = items[id];
        if(typeof result !== "undefined"){
            data = result
        }
        return data;
    }

    changeGeneral = (field)=>{
        // if does not exist just return
        if (typeof this.state[field] === "undefined"){return null}

        let tmp = {}
        tmp[field] = !this.state[field]

        if(field === "history"){
            // changing to current
            if(tmp[field] === false){
                // change the mode and archive previous value
                tmp["mode"] = true
                tmp["modeTmp"] = this.state["mode"]
            }else{
                tmp["mode"] = this.state["modeTmp"]
            }

        }
        this.setState(tmp);
    }

    processFilter = (tmp, filter) =>{
        for(let i=0;i<filter.length;i++){
            let template = this.state.templates[filter[i].template]
            if(typeof template === "undefined"){return tmp}
            let accessor = filter[i].id
            if(typeof template.filter !== "undefined"){
                tmp = tmp.filter((item) => template.filter(item[accessor], filter[i].value))
            }else{
                tmp = tmp.filter((item)=> filterGeneralText(item[accessor],filter[i].value))
            }
        }
        return tmp;
    }

    processGraph = (rawData, filter, flip) =>{
        let data = this.processFilter(rawData, filter);
        let processedData = {value:[],transactions:[],quantity:[],avgPrice:[]};
        let tmp = {};
        for(let i=0;i<data.length;i++){
            let rowData = data[i].raw;
            let date = rowData.date;
            if(typeof tmp[date] === "undefined"){
                tmp[date] = {
                    value:0,
                    transactions:0,
                    quantity:0,
                    avgPrice:0
                }
            }
            if(flip){
                if(typeof rowData.sellPriceTotal === "string"){
                    tmp[date].value += goldToNumber(rowData.sellPriceTotal);
                }
                if(!isNaN(rowData.sellPriceTotal)){
                    tmp[date].value += rowData.sellPriceTotal;
                }
            }else{
                tmp[date].value += rowData.avgPrice * rowData.quantity;
            }
            tmp[date].transactions += rowData.transactions || 0;
            tmp[date].quantity += rowData.quantity || 0;
        }
        let dates = Object.keys(tmp);
        dates.sort();
        for(let i=0;i<dates.length;i++){
            let date = new Date(dates[i]).getTime();
            let data = tmp[dates[i]];
            data.avgPrice = data.value/data.quantity;
            processedData.value.push([date,Math.floor(data.value)]);
            processedData.transactions.push([date,Math.floor(data.transactions)]);
            processedData.quantity.push([date,Math.floor(data.quantity)]);
            processedData.avgPrice.push([date,Math.floor(data.avgPrice)]);
        }
        return processedData;
    }

    getDataFromNav = async (dataFromChild) => {
        let tmp= {};
        if(!isNaN(dataFromChild.min)){
            tmp.startDate = new Date(new Date(dataFromChild.min).setUTCHours(0,0,0,0)).toISOString();
            tmp.startGraphDate = dataFromChild.min;
        }
        if(!isNaN(dataFromChild.max)){
            tmp.endDate = new Date(new Date(dataFromChild.max).setUTCHours(0,0,0,0)).toISOString();
            tmp.endGraphDate = dataFromChild.max;
        }
        tmp.dateRangeStart =Date.parse(tmp.startDate);
        tmp.dateRangeEnd = Date.parse(tmp.endDate);
        this.setState(tmp);
    };

    getTotals = (rawData, filter, start, end, sell) =>{
        let tmp = {
            value:0,
            transactions:0,
            quantity:0,
            avgPrice:0,
            taxes:0
        };

        let data = this.processFilter(rawData, filter);
        for(let i=0;i<data.length;i++){
            let rowData = data[i].raw;
            if(typeof start !== "undefined" && typeof end !== "undefined") {
                let currentDate = Date.parse(rowData.date);
                if(start <= currentDate && currentDate <= end) {}else{continue;}
            }
            let value = rowData.avgPrice * rowData.quantity || 0;
            tmp.value += value;
            tmp.transactions += rowData.transactions || 0;
            tmp.quantity += rowData.quantity || 0;
            if(sell){
                tmp.taxes += tax(value) + listingFee(value);
            }
        }
        tmp.avgPrice = tmp.value/tmp.quantity;

        return tmp;
    }

    convertToCsv = (data, compact) => {
        let tmp = {};
        tmp.data = [];

        for(let i=0;i<data.length;i++){
            let rawdata = data[i].raw;
            tmp.data.push(rawdata);
        }
        if(compact){
            tmp.headers = []
        }else{
            tmp.headers = [{ label: 'Date', key: 'date' }]
        }
        let temp = [
            { label: 'ID', key: 'id' },
            { label: 'Name', key: 'name' },
            { label: 'Quantity', key: 'quantity' },
            { label: 'Average Price', key: 'avgPrice' },
            { label: 'Transactions', key: 'transactions' }
        ];

        for(let i=0;i<temp.length;i++){
            tmp.headers.push(temp[i])
        }

        return tmp;
    }

    // let this be the original dropdown option
    handleCharacterSelection = async (event) => {
        let account = event.target.value;

        let {account_data, accountMain, itemData} = this.state;
        if(account === "All"){
            let tmp = {
                accounts_selected: [account],
                account: undefined,
                compactedSell: [],
                compactedBuy: [],
                minDate: undefined,
                maxDate: undefined,
            };

            let temp = {buy:{}, sell:{}, account:{}};
            let userIDs = Object.keys(accountMain.gameAccounts);
            for(let id of userIDs) {
                if (id === "All") {
                    continue;
                }

                tmp.accounts_selected.push(id);

                let userIDData = account_data[id];
                if (typeof tmp.account === "undefined") {
                    tmp.account = userIDData.history.account;
                }

                for (let j = 0; j < userIDData.history.buy.length; j++) {
                    let day = userIDData.history.buy[j];
                    if (typeof temp.buy[day.date] === "undefined") {
                        temp.buy[day.date] = day;
                    } else {
                        let ids = day.ids;
                        for (let k = 0; k < ids.length; k++) {
                            if (typeof temp.buy[day.date].data[ids[k]] === "undefined") {
                                temp.buy[day.date].data[ids[k]] = day.data[ids[k]];
                                temp.buy[day.date].ids.push(ids[k]);
                            } else {
                                if (temp.buy[day.date].data[ids[k]].firstTime > day.data[ids[k]].firstTime) {
                                    temp.buy[day.date].data[ids[k]].firstTime = day.data[ids[k]].firstTime
                                }

                                temp.buy[day.date].data[ids[k]].quantity += day.data[ids[k]].quantity;
                                temp.buy[day.date].data[ids[k]].totalPrice += day.data[ids[k]].totalPrice;
                                temp.buy[day.date].data[ids[k]].totalTime += day.data[ids[k]].totalTime;
                                temp.buy[day.date].data[ids[k]].transactions += day.data[ids[k]].transactions;
                            }
                        }
                    }
                }

                for (let j = 0; j <userIDData.history.sell.length; j++) {
                    let day = userIDData.history.sell[j];
                    if (typeof temp.sell[day.date] === "undefined") {
                        temp.sell[day.date] = day;
                    } else {
                        let ids = day.ids;
                        for (let k = 0; k < ids.length; k++) {
                            if (typeof temp.sell[day.date].data[ids[k]] === "undefined") {
                                temp.sell[day.date].data[ids[k]] = day.data[ids[k]];
                                temp.sell[day.date].ids.push(ids[k]);
                            } else {
                                if (temp.sell[day.date].data[ids[k]].firstTime > day.data[ids[k]].firstTime) {
                                    temp.sell[day.date].data[ids[k]].firstTime = day.data[ids[k]].firstTime
                                }
                                temp.sell[day.date].data[ids[k]].quantity += day.data[ids[k]].quantity;
                                temp.sell[day.date].data[ids[k]].totalPrice += day.data[ids[k]].totalPrice;
                                temp.sell[day.date].data[ids[k]].totalTime += day.data[ids[k]].totalTime;
                                temp.sell[day.date].data[ids[k]].transactions += day.data[ids[k]].transactions;
                            }
                        }
                    }
                }
            }

            tmp.compactedSell = [];
            tmp.compactedBuy = [];

            let buyKeys = Object.keys(temp.buy);
            for(let i=0;i<buyKeys.length;i++){
                tmp.compactedBuy.push(temp.buy[buyKeys[i]])
            }
            let sellKeys = Object.keys(temp.sell);
            for(let i=0;i<sellKeys.length;i++){
                tmp.compactedSell.push(temp.sell[sellKeys[i]])
            }



            // sort it so its in order
            tmp.compactedSell.sort((a,b)=> sortCustom (a,b,"date"))
            tmp.compactedBuy.sort((a,b)=> sortCustom (a,b,"date"))

            tmp.graphBuy = this.convertToTable(tmp.compactedBuy, itemData).normal;
            tmp.graphSell = this.convertToTable(tmp.compactedSell, itemData).normal;


            let {min,max} = this.getMinMaxDate(tmp.compactedSell, tmp.compactedBuy)
            tmp.maxDate = max
            tmp.minDate = min


            this.setState(tmp)
        }else{
            this.setState(await this.setAccountActive(account_data, account, accountMain));
        }
    }


    setAccountActive = async (account_data, account, accountMain) => {
        let { itemData } = this.state;


        // pull the history from memory

        // current only updates if it hasn't already been updated this session
        if(typeof account_data[account].current === "undefined"){
            account_data[account].current = await this.currentManager(itemData, accountMain.gameAccounts[account].key)
        }

        return {
            accounts_selected: [account],
            account: account_data[account].history.account,
            compactedSell: account_data[account].history.sell,
            compactedBuy: account_data[account].history.buy,
            minDate: account_data[account].history.date.min,
            maxDate: account_data[account].history.date.max,

            graphBuy: this.convertToTable(account_data[account].history.buy, itemData).normal,
            graphSell: this.convertToTable(account_data[account].history.sell, itemData).normal,

            current: account_data[account].current,
            // just in case new data has been added reset it
            account_data: account_data
        };
    }

    processFlipData = () =>{
        let tmp = {table:[], tableCompact:[], graph: [], totals:{value:0, valueBuy:0, valueSell:0, transactions:0, quantity:0, avgPrice:0, taxes:0, profit:0}};
        let temp = {buy:{}, sell:{}, flips: [], ids:[], dates:[]};

        let soldItems = this.state.compactedSell;
        let boughtItems = this.state.compactedBuy;

        for(let i=0;i<soldItems.length;i++){
            temp.sell[soldItems[i].date] = soldItems[i];
            temp.dates.push(soldItems[i].date);
            for(let j=0;j<soldItems[i].ids.length;j++){
                temp.ids.push(soldItems[i].ids[j])
            }
        }
        for(let i=0;i<boughtItems.length;i++){
            temp.buy[boughtItems[i].date] = boughtItems[i];
            temp.dates.push(boughtItems[i].date);
        }

        let ids = uniq(temp.ids);
        temp.ids = {};
        for(let i=0;i<ids.length;i++){
            temp.ids[ids[i]] = {
                earliestDate:"",
                quantity: 0,
                totalPrice:0
            };
        }
        temp.dates = uniq(temp.dates);

        for(let i=0;i<temp.dates.length;i++){
            let date = temp.dates[i];

            // buys first
            if(typeof temp.buy[date] !== "undefined"){
                // bought items this day
                let day = temp.buy[date];
                for(let j=0;j< day.ids.length;j++){
                    if(typeof temp.ids[day.ids[j]] !== "undefined"){
                        // id is on teh sold list
                        if(temp.ids[day.ids[j]].quantity === 0){
                            temp.ids[day.ids[j]].earliestDate = date;
                        }
                        temp.ids[day.ids[j]].quantity += day.data[day.ids[j]].quantity;
                        temp.ids[day.ids[j]].totalPrice += day.data[day.ids[j]].totalPrice;
                    }
                }
            }

            // sorts the sold items
            if(typeof temp.sell[date] !== "undefined"){
                // bought items this day
                let day = temp.sell[date];
                for(let j=0;j< day.ids.length;j++){
                    if(typeof temp.ids[day.ids[j]] !== "undefined"){
                        // id is on teh sold list
                        let vInventory = temp.ids[day.ids[j]];
                        if(vInventory.quantity >0){
                            let sold = day.data[day.ids[j]];
                            let itemsData = this.state.itemData[day.ids[j]];
                            let quantity = sold.quantity;
                            if(vInventory.quantity < sold.quantity){
                                quantity = vInventory.quantity;
                            }
                            let buyValue = (vInventory.totalPrice/vInventory.quantity)*quantity;
                            let sellValue = (sold.totalPrice/sold.quantity)*quantity;
                            let tax = 0.15 * sellValue;

                            let flip = {
                                id: day.ids[j],
                                name: itemsData.name,
                                img: itemsData.img,
                                buy: itemsData.buy_price,
                                sell: itemsData.sell_price,
                                type: itemsData.type,
                                rarity: itemsData.rarity,
                                level: itemsData.level,
                                statName: itemsData.statName,
                                weaponType: itemsData.weaponType,
                                charm: itemsData.charm,
                                buyDate: vInventory.earliestDate,
                                date: date,
                                days: Math.ceil((new Date(date) - new Date(vInventory.earliestDate)) / 86400000)+1,
                                quantity: quantity,
                                raw:{
                                    id: day.ids[j],
                                    name: itemsData.name,
                                    img: itemsData.img,
                                    buy: itemsData.buy_price,
                                    sell: itemsData.sell_price,
                                    type: itemsData.type,
                                    rarity: itemsData.rarity,
                                    level: itemsData.level,
                                    statName: itemsData.statName,
                                    weaponType: itemsData.weaponType,
                                    charm: itemsData.charm,
                                    buyDate: vInventory.earliestDate,
                                    date: date,
                                    days: Math.ceil((new Date(date) - new Date(vInventory.earliestDate)) / 86400000)+1,
                                    quantity:quantity,
                                    buyPriceTotal: buyValue,
                                    sellPriceTotal: sellValue,
                                    profit:(0.85 * sellValue) - buyValue,
                                    currentValueBuy: itemsData.buy_price * quantity,
                                    currentValueSell: itemsData.sell_price * quantity,
                                    currentProfitBuy: (0.85 * (itemsData.buy_price * quantity)) - buyValue,
                                    currentProfitSell: (0.85 * (itemsData.sell_price * quantity)) - buyValue,
                                    profitTax: tax,
                                    currentProfitBuyTax: 0.15 * (itemsData.buy_price * quantity),
                                    currentProfitSellTax: 0.15 * (itemsData.sell_price * quantity),
                                    transactions: 1,
                                    avgPrice: sold.totalPrice/sold.quantity
                                }
                            };

                            flip.buyPriceTotal = buyValue
                            flip.sellPriceTotal = sellValue
                            flip.profit = flip.raw.profit
                            flip.currentValueBuy = flip.raw.currentValueBuy
                            flip.currentValueSell = flip.raw.currentValueSell
                            flip.currentProfitBuy = flip.raw.currentProfitBuy
                            flip.currentProfitSell = flip.raw.currentProfitSell
                            flip.profitTax = flip.raw.profitTax
                            flip.currentProfitBuyTax = flip.raw.currentProfitBuyTax
                            flip.currentProfitSellTax = flip.raw.currentProfitSellTax
                            flip.avgPrice = flip.raw.avgPrice

                            if(quantity >0){
                                temp.flips.push(flip);
                            }

                            // set vInventory
                            temp.ids[day.ids[j]].quantity -= quantity;
                            temp.ids[day.ids[j]].totalPrice -= buyValue;
                        }
                    }
                }
            }
        }
        // apply filter to get the graph
        tmp.graph = this.processGraph(temp.flips, this.state.filter, true);

        // apply daterange for table
        let start = this.state.dateRangeStart;
        let end = this.state.dateRangeEnd;
        let temp_sorting = {};
        for(let i=0;i<temp.flips.length;i++){
            if(typeof start !== "undefined" && typeof end !== "undefined") {
                let currentDate = Date.parse(temp.flips[i].date);
                if (start <= currentDate && currentDate <= end) {
                }else{continue}
            }
            tmp.table.push(temp.flips[i]);
            if(typeof temp_sorting[temp.flips[i].id] === "undefined"){
                temp_sorting[temp.flips[i].id] = temp.flips[i].raw;
                temp_sorting[temp.flips[i].id].buyPriceTotal = 0;
                temp_sorting[temp.flips[i].id].days = 0;
                temp_sorting[temp.flips[i].id].profit = 0;
                temp_sorting[temp.flips[i].id].quantity = 0;
                temp_sorting[temp.flips[i].id].sellPriceTotal = 0;
                temp_sorting[temp.flips[i].id].transactions = 0;
            }
            temp_sorting[temp.flips[i].id].transactions += 1;
            temp_sorting[temp.flips[i].id].days += temp.flips[i].days;
            temp_sorting[temp.flips[i].id].quantity += temp.flips[i].quantity;
            temp_sorting[temp.flips[i].id].profit += goldToNumber(temp.flips[i].profit);
            temp_sorting[temp.flips[i].id].buyPriceTotal += goldToNumber(temp.flips[i].buyPriceTotal);
            temp_sorting[temp.flips[i].id].sellPriceTotal += goldToNumber(temp.flips[i].sellPriceTotal);
        }

        let compactedKeys = Object.keys(temp_sorting);
        for(let i=0;i<compactedKeys.length;i++){
            let currentValueBuy = temp_sorting[compactedKeys[i]].quantity * temp_sorting[compactedKeys[i]].buy;
            temp_sorting[compactedKeys[i]].currentValueBuy = currentValueBuy
            temp_sorting[compactedKeys[i]].currentProfitBuy = (0.85*currentValueBuy)-temp_sorting[compactedKeys[i]].buyPriceTotal

            let currentValueSell = temp_sorting[compactedKeys[i]].quantity * temp_sorting[compactedKeys[i]].sell;
            temp_sorting[compactedKeys[i]].currentValueSell = currentValueSell
            temp_sorting[compactedKeys[i]].currentProfitSell = (0.85*currentValueSell)-temp_sorting[compactedKeys[i]].buyPriceTotal

            temp_sorting[compactedKeys[i]].profitTax = temp_sorting[compactedKeys[i]].profit*0.15

            tmp.tableCompact.push(temp_sorting[compactedKeys[i]])
        }

        let filteredData = this.processFilter(temp.flips, this.state.filter);
        for(let i=0;i<filteredData.length;i++){
            if(typeof start !== "undefined" && typeof end !== "undefined") {
                let currentDate = Date.parse(filteredData[i].date);
                if (start <= currentDate && currentDate <= end) {
                }else{continue}
            }
            tmp.totals.valueBuy += goldToNumber(filteredData[i].buyPriceTotal);
            tmp.totals.valueSell += goldToNumber(filteredData[i].sellPriceTotal);
            tmp.totals.transactions += 1;
            tmp.totals.quantity += filteredData[i].quantity;
            tmp.totals.taxes += goldToNumber(filteredData[i].sellPriceTotal) * 0.15;
            tmp.totals.profit += goldToNumber(filteredData[i].profit);
        }

        return tmp;
    }

    tableManager = (data, transactions, full) => {
        let config = {
            className: {
                //table:"table table-primary table-striped table-bordered",
                table: "centerTable table-primary table-striped table-highlight",
                // button:"btn btn-primary",input:"form-control"
            },
            templates: {
                "text": {
                    className: "left",
                },
                "textImg": {
                    contents: (item) => <TextImg item={item}/>,
                    className: "left",
                },
                "number": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "percent": {
                    className: "right",
                    sort: sortGeneral,
                    filter: filterGeneral
                },
                "gold": {
                    className: "right",
                  contents:(item, accessor)=> <GetCurrency number={item[accessor]} size={25} />,
                    sort: sortGeneral,
                    filter: (item, filter) => filterGeneral(item, filter, 10000)
                }
            },
            colsToDisplay:20,
            filter: {active:true},
            sort:{col:"date", desc:true},
            headers: {
                "Details": {
                    className:"left",
                    cols: [
                        { template: "text", header: "Date", accessor: "date" },
                        { template: "textImg", header: "Item", accessor: "name" },
                    ]
                },
                "Filters": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "number", header: "ID", accessor: "id" },
                        { template: "text", header: "Type", accessor: "type" },
                        { template: "text", header: "Sub-Type", accessor: "weaponType" },
                        { template: "text", header: "Rarity", accessor: "rarity" },
                        { template: "number", header: "Level", accessor: "level" },
                        { template: "text", header: "Stat", accessor: "statName" },
                        { template: "text", header: "Charm", accessor: "charm" },
                    ]
                },
                "History": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "number", header: "Transactions", accessor: "transactions" },
                        { template: "number", header: "Quantity", accessor: "quantity" },
                        { template: "gold", header: "Avg Price", accessor: "avgPrice" },
                        { template: "gold", header: "Value", accessor: "totalPrice" },
                    ]
                },
                "Current Buy": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "gold", header: "Price", accessor: "buy" },
                        { template: "gold", header: "Value", accessor: "currentValueBuy" },
                        { template: "gold", header: "Difference", accessor: "differenceValueBuy" },
                    ]
                },
                "Current Sell": {
                    collapse:true,
                    className:"left",
                    cols: [
                        { template: "gold", header: "Price", accessor: "sell" },
                        { template: "gold", header: "Value", accessor: "currentValueSell" },
                        { template: "gold", header: "Difference", accessor: "differenceValueSell" },
                    ]
                },
            },
            collapse:{ "Filters": true },
            headerOrder:["Details","Filters", "History", "Current Buy", "Current Sell"]
        }
        if(transactions){
            if(full){
                config.headers["Details"].cols = [
                    { template: "textImg", header: "Item", accessor: "name" },
                ]
                config.sort.col = "name"
            }
        }else{
            config.headers["History"].cols = [
                { template: "number", header: "Quantity", accessor: "quantity" },
                { template: "gold", header: "Buy", accessor: "buyPriceTotal" },
                { template: "gold", header: "Sell", accessor: "sellPriceTotal" },
                { template: "gold", header: "Taxes", accessor: "profitTax" },
                { template: "gold", header: "Profit", accessor: "profit" },
            ]
            config.headers["Current Buy"].cols = [
                { template: "gold", header: "Value", accessor: "currentValueBuy" },
                { template: "gold", header: "Profit", accessor: "currentProfitBuy" },
            ]
            config.headers["Current Sell"].cols = [
                { template: "gold", header: "Value", accessor: "currentValueSell" },
                { template: "gold", header: "Profit", accessor: "currentProfitSell" },
            ]

            if(full){
                config.headers["Details"].cols = [
                    { template: "textImg", header: "Item", accessor: "name" },
                ]
                config.sort.col = "name"
            }
        }

        if(typeof this.state.templates === "undefined"){
            this.setState({templates:config.templates})
        }

        return this.createTable(data, config)
    }

    createTable = (data, config) => {
        return <SilverTable
          data={data}
          config={config}
          callbackToParent={this.callbackToParent}
        />
    }

    callbackToParent = (result) => {
        let filter = []
        let newFilters = Object.keys(result.filter)
        for (let i = 0; i < newFilters.length; i++) {
            let data = result.colOrder.filter(item => item.accessor === newFilters[i])
            if(data.length === 0){continue}
            let selected = data[0]
            filter.push({
                id: selected.accessor,
                value: result.filter[selected.accessor],
                template: selected.template
            })
        }
        this.setState({ filter: filter })
    }

    generateGraph = (graphData) =>{
        return <div>
            <HighchartsStockChart
              plotOptions={graphOptions.plotOptions}
              styledMode
            >
                <Chart />
                <Legend/>

                <RangeSelector
                  //selected={5}
                >
                    <RangeSelector.Button count={2} type="day">3d</RangeSelector.Button>
                    <RangeSelector.Button count={7} type="day">7d</RangeSelector.Button>
                    <RangeSelector.Button count={1} type="month">1m</RangeSelector.Button>
                    <RangeSelector.Button count={3} type="month">3m</RangeSelector.Button>
                    <RangeSelector.Button count={12} type="month">1y</RangeSelector.Button>
                    <RangeSelector.Button type="all">All</RangeSelector.Button>
                    <RangeSelector.Input boxBorderColor="#7cb5ec" />
                </RangeSelector>

                <Tooltip
                  shared={true}
                  formatter={function () {
                      let s = '<b> '+dateFormat(new Date(this.x),"yyyy-mm-dd ddd")+'</b>';
                      for (let i = 0; i < this.points.length; i++) {
                          let name = this.points[i].series.name;
                          let tmp = '<br/><span style="color:' + this.points[i].color + '">\u25CF</span>' + this.points[i].series.name + ': ' + this.points[i].y.toLocaleString('en-IE', {maximumFractionDigits: 0});
                          if (name === "AvgPrice" || name === "Value" ) {
                              tmp = '<br/><span style="color:' + this.points[i].color + '">\u25CF</span>' + this.points[i].series.name + ': ' + convertToGold(this.points[i].y);
                          }
                          s += tmp;
                      }
                      return s;
                  }}
                />

                <XAxis onSetExtremes={this.getDataFromNav} min={this.state.minDate} max={this.state.maxDate} type={"datetime"}/>

                <YAxis
                  id={"axis_transactions"}
                  labels={{ enabled: false}}
                >
                    <ColumnSeries grouping={false} dataGrouping={{type: 'column', approximation:"average", groupAll:true}} name="Transactions"  id="transactions" data={graphData.transactions} />
                </YAxis>
                <YAxis
                  id={"axis_quantity"}
                  labels={{ enabled: false}}
                >
                    <ColumnSeries grouping={false} dataGrouping={{type: 'column', approximation:"average", groupAll:true}} name="Quantity"  id="quantity" data={graphData.quantity} />
                </YAxis>
                <YAxis
                  id={"axis_avgPrice"}
                  labels={{ enabled: false}}
                  opposite
                >
                    <ColumnSeries grouping={false} dataGrouping={{type: 'column', approximation:"average", groupAll:true}} name="AvgPrice"  id="avgPrice" data={graphData.avgPrice} />
                </YAxis>
                <YAxis
                  id={"axis_value"}
                  labels={{ enabled: false}}
                  opposite
                >
                    <ColumnSeries grouping={false} dataGrouping={{type: 'column', approximation:"average", groupAll:true}} name="Value"  id="value" data={graphData.value} />
                </YAxis>

                <Navigator
                  xAxis={{
                      min: this.state.minDate,
                      max: this.state.maxDate
                  }}
                >
                    <Navigator.Series seriesId="value" />
                    <Navigator.Series seriesId="transactions" />
                    <Navigator.Series seriesId="quantity" />
                    <Navigator.Series seriesId="avgPrice" />
                </Navigator>
            </HighchartsStockChart>
        </div>;

    }

    getDetails = () =>{
        let {accounts_selected, accountMain, account} = this.state;

        let characterDropdown = [];
        let userIDs = Object.keys(accountMain.gameAccounts);
        if(userIDs.length >0){
            characterDropdown.push(<option key={-1} value={accounts_selected[0]} >{accounts_selected[0]}</option>);
            for(let i=0;i<userIDs.length;i++){
                if(userIDs[i] !== accounts_selected[0]){
                    characterDropdown.push(<option key={i} value={userIDs[i]}>{userIDs[i]}</option>)
                }
            }
            characterDropdown.push(<option key={userIDs.length} value="All">All</option>)
        }
        let characterSelect = <select value={accounts_selected[0]} onChange={this.handleCharacterSelection}>{characterDropdown}</select>

        let sinceLastUpdate = secondsToHms((new Date() - new Date(account.lastUpdate))/1000);
        let toNextUpdate = secondsToHms((new Date(account.nextUpdate) - new Date())/1000);

        return {characterSelect, sinceLastUpdate, toNextUpdate }
    }

    getDetailsSecondary = () =>{
        let buySellText, fullCompactText, graphText, modeText, data, tableData, csvData, rawData, totals, flipData, profitText, valueText,csv,history

        if(!this.state.fullCompact) {
            fullCompactText = <span><b>Full</b>/Compact</span>;
        }else{
            fullCompactText = <span>Full/<b>Compact</b></span>;
        }
        if(this.state.mode){
            modeText = <span>Mode: <b>Transactions</b>/Flipping</span>;

            if(!this.state.buySell) {
                buySellText = <span><b>Buy</b>/Sell</span>;
                rawData = this.state.compactedBuy;
            }else{
                buySellText = <span>Buy/<b>Sell</b></span>;
                rawData = this.state.compactedSell;
            }

            data = this.convertToTable(rawData, this.state.itemData,this.state.dateRangeStart,this.state.dateRangeEnd);
            if(!this.state.fullCompact) {
                tableData = data.normal;
                csvData = this.convertToCsv(data.normal,false);
                csv = <CSVLink data={csvData.data} headers={csvData.headers} separator={","}>CSV</CSVLink>;
            }else{
                tableData = data.compact;
                csvData = this.convertToCsv(data.compact,true);
                csv = <CSVLink data={csvData.data} headers={csvData.headers} separator={","}>CSV</CSVLink>;
            }

            totals = this.getTotals(data.normal, this.state.filter, this.state.dateRangeStart, this.state.dateRangeEnd,this.state.buySell);
            valueText = <span><b>Value:</b><GetCurrency number={totals.value} size={25} />. </span>;
        }else{
            modeText = <span>Mode: Transactions/<b>Flipping</b></span>;
            flipData = this.processFlipData();
            totals = flipData.totals

            valueText = <span><b>Value (Buy): </b><GetCurrency number={totals.valueBuy} size={25} />. <b>Value (Sell):</b> <GetCurrency number={totals.valueSell} size={25} />. </span>;
            profitText = <span><b>Profit:</b> <GetCurrency number={totals.profit} size={25} />. <b>Profit %:</b> {(totals.profit/totals.valueBuy).toLocaleString(undefined, {style:"percent",minimumFractionDigits: 2, maximumFractionDigits: 2})}. </span>;

            if(!this.state.fullCompact) {
                tableData = flipData.table
            }else{
                tableData = flipData.tableCompact
            }
        }

        if(this.state.graph) {
            graphText = <span>Graph: <b>On</b>/Off</span>;
        }else{
            graphText = <span>Graph: On/<b>Off</b></span>;
        }

        if(this.state.history){
            // old mode
            history = <span><b>History</b>/Current</span>
        }else{
            // current

            // this does the flipping change
            modeText = undefined
            history = <span>History/<b>Current</b></span>

            let current = this.state.current
            if(!this.state.buySell) {
                rawData = current.buys
            }else{
                rawData = current.sells
            }
            data = this.convertToTable(rawData, this.state.itemData,current.minDate,current.maxDate);

            if(!this.state.fullCompact) {
                tableData = data.normal;
                csvData = this.convertToCsv(data.normal,false);
                csv = <CSVLink data={csvData.data} headers={csvData.headers} separator={","}>CSV</CSVLink>;
            }else{
                tableData = data.compact;
                csvData = this.convertToCsv(data.compact,true);
                csv = <CSVLink data={csvData.data} headers={csvData.headers} separator={","}>CSV</CSVLink>;
            }

            totals = this.getTotals(data.normal, this.state.filter, current.minDate, current.maxDate,current.sells);
            valueText = <span><b>Value:</b><GetCurrency number={totals.value} size={25} />. </span>;
        }

        let days = {};
        days.total = Math.ceil((new Date(this.state.maxDate) - new Date(this.state.minDate)) / 86400000)+1;
        days.selected = Math.ceil((new Date(this.state.dateRangeEnd) - new Date(this.state.dateRangeStart)) / 86400000)+1;


        // allows for easiar adding of stuff in future
        let mainSpanDetails = [
            {field: "history", contents: history},
            {field: "mode", contents: modeText},
            {field: "buySell", contents: buySellText},
            {field: "fullCompact", contents: fullCompactText},
            {field: "graph", contents: graphText},
        ]

        // eslint-disable-next-line
        let mainSpan = <span>{mainSpanDetails.map(rowData => {if(rowData.contents){return <span><span onClick={()=>{this.changeGeneral(rowData.field)}} style={{cursor: "pointer"}}>{rowData.contents}</span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}})}</span>

        return {valueText, totals, profitText,days, csv, tableData,mainSpan }
    }


    render() {
        if(typeof this.state.session === "undefined"){return <Redirect to='/login'  />;}
        if(this.state.compactedSell === "" || this.state.compactedBuy === "" ){return <Loading/>}
        if(typeof this.state.error !== "undefined"){return this.state.error}

        let outputTable, switches, graph, details

        if(typeof this.state.accountMain !== "undefined" && typeof this.state.account !== "undefined" ){
            let {characterSelect, sinceLastUpdate, toNextUpdate } = this.getDetails()
            details = <span><b>Account:</b> {characterSelect}   <b>Last Update:</b> {sinceLastUpdate}   <b>Next Update:</b> {toNextUpdate}</span>
            switches = <div>{details}</div>;
        }

        if(this.state.compactedSell.length !== 0 && this.state.compactedBuy.length !== 0 ) {
            let {valueText, totals, profitText,days, csv, tableData,mainSpan } = this.getDetailsSecondary()
            switches = <div>
                {details}
                <br />
                {valueText} <b>Quantity:</b> {totals.quantity.toLocaleString()}. <b>Taxes:</b> <GetCurrency number={totals.taxes} size={25} />. {profitText} <b>Days (Total):</b> {days.total} <b>Days (Selected):</b> {days.selected}
                <br />
                <br />
                <span>
                    {mainSpan}
                    {csv}
                </span>
                <br />
            </div>

            outputTable = this.tableManager(tableData, this.state.mode, this.state.fullCompact)
        }

        if(this.state.compactedSell.length !== 0 && this.state.compactedBuy.length !== 0 && this.state.graph){
            let graphData;
            if(this.state.buySell) {
                graphData = this.processGraph(this.state.graphSell,this.state.filter,false);
            }
            if(!this.state.buySell) {
                graphData = this.processGraph(this.state.graphBuy,this.state.filter, false);
            }
            if(!this.state.mode){
                let flipData = this.processFlipData();
                graphData = flipData.graph;
            }
            graph = this.generateGraph(graphData)
        }

        return <div style={{align:"center"}}>
            <Documentation url={"https://gitlab.com/Silvers_Gw2/Stats_Frontend/-/wikis/archives#transactions"} />
            <br/>
            {switches}
            {graph}
            {outputTable}
        </div>
    }
}

export const Transaction_Viewer = withHighcharts(TransactionViewer_Component, Highcharts);